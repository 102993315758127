// value 순서 : StatusSpan 컴포넌트와 연결되어 있음
export const LINKAGE_STATUS = {
  FAIL: '연동 실패',
  WAIT: '연동 대기',
  STOP: '연동 중지',
  FINISH: '연동 완료',
  UNAVAILABLE: '연동 불가',
};

export const LINKED_FAIL = 'FAIL';
export const LINKED_UNAVAILABLE = 'UNAVAILABLE';

// 노션 - '판매몰 데이터 연동 실패코드' 문서 참고
export const DATA_INTERLOCK_LINK_ERR_CASE = {
  askInquire: [
    'FRT112',
    'FRT113',
    'FRT114',
    'FRT122',
    'FRT123',
    'FRT131',
    'FRT132',
    'FRT133',
    'FRT141',
    'FRT142',
    'FRT143',
    'FRT168',
    'FRT169',
    'FRT997',
    'FRT998',
    'FRT999',
  ],
  captureRetry: ['FRT151', 'FRT155', 'FRT158'],
  editInfo: ['FRT111'],
  noBtn: [
    'FRT126',
    'FRT127',
    'FRT129',
    'FRT134',
    'FRT157',
    'FRT161',
    'FRT162',
    'FRT163',
    'FRT164',
    'FRT165',
    'FRT167',
  ],
  secondCheckRetry: ['FRT121', 'FRT124', 'FRT125', 'FRT128', 'FRT171'],
};

// 연동 상태별 수정, 삭제, 대출 가능 여부
export const statusActions = {
  FAIL: { modify: true, delete: true, loan: false },
  WAIT: { modify: false, delete: false, loan: false },
  STOP: { modify: true, delete: true, loan: false },
  FINISH: { modify: true, delete: true, loan: true },
  UNAVAILABLE: { modify: true, delete: true, loan: false },
};

// 연동 실패 -> 연동 대기 -> 연동 중지 -> 연동 완료 순으로 보여짐
// FAIL -> WAIT -> STOP -> FINISH
export const statusOrder = (a, b) => {
  if (a.status === 'FAIL' || b.status === 'FAIL') {
    if (a.status === 'FAIL') {
      return -1;
    } else {
      return 1;
    }
  } else if (a.status === 'WAIT' || b.status === 'WAIT') {
    if (a.status === 'WAIT') {
      return -1;
    } else {
      return 1;
    }
  } else if (a.status === 'STOP' || b.status === 'STOP') {
    if (a.status === 'STOP') {
      return -1;
    } else {
      return 1;
    }
  }
  return 1;
};
