import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const ContentBox = styled.div`
  display: block;
  width: 100%;
  padding: 32px 0 0;
`;

export const TextBlue = styled.span`
  color: ${theme.color.blue};
`;

export const FirstText = styled.div`
  display: block;
  width: 100%;
  padding: 41px 0 18px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  text-align: center;
  color: ${theme.color.black};

  @media screen and (max-width: 375px) {
    font-size: 1.4rem;
  }
`;

export const SecondText = styled.div`
  display: block;
  width: 100%;
  padding: 45px 0 0 0;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2.1rem;
  text-align: center;
  color: ${theme.color.black};
`;

export const TableWrapper = styled.table`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const TableHead = styled.thead``;

export const TableHeadRow = styled.tr`
  width: 100%;
`;

export const TableHeadColumn = styled.th`
  padding: 11px 2px;
  line-height: 2.3rem;
  background: ${theme.backgroundColor.icyBlue};
  border-left: 1px solid ${theme.borderColor.white};
  font-weight: 500;
  font-size: 1.3rem;
  text-align: center;
  color: ${theme.color.black};

  &:first-child {
    border-left: none;
  }

  ${props => {
    if (props.colSpan) {
      return css`
        colspan: ${props.colSpan};
      `;
    }
  }}

  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }
`;

export const TableBody = styled.tbody``;

export const TableBodyRow = styled.tr`
  width: 100%;
  margin: 2px 0 0;
  border-top: 1px solid ${theme.borderColor.white};
`;

export const TableBodyColumn = styled.td`
  padding: 11px 2px;
  line-height: 2.3rem;
  background: ${theme.backgroundColor.table};
  border-left: 1px solid ${theme.borderColor.white};
  font-weight: 500;
  font-size: 1.3rem;
  text-align: center;
  vertical-align: top;
  color: ${theme.color.black};

  &:first-child {
    border-left: none;
  }

  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }
`;

export const ConnectDataProviderMessageWrapper = styled.a`
  display: block;
  width: 100%;
  height: 45px;
  margin: 23px 0 0;

  line-height: 4.3rem;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;

  border: 1px solid ${theme.borderColor.blue};
  border-radius: 10px;
  background: ${theme.backgroundColor.blue};
  color: ${theme.color.white};
  cursor: pointer;

  &:disabled {
    background: ${theme.backgroundColor.buttonGrey};
    border: 1px solid ${theme.borderColor.buttonGrey};
    cursor: default;
  }
`;

export const ConnectDataProviderMessage = styled.div`
  display: block;
  width: 100%;
  border-radius: 10px;
  background: ${theme.backgroundColor.contentBox2};
  text-align: center;
  padding: 22px 0 26px;
  margin: 10px 0 28px;
`;

export const ConnectDataProviderMessageSub = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: ${theme.color.blue};
  font-weight: 500;
  line-height: 1;
`;

export const ConnectDataProviderMessageMain = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: ${theme.color.blue};
  font-weight: 700;
  line-height: 1;
  margin: 10px 0 0;
`;

export const ChildText = styled.div`
  width: 100%;
  padding: 28px 0 28px;
`;

export const ChildTextContent = styled.div`
  float: right;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: ${props => (!!props.bold ? '500' : '400')};
  color: ${theme.color.black};

  white-space: pre-line;

  &:not(:first-of-type) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 375px) {
    font-size: 1.4rem;
  }
`;

export const BlueModifySection = styled.div`
  margin: 23px 0;
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-end'};
  width: 100%;
`;

export const BlackModifyText = styled.div`
  font-size: ${theme.fontSize.h4};
  color: ${theme.color.black};

  @media screen and (max-width: 375px) {
    font-size: 1.4rem;
  }
`;

export const BlueModifyText = styled.div`
  cursor: pointer;
  font-size: 1.4rem;
  color: ${theme.color.blue};
  @media screen and (max-width: 375px) {
    font-size: 1.3rem;
  }
`;

export const BackgroundTextBox = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background: ${props =>
    props.backgroundColor
      ? theme.backgroundColor[props.backgroundColor]
      : theme.backgroundColor.contentBox};
  font-color: ${props =>
    props.color ? theme.color[props.color] : theme.color.black};
`;

export const BackgroundTextBoxDiv = styled.div`
  color: ${theme.color.white};
`;

export const BackgroundTextBoxTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  font-weight: ${theme.fontWeight.bold};

  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
  }
`;

export const BackgroundTextBoxContents = styled.div`
  font-size: ${theme.fontSize.h5};
  line-height: 2.3rem;
  margin: 10px 0 0 0;
  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
  }
`;

export const ChildTextRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0 12px;
`;

export const BlueBackgroundBox = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  margin-top: 23px;
  padding: 20px;
  border-radius: 10px;
  background: ${theme.backgroundColor.blue};
  color: ${theme.color.white};
`;

export const SmallText = styled.div`
  font-size: ${theme.fontSize.h5};
  color: ${theme.color.white};
`;

export const BlueBackgroundBoxText = styled.div`
  margin-top: 10px;
  font-size: ${theme.fontSize.h4};
  font-weight: ${theme.fontWeight.normal};
  color: ${theme.color.white};
`;
