import styled from 'styled-components';
import theme from 'styles/theme';
import { AreaRow } from 'styles/styleArea';

export const Footer = styled.footer`
  display: block;
  width: 100%;
  margin: 60px 0 0;
  padding: 0;
  background: ${theme.backgroundColor.white};
`;

export const CSFooterWrapper = styled.div`
  min-height: 0;
  padding: 0;
`;

export const CSTitle = styled.div`
  font-weight: 500;
  font-size: 1.6rem;

  @media screen and (max-width: 375px) {
    font-weight: ${theme.fontWeight.normal};
    font-size: 1.4rem;
  }
`;

export const PhoneAreaRow = styled(AreaRow)`
  padding: 18px 16px;
  border-radius: 10px;
  background: ${theme.backgroundColor.footerBox};

  @media screen and (max-width: 375px) {
    padding: 12px 10px;
  }
`;

export const KaKaoAreaRow = styled(AreaRow)`
  padding: 18px 16px;
  border-radius: 10px;
  background: ${theme.backgroundColor.kakaoTalk};
  @media screen and (max-width: 375px) {
    padding: 10px 8px;
  }
`;

export const PhoneLink = styled.a`
  height: 24px;

  margin-left: 6px;
  text-align: left;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  white-space: nowrap;
  color: ${theme.color.black};

  @media screen and (max-width: 375px) {
    font-size: 1.4rem;
  }
`;

export const KaKaoLink = styled.a`
  height: 24px;

  padding: 0 0 0 38px;
  text-align: left;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  white-space: nowrap;
  color: ${theme.color.black};
  background: url(/images/cacaotalk.png) left no-repeat;
  background-size: 30px 26px;

  @media screen and (max-width: 375px) {
    font-size: 1.4rem;
  }
`;

export const Label = styled.div`
  text-align: left;
  line-height: 2.6rem;
  font-weight: 400;
  font-size: 1.4rem;

  color: ${theme.color.grey5};

  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
    line-height: 2.4rem;
  }
`;

export const UnderlinedText = styled.span`
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
`;
