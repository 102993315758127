import DOMPurify from 'dompurify';
const APP_HOST = process.env.REACT_APP_API_HOST;
const APP_DOMAIN = process.env.REACT_APP_DOMAIN;

export const checkCharCode = str => {
  // str의 마지막 음절의 유니코드(UTF-16)
  const charCode = str.charCodeAt(str.length - 1);
  // 유니코드의 한글 범위 내에서 해당 코드의 받침 확인
  const consonantCode = (charCode - 44032) % 28;
  if (consonantCode === 0) {
    // 0이면 받침 없음
    return `${str}를`;
  }
  // 1 이상이면 받침 있음
  return `${str}을`;
};

export const createMarkup = contents => {
  if (!contents) {
    return;
  }
  if (contents.includes(APP_DOMAIN)) {
    const modifiedContents = contents.replaceAll(
      `href="${APP_DOMAIN}`,
      `href="${APP_HOST}`
    );
    return { __html: DOMPurify.sanitize(modifiedContents) };
  }
  return { __html: DOMPurify.sanitize(contents) };
};

// 쿠키에 저장하는 함수
export const setCookie = (name, value, exp = 30) => {
  let date = new Date();
  // 날짜 생성
  date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000); // 밀리초 단위
  // setTime()은 1970년1월1일부터 경과된 시간을 밀리초로 수정함 (날짜정보를 수정)
  //getTime()은 1970년1월1일부터 경과된 시간을 밀리초로 표기함 (날짜정보를 가져올떄)
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/ `;
  //toUTCString()은 UTC 표준 시간대를 사용하여 지정된 날짜를 나타내는 문자열
};

// Expires 속성: 쿠키의 파기 날짜를 지정하는 속성이다.
// Name 속성과 Value 속성: 데이터를 저장하고 읽는 데 사용하는 속성이기 때문에, 반드시 지정해야 속성이다.
// Path 속성: ' / '로 설정하면 도메인 내의 모든 곳에서 접근할 수 있다. Path 속성은 일부러 지정하는 경우가 많은데 특정 경로(폴더명)를 설정한다. /폴더/ 로 설정하면, 폴더 또는 현재 폴더의 하위에서 접근할 수 있다.

// 키값 기준으로 쿠키에 저장된 값을 가져오는 함수
export const getCookie = name => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }
  return null;
};

// 만료일을 예전으로 설정해 쿠키를 삭제
export const deleteCookie = name => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1999 00:00:10 GMT;';
};

const userAgent = window.navigator.userAgent;

export const isIE = () => {
  return /MSIE|Trident/.test(userAgent);
};

export const isEdge = () => {
  return /Edge\/\d./i.test(userAgent);
};

export const getIEVersion = () => {
  const re = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
  return re.exec(userAgent) != null ? parseFloat(RegExp.$1) : -1;
};

/* 로컬 스토리지 */
export const KEY_SESSION = 'session';
export const KEY_REFRESH = 'refresh';
export const KEY_MEMBERCERTIFICATIONSEQ = 'MCS';
export const KEY_EXPIRETIME = 'ET';
export const KEY_DATAPROVIDETYPE = 'dataProvideType';
export const KEY_DEEPLINKHASH = 'deepLinkHash'; //동적 딥링크:
export const KEY_ACCESSID = 'accessId'; //이전 정적 딥링크:
export const KEY_GOODSHASH = 'goodsHash'; //현재 정적 딥링크:
export const KEY_INTERLOCK_HASH = 'interlockHash'; // 회원가입 딥링크:
export const KEY_DEEPLINK_TYPE = 'deepLinkType';
export const KEY_DATAPROVIDEAGREESEQ = 'dataProvideAgreeSeq';
export const KEY_FO_POPUP_EXPIRED_DATE = 'foPopupExpiredDate'; // 메인 팝업 만료일
export const KEY_INFLOW_CHANNEL = 'inflowChannel';
// 정적 링크로 들어왔을 때
export const KEY_ESSENTIAL_MALLS = 'essentialMalls'; // 금융사 연동 회원가입 - 필수몰
export const KEY_ACCESS_URL = 'accessUrl';
/* 세션 스토리지 */
export const KEY_LOGIN_TRIAL = 'loginTrial';
export const KEY_MEMBER_LOGIN_FAILURE_DT = 'memberLoginFailureDt';

export const getSession = () => localStorage.getItem(KEY_SESSION);
export const getRefresh = () => localStorage.getItem(KEY_REFRESH);
export const getMemberCertificationSeq = () => {
  const expireTime = localStorage.getItem(KEY_EXPIRETIME);
  if (Date.now() > expireTime) {
    return localStorage.removeItem(KEY_MEMBERCERTIFICATIONSEQ);
  } else {
    return localStorage.getItem(KEY_MEMBERCERTIFICATIONSEQ);
  }
};

export const getDeepLinkHash = () => localStorage.getItem(KEY_DEEPLINKHASH);
export const getGoodsHash = () => localStorage.getItem(KEY_GOODSHASH);
export const getAccessId = () => localStorage.getItem(KEY_ACCESSID);

export const getInterlockHash = () => localStorage.getItem(KEY_INTERLOCK_HASH);

export const getAccessUrl = () => localStorage.getItem(KEY_ACCESS_URL);
export const getDeepLinkType = () => localStorage.getItem(KEY_DEEPLINK_TYPE);
export const getDataProvideAgreeSeq = () =>
  localStorage.getItem(KEY_DATAPROVIDEAGREESEQ);
export const getInflowChannel = () => localStorage.getItem(KEY_INFLOW_CHANNEL);

export const getLoginTrial = () => sessionStorage.getItem(KEY_LOGIN_TRIAL);
export const getLoginFailureDt = () =>
  sessionStorage.getItem(KEY_MEMBER_LOGIN_FAILURE_DT);

export const getEssentialMalls = () =>
  JSON.parse(localStorage.getItem(KEY_ESSENTIAL_MALLS));

export const inViewport = element => {
  const domRect = element.getBoundingClientRect();
  return !(domRect.top > window.innerHeight || domRect.bottom < 0);
};
