import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';

const DeepLinkNoBtnModal = ({ alertMessage }) => {
  const navigate = useNavigate();
  const onClose = () => {
    navigate('/');
  };

  return (
    <NoBtnModal showModal={!!alertMessage} onClose={onClose}>
      <ScrollArea>
        <Contents>{alertMessage}</Contents>
      </ScrollArea>
    </NoBtnModal>
  );
};

export default DeepLinkNoBtnModal;
