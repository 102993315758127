import React from 'react';
import { Label } from 'pages/MyPage/MyInform/MemberWithdrawal/MemberWithdrawalPage/style';
import {
  CheckCard,
  CheckCardDiv,
  CheckCardText,
  CheckCardSpan,
  RadioInput,
  IconSpan,
} from './style';

const SelectManagerRow = ({
  businessManagerSeq,
  managerName,
  mobilePhone,
  department,
  position,
  checkStatusList,
  onChangeRadioButton,
}) => {
  return (
    <CheckCard>
      <CheckCardDiv>
        <Label className="check-card">
          <RadioInput
            type="radio"
            className="check-card"
            value={businessManagerSeq}
            checked={
              checkStatusList.find(item => item.isChecked)
                ?.businessManagerSeq === businessManagerSeq
            }
            onChange={onChangeRadioButton}
          />
          {managerName}
          <IconSpan className="notice-check-icon" />
        </Label>
        <CheckCardSpan>{!!mobilePhone && mobilePhone}</CheckCardSpan>
      </CheckCardDiv>
      <CheckCardText>
        {department}
        {department && position && '/'}
        {position}
      </CheckCardText>
    </CheckCard>
  );
};

export default SelectManagerRow;
