import styled from 'styled-components';
import theme from 'styles/theme';

export const LoanCard = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding: 16px 0 17px;
  border-bottom: 1px solid ${theme.borderColor.lightGrey};

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const LoanCardColumn = styled.div`
  position: relative;
  float: right;
  width: 100%;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

export const LoanBankName = styled.div`
  display: block;
  width: 100%;
  margin: 7px 0 0;
  padding: 0 0 14px;
  line-height: 2rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${theme.color.black};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LoanCardRow = styled.div`
  display: block;
  width: 100%;
  margin: 4px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const LoanCardRowSecond = styled.p`
  float: left;
  width: calc(100% - 74px);
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
  color: ${theme.color.black};
`;

export const DetailModalWrap = styled.div`
  display: block;
  width: 100%;
  margin: 10px 0 0;
  padding: 20px 0 0;
  border-radius: 10px;
`;

export const DetailModalTitle = styled.div`
  display: block;
  width: 100%;
  margin: 19px 0 0;
  font-size: 1.3rem;
  font-weight: 400;
  color: ${theme.color.navyBlue};
  text-align: left;
  line-height: 2.1rem;

  &:first-child {
    margin: 0;
  }
`;

export const DetailModalTitleTable = styled.div`
  display: block;
  width: 100%;
  margin: 19px 0 0;
  padding: 0 0 10px 0;
  font-size: 1.3rem;
  font-weight: 400;
  color: ${theme.color.navyBlue};
  text-align: left;
  line-height: 2.1rem;

  &:first-child {
    margin: 0;
  }
`;

export const DetailModalData = styled.div`
  display: block;
  width: 100%;
  margin: 3px 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const DetailModalText = styled.div`
  float: left;
  display: block;
  color: ${theme.color.black};
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  line-height: 2.1rem;
`;

export const ButtonWrap = styled.div`
  display: flex;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const RoundBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  padding: 15px;
  border: 0.5px solid ${theme.borderColor.lightGrey};
  border-radius: 5px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${theme.color.black};
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailButton = styled.button`
  color: ${theme.color.blue};
  width: 155px;
  font-size: 1.6rem;
  margin: 0 0 10px;
  text-align: end;
  font-weight: 600;

  @media screen and (max-width: 320px) {
    font-size: 1.5rem;
  }
`;

export const NoticeText = styled.p`
  color: ${theme.color.red};
  font-size: 1.3rem;
  line-height: 1.6rem;

  @media screen and (max-width: 320px) {
    font-size: 1.2rem;
  }
`;

export const MallNameSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DateSpan = styled.span`
  font-size: 1.3rem;
  min-width: 135px;
`;
