import styled from 'styled-components';
import theme from 'styles/theme';

export const DetailModalTitle = styled.div`
  display: block;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: ${theme.color.black};
  text-align: left;
`;

export const DetailModalWrap = styled.div`
  display: block;
  width: 100%;
  padding: 10px 0 0;
  border-radius: 10px;
`;

export const TextFieldWrap = styled.div`
  display: flex;
`;
