import styled from 'styled-components';
import theme from 'styles/theme';

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 130px;

  @media screen and (max-width: 320px) {
    min-width: 120px;
    padding-left: 5px;
  }
`;

export const Flag = styled.span`
  background-color: rgba(252, 84, 84, 0.22);
  color: #fc5555;
  min-width: 65px;
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.9rem;
  margin: 0 0 12px;
  padding-top: 2px;
  border-radius: 12px;

  @media screen and (max-width: 320px) {
    font-size: 1.2rem;
    min-width: 60px;
  }
`;

export const DetailButton = styled.button`
  color: ${theme.color.blue};
  width: 140px;
  line-height: 2rem;
  font-size: 1.6rem;
  margin: 0 0 10px;
  text-align: end;
  font-weight: 600;

  @media screen and (max-width: 320px) {
    font-size: 1.5rem;
  }
`;

export const NoticeText = styled.p`
  color: ${theme.color.red};
  font-size: 1.3rem;
  line-height: 1.7rem;
  margin-bottom: -10px;
`;

export const MallNameSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DateSpan = styled.span`
  font-size: 1.3rem;
  min-width: 135px;
`;
