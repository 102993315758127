import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useApp from 'hooks/useApp';
import api from 'utils/api';
import Toggle from 'components/Toggle';
import ROUTE_PATH from 'routes/config';
import { getCurrentDate } from 'tools/DateTool';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import IdentificationBox from 'components/IdentificationBox';
import { TwoBtnModal, NoBtnModal, OneBtnModal } from 'components/Modal';
import { MARKETING_TYPE } from './constants';
import { ScrollArea, Contents } from 'components/Modal/style';
import theme from 'styles/theme';
import {
  ContentBox2,
  ContentRow,
  ContentRowTitle,
  ContentTitle,
  ContentData,
  ContentDataText,
  ContentButton,
} from './style';

const MyInform = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useApp();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const { mypage } = ROUTE_PATH;
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isSMSChecked, setIsSMSChecked] = useState(false);
  const [showOneBtnModal, setShowOneBtnModal] = useState(false);
  const [showNoBtnModal, setShowNoBtnModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [businessData, setBusinessData] = useState({
    result: {},
    member: {},
  });
  const { result, member } = businessData;

  const onClickLogout = () => {
    auth.logout();
  };

  const lastPasswordUpdatedText = member?.lastPasswordUpdated
    ? `${member.lastPasswordUpdated} 변경`
    : '';

  const {
    user: { memberSeq },
    memberRoleData,
  } = auth;

  const handleMarketingToggle = e => {
    const { name, checked } = e.target;
    name === 'EMAIL' ? setIsEmailChecked(checked) : setIsSMSChecked(checked);

    !!checked
      ? setModalMessage(
          `${getCurrentDate()} ${
            MARKETING_TYPE[name]
          } 마케팅정보 수신 동의 되었습니다.`
        )
      : setModalMessage(
          `${getCurrentDate()} ${
            MARKETING_TYPE[name]
          } 마케팅정보 수신 동의를 철회하였습니다.`
        );

    const toggleData = {
      marketingType: name,
      agreeYn: !!checked ? 'Y' : 'N',
    };
    sendMarketingStatus({ toggleData });
  };

  const sendMarketingStatus = ({ toggleData }) => {
    api({
      url: '/v1/au/member/marketing',
      method: 'put',
      headers: { 'content-type': 'application/json' },
      data: toggleData,
    })
      .then(res => {
        const { success } = res.data;
        if (success) {
          setShowNoBtnModal(true);
        }
      })
      .catch(err => {
        console.error(err);
        const { data } = err.response;
        setModalMessage(data.message);
        setShowNoBtnModal(true);
      });
  };

  const openModal = message => {
    setModalMessage(message);
    setShowNoBtnModal(true);
  };

  const onClickChangePhone = () => {
    setModalMessage('휴대폰 본인인증하면, 휴대폰번호가 변경돼요.');
    setShowOneBtnModal(true);
  };

  useEffect(() => {
    const getBusinessData = () => {
      api({
        url: `/v1/au/member/${memberSeq}`,
        method: 'get',
        headers: { 'content-type': 'application/json' },
      })
        .then(res => {
          const { success, data } = res.data;
          if (success) {
            setBusinessData(data);
            data.member?.emailReceptionAgreeYn === 'Y'
              ? setIsEmailChecked(true)
              : setIsEmailChecked(false);
            data.member?.smsReceptionAgreeYn === 'Y'
              ? setIsSMSChecked(true)
              : setIsSMSChecked(false);
          } else {
            setBusinessData({
              result: {},
              member: {},
            });
          }
        })
        .catch(err => {
          console.error(err);
        });
    };

    getBusinessData();
  }, [auth, memberSeq]);

  // 본인인증 완료(성공)
  const identificationSuccessCallback = useCallback(memberCertificationSeq => {
    const postData = {
      memberCertificationSeq: memberCertificationSeq,
    };
    changeMobilePhone({
      postData: postData,
      setModalMessage: setModalMessage,
      setShowNoBtnModal: setShowNoBtnModal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 본인인증 완료(decode 실패)
  const identificationExceptCallback = useCallback(err => {
    console.error(err);
    setModalMessage(err?.response?.data?.message);
    setShowNoBtnModal(true);
  }, []);

  const changeMobilePhone = ({
    postData,
    setModalMessage,
    setShowNoBtnModal,
  }) => {
    api({
      url: '/v1/au/member/phone',
      method: 'put',
      headers: { 'content-type': 'application/json' },
      data: postData,
    })
      .then(res => {
        const { success, message } = res.data;
        if (success) {
          navigate(mypage.myInform, { state: { message, step: 2 } });
        } else {
          setModalMessage(message);
          setShowNoBtnModal(true);
        }
      })
      .catch(err => {
        console.error(err);
        const { data } = err.response;
        setModalMessage(data.message);
        setShowNoBtnModal(true);
      });
  };

  // 다른페이지에서 navigate로 넘어왔을때 모달 메세지 띄어준다.
  useEffect(() => {
    const { state } = location;
    if (state) {
      state?.message && openModal(state.message);
      navigate(mypage.myInform, { state: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {result && (
        <ContentBox2>
          <ContentRow>
            <ContentRowTitle>사업자정보</ContentRowTitle>
            {memberRoleData?.representativeYn === 'Y' && (
              <ContentButton
                tabIndex={0}
                className="btn-c-more"
                onClick={() => {
                  navigate(mypage.updateBusiness);
                }}
              >
                사업자정보 변경
              </ContentButton>
            )}
          </ContentRow>
          <ContentTitle>상호명</ContentTitle>
          <ContentData>
            <ContentDataText>{result.businessName}</ContentDataText>
          </ContentData>
          <ContentTitle>사업자번호</ContentTitle>
          <ContentData>
            <ContentDataText>
              {result.businessRegistrationNumber}
            </ContentDataText>
          </ContentData>
          <ContentTitle>설립일</ContentTitle>
          <ContentData>
            <ContentDataText>{result.foundationDate}</ContentDataText>
          </ContentData>
          <ContentTitle>대표자</ContentTitle>
          <ContentData>
            <ContentDataText>{result.representativeName}</ContentDataText>
          </ContentData>
          <ContentTitle>사업자 상태</ContentTitle>
          <ContentData>
            <ContentDataText>{result.businessInfo}</ContentDataText>
          </ContentData>
        </ContentBox2>
      )}

      <ContentBox2>
        <ContentRow>
          <ContentRowTitle>회원정보</ContentRowTitle>
        </ContentRow>
        <ContentTitle>아이디</ContentTitle>
        <ContentData>
          <ContentDataText>{member.memberId}</ContentDataText>
        </ContentData>
        <ContentTitle>비밀번호</ContentTitle>
        <ContentData>
          <ContentDataText>{lastPasswordUpdatedText}</ContentDataText>
          <ContentButton
            tabIndex={0}
            className="btn-c-more2"
            onClick={() => {
              navigate(mypage.updatePassword);
            }}
          >
            변경
          </ContentButton>
        </ContentData>
        <ContentTitle>이메일</ContentTitle>
        <ContentData>
          <ContentDataText>{member.memberEmail}</ContentDataText>
          <ContentButton
            tabIndex={0}
            className="btn-c-more2"
            onClick={() => {
              navigate(mypage.updateEmail);
            }}
          >
            변경
          </ContentButton>
        </ContentData>
        <ContentTitle>휴대폰번호</ContentTitle>
        <ContentData>
          <ContentDataText>{member.mobilePhone}</ContentDataText>
          {/* 휴대폰 본인인증 */}
          <ContentButton
            tabIndex={0}
            className="btn-c-more2"
            onClick={onClickChangePhone}
          >
            변경
          </ContentButton>
        </ContentData>
        <OneBtnModal
          showModal={showOneBtnModal}
          onClose={() => {
            setShowOneBtnModal(false);
          }}
          btnContent={
            <IdentificationBox
              modalButton={true}
              identificationPurposeType="PHONE_CHANGE"
              identificationSuccessCallback={identificationSuccessCallback}
              identificationExceptCallback={identificationExceptCallback}
            />
          }
        >
          <ScrollArea>
            <Contents>{modalMessage}</Contents>
          </ScrollArea>
        </OneBtnModal>
      </ContentBox2>

      <ContentBox2>
        <ContentRow>
          <ContentRowTitle>마케팅정보 수신 동의</ContentRowTitle>
        </ContentRow>
        <Toggle
          toggleTitle="이메일"
          toggleName="EMAIL"
          toggleChecked={isEmailChecked}
          onChangeToggle={handleMarketingToggle}
        />
        <Toggle
          toggleTitle="문자"
          toggleName="SMS"
          toggleChecked={isSMSChecked}
          onChangeToggle={handleMarketingToggle}
        />

        <NoBtnModal
          showModal={showNoBtnModal}
          onClose={() => {
            setShowNoBtnModal(false);
          }}
        >
          <ScrollArea>
            <Contents>{modalMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>
      </ContentBox2>
      <StyledActiveButton tabIndex={0} onClick={() => setShowLogoutModal(true)}>
        로그아웃
      </StyledActiveButton>
      <StyledActiveButton
        tabIndex={0}
        onClick={() => {
          navigate(mypage.withdrawal);
        }}
        style={{
          background: theme.backgroundColor.contentBox,
          color: theme.color.black,
        }}
      >
        회원탈퇴
      </StyledActiveButton>
      <TwoBtnModal
        showModal={showLogoutModal}
        leftButton="아니오"
        rightButton="예"
        onClose={() => {
          setShowLogoutModal(false);
        }}
        onClick={onClickLogout}
      >
        <ScrollArea>
          <Contents>로그아웃 하시겠어요?</Contents>
        </ScrollArea>
      </TwoBtnModal>
    </>
  );
};

export default MyInform;
