import styled from 'styled-components';
import theme from 'styles/theme';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px 0;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3rem;
  text-align: left;
  color: ${theme.color.black};
`;

export const TotalCount = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  font-size: 1.3rem;
  font-weight: 500;
  color: ${theme.color.black};
`;

export const Filter = styled.div`
  display: flex;
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 201px); /** Topbar와 본문 padding 제외한 값*/
  font-size: 1.5rem;
`;

export const EmptyTitle = styled.div`
  margin-top: 24px;
  color: ${theme.color.black};
  font-size: 1.6rem;
  font-weight: 500;
`;

export const FileImage = styled.div`
  width: 122.5px;
  height: 68.25px;
  background-image: url('/images/file_illo.png');
  background-size: contain;
`;

export const Text = styled.p`
  margin-top: 24px;
  color: ${theme.color.grey6};
`;

export const Section = styled.div`
  padding-top: 36px;
  margin-bottom: 20px;

  &:nth-child(1) {
    padding-top: 0px;
  }
`;

export const Title = styled.div`
  margin-bottom: 20px;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: ${theme.color.black};
`;

export const Contents = styled.div`
  margin: 15px 0px;
  width: 100%;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${theme.color.black};
`;

export const RoundBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
  padding: 15px;
  border: 0.5px solid ${theme.borderColor.lightGrey};
  border-radius: 5px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${theme.color.black};
`;

//정산금 정보 테이블
export const TableSection = styled.div`
  height: 400px;
  overflow-x: scroll;
  overflox-y: scroll;
  scrollbar-width: thin;
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${theme.color.black};
`;

export const TableHeader = styled.th`
  padding: 10px;
  background: ${theme.backgroundColor.table};
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  border-top: 1.5px solid ${theme.borderColor.grey5};
  border-bottom: 1.5px solid ${theme.borderColor.lightGrey};
  font-weight: 600;
`;

export const TableRow = styled.tr``;

export const TableData = styled.td`
  padding: 10px;
  white-space: nowrap;
  border-bottom: 1.5px solid ${theme.borderColor.lightGrey};
  text-align: right;
`;
