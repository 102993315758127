import styled from 'styled-components';

export const MarkerWrapper = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-2px);

  width: 15px;
  height: 15px;
  margin: 0 0 0 5px;

  border-radius: 50%;
  background-color: #5e15fa;
`;

export const MarkerSymbol = styled.span`
  padding-top: 1px;
  font-size: 1rem;
  color: #ffffff;
`;
