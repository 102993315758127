import styled from 'styled-components';

export const VideoBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: calc(280px - 40px);
  padding-bottom: 56.25%;
`;

export const GuideStepImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  border-radius: 20px;

  @media screen and (max-width: 375px) {
    border-radius: 15px;
  }
`;

export const PaddingBox = styled.div`
  display: block;
  height: auto;
  padding: 10px;
`;

export const GuideContents = styled.div`
  display: block;
  width: 100%;
  font-size: 1.4rem;
  line-height: 2rem;
  img {
    width: 100%;
  }
`;
