import styled from 'styled-components';
import theme from 'styles/theme';

export const TextMedium = styled.div`
  width: 100%;
  padding: 41px 0 18px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  text-align: center;
  color: ${theme.color.black};
`;

export const TextBlue = styled.span`
  color: ${theme.color.blue};
`;

export const ChildTextContent2 = styled.div`
  display: block;
  width: 100%;
  margin: 24px 0 48px 0;
  line-height: 2.1rem;

  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  color: ${theme.color.black};
`;

export const TextButton = styled.button`
  margin-left: 5px;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: underline;
`;
