export const EVENT_TAB_OPTIONS = [
  {
    label: '진행중 이벤트',
    value: 'open',
    url: '/v1/na/event/open',
  },
  {
    label: '종료된 이벤트',
    value: 'closed',
    url: '/v1/na/event/closed',
  },
];
