import styled from 'styled-components';

export const BackgroundImg = styled.img`
  position: fixed;
  width: 550px;
  height: auto;
  top: 50vh;
  left: calc(50% - 580px);
  transform: translateY(-50%);

  @media screen and (max-width: 1199.9px) {
    display: none;
  }
`;
