import React from 'react';
import { SelectBoxSection, Select, SelectOption } from './style';

const SelectBox = ({
  optionList,
  optionHandleChange,
  onClickSelect = () => {},
  name,
  value,
  isNotDisabled = false,
  margin = false,
  noPadding = false,
  $grayPadding = '',
  ...props
}) => {
  return (
    <SelectBoxSection margin={margin} noPadding={noPadding}>
      <Select
        onClick={onClickSelect}
        options={optionList}
        onChange={optionHandleChange}
        name={name}
        value={value}
        $grayPadding={$grayPadding}
        {...props}
      >
        {optionList?.map((option, index) => (
          <SelectOption
            {...props}
            key={`SelectItems_${option.value}_${index}`}
            value={option.value}
            disabled={!isNotDisabled}
            hidden={option.hidden}
          >
            {option.label}
          </SelectOption>
        ))}
      </Select>
    </SelectBoxSection>
  );
};

export default SelectBox;
