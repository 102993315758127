import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import qs from 'qs';
import dayjs from 'dayjs';
import useApp from 'hooks/useApp';
import ROUTE_PATH from 'routes/config';
import { createDataRequest, getDataRequest } from 'utils/request';
import { createMarkup } from 'tools/WebTool';
import { formatStringDate } from 'tools/DateTool';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import ShareBottomPopup from 'components/Popup/ShareBottomPopup';
import { OneBtnModal, TwoBtnModal } from 'components/Modal';
import EventQuestion from './EventQuestion';
import EventStipulation from './EventStipulation';
import ShareButton from './ShareButton';
import { EVENT_TARGET_CD, RESULT_CONTENTS_LIST } from './constants';
import { Contents, ScrollArea } from 'components/Modal/style';
import { StyledReactQuill } from 'styles/StyledReactQuill';
import {
  EmptyContent,
  EmptyTitle,
  FileImage,
  Text as EmptyText,
} from '../EventPage/EventList/style';
import {
  BlackLabel,
  BlueLabel,
  DefaultEventDetailSection,
  Description,
  EditorBox,
  EventDetailWrapper,
  EventFooterWrapper,
  EventImageBox,
  EventQuestionSection,
  EventResultSection,
  Subject,
  SubjectWrapper,
  Text,
  TextBox,
} from './style';

const EventDetailPage = () => {
  const params = useParams();
  const { auth } = useApp();
  const navigate = useNavigate();
  const [eventData, setEventData] = useState({});
  const { result = {}, questionList = [] } = eventData;
  const [postData, setPostData] = useState({});
  const [isOneBtnModalOpen, setIsOneBtnModalOpen] = useState(false);
  const [isTwoBtnModalOpen, setIsTwoBtnModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isRequestLoading, setIsRequestLoading] = useState(true);
  const [isFirstTouched, setIsFirstTouched] = useState(true);
  const [showShareBottomPopup, setShowShareBottomPopup] = useState(false);
  const [stipulationList, setStipulationList] = useState([]);

  // result 데이터 가공
  result.eventPeriod =
    !!result.beginDt || !!result.endDt
      ? formatStringDate(result.beginDt, 'time') +
        ' ~ ' +
        formatStringDate(result.endDt, 'time')
      : '';
  result.resultPresentationDate = !!result.resultPresentationContents
    ? result.resultPresentationContents
    : formatStringDate(result.resultPresentationDate, 'dot');

  const resultContentsList =
    result.resultPresentationYn === 'Y'
      ? RESULT_CONTENTS_LIST
      : RESULT_CONTENTS_LIST.filter(
          item => item.value !== 'resultPresentationDate'
        );

  useEffect(() => {
    if (!params.seq) {
      return;
    }
    getDataRequest({
      url: `/v1/na/event/${params.seq}`,
      setData: setEventData,
      successFnc: data => setStipulationList(data.stipulationList),
      errorFnc: (data, message) => {
        setEventData({ result: { endDt: data.endDt } });
      },
      exceptFnc: () => navigate(ROUTE_PATH.event),
    });

    setPostData({
      eventSeq: Number(params.seq),
      answerList: [],
      agreedEventStipulationMappingSeqList: [],
      disagreedEventStipulationMappingSeqList: [],
    });
  }, [params.seq]);

  useEffect(() => {
    if (isOneBtnModalOpen === false && isRequestLoading === false) {
      window.location.reload();
    }
  }, [isOneBtnModalOpen, isRequestLoading]);

  const onClickRequestBtn = event => {
    setIsFirstTouched(true);
    checkUserLogin(event, 'login');
    const resultValidation = validateCreateEvent({
      params: postData,
      questionList,
      stipulationList,
    });

    if (!auth.authenticated) {
      return;
    }

    if (resultValidation.success === false) {
      setModalMessage(resultValidation.message);
      setIsOneBtnModalOpen(true);
      return;
    }

    createDataRequest({
      url: '/v1/au/event',
      data: postData,
      setModalMessage,
      setShowModal: setIsOneBtnModalOpen,
      setShowAlertModal: setIsOneBtnModalOpen,
      successFnc: () => {
        setIsRequestLoading(false);
      },
    });
  };

  const checkUserLogin = event => {
    if (
      !auth.authenticated &&
      (isFirstTouched || event.target.name === 'requestEvent')
    ) {
      setModalMessage('로그인 후 이벤트 신청 가능합니다. 로그인 하시겠습니까?');
      setIsTwoBtnModalOpen(true);
      event.target.value = '';
      event.target.checked = false;
      setIsFirstTouched(false);
    }
  };

  const validateCreateEvent = ({ params, questionList, stipulationList }) => {
    const result = {
      success: true,
      message: '',
    };

    if (
      params.disagreedEventStipulationMappingSeqList.some(
        seq =>
          stipulationList.find(item => item.eventStipulationMappingSeq === seq)
            .essentialYn === 'Y'
      )
    ) {
      result.success = false;
      result.message = '필수 약관에 동의해 주세요.';
      return result;
    }

    const answeredEventQuestionItemSeqList = params.answerList.map(
      ({ eventQuestionItemSeq }) => eventQuestionItemSeq
    );
    const isAllQuestionAnswered = questionList.every(item =>
      item.itemList
        .map(({ eventQuestionItemSeq }) => eventQuestionItemSeq)
        .some(seq => answeredEventQuestionItemSeqList.includes(seq))
    );
    if (!isAllQuestionAnswered) {
      result.success = false;
      result.message = '답변을 입력해 주세요.';
      return result;
    }

    return result;
  };

  const resetModal = () => {
    setModalMessage('');
    setIsOneBtnModalOpen(false);
    setIsTwoBtnModalOpen(false);
  };

  const onClickLoginBtn = e => {
    const search =
      '?' +
      qs.stringify({
        'redirect-to': ROUTE_PATH.eventDetailDefault + '/' + params.seq,
      });

    navigate(ROUTE_PATH.login.login + search);
  };

  const isAgreeBtnDisabled = stipulationList
    .filter(item => item.required === true)
    .some(item => item.checked === false);

  if (!result.endDt) {
    <EventDetailWrapper />;
  }

  // 종료된 이벤트일 시
  if (dayjs() > dayjs(result.endDt)) {
    const onClickBtn = () => {
      navigate(ROUTE_PATH.event);
    };

    return (
      <EventDetailWrapper>
        <EmptyContent>
          <FileImage />
          <EmptyTitle>종료된 이벤트 입니다.</EmptyTitle>
          <EmptyText>다른 이벤트를 확인해 주세요.</EmptyText>
        </EmptyContent>

        <DefaultEventDetailSection>
          <StyledActiveButton
            tabIndex={0}
            disabled={false}
            onClick={onClickBtn}
          >
            진행중인 이벤트 보기
          </StyledActiveButton>
        </DefaultEventDetailSection>
      </EventDetailWrapper>
    );
  }

  return (
    !!result.eventSeq && (
      <EventDetailWrapper paddingBottom={!result.caution}>
        {result.imageFilePath && (
          <EventImageBox>
            <img
              src={result.imageFilePath}
              width="100%"
              alt="event_thumbnail"
            />
          </EventImageBox>
        )}
        <SubjectWrapper>
          <Subject>{result.subject}</Subject>
          <Description>{result.principalDesc}</Description>
        </SubjectWrapper>
        <EventResultSection>
          {resultContentsList.map((item, index) => {
            return (
              <TextBox key={`result_contents_${item.value}_${index}`}>
                <BlueLabel>{item.label}</BlueLabel>
                {item.type === 'editor' ? (
                  <StyledReactQuill
                    value={result[item.value]}
                    readOnly={true}
                  />
                ) : (
                  <Text>{result[item.value]}</Text>
                )}
              </TextBox>
            );
          })}

          <EventQuestionSection>
            <ShareButton setShowShareBottomPopup={setShowShareBottomPopup} />
          </EventQuestionSection>
        </EventResultSection>
        {result.eventTypeCd ===
          EVENT_TARGET_CD.find(item => item.name === '참여이벤트').code &&
          questionList?.length > 0 && (
            <EventQuestionSection>
              <EventQuestion
                questionList={questionList}
                setPostData={setPostData}
                checkUserLogin={checkUserLogin}
              />
            </EventQuestionSection>
          )}
        {result.stipulationExposureYn === 'Y' && stipulationList?.length > 0 && (
          <DefaultEventDetailSection>
            <EventStipulation
              stipulationList={stipulationList}
              setStipulationList={setStipulationList}
              setPostData={setPostData}
            />
          </DefaultEventDetailSection>
        )}
        {result.buttonExposureYn === 'Y' && (
          <DefaultEventDetailSection>
            <StyledActiveButton
              tabIndex={0}
              disabled={isAgreeBtnDisabled}
              name="requestEvent"
              onClick={onClickRequestBtn}
            >
              {result.buttonName || ''}
            </StyledActiveButton>
          </DefaultEventDetailSection>
        )}
        {!!result.caution && (
          <EventFooterWrapper>
            <BlackLabel>이벤트 유의사항</BlackLabel>
            <EditorBox
              dangerouslySetInnerHTML={createMarkup(result.caution || '')}
            />
          </EventFooterWrapper>
        )}
        {/* 모달 */}
        <OneBtnModal
          showModal={isOneBtnModalOpen}
          onClose={resetModal}
          onClick={resetModal}
          btnContent="확인"
        >
          <ScrollArea>
            <Contents style={{ marginTop: '15px' }}>{modalMessage}</Contents>
          </ScrollArea>
        </OneBtnModal>
        {/*로그인 권유 모달*/}
        <TwoBtnModal
          showModal={isTwoBtnModalOpen}
          onClose={resetModal}
          leftButton="취소"
          rightButton="확인"
          onClick={onClickLoginBtn}
        >
          <ScrollArea>
            <Contents>{modalMessage}</Contents>
          </ScrollArea>
        </TwoBtnModal>

        {/*하단 공유하기 팝업*/}
        <ShareBottomPopup
          showModal={showShareBottomPopup}
          onClose={() => setShowShareBottomPopup(false)}
          eventTitle={result.subject}
          eventDesc={result.principalDesc}
        />
      </EventDetailWrapper>
    )
  );
};

export default EventDetailPage;
