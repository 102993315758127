import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import qs from 'qs';
import useApp from 'hooks/useApp';
import ROUTE_PATH from 'routes/config';
import { getDataRequest } from 'utils/request';
import {
  getDeepLinkHash,
  KEY_ACCESSID,
  KEY_DEEPLINKHASH,
  KEY_INFLOW_CHANNEL,
  KEY_ACCESS_URL,
  KEY_DATAPROVIDEAGREESEQ,
  createMarkup,
  getGoodsHash,
  KEY_GOODSHASH,
} from 'tools/WebTool';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import StyledLinkButton from 'components/Button/StyledLinkButton';
import FoldedCard from 'components/FoldedCard';
import { TwoBtnModal } from 'components/Modal';
import DeepLinkNoBtnModal from '../DeepLinkNoBtnModal';
import { CONTENTS_BY_BTN, DEEPLINK_TYPE } from '../constants';
import { Card, CardName } from 'components/FoldedCard/style';
import { Contents, ScrollArea } from 'components/Modal/style';
import { PreviewSection } from 'styles/styleArea';
import {
  BlueBox,
  BlueBoxContents,
  BlueBoxTitle,
} from 'components/BlueAlertBox/style';
import { ChildTextContent2, TextButton } from './style';

//현재 동의 진입 페이지
const DeepLinkAgreeEntryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { auth } = useApp();
  const { login, deepLink } = ROUTE_PATH;
  const [searchParams, setSearchParams] = useSearchParams();
  const [financeInstitutionName, setFinanceInstitutionName] = useState({});
  const [deepLinkContentsData, setDeepLinkContentsData] = useState({});
  const [guideList, setGuideList] = useState([]);
  const [showTwoBtnModal, setShowTwoBtnModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const deepLinkHash = getDeepLinkHash();
  // 동적 예시: /data-provide-agree?deepLinkHash=${deepLinkHash}
  const goodsHash = getGoodsHash();
  // 정적 예시: /data-provide-agree?goodsHash=${goodsHash}

  useEffect(() => {
    if (auth.authenticated) {
      auth.logout({ moveToHome: false });
      alert(
        '데이터 제공 동의를 위해 기존 계정이 로그아웃되었습니다.\n다시 로그인을 진행해 주세요.'
      );
    }
    if (!!location.search && !!searchParams.get('deepLinkHash')) {
      localStorage.setItem(KEY_DEEPLINKHASH, searchParams.get('deepLinkHash'));
      //방어코드
      localStorage.removeItem(KEY_ACCESSID);
      localStorage.removeItem(KEY_GOODSHASH);
      localStorage.removeItem(KEY_ACCESS_URL);
      localStorage.removeItem(KEY_DATAPROVIDEAGREESEQ);
    }
    // 정적
    if (!!location.search && !!searchParams.get('goodsHash')) {
      localStorage.setItem(KEY_GOODSHASH, searchParams.get('goodsHash'));
      //방어코드
      localStorage.removeItem(KEY_DEEPLINKHASH);
    }
    if (
      !!location.search &&
      !searchParams.get('deepLinkHash') &&
      !searchParams.get('goodsHash')
    ) {
      navigate('/');
    }
  }, [location, params, searchParams, auth]);

  //동적일 경우
  useEffect(() => {
    if (!!deepLinkHash) {
      const deepLinkContentsSuccessFnc = data => {
        localStorage.setItem(KEY_INFLOW_CHANNEL, data?.interlockKey);
        localStorage.setItem(
          KEY_DATAPROVIDEAGREESEQ,
          data?.dataProvideAgreeSeq
        );
        setFinanceInstitutionName(data?.financeInstitutionName);
        setDeepLinkContentsData(data?.deeplinkList);
        setGuideList(data?.deeplinkList.guidanceResultList);
      };
      getDataRequest({
        url: `/v1/na/data-provide-agree/dynamic/deeplink-contents/${deepLinkHash}`,
        successFnc: deepLinkContentsSuccessFnc,
        setModalMessage: setAlertMessage,
      });
    }
  }, [deepLinkHash]);

  //정적일 경우
  useEffect(() => {
    if (!!goodsHash) {
      const deepLinkContentsSuccessFnc = data => {
        localStorage.setItem(KEY_INFLOW_CHANNEL, data?.interlockKey);
        localStorage.setItem(KEY_ACCESS_URL, window.location.href);
        setFinanceInstitutionName(data?.financeInstitutionName);
        setDeepLinkContentsData(data?.deeplinkList);
        setGuideList(data?.deeplinkList?.guidanceResultList);
      };
      getDataRequest({
        url: `/v1/na/data-provide-agree/static/deeplink-contents/${goodsHash}`,
        successFnc: deepLinkContentsSuccessFnc,
        setModalMessage: setAlertMessage,
      });
    }
  }, [goodsHash]);

  const onClickDetailBtn = seq => {
    setGuideList(list =>
      list.map((item, index) =>
        index === seq
          ? { ...item, isOpen: !item.isOpen }
          : { ...item, isOpen: false }
      )
    );
  };

  const onClickJoinMemberBtn = () => {
    navigate(login.joinMember);
  };

  const onClickCustomBtn = () => {
    window.open(deepLinkContentsData.customButtonUrl, '_blank');
  };

  const renderTwoBtnModal = () => {
    return (
      <TwoBtnModal
        showModal={showTwoBtnModal}
        onClose={() => setShowTwoBtnModal(false)}
        onClick={onClickJoinMemberBtn}
        leftButton="닫기"
        rightButton="회원 가입하기"
      >
        <ScrollArea>
          <Contents textAlign="left">{`${financeInstitutionName} 고객님,
          ${financeInstitutionName}의 대출 신청을 위한
          데이터 제공동의는 아래 요건이 완료되어야 가능합니다.\n
          1. 사업자 회원가입
          2. 판매몰의 데이터 연동 완료\n
          위 내용을 완료 후 ${financeInstitutionName}에 다시 한번 문의 부탁드립니다.
          `}</Contents>
        </ScrollArea>
      </TwoBtnModal>
    );
  };

  if (!!alertMessage) {
    return (
      <DeepLinkNoBtnModal
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
      />
    );
  }

  const contentsByBtn =
    CONTENTS_BY_BTN[deepLinkContentsData.buttonSectionCd] || {};

  const onClickBtn = btnName => {
    if (btnName === '로그인') {
      const search =
        '?' +
        qs.stringify({
          'redirect-to': deepLink.agree,
        });

      navigate(login.login + search);
    } else if (btnName === '회원가입' && !!financeInstitutionName) {
      if (!!deepLinkHash) {
        setShowTwoBtnModal(true);
      } else {
        onClickJoinMemberBtn();
      }
    } else {
      return;
    }
  };

  // 2. 딥링크 안내 페이지 (담보)
  return (
    !!deepLinkContentsData && (
      <div>
        {/* 컨텐츠 1번 영역 */}
        <div style={{ marginTop: '20px' }}>
          <PreviewSection
            dangerouslySetInnerHTML={createMarkup(
              deepLinkContentsData.deeplinkContents
            )}
          />
        </div>
        {/* 컨텐츠 2번 영역 : 추가 안내*/}
        {deepLinkContentsData.additionGuidanceExposureYn === 'Y' && (
          <BlueBox>
            <BlueBoxTitle>추가 안내</BlueBoxTitle>
            <BlueBoxContents>
              <PreviewSection
                dangerouslySetInnerHTML={createMarkup(
                  deepLinkContentsData.additionGuidanceContents
                )}
              />
            </BlueBoxContents>
          </BlueBox>
        )}

        {/* 버튼 영역*/}
        <div>
          <StyledActiveButton
            tabIndex={0}
            onClick={() => onClickBtn(contentsByBtn.blueBtnName)}
          >
            {contentsByBtn.blueBtnName}
          </StyledActiveButton>
          {deepLinkContentsData.customButtonExposureYn === 'Y' && (
            <StyledLinkButton onClick={onClickCustomBtn}>
              {deepLinkContentsData.customButtonName}
            </StyledLinkButton>
          )}
          <ChildTextContent2>
            {contentsByBtn.textQuestion}
            <TextButton
              tabIndex={0}
              title={contentsByBtn.textBtnName}
              onClick={() => onClickBtn(contentsByBtn.textBtnName)}
            >
              {contentsByBtn.textBtnName}
            </TextButton>
          </ChildTextContent2>
        </div>

        {/* 그 외 안내 사항(아코디언) */}
        {guideList && guideList.length > 0 && (
          <div>
            <Card>
              <CardName style={{ fontWeight: 500 }}>주요 안내 사항</CardName>
            </Card>
            {guideList.map((item, index) => {
              return (
                <FoldedCard
                  key={`FoldedCard_${index}`}
                  index={index}
                  borderTop={false}
                  isOpen={item.isOpen}
                  title={item.subject}
                  contents={item.contents}
                  onClickDetailBtn={onClickDetailBtn}
                />
              );
            })}
            {renderTwoBtnModal()}
          </div>
        )}
      </div>
    )
  );
};

export default DeepLinkAgreeEntryPage;
