import React, { useCallback, useEffect, useState } from 'react';
import ROUTE_PATH from 'routes/config';
import api from 'utils/api';
import { createDataRequest, getDataRequest } from 'utils/request';
import useApp from 'hooks/useApp';
import { validateEmail, validatePassword } from 'tools/ValidationTool';
import {
  getEssentialMalls,
  getAccessUrl,
  getGoodsHash,
  getInflowChannel,
  KEY_INFLOW_CHANNEL,
  KEY_DATAPROVIDEAGREESEQ,
} from 'tools/WebTool';
import { checkKor, formatBizRegistrationNumber } from 'tools/StringTool';
import IdentificationBox from 'components/IdentificationBox';
import TextField from 'components/TextField';
import { NoBtnModal } from 'components/Modal';
import GuideBox from 'components/GuideBox';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { alertMessages } from 'messages/alertMessages';
import JoinMemberStep3 from '../JoinMemberStep3';
import PolicyAgreeRow from './PolicyAgreeRow';
import { Contents, ScrollArea } from 'components/Modal/style';
import { TextBoxContents, TextBoxTitle } from 'pages/MyPage/LoanRequest/style';
import {
  BusinessTypeInputRadioEnd,
  BusinessTypeInputRadioStart,
  BusinessTypeInputWrapper,
  BusinessTypeLabel,
  BusinessTypeWrapper,
  ContentWrapper,
  Line,
  PolicyAgreeAllCheckbox,
  PolicyAgreeAllCheckboxIcon,
  PolicyAgreeAllCheckboxLabel,
  PolicyAgreeAllWrapper,
  TextMedium,
  TitleName,
} from './style';

const JoinMemberStep2 = ({
  memberName = '',
  certificationYn = '',
  businessRegistrationNumber = '',
  foundationDate = '',
  businessName = '',
  businessInfo = '',
  businessSectionType = '',
  businessManagerHash = '',
}) => {
  const { auth } = useApp();
  const { login } = ROUTE_PATH;

  //input data
  const [sellerInfo, setSellerInfo] = useState({
    id: '',
    newPassword: '',
    email: '',
  });
  const [messages, setMessages] = useState({
    id: '',
    newPassword: '',
    email: '',
  });

  //중복 체크 data
  const [isDuplicationChecked, setIsDuplicationChecked] = useState(false);
  const [checkedIdList, setCheckedIdList] = useState([]);

  //약관 data
  const [_policies, _setPolicies] = useState([]);
  const [essentialPolicies, setEssentialPolicies] = useState([]);
  const [choicePolicies, setChoicePolicies] = useState([]);
  const [agreedPolicies, setAgreedPolicies] = useState([]);

  //회원가입 버튼
  const [isAbleIdentification, setIsAbleIdentification] = useState(false);

  //alert modal data
  const [showTwoBtnModal, setShowTwoBtnModal] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState('');
  const [showModalAlertMessage, setShowModalAlertMessage] = useState(false);
  const [goodsData, setGoodsData] = useState({});

  // 정적 딥링크 dataProviderAgreeSeq 생성에 사용됨
  const inflowChannel = getInflowChannel();
  const goodsHash = getGoodsHash();
  const accessUrl = getAccessUrl();
  const essentialMalls = getEssentialMalls();

  //input onChange 함수
  const onChange = e => {
    const validateType = 'change';
    const { name, value } = e.target;

    const trimValue = value.trim();
    setSellerInfo(prevState => ({ ...prevState, [name]: trimValue }));

    if (name === 'id') {
      const isAlreadyChecked = checkedIdList?.includes(trimValue);

      setIsDuplicationChecked(isAlreadyChecked);
      setMessages(prevState => ({
        ...prevState,
        id: '',
      }));
    }
  };

  //중복 체크
  const checkIdDuplication = () => {
    const params = { memberId: sellerInfo.id };
    if (!!sellerInfo.id) {
      const successFnc = () => {
        setIsDuplicationChecked(true);
        setCheckedIdList(prev => [...prev, sellerInfo.id]);
        setShowModalAlertMessage(true);
        setModalAlertMessage('사용 가능한 아이디입니다.');
        setMessages(prevState => ({
          ...prevState,
          id: '',
        }));
      };
      getDataRequest({
        url: `/v1/na/member/duplicate-id`,
        params,
        successFnc,
        setModalMessage: setModalAlertMessage,
        setShowAlertModal: setShowModalAlertMessage,
      });
    }
  };

  //커서 out
  const onBlur = e => {
    const validateType = 'blur';
    const { name, value } = e.target;
    if (name === 'id') {
      const isAlreadyChecked = checkedIdList?.includes(value);
      setIsDuplicationChecked(isAlreadyChecked);
      const resultValidate = _validateId({
        id: sellerInfo.id,
        isDuplicationChecked: isAlreadyChecked,
      });
      setMessages(prevState => ({
        ...prevState,
        id: resultValidate.message,
      }));
    } else if (name === 'newPassword') {
      const resultValidate = _validateNewPw({
        validateType: validateType,
        pw: value,
      });
      setMessages(prevState => ({
        ...prevState,
        [name]: resultValidate.message,
      }));
    } else if (name === 'email') {
      const resultValidate = _validateEmail({
        validateType: validateType,
        email: value,
      });
      setMessages(prevState => ({
        ...prevState,
        [name]: resultValidate.message,
      }));
    }
  };

  //약관
  const getPolicies = useCallback(() => {
    api({
      url: `/v1/na/stipulation?stipulationClassificationCd=SCC100`,
      method: 'get',
      headers: { 'content-type': 'application/json' },
    })
      .then(res => {
        const { success, data } = res.data;
        if (success) {
          if (data?.result?.length > 0) {
            _setPolicies(data.result);
          }
        } else {
          setModalAlertMessage(
            '오류가 발생하였습니다.\n관리자에게 문의하세요.'
          );
          setShowModalAlertMessage(true);
        }
      })
      .catch(err => {
        const { data } = err.response;
        setModalAlertMessage(data?.message);
        setShowModalAlertMessage(true);
      });
  }, []);

  useEffect(() => {
    getPolicies();
  }, [getPolicies]);

  useEffect(() => {
    setEssentialPolicies(_policies?.filter(item => item.essentialYn === 'Y'));
    setChoicePolicies(_policies?.filter(item => item.essentialYn !== 'Y'));
  }, [_policies]);

  const onChangePolicyAgreeAll = event => {
    if (event.target.checked) {
      setAgreedPolicies(
        essentialPolicies
          .concat(choicePolicies)
          .map(item => item.stipulationSeq)
      );
    } else {
      setAgreedPolicies([]);
    }
  };

  const openPopupPolicy = stipulationSeq => {
    window.open(`${login.joinMemberPolicyDefault}/${stipulationSeq}`, '_blank');
  };

  const togglePolicyAgree = stipulationSeq => {
    setAgreedPolicies(prev => {
      if (!prev.includes(stipulationSeq)) {
        return [...prev, stipulationSeq];
      } else {
        return prev.filter(item => item !== stipulationSeq);
      }
    });
  };

  //회원가입 버튼 disabled 값 변경
  useEffect(() => {
    if (
      _validateId({
        id: sellerInfo.id,
        isDuplicationChecked,
      }).success &&
      _validateNewPw({
        pw: sellerInfo.newPassword,
      }).success &&
      _validateEmail({
        email: sellerInfo.email,
      }).success &&
      // agreed
      essentialPolicies.filter(
        item => !agreedPolicies.includes(item.stipulationSeq)
      ).length === 0
    ) {
      setIsAbleIdentification(true);
    } else {
      setIsAbleIdentification(false);
    }
  }, [sellerInfo, essentialPolicies, agreedPolicies, isDuplicationChecked]);

  //회원가입
  const addMember = useCallback(
    ({ memberCertificationSeq }) => {
      const data = {
        memberId: sellerInfo.id,
        memberPassword: sellerInfo.newPassword,
        memberName,
        memberEmail: sellerInfo.email,
        memberCertificationSeq,
        inflowChannelValue: inflowChannel,
        agreedStipulationSeq: agreedPolicies,
        disagreedStipulationSeq: essentialPolicies
          .concat(choicePolicies)
          .filter(item => !agreedPolicies.includes(item.stipulationSeq))
          .map(item => item.stipulationSeq),
        certificationYn,
        businessRegistrationNumber,
        foundationDate,
        businessName,
        businessInfo,
        businessSection: businessSectionType,
        businessManagerHash,
      };
      api({
        url: `/v1/na/member?`,
        method: 'post',
        headers: { 'content-type': 'application/json' },
        data: data,
      })
        .then(res => {
          const { data } = res;
          if (data.success) {
            localStorage.removeItem(KEY_INFLOW_CHANNEL);
            // 회원 가입 성공 시, 자동 로그인
            auth.login({
              params: {
                memberId: sellerInfo.id,
                password: sellerInfo.newPassword,
              },
              successCallback: () => {
                if (!!goodsHash && !!accessUrl) {
                  const createDataProvideAgreeSuccessFnc = (
                    goodsData,
                    message
                  ) => {
                    localStorage.setItem(
                      KEY_DATAPROVIDEAGREESEQ,
                      goodsData?.dataProvideAgreeSeq
                    );
                    setGoodsData({ ...goodsData, message });
                    setShowTwoBtnModal(true); // 회원가입 성공 모달 노출
                  };

                  createDataRequest({
                    url: 'v1/au/data-provide-agree/create',
                    data: {
                      goodsHash: goodsHash,
                      deepLink: accessUrl,
                      // 현재는 정적 링크에서 필수몰을 받을 수 없기에 무조건 빈 리스트를 전송함
                      // 필수몰을 받는 케이스가 발생할 경우 essentialMalls 사용해야 함
                      malls: essentialMalls ?? [],
                    },
                    successFnc: createDataProvideAgreeSuccessFnc,
                    errorFnc: err => {
                      console.error(err);
                      setShowTwoBtnModal(true); // 회원가입 성공 모달 노출
                    },
                    serverErrorFnc: err => {
                      console.error(err);
                      setShowTwoBtnModal(true); // 회원가입 성공 모달 노출
                    },
                  });
                } else {
                  setShowTwoBtnModal(true); // 회원가입 성공 모달 노출
                }
              },
            });
          } else {
            setModalAlertMessage(data.message);
            setShowModalAlertMessage(true);
          }
        })
        .catch(err => {
          setModalAlertMessage(err?.response?.data?.message);
          setShowModalAlertMessage(true);
        });
    },
    [
      sellerInfo,
      memberName,
      certificationYn,
      businessRegistrationNumber,
      foundationDate,
      businessName,
      businessInfo,
      businessSectionType,
      agreedPolicies,
      essentialPolicies,
      choicePolicies,
      auth,
    ]
  );

  // 본인인증 완료(성공)
  const identificationSuccessCallback = useCallback(
    memberCertificationSeq => {
      addMember({ memberCertificationSeq: memberCertificationSeq });
    },
    [addMember]
  );

  // 본인인증 완료(decode 실패)
  const identificationExceptCallback = useCallback(err => {
    console.error(err);
    setModalAlertMessage(err?.response?.data?.message);
    setShowModalAlertMessage(true);
  }, []);

  const isOthersAlreadyPassed =
    messages.id === alertMessages.validateIdDuplication;

  const renderNoBtnModal = () => {
    return (
      <NoBtnModal
        showModal={showModalAlertMessage}
        onClose={() => {
          setShowModalAlertMessage(false);
          setModalAlertMessage('');
        }}
      >
        <ScrollArea>
          <Contents>{modalAlertMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    );
  };

  return (
    <div>
      {businessManagerHash ? (
        <>
          <TextMedium>
            <TitleName>{businessName}</TitleName>
            <br />
            사업자 직원으로 초대되었습니다.
            <br />
            회원가입을 진행해 주세요.
          </TextMedium>

          <TextBoxTitle>사업자 번호</TextBoxTitle>
          <TextBoxContents>
            {formatBizRegistrationNumber(businessRegistrationNumber)}
          </TextBoxContents>
        </>
      ) : (
        <>
          <TextMedium>
            <TitleName>{businessName}</TitleName>
            님
            <br />
            사업자 정보 인증이 완료되었어요.
            <br />
            회원가입을 진행해 주세요.
          </TextMedium>
          <BusinessTypeWrapper>
            <BusinessTypeLabel>사업자 구분</BusinessTypeLabel>
            <BusinessTypeInputWrapper>
              <BusinessTypeInputRadioStart
                type="radio"
                id="btype1"
                checked={businessSectionType !== 'CORP'}
                readOnly
              />
              <label htmlFor="btype1">개인사업자</label>
              <BusinessTypeInputRadioEnd
                type="radio"
                id="btype2"
                checked={businessSectionType === 'CORP'}
                readOnly
              />
              <label htmlFor="btype2">법인사업자</label>
            </BusinessTypeInputWrapper>
          </BusinessTypeWrapper>
        </>
      )}
      <TextField
        type="text"
        title="아이디"
        name="id"
        placeholder="아이디"
        onChange={onChange}
        onBlur={onBlur}
        showMessage={messages.id}
        message={messages.id}
        value={sellerInfo.id}
        maxLength={30}
      />
      {!isDuplicationChecked && isOthersAlreadyPassed && (
        <StyledActiveButton
          tabIndex={0}
          onClick={checkIdDuplication}
          style={messages.id ? { marginTop: '0' } : {}}
        >
          아이디 중복 확인
        </StyledActiveButton>
      )}
      <TextField
        type="password"
        title="비밀번호"
        name="newPassword"
        placeholder="비밀번호"
        onChange={onChange}
        onBlur={onBlur}
        showMessage={messages.newPassword}
        message={messages.newPassword}
        value={sellerInfo.newPassword}
        maxLength={30}
      />
      <TextField
        type="text"
        title="이메일"
        name="email"
        placeholder="이메일"
        onChange={onChange}
        onBlur={onBlur}
        showMessage={messages.email}
        message={messages.email}
        value={sellerInfo.email}
        maxLength={40}
      />

      <PolicyAgreeAllWrapper>
        <PolicyAgreeAllCheckboxLabel>
          <PolicyAgreeAllCheckbox
            type="checkbox"
            onChange={onChangePolicyAgreeAll}
            checked={
              essentialPolicies.concat(choicePolicies).length ===
                agreedPolicies.length || false
            }
            readOnly
          />
          모두 동의합니다.
          <PolicyAgreeAllCheckboxIcon />
        </PolicyAgreeAllCheckboxLabel>
      </PolicyAgreeAllWrapper>
      <ContentWrapper>
        <GuideBox guideMessage="전체 동의에는 필수 및 선택 정보에 대한 동의가 포함되어 있으며, 개별적으로 동의를 선택 하실 수 있습니다. 선택 항목에 대한 동의를 거부하시는 경우에도 서비스 이용이 가능합니다." />
      </ContentWrapper>
      <Line />
      {essentialPolicies.map((item, index) => {
        return (
          <PolicyAgreeRow
            key={`JoinPolicyAgree_Essential_${item.stipulationSeq}_${index}`}
            item={item}
            togglePolicyAgree={togglePolicyAgree}
            agreedPolicies={agreedPolicies}
            openPopupPolicy={openPopupPolicy}
            policyTypeText="필수"
          />
        );
      })}
      <Line />
      {choicePolicies.map((item, index) => {
        return (
          <PolicyAgreeRow
            key={`JoinPolicyAgree_Choice_${item.stipulationSeq}_${index}`}
            item={item}
            togglePolicyAgree={togglePolicyAgree}
            agreedPolicies={agreedPolicies}
            openPopupPolicy={openPopupPolicy}
            policyTypeText="선택"
          />
        );
      })}

      <IdentificationBox
        subText={
          businessManagerHash
            ? '본인 명의 휴대폰으로 본인인증 후'
            : '대표자 명의 휴대폰으로 본인인증 후'
        }
        text="회원가입"
        disabled={!isAbleIdentification}
        identificationSuccessCallback={identificationSuccessCallback}
        identificationExceptCallback={identificationExceptCallback}
      />

      {!!auth.authenticated && showTwoBtnModal && (
        <JoinMemberStep3
          businessManagerHash={businessManagerHash}
          goodsData={goodsData}
        />
      )}

      {renderNoBtnModal()}
    </div>
  );
};

const _validateId = ({ validateType, id, isDuplicationChecked }) => {
  const result = {
    success: true,
    message: '',
  };
  const hasKorean = checkKor(id);

  if (
    result.success &&
    (validateType === undefined || validateType === 'change')
  ) {
  }
  if (
    result.success &&
    (validateType === undefined || validateType === 'blur')
  ) {
    if (!id) {
      result.success = false;
      result.message = alertMessages.typeId;
    } else if (id.trim().length < 4) {
      result.success = false;
      result.message = alertMessages.validateIdLength;
    } else if (hasKorean) {
      result.success = false;
      result.message = alertMessages.validateIdCharacter;
    } else if (!isDuplicationChecked) {
      result.success = false;
      result.message = alertMessages.validateIdDuplication;
    }
  }

  return result;
};

const _validateNewPw = ({ validateType, pw }) => {
  const result = {
    success: true,
    message: '',
  };
  if (
    result.success &&
    (validateType === undefined || validateType === 'change')
  ) {
  }
  if (
    result.success &&
    (validateType === undefined || validateType === 'blur')
  ) {
    if (!pw) {
      result.success = false;
      result.message = alertMessages.typePw;
    } else if (!validatePassword(pw)) {
      result.success = false;
      result.message = alertMessages.validatePw;
    }
  }
  return result;
};

const _validateEmail = ({ validateType, email }) => {
  const result = {
    success: true,
    message: '',
  };
  if (
    result.success &&
    (validateType === undefined || validateType === 'change')
  ) {
  }
  if (
    result.success &&
    (validateType === undefined || validateType === 'blur')
  ) {
    if (!email) {
      result.success = false;
      result.message = alertMessages.typeEmail;
    } else if (!validateEmail(email)) {
      result.success = false;
      result.message = alertMessages.validateEmail;
    }
  }
  return result;
};

export default JoinMemberStep2;
