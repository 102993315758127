import React from 'react';
import DataProviderTableRow from './DataProviderTableRow';
import {
  Table,
  TableBody,
  TableDiv,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from '../style';

const DataProviderTable = ({ tableHead, dataProviderList, tableDataType }) => {
  const mallSeqList = dataProviderList?.map(mall => mall?.mallSeq);
  return (
    <TableDiv>
      <Table>
        <TableHead>
          <TableHeadRow>
            {tableHead.map((item, index) => {
              return (
                <TableHeadCell key={index} type={tableDataType}>
                  {item}
                </TableHeadCell>
              );
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {dataProviderList &&
            dataProviderList.length > 0 &&
            dataProviderList.map((item, index) => {
              // 중복으로 등록된 몰일 경우 아이디를 추가로 노출
              const isDuplicatedMall =
                mallSeqList?.filter(mallSeq => mallSeq === item?.mallSeq)
                  ?.length > 1;
              return (
                <DataProviderTableRow
                  key={`dataProviderTableRow_${
                    item.dataProviderSeq || item.mallSeq || 'index'
                  }_${index}`}
                  item={item}
                  type={tableDataType}
                  isDuplicatedMall={isDuplicatedMall}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableDiv>
  );
};

export default DataProviderTable;
