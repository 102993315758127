export const AGREE_LIST = [
  {
    stipulationSeq: 0, // 고유한 값
    dataProvideAgreeStipulationSeq: 0,
    stipulationTypeCd: 'STC100',
    stipulationCdName: '',
    stipulationContents: '',
    required: false,
    checked: false,
  },
];
