import React, { useEffect, useState } from 'react';
import { validateDataRequest, getListDataRequest } from 'utils/request';
import { replaceSpecialCharacter } from 'tools/StringTool';
import { NoBtnModal, TwoVerticalBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import DataInterlockLinkInfo from './DataInterlockLinkInfo';
import { LINKAGE_FAIL_USER_MESSAGES } from 'pages/MyPage/DataLinkage/constants';
import { FirstText, ChildTextContent } from 'pages/MyPage/DataLinkage/style';
import { BizNumberContainer, LinkageGuideLink, LinkContainer } from './style';
import { DataLinkagePaddingWrapper } from 'pages/MyPage/DataLinkage/DataLinkageRequest/style';

const IndividualModifyStep2 = ({
  modifiedDataInterlockInfo,
  setModifiedDataInterlockInfo,
  setStep,
  redGuideContents,
  secondAuthReleaseMessage,
  businessRegistrationNumber,
}) => {
  //data state
  const {
    mallName,
    mallSeq,
    mallMainId,
    mallMainPassword,
    dataProviderSeq,
    scrapingCompletedYn,
    secondAuthReleaseType,
    partnerCode,
    secondAuthType,
    secondAuthValue,
    mallInterlockStatus,
  } = modifiedDataInterlockInfo;

  const [isChecked, setIsChecked] = useState(false);
  const [mallAuthValidateTryCount, setMallAuthValidateTryCount] = useState(0);
  const [captchaData, setCaptchaData] = useState({
    captchaId: '',
    captchaUrl: '',
    captchaValue: '',
  });

  //모달 메시지 state
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isOpenTwoBtnModal, setIsOpenTwoBtnModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  // 자주 묻는 질문
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    // 판매몰의 자주 묻는 질문
    getListDataRequest({
      url: '/v1/au/faq/interlock/list',
      params: {
        mallSeq,
      },
      successFnc: list => {
        setFaqList(list.map(item => ({ ...item, isOpen: false })));
      },
    });
  }, [mallSeq]);

  // 아이디, 비밀번호 입력
  const onChangeTextField = event => {
    const { name, value } = event.target;

    const phoneNumValue = value.replace(/[^0-9]/g, '');
    if (name === 'secondAuthValue' && secondAuthType === 'SAT111') {
      !!setModifiedDataInterlockInfo &&
        setModifiedDataInterlockInfo(prev => ({
          ...prev,
          [name]: phoneNumValue,
        }));
    } else {
      !!setModifiedDataInterlockInfo &&
        setModifiedDataInterlockInfo(prev => ({ ...prev, [name]: value }));
    }
  };

  const onChangeSecondAuthType = value => {
    !!setModifiedDataInterlockInfo &&
      setModifiedDataInterlockInfo(prev => ({
        ...prev,
        secondAuthType: value,
        secondAuthValue: '',
      }));
  };

  // 캡차 인증 입력
  const onChangeCaptchaTextField = event => {
    const { name } = event.target;
    const modifiedValue = replaceSpecialCharacter(event.target.value?.trim());
    !!setCaptchaData &&
      setCaptchaData(prev => ({ ...prev, [name]: modifiedValue }));
  };

  // 버튼 활성화
  const activateButton = () => {
    let isDisabled = true;

    if (!!mallMainId && !!mallMainPassword) {
      if (mallSeq === 10017 && !partnerCode) {
        isDisabled = true;
      } else {
        isDisabled = false;
      }
      if (secondAuthReleaseType === 'REQUIRED') {
        isDisabled = !isChecked;
        if (captchaData?.errorCode) {
          isDisabled = !(isChecked && captchaData?.captchaValue);
        }
      }
    }

    return isDisabled;
  };

  //판매몰 유효성 체크
  const onClickValidateMallAuth = () => {
    if (secondAuthType === 'SAT112' && !secondAuthValue?.includes('@')) {
      alert('이메일은 @까지 입력해 주세요.');
      return;
    }
    // 계정 정보를 3회 잘못 입력했을 경우
    if (mallAuthValidateTryCount === 2 && !captchaData?.captchaUrl) {
      setIsOpenTwoBtnModal(true);
      setModalMessage(
        `계정을 2회 이상 틀리셨습니다. \n 계속 틀릴 경우 아이디가 잠금 처리됩니다. \n 해당 판매몰에서 아이디/비밀번호 찾기를 진행 후 계정 확인하는 것을 권장 드립니다. \n 계속 진행하시겠습니까?`
      );
      setMallAuthValidateTryCount(3);
    } else {
      const errorFnc = data => {
        const { errorCode = '' } = data;
        setMallAuthValidateTryCount(prev => prev + 1);

        let errorMessageByErrorCode = errorCode
          ? LINKAGE_FAIL_USER_MESSAGES[errorCode]
          : LINKAGE_FAIL_USER_MESSAGES.ETC;

        if (errorCode === 'E11420') {
          errorMessageByErrorCode = `판매몰 회원 정책으로 인해 로그인이 실패하였습니다. ${mallName}의 고객센터로 문의해주세요.`;
        } else if (errorCode === 'E11415') {
          setCaptchaData({ ...data, captchaValue: '' });
        } else if (errorCode === 'E11900') {
          setIsChecked(false);
        }

        setModalMessage(errorMessageByErrorCode);
        setIsMessageModalOpen(true);
      };

      validateDataRequest({
        url: `/v1/au/data-provider/individual/mallAuthValidate`,
        data: {
          dataProviderSeq,
          mallSeq,
          mallId: mallMainId,
          mallPassword: mallMainPassword,
          captchaId: captchaData.captchaId,
          captchaValue: captchaData.captchaValue,
          partnerCode: partnerCode || '',
          secondAuthType: secondAuthType || '',
          secondAuthValue: secondAuthValue || '',
        },
        successFnc: () => {
          setStep(3);
        },
        errorFnc: errorFnc,
        serverErrorFnc: () => {
          setMallAuthValidateTryCount(prev => prev + 1);
        },
        setModalMessage: setModalMessage,
        setShowAlertModal: setIsMessageModalOpen,
      });
    }
  };

  // 2차 인증 해제 완료 체크박스
  const onChangeCheckBox = event => {
    const { checked } = event.target;
    setIsChecked(checked);
    const secondAuthReleaseYn = checked === true ? 'Y' : 'N';
    setModifiedDataInterlockInfo(prev => ({ ...prev, secondAuthReleaseYn }));
  };

  //계정 2회 이상 오류 모달
  //top 버튼
  const onClickCloseTwoBtnModal = () => {
    setIsOpenTwoBtnModal(false);
  };

  //Bottom 버튼
  const onClickBottomBtn = () => {
    setIsOpenTwoBtnModal(false);
    onClickValidateMallAuth();
  };

  // 자주 묻는 질문 - 펼침 버튼
  const onClickDetailBtn = seq => {
    setFaqList(prev =>
      prev.map((item, index) =>
        index === seq
          ? { ...item, isOpen: !item.isOpen }
          : { ...item, isOpen: false }
      )
    );
  };

  return (
    <>
      <DataLinkagePaddingWrapper>
        <FirstText>
          계정 수정이 필요한 경우
          <br />
          수정 후 판매몰 계정확인을 진행해 주세요.
        </FirstText>
        <BizNumberContainer>
          <ChildTextContent bold>사업자 번호</ChildTextContent>
          <ChildTextContent>
            {businessRegistrationNumber || '-'}
          </ChildTextContent>
        </BizNumberContainer>
        <LinkContainer>
          <LinkageGuideLink
            target="_blank"
            rel="noopener noreferrer"
            href={
              !!mallName
                ? `${window.location.origin}/data-linkage/guide?mallName=${mallName}`
                : `${window.location.origin}/data-linkage/guide`
            }
          >
            연동 가이드 보기
          </LinkageGuideLink>
        </LinkContainer>
      </DataLinkagePaddingWrapper>
      <DataInterlockLinkInfo
        mallInterlockStatus={mallInterlockStatus}
        redGuideContents={redGuideContents}
        scrapingCompletedYn={scrapingCompletedYn}
        secondAuthReleaseType={secondAuthReleaseType}
        captchaData={captchaData}
        onClickValidateMallAuth={onClickValidateMallAuth}
        faqList={faqList}
        mallName={mallName}
        mallSeq={mallSeq}
        partnerCode={partnerCode}
        secondAuthType={secondAuthType}
        secondAuthValue={secondAuthValue}
        mallMainId={mallMainId}
        onChangeTextField={onChangeTextField}
        mallMainPassword={mallMainPassword}
        secondAuthReleaseMessage={secondAuthReleaseMessage}
        isChecked={isChecked}
        onChangeCheckBox={onChangeCheckBox}
        onChangeCaptchaTextField={onChangeCaptchaTextField}
        activateButton={activateButton}
        onClickDetailBtn={onClickDetailBtn}
        onChangeSecondAuthType={onChangeSecondAuthType}
      />

      {/* alert 메세지 모달*/}
      <NoBtnModal
        showModal={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
      >
        <ScrollArea>
          <Contents>{modalMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>

      <TwoVerticalBtnModal
        showModal={isOpenTwoBtnModal}
        onClose={onClickCloseTwoBtnModal}
        topButton="아니요, 계정을 확인해 볼게요"
        bottomButton="네, 계속 진행할게요."
        onClickTopBtn={onClickCloseTwoBtnModal}
        onClickBottomBtn={onClickBottomBtn}
      >
        <ScrollArea>
          <Contents>{modalMessage}</Contents>
        </ScrollArea>
      </TwoVerticalBtnModal>
    </>
  );
};

export default IndividualModifyStep2;
