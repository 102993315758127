import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const Footer = styled.footer`
  width: 100%;
  min-width: ${theme.layout.minWidth};
  padding: 40px 35px;
  background: ${theme.backgroundColor.white};

  @media screen and (max-width: 375px) {
    padding: 30px 20px;
  }
`;

export const CCNLogoImg = styled.img`
  width: 175px;
  height: 13px;

  @media screen and (max-width: 375px) {
    width: 162px;
    height: 12px;
  }
`;

export const AreaBox = styled.div`
  width: 100%;
  display: flex;
  ${props => {
    if (props.className) {
      if (props.className === 'ff-row2') {
        return css`
          margin: 24px 0 0;

          @media screen and (max-width: 375px) {
            margin: 18px 0 0;
          }
        `;
      }
      if (props.className === 'ff-r4wrap') {
        return css`
          display: inline;
        `;
      }
      if (props.className === 'ff-row3') {
        return css`
          margin: 38px 0 15px;

          @media screen and (max-width: 375px) {
            margin: 28px 0 15px;
          }
        `;
      }
      if (props.className === 'ff-row5') {
        return css`
          margin: 28px 0 0;
          flex-direction: column;
          @media screen and (max-width: 375px) {
            margin: 18px 0 0;
          }
        `;
      }
      if (props.className === 'ff-row5-one') {
        return css`
          margin: 3px 0 0;
        `;
      }
    }
  }}
`;

export const MenuButton = styled.a`
  padding: 0px 17px;
  color: ${theme.color.grey9};

  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  border-left: 1px solid ${theme.color.grey9};

  &:first-child {
    padding: 0px 17px 0px 0px;
    border-left: none;
  }

  &:last-child {
    color: ${theme.color.grey5};
  }

  @media screen and (max-width: 375px) {
    padding: 0px 10px;
    font-size: 1.2rem;

    &:first-child {
      padding: 0px 10px 0px 0px;
      border-left: none;
    }
  }
`;

export const AizenLogoImg = styled.img`
  width: 63px;
  height: 37px;

  @media screen and (max-width: 375px) {
    width: 40px;
    height: 24px;
  }
`;

export const AizenGlobalText = styled.div`
  height: 37px;
  line-height: 3.2rem;
  padding: 0 0 0 10px;
  font-size: 1.6rem;
  font-weight: 800;
  color: ${theme.color.aizenBlue};
  text-align: left;

  @media screen and (max-width: 375px) {
    height: 24px;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 600;
  }
`;

export const Span = styled.span`
  display: inline;
  position: relative;
  line-height: 2.4rem;
  font-size: 1.4rem;
  text-align: left;
  vertical-align: top;
  color: ${theme.color.grey5};

  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
  }
`;

export const ISpan = styled.span`
  padding: 0 3px;
  line-height: 2.4rem;
  font-size: 0.8rem;
  vertical-align: top;
  color: ${theme.color.lightGrey};
`;

export const Copyright = styled.div`
  width: 100%;
  margin: 10px 0 0;
  line-height: 2.4rem;
  font-size: 1.4rem;
  text-align: left;
  color: ${theme.color.grey5};

  @media screen and (max-width: 375px) {
    line-height: 2rem;
    font-size: 1.2rem;
  }
`;

export const ListIcon = styled.div`
  width: 6px;
  height: 6px;
  margin: 10px 10px 0px 0px;
  border-radius: 3px;
  background: ${theme.backgroundColor.greyA};

  @media screen and (max-width: 375px) {
    width: 4px;
    height: 4px;
  }
`;

export const ListText = styled.div`
  width: calc(100% - 16px);
  text-align: left;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.4rem;
  color: ${theme.color.grey5};

  @media screen and (max-width: 375px) {
    line-height: 2rem;
    font-size: 1.1rem;
  }
`;
