import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const SelectBoxSection = styled.div`
  width: 100%;
  padding: ${props => (props.noPadding ? '0' : '0 0 13px')};
  margin: ${props => (!!props.margin ? '20px 0' : '0')};
`;

export const Select = styled.select`
  padding: 0 17px 0 14px;
  width: 100%;
  height: 34px;
  line-height: 3.2rem;
  border: 1px solid ${theme.borderColor.blue};
  border-radius: 17px;
  background: #fff url(/images/icons/dropdown/main_ar_s2r.png) calc(100% - 11px)
    center no-repeat;
  background-size: 8px 4px;
  color: ${theme.color.blue};
  vertical-align: middle;
  cursor: pointer;
  appearance: none;

  ${props => {
    if (props.$gray) {
      return css`
        padding: ${props =>
          props.$grayPadding ? props.$grayPadding : '0 22px 0 28px'};
        font-weight: 500;
        border: none;
        background: #fff url(/images/icons/dropdown/tri-opened-g1.png)
          calc(100% - 7px) center no-repeat; // 화살표 위치
        background-size: 8px 4px;
        color: ${theme.color.grey7};
        text-align-last: right;
        font-size: 1.35rem;

        // 말줄임처리
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;

        @media screen and (max-width: 320px) {
          font-size: 1.2rem;
        }
      `;
    }
  }}
`;

export const SelectOption = styled.option`
  padding: inherit;
  height: 34px;
  line-height: 3.2rem;

  ${props => {
    if (props.$gray) {
      return css`
        text-align: left;
      `;
    }
  }}
`;
