export const SERVICE_CENTER_TAB_OPTIONS = [
  {
    label: '공지사항',
    value: 'notice',
    tab: 'notice',
  },
  {
    label: '1:1 문의',
    value: 'oneToOneInquiry',
    tab: 'QnA',
  },
  {
    label: '자주 묻는 질문',
    value: 'faq',
    tab: 'FAQ',
  },
];
