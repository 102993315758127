import React from 'react';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import { tooltipMessages } from './constants';

const TooltipModal = ({
  isTooltipModalOpen = false,
  onClickTooltip = () => {},
  tooltipMessageCd = '',
}) => {
  return (
    <NoBtnModal
      showModal={isTooltipModalOpen}
      onClose={onClickTooltip}
      title="대출 불가 안내"
    >
      <ScrollArea>
        <Contents>{tooltipMessages?.[tooltipMessageCd]}</Contents>
      </ScrollArea>
    </NoBtnModal>
  );
};

export default TooltipModal;
