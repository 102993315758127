import styled from 'styled-components';
import theme from 'styles/theme';

export const BlueBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => (props.margin ? props.margin : '23px 0 0')};
  padding: 16px;
  border-radius: 5px;
  background: ${props =>
    props.background
      ? `${theme.backgroundColor[props.background]}`
      : `${theme.backgroundColor.icyBlue}`};

  a {
    text-decoration: underline;
    color: ${theme.color.blue};
  }
`;

export const BlueBoxTitle = styled.div`
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  align-items: flex-start; // 수정하면 안됩니다 (두줄일 경우가 있음)

  padding: 0 0 6px;

  font-weight: ${props => (props.fontNormal ? '400' : '600')};
  font-size: ${theme.fontSize.h5};
`;

export const Title = styled.div`
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  align-items: flex-start; // 수정하면 안됩니다 (두줄일 경우가 있음)

  padding: 0 0 6px;

  font-weight: ${props => (props.fontNormal ? '400' : '600')};
  font-size: ${theme.fontSize.h5};
`;

export const TitleIcon = styled.div`
  padding: 4px 6px 0 0;
`;

export const TitleText = styled.div`
  line-height: 2.4rem;
  font-size: 1.4rem;
`;

export const Contents = styled.div`
  font-size: ${theme.fontSize.h5};
  line-height: 2.4rem;

  text-align: center; // string이 들어가면 center, 태그가 들어가면 left 정렬

  a > u {
    color: ${theme.color.blue};
  }

  white-space: pre-line;
`;

export const BlueBoxContents = styled.div`
  font-size: ${theme.fontSize.h5};
  line-height: 2.4rem;

  text-align: center; // string이 들어가면 center, 태그가 들어가면 left 정렬

  a > u {
    color: ${theme.color.blue};
  }

  white-space: pre-line;
`;
