export const validateEmail = email =>
  !!email &&
  // /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
  /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
    email
  );

/* memberId validation */
export const validateMemberId = memberId => {
  if (memberId && memberId.trim().length > 3) {
    return true;
  } else {
    return false;
  }
};

/* password validation */
export const validatePassword = password => {
  /*
   * [변경전] 대/소문자, 숫자, 기호 조합 8자 이상 (https://stackoverflow.com/a/21456918)
   * const rule = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
   */

  /* 최소 8자 이상, 최소 1개 이상의 알파벳, 최소 1개 이상의 숫자, 공백금지 */
  const rule = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?!.* ).*$/;
  return rule.test(password);
};

/* memberId and password validation */
export const validateLogin = ({ params }) => {
  const result = {
    success: true,
    message: '',
  };
  if (!!params.memberId === false) {
    result.success = false;
    result.message = '아이디는 필수 항목입니다.';
  } else if (!!params.password === false) {
    result.success = false;
    result.message = '비밀번호는 필수 항목입니다.';
  }
  return result;
};

// validate number and hyphen
export const validateNumberHyphen = v => {
  return v === '' || /^[0-9-\b]+$/.test(v);
};

// validate only number
export const validateNumber = v => v === '' || /^[0-9\b]+$/.test(v);

export const hasSpace = str => /[\s]+/g.test(str);

export const hasKorean = str => /[ㄱ-ㅎㅏ-ㅣ가-힣]+/g.test(str);

export const hasSpaceOrKorean = str => /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣\s]+/g.test(str);

export const notAlphabetOrDigit = str => /[^0-9a-zA-Z]+/g.test(str);

export const hasAlphabet = str => /[a-zA-Z]+/g.test(str);

export const hasDigit = str => /[0-9]+/g.test(str);

export const isValidJsonString = str => {
  try {
    const json = JSON.parse(str);
    return typeof json === 'object';
  } catch (e) {
    return false;
  }
};

/* owner validation */
export const validateOwnerName = owner => {
  if (!!owner === false) {
    return false;
  }
  // 첫글자에 공백
  if (hasSpace(owner.charAt(0))) {
    return false;
  }
  if (owner.length < 2) {
    return false;
  }
  if (owner.length > 20) {
    return false;
  }
  if (hasKorean(owner)) {
    if (hasAlphabet(owner) || hasSpace(owner) || hasDigit(owner)) {
      return false;
    }
  }
  return true;
};

/* BizNum validation */
export const validateBizNum = bizNum => {
  if (bizNum.length !== 10) {
    return false;
  } else {
    return true;
  }
};

/* foundationDate validation */
export const validateFoundationDate = bizNum => {
  if (bizNum.length !== 8) {
    return false;
  } else {
    return true;
  }
};
