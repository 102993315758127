import React, { useEffect, useRef, useState } from 'react';
import SelectBox from 'components/SelectBox';
import FoldedCard from 'components/FoldedCard';
import NoListText from 'components/NoListText';
import HeadlessPagination from 'components/HeadlessPagination';
import {
  CategoryWrapper,
  CategoryButtonWrapper,
  CategoryButton,
  CategoryToggleButton,
  TitleText,
  TitleBox,
  SelectWrapper,
} from './style';

const SearchCategorySection = ({
  faqCategoryList,
  onClickCategoryBtn,
  selectedCategory = {},
  findValue,
  faqList = [],
  setFaqList,
  page,
  setPage,
  paginationData,
  mallList = [],
  onChangeMall = () => {},
  selectedMallSeq = '',
}) => {
  const categoryRef = useRef(null);
  const [initialWrapperHeight, setInitialWrapperHeight] = useState(0);
  const [showToggle, setShowToggle] = useState(false);
  const [isCategoryToggleOpen, setIsCategoryToggleOpen] = useState(false);

  const onClickDetailBtn = seq => {
    setFaqList(list =>
      list.map((item, index) =>
        index === seq
          ? { ...item, isOpen: !item.isOpen }
          : { ...item, isOpen: false }
      )
    );
  };

  const onClickToggleBtn = () => {
    setIsCategoryToggleOpen(prev => !prev);
  };

  useEffect(() => {
    setInitialWrapperHeight(categoryRef?.current?.offsetHeight);
    const initialWrapperHeight = categoryRef?.current?.offsetHeight;
    const initialWrapperWidth = categoryRef?.current?.offsetWidth;

    if (initialWrapperHeight > 100 && initialWrapperWidth < 400) {
      setShowToggle(true);
    } else {
      setShowToggle(false);
    }
  }, []);

  return (
    <>
      <CategoryWrapper ref={categoryRef}>
        <CategoryButtonWrapper
          isOpen={isCategoryToggleOpen}
          initialWrapperHeight={initialWrapperHeight}
        >
          {faqCategoryList?.map((item, index) => (
            <CategoryButton
              key={index}
              isClicked={item.isClicked}
              onClick={() => onClickCategoryBtn(item.code)}
            >
              {item.codeName}
            </CategoryButton>
          ))}
        </CategoryButtonWrapper>
        {!showToggle && (
          <CategoryToggleButton
            isOpen={isCategoryToggleOpen}
            onClick={onClickToggleBtn}
          />
        )}
      </CategoryWrapper>

      <TitleBox>
        <TitleText>
          {selectedCategory.codeName || `'${findValue}' 검색 결과` || ''}
        </TitleText>
        {selectedCategory.code === 'FAQ120' && (
          <SelectWrapper>
            <SelectBox
              optionList={mallList}
              optionHandleChange={onChangeMall}
              name="selectMall"
              value={selectedMallSeq}
              isNotDisabled={true}
              noPadding={true}
              $gray
            />
          </SelectWrapper>
        )}
      </TitleBox>

      {paginationData.totalCount > 0 && (
        <>
          {faqList.map((item, index) => (
            <FoldedCard
              name="faq"
              key={`FoldedCard_${index}`}
              index={index}
              isOpen={item.isOpen}
              title={item.subject}
              subText={item.classificationCdNm}
              contents={item.contents}
              seq={item.faqSeq}
              onClickDetailBtn={onClickDetailBtn}
              borderTop={index === 0}
              questionMark={true}
              findValue={findValue}
            />
          ))}
          {selectedCategory.code !== 'FAQ100' && (
            <HeadlessPagination
              page={page}
              setPage={setPage}
              paginationData={paginationData}
            />
          )}
        </>
      )}
      {paginationData.totalCount === 0 &&
        (!!selectedCategory.codeName ? (
          <NoListText
            title="자주 묻는 질문이 없습니다."
            description="궁금한 점이 있으시면 고객센터로 문의해 주세요."
            border
          />
        ) : (
          <NoListText
            title={`검색 결과가 없습니다.\n띄어쓰기나 맞춤법을 확인 후\n다시 한번 검색해 보세요.`}
            description=""
            border
          />
        ))}
    </>
  );
};

export default SearchCategorySection;
