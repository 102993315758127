import React from 'react';
import {
  PolicyDetailContent,
  PolicyTitle,
} from '../DeepLink/DeepLinkPolicyPopupPage/style';

const DefaultPopupPage = () => {
  const stipulation = window.opener?._stipulation;

  return (
    <div>
      <PolicyTitle>{stipulation?.stipulationCdName}</PolicyTitle>
      <PolicyDetailContent
        dangerouslySetInnerHTML={{
          __html:
            stipulation?.stipulationContents ||
            '<div style="display: flex; justify-content: center; align-items: center; min-height: 100px;">Not Available<div>',
        }}
      />
    </div>
  );
};

export default DefaultPopupPage;
