import axios from 'axios';
import qs from 'qs';
import {
  getSession,
  getRefresh,
  KEY_SESSION,
  KEY_REFRESH,
  KEY_MEMBERCERTIFICATIONSEQ,
  KEY_EXPIRETIME,
} from 'tools/WebTool';

const host = process.env.REACT_APP_API_HOST;

let isTokenRefreshing = false;
let axiosApiUrlQue = [];
let axiosApiQue = [];

const api = async ({ ...params }) => {
  const {
    data,
    headers = {},
    fncResolve = r => {
      return r;
    },
    ...config
  } = params;
  const session = getSession();

  const response = await axios({
    baseURL: host + '/api',
    headers: Object.assign(
      { 'content-type': 'application/x-www-form-urlencoded' },
      session && { authorization: 'Bearer ' + session },
      headers
    ),
    data: !headers['content-type'] ? qs.stringify(data) : data,
    ...config,
  })
    .then(fncResolve)
    .catch(err => {
      const { config, response: { status = 0 } = {} } = err;
      const originalRequest = config;
      if (
        !['/v1/na/auth/login', '/v1/na/auth/refresh'].includes(
          originalRequest.url
        ) &&
        status === 401
      ) {
        if (!isTokenRefreshing) {
          isTokenRefreshing = true;
          const refresh = getRefresh();
          api({
            url: '/v1/na/auth/refresh',
            method: 'post',
            headers: Object.assign(
              { 'content-type': 'application/x-www-form-urlencoded' },
              session && { authorization: 'Bearer ' + session },
              refresh && { 'authorization-refresh': 'Bearer ' + refresh }
            ),
            crossDomain: true,
            withCredentials: true,
          })
            .then(response => {
              const { headers: _headers } = response;
              const {
                authorization: _session,
                'authorization-refresh': _refresh,
              } = _headers;
              if (!!_session && !!_refresh) {
                _session && localStorage.setItem(KEY_SESSION, _session);
                _refresh && localStorage.setItem(KEY_REFRESH, _refresh);
              }
              isTokenRefreshing = false;
              axiosApiQue.map(callback => {
                return callback(_session);
              });
            })
            .catch(err => {
              const message = err?.response?.data?.message ?? '';
              const errorNo = err?.response?.data['error-no'] ?? 0;
              if (!!message && (errorNo === 10000 || errorNo === 10010)) {
                alert(message);
              }

              localStorage.removeItem(KEY_SESSION);
              localStorage.removeItem(KEY_REFRESH);
              localStorage.removeItem(KEY_MEMBERCERTIFICATIONSEQ);
              localStorage.removeItem(KEY_EXPIRETIME);

              // 인증이 필요한 api 통신할때 인증 안되어있으면 로그인으로 리다이렉트
              if (window.location.pathname !== '/login') {
                window.location.href = '/login';
              }
            });
        }
        return new Promise(resolve => {
          if (!axiosApiUrlQue.includes(originalRequest.url)) {
            axiosApiUrlQue.push(originalRequest.url);
            axiosApiQue.push(session => {
              const _params = { ...params, headers: params?.headers ?? {} };
              _params.headers.authorization = 'Bearer ' + session;
              resolve(api(_params));
            });
          }
        });
      }
      return Promise.reject(err);
    });
  return response;
};

export default api;
