import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useApp from 'hooks/useApp';
import {
  createDataRequest,
  getDataRequest,
  getListDataRequest,
  getResultDataRequest,
} from 'utils/request';
import { getStipulationList } from 'pages/MyPage/LoanLimit/LoanLimitBasicPage';
import ROUTE_PATH from 'routes/config';
import PolicyContents from '../LoanLimit/LoanLimitBasicPage/IdentificationModal/PolicyContents';
import TextField from 'components/TextField';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import WideButton from 'components/Button/WideButton';
import NoBtnAlertModal from './NoBtnAlertModal';
import TwoBtnAlertModal from './TwoBtnAlertModal';
import OneBtnAlertModal from './OneBtnAlertModal';
import SelectManagerModal from './SelectManagerModal';
import { modalMessages, buttonContents, unit } from './constants';
import {
  TextMedium,
  TextBlue,
  TextBox,
  TextBoxDiv,
  TextBoxTitle,
  TextBoxContents,
  TextBoxDivTextField,
  ChildText,
  ChildTextContent,
  TextBoxContentsHighlight,
  Contents,
} from './style';
import {
  PolicyAgreeAllCheckbox,
  PolicyAgreeAllCheckboxIcon,
  PolicyAgreeAllCheckboxLabel,
  PolicyAgreeAllWrapper,
} from 'pages/Login/JoinMemberPage/JoinMemberStep2/style';
import { StipulationContents } from 'components/StipulationAgreeRow/style';
import { createMarkup } from 'tools/WebTool';

const LoanRequest = () => {
  const { auth } = useApp();
  const { businessName, memberRoleData, memberTotalData } = auth;
  const { mypage, login } = ROUTE_PATH;
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;

  // 대출 상품 상세 데이터
  const [_loanDetailData, _setLoanDetailData] = useState({}); // 통신해서 받는 데이터
  const [loanDetailData, setLoanDetailData] = useState({}); // 통신해서 받은 데이터와 부모 화면에서 넘겨준 state.loanDetailData를 합친 객체
  // 도이치파이낸셜의 seq 는 10003 이다.
  const isFinanceInstDeutsch = loanDetailData?.financeInstitutionSeq === 10003;

  // alert 모달 state
  const [showNoBtnAlertModal, setShowNoBtnAlertModal] = useState(false);
  const [showNoBtnAlertModalText, setShowNoBtnAlertModalText] = useState('');

  const [showOneBtnAlertModal, setShowOneBtnAlertModal] = useState(false);
  const [showOneBtnAlertModalText, setShowOneBtnAlertModalText] = useState('');
  const [showOneBtnAlertModalBtnText, setShowOneBtnAlertModalBtnText] =
    useState('');

  const [showTwoBtnAlertModal, setShowTwoBtnAlertModal] = useState(false);
  const [showTwoBtnAlertModalBtnText, setShowTwoBtnAlertModalBtnText] =
    useState({ left: '', right: '' });

  // 담당자 선택 모달 state
  const [validManagerList, setValidManagerList] = useState([]);
  const [showSelectManagerModal, setShowSelectManagerModal] = useState(false);

  // 대출 신청 params
  const [creditGoodsSeq, setCreditGoodsSeq] = useState(0); //대출 신청 params - 1. 대출 상품 번호
  const [memberCertificationSeq, setMemberCertificationSeq] = useState(0); //대출 신청 params - 2. 본인인증번호
  const [selectedManager, setSelectedManager] = useState({}); //대출 신청 params - 3. 사업 담당자 일련번호
  const [loansLimitInquirySeq, setLoansLimitInquirySeq] = useState(0); //대출 신청 params - 5. 대출한도조회 일련번호
  const [loanAmountText, setLoanAmountText] = useState(''); //대출 신청 params - 6. 대출 신청 금액 input 입력값
  const [loanAmountValue, setLoanAmountValue] = useState(0); //대출 신청 params - 6. 대출 신청 금액 실제값
  const [inputMessages, setInputMessages] = useState(''); // 대출 신청 금액 유효성 검사

  // 약관 목록
  const [stipulationList, setStipulationList] = useState([]);

  //memberRoleData에서는 representativeYn, managerList에서는 representationYn 참고
  const isRepresentation = memberRoleData?.representativeYn === 'Y';
  const dataUseType = location.pathname.includes('credit-loan')
    ? 'CREDIT_LOAN_CMPRS'
    : 'LOAN_CMPRS';

  // 상품 데이터 받아오기
  useEffect(() => {
    if (!!state) {
      setCreditGoodsSeq(state.creditGoodsMasterEventSeq);
      setMemberCertificationSeq(state.memberCertificationSeq);
      setLoanDetailData(state.loanDetailData);
      setLoansLimitInquirySeq(state.loansLimitInquirySeq);

      getResultDataRequest({
        url: `/v1/au/loans-limit/credit-request/detail/${state.creditGoodsMasterEventSeq}`,
        setData: _setLoanDetailData,
        setModalMessage: setShowNoBtnAlertModalText,
        setShowAlertModal: setShowNoBtnAlertModal,
      });
    } else {
      navigate(-1);
    }
  }, [state]);

  useEffect(() => {
    const { maximumLimit = 0, ...rest } = _loanDetailData;
    // 최대한도 미설정시 maximumLimit 0으로 온다. (이전 화면에서 넘겨준 값으로 최대한도를 설정한다.)
    // prev는 이전 화면에서 state로 넘겨준 값, _loanDetailData는 api 통신으로 받은 값
    setLoanDetailData(prev => {
      return maximumLimit === 0
        ? { ...prev, ...rest }
        : {
            ...prev,
            maximumLimit: Math.min(maximumLimit, prev.maximumLimit),
            ...rest,
          };
    });
  }, [_loanDetailData]);

  useEffect(() => {
    setLoanAmountValue(loanDetailData?.maximumLimit);
    setLoanAmountText(loanDetailData?.maximumLimit?.toLocaleString());
  }, [loanDetailData.maximumLimit]);

  useEffect(() => {
    // 유효한 매니저 (대표 or 회원상태)
    const successFnc = listData => {
      const validData = listData?.filter(
        item =>
          item.useYn === 'Y' &&
          (item.representationYn === 'Y' || item.invitationCompletionYn === 'Y')
      );
      setValidManagerList(validData);
    };
    getListDataRequest({ url: '/v1/au/manager', successFnc });
    // 약관 목록 조회 (약관 타입 only)
    getStipulationList({
      setStipulationList,
      stipulationClassificationCd: 'SCC500',
    });
  }, []);

  useEffect(() => {
    // 도이치파이낸셜, 신용 대출 예외 케이스 (대표자만 신청 가능)
    if (isFinanceInstDeutsch === true || dataUseType === 'CREDIT_LOAN_CMPRS') {
      // 담당자 중에서 대표 찾기
      if (validManagerList?.length > 0) {
        const representativeManager = validManagerList?.find(
          item => item.representationYn === 'Y'
        );
        const {
          businessManagerSeq = 0,
          managerName = '',
          department = '',
          position = '',
          contactNo = '',
          mobilePhone = '',
        } = representativeManager;

        setSelectedManager({
          businessManagerSeq,
          managerName,
          contactNo,
          mobilePhone,
          department,
          position,
          isChecked: true,
        });
      }
    }

    // 담보 케이스 직원 신청 (직원만 신청 가능)
    if (isRepresentation === false && dataUseType === 'LOAN_CMPRS') {
      // 담당자 중에서 해당 직원 찾기
      if (validManagerList?.length > 0) {
        const representativeManager = validManagerList?.find(
          item => item.mobilePhone === memberTotalData?.mobilePhone
        );
        const {
          businessManagerSeq = 0,
          managerName = '',
          department = '',
          position = '',
          contactNo = '',
          mobilePhone = '',
        } = representativeManager;

        setSelectedManager({
          businessManagerSeq,
          managerName,
          contactNo,
          mobilePhone,
          department,
          position,
          isChecked: true,
        });
      }
    }
  }, [isFinanceInstDeutsch, validManagerList, dataUseType, isRepresentation]);

  const isInteger = number => {
    const checkUnit = number / unit[loanDetailData?.limitUnit];
    return checkUnit % 1 === 0;
  };

  // 대출 희망 금액 input change
  const onChangeLoanAmount = e => {
    const value = e.target.value;
    const onlyNumberValue = value.replace(/[^0-9]/g, '');
    const realNumberValue = +onlyNumberValue;

    setLoanAmountValue(realNumberValue);
    setLoanAmountText(realNumberValue?.toLocaleString());

    // 단위 체크
    if (!isInteger(realNumberValue)) {
      setInputMessages(
        `${loanDetailData?.limitUnitName} 단위로 입력이 가능합니다.`
      );
      // 한도 금액 초과 체크
    } else if (realNumberValue > loanDetailData?.maximumLimit) {
      setInputMessages('대출 신청 가능 금액을 초과할 수 없습니다.');
    } else {
      setInputMessages('');
    }
  };

  const onBlur = e => {
    // 통화 추가
    setLoanAmountText(prev => prev + ` 원`);

    // 단위 체크
    if (!isInteger(loanAmountValue)) {
      setInputMessages(
        `${loanDetailData?.limitUnitName} 단위로 입력이 가능합니다.`
      );

      // 최저 금액 체크
    } else if (loanAmountValue < loanDetailData?.lowestLimit) {
      setInputMessages('최소 신청 금액을 확인해주세요.');

      // 한도 금액 초과 체크
    } else if (loanAmountValue > loanDetailData?.maximumLimit) {
      setInputMessages('대출 신청 가능 금액을 초과할 수 없습니다.');
    } else {
      setInputMessages('');
    }
  };

  const onFocus = e => {
    setLoanAmountText(loanAmountValue?.toLocaleString());
  };

  // 매니저 선택 모달 열기
  const onClickShowManagerModal = () => {
    setShowSelectManagerModal(true);
  };

  // 매니저 선택 함수
  const onClickSelectManager = () => {
    setShowSelectManagerModal(false);
  };

  const onClickNavigate = path => {
    navigate(path);
  };

  // 대출 신청 완료 버튼 클릭
  const onClickRequestLoan = () => {
    // 대출신청 사전체크
    getDataRequest({
      url: `/v1/au/credit-request/validation`,
      params: { dataUseType },
      successFnc: createLoanRequest,
      errorFnc: handleValidationStatus,
      setModalMessage: setShowNoBtnAlertModalText,
      setShowAlertModal: setShowNoBtnAlertModal,
    });
  };

  const handleValidationStatus = (data, message) => {
    if (message === 'certificationNo') {
      // 대표자 본인인증 실패 (대표자가 변경됨)
      setShowOneBtnAlertModalText(modalMessages.certificationNo);
      setShowOneBtnAlertModalBtnText(buttonContents.login);
      setShowOneBtnAlertModal(true);
    }
    if (message === 'memberStatusNo') {
      // 회원이 활동상태가 아닐때
      setShowNoBtnAlertModalText(
        `${data.memberStatus} ${modalMessages.memberStatusNo}`
      );
      setShowNoBtnAlertModal(true);
    }
    if (message === 'BusinessNumberNo') {
      // 사업자 번호로 조회가 안될때
      setShowOneBtnAlertModalText(modalMessages.BusinessNumberNo);
      setShowOneBtnAlertModalBtnText(buttonContents.updateBusiness);
      setShowOneBtnAlertModal(true);
    }
    if (message === 'BusinessClose') {
      // 휴폐업자
      setShowNoBtnAlertModalText(modalMessages.BusinessClose);
      setShowNoBtnAlertModal(true);
    }
  };

  const onClickModalButton = () => {
    if (showOneBtnAlertModalBtnText === buttonContents.login) {
      navigate(login.login);
    }
    if (showOneBtnAlertModalBtnText === buttonContents.updateBusiness) {
      navigate(mypage.updateBusiness);
    }
  };

  // 대출 신청하기
  const createLoanRequest = () => {
    const params = {
      creditGoodsMasterEventSeq: creditGoodsSeq,
      memberCertificationSeq: memberCertificationSeq,
      businessManagerSeq: selectedManager?.businessManagerSeq,
      managerName: selectedManager?.managerName,
      managerContact: selectedManager?.mobilePhone,
      requestAmt: loanAmountValue,
      loansLimitInquirySeq,
      agreeList: stipulationList.map(({ agreeYn, stipulationSeq }) => ({
        agreeYn,
        stipulationSeq,
      })),
    };

    createDataRequest({
      url: `/v1/au/credit-request`,
      data: params,
      successMessage: `대출 신청을 완료했어요.\n금융기관 담당자가 신청 내용을 확인 후 대출 담당자에게 연락 예정이에요.`,
      setShowModal: setShowTwoBtnAlertModal,
      successFnc: () => {
        setShowTwoBtnAlertModalBtnText({
          left: '추가 대출 신청',
          right: '신청 내용 확인',
        });
      },
      setModalMessage: setShowNoBtnAlertModalText,
      setShowAlertModal: setShowNoBtnAlertModal,
    });
  };

  const onClickViewBtn = stipulationSeq => {
    if (!stipulationSeq) {
      return;
    }
    window._stipulationData = stipulationList.find(
      item => item.stipulationSeq === stipulationSeq
    );
    window.open(`${ROUTE_PATH.policyDefault}/${stipulationSeq}`, '_blank');
  };

  // 완료 버튼 비활성화 여부
  const isAgreeBtnDisabled = stipulationList
    .filter(item => item.required === true)
    .some(item => item.checked === false);

  const isRequestBtnDisabled =
    loanAmountValue === 0 ||
    !selectedManager?.managerName ||
    !!inputMessages ||
    isAgreeBtnDisabled;

  return (
    <>
      <TextMedium>
        <TextBlue>{businessName}</TextBlue>님
        <br />
        <TextBlue>{loanDetailData?.financeInstitutionName}</TextBlue> 대출
        정보를 입력해주세요.
      </TextMedium>
      <TextBox>
        <TextBoxTitle>대출 상품</TextBoxTitle>
        <TextBoxContents>
          {loanDetailData?.creditGoodsMasterEventName}
        </TextBoxContents>
      </TextBox>
      <TextBox>
        <TextBoxTitle>최소 신청 금액</TextBoxTitle>
        <TextBoxContents>
          {loanDetailData?.lowestLimit?.toLocaleString()}원
        </TextBoxContents>
      </TextBox>
      <TextBox>
        <TextBoxTitle>대출 신청 가능 금액</TextBoxTitle>
        <TextBoxContentsHighlight>
          {loanDetailData?.maximumLimit?.toLocaleString()}원
        </TextBoxContentsHighlight>
      </TextBox>
      <TextBox>
        <TextBoxTitle>
          대출 신청 희망 금액 (단위: {loanDetailData?.limitUnitName})
        </TextBoxTitle>
        <TextField
          title="대출 신청 희망 금액"
          maxLength={100}
          placeholder="신청 금액"
          onChange={onChangeLoanAmount}
          onFocus={onFocus}
          value={loanAmountText || ''}
          style={{ margin: '0px' }}
          onBlur={onBlur}
          showMessage={inputMessages}
          message={inputMessages}
          step={10000}
        />
      </TextBox>
      <TextBox>
        <TextBoxDiv style={{ margin: '0 0 10px' }}>
          <TextBoxTitle>대출 담당자</TextBoxTitle>
          {isFinanceInstDeutsch === false &&
            dataUseType !== 'CREDIT_LOAN_CMPRS' &&
            isRepresentation && (
              <StyledActiveButton $small onClick={onClickShowManagerModal}>
                담당자 선택
              </StyledActiveButton>
            )}
        </TextBoxDiv>
        <TextBoxDiv>
          <TextBoxDivTextField value={selectedManager?.managerName || ''}>
            {selectedManager?.managerName || '대출 담당자'}
          </TextBoxDivTextField>
        </TextBoxDiv>
      </TextBox>

      <Contents>
        {dataUseType === 'CREDIT_LOAN_CMPRS'
          ? `[주요 안내]
            데이터 제공 동의 시, 연동된 판매몰의 매출 및 정산 데이터가 금융기관에 제공됩니다.`
          : `[주요 안내]
            데이터 제공 동의 시, 동의한 판매몰의 정산 기간 내 정산 예정금은 타 금융기관에 담보로 제공할 수 없습니다.`}
      </Contents>

      {stipulationList.length > 0 && (
        <PolicyContents
          stipulationList={stipulationList}
          setStipulationList={setStipulationList}
          onClickViewBtn={onClickViewBtn}
          noText={true}
        />
      )}

      <WideButton
        onClick={onClickRequestLoan}
        disabled={isRequestBtnDisabled}
        subText="데이터 제공 동의 후"
        text={
          dataUseType === 'CREDIT_LOAN_CMPRS'
            ? '신용 대출 신청 완료'
            : '담보 대출 신청 완료'
        }
      />
      <ChildText>
        <ChildTextContent>
          대출 심사 후 대출 금액은 변동될 수 있습니다.
        </ChildTextContent>
      </ChildText>

      {/* 매니저 선택 모달 */}
      <SelectManagerModal
        showSelectManagerModal={showSelectManagerModal}
        setShowSelectManagerModal={setShowSelectManagerModal}
        onClickSelectManager={onClickSelectManager}
        onCloseManagerModal
        bankName={loanDetailData?.financeInstitutionName}
        setSelectedManager={setSelectedManager}
        selectedManager={selectedManager}
        managerList={validManagerList}
      />

      {/* 기본적인 통신 alert 모달  */}
      <NoBtnAlertModal
        showNoBtnAlertModal={showNoBtnAlertModal}
        setShowNoBtnAlertModal={setShowNoBtnAlertModal}
        showNoBtnAlertModalText={showNoBtnAlertModalText}
      />
      <OneBtnAlertModal
        showOneBtnAlertModal={showOneBtnAlertModal}
        setShowOneBtnAlertModal={setShowOneBtnAlertModal}
        showOneBtnAlertModalText={showOneBtnAlertModalText}
        showOneBtnAlertModalBtnText={showOneBtnAlertModalBtnText}
        onClickModalButton={onClickModalButton}
      />
      <TwoBtnAlertModal
        showTwoBtnAlertModal={showTwoBtnAlertModal}
        showTwoBtnAlertModalText={showNoBtnAlertModalText}
        showTwoBtnAlertModalBtnText={showTwoBtnAlertModalBtnText}
        onClose={() => setShowTwoBtnAlertModal(false)}
        onClickLeftBtn={() => onClickNavigate(mypage.checkLoan)}
        onClickRightBtn={() => onClickNavigate(mypage.manageLoanDataProvide)}
      />
    </>
  );
};

export default LoanRequest;
