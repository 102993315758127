import React, { useEffect, useState } from 'react';
import api from 'utils/api';
import ROUTE_PATH from 'routes/config';
import SelectBox from 'components/SelectBox';
import { useNavigate } from 'react-router-dom';
import {
  JoinMemberPolicyPopupDetailContent,
  JoinMemberPolicyPopupDetailLine,
  JoinMemberPolicyPopupDetailWrapper,
  JoinPolicyTitle,
  JoinPolicyDiv,
  JoinPolicyBottom,
} from './style';
import { getKoreanFormatDate } from 'tools/DateTool';

const JoinMemberPolicyPopupDetail = ({ stipulationSeq = 0 }) => {
  const navigate = useNavigate();
  const [stipulation, setStipulation] = useState({});
  const [subStipulationList, setSubStipulationList] = useState([]);

  useEffect(() => {
    getStipulation({
      stipulationSeq,
      setStipulation,
      navigate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stipulationSeq]);

  useEffect(() => {
    if (stipulation?.effectiveDateList?.length > 0) {
      setSubStipulationList(
        stipulation.effectiveDateList.map(item => {
          const effectiveDateArr = item.effectiveDate?.split('-');
          return {
            value: `${item.stipulationSeq}`,
            label: `시행일 - ${
              effectiveDateArr && effectiveDateArr.length === 3
                ? `${effectiveDateArr[0]}년 ${effectiveDateArr[1]}월 ${effectiveDateArr[2]}일`
                : 'None'
            }`,
          };
        })
      );
    }
  }, [stipulation]);

  const onChangeStipulation = event => {
    const selectedStipulationSeq = event.target.value;
    navigate(
      `${ROUTE_PATH.login.joinMemberPolicyDefault}/${selectedStipulationSeq}`
    );
  };

  return (
    <div>
      <JoinMemberPolicyPopupDetailLine />
      <JoinMemberPolicyPopupDetailWrapper>
        {subStipulationList && subStipulationList.length > 1 && (
          <SelectBox
            optionList={subStipulationList}
            optionHandleChange={onChangeStipulation}
            value={stipulationSeq}
            isNotDisabled={true}
          />
        )}
        <JoinPolicyTitle>{stipulation?.stipulationCdName}</JoinPolicyTitle>
        <JoinMemberPolicyPopupDetailContent
          dangerouslySetInnerHTML={{
            __html:
              stipulation?.stipulationContents ||
              '<div style="display: flex; justify-content: center; align-items: center; min-height: 100px;">Not Available<div>',
          }}
        />
        <JoinPolicyBottom>
          <JoinPolicyDiv>부칙</JoinPolicyDiv>
          <JoinPolicyDiv>
            본 약관은 {getKoreanFormatDate(stipulation.stipulationBeginDate)}
            부터 적용됩니다.
          </JoinPolicyDiv>
        </JoinPolicyBottom>
      </JoinMemberPolicyPopupDetailWrapper>
    </div>
  );
};

const getStipulation = ({ stipulationSeq, setStipulation, navigate }) => {
  api({
    url: `/v1/na/stipulation/${stipulationSeq}`,
    method: 'get',
  })
    .then(res => {
      const { data } = res;
      if (data.success) {
        setStipulation(data.data.result);
      } else {
        navigate(ROUTE_PATH.login.joinMemberPolicyDefault);
      }
    })
    .catch(err => {
      console.error(err);
      navigate(ROUTE_PATH.login.joinMemberPolicyDefault);
    });
};

export default JoinMemberPolicyPopupDetail;
