import styled from 'styled-components';
import { Tab } from '@headlessui/react';
import theme from 'styles/theme';

export const StyledTabList = styled(Tab.List)`
  display: inline-flex;
  width: 100%;
  border-radius: 50px;
  background-color: ${theme.backgroundColor.contentBox};
`;

export const SecondaryTabList = styled(Tab.List)`
  display: inline-flex;
  width: 100%;
`;

const DefaultTab = styled(Tab)`
  width: 100%;
  height: 51px;
  line-height: 51px;

  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;

  color: ${theme.color.policyGrey};

  -webkit-transition-duration: 0.25s; /* Safari, Chrome */
  transition-duration: 0.25s;
`;

export const StyledTab = styled(DefaultTab)`
  border: 1.5px solid ${theme.borderColor.contentBox};
  border-radius: 50px;
  background-color: ${theme.backgroundColor.contentBox};

  &:first-child {
    border-start-start-radius: 50px;
    border-end-start-radius: 50px;
  }

  &:last-child {
    border-start-end-radius: 50px;
    border-end-end-radius: 50px;
  }

  ${props => {
    if (props.selected) {
      return `
        border-radius: 50px;
        background-color: ${theme.backgroundColor.white};
        color: ${theme.color.blue};
      `;
    }
  }}
`;

export const SecondaryTab = styled(DefaultTab)`
  border-bottom: 1.5px solid ${theme.borderColor.contentBox};

  @media screen and (max-width: 375px) {
    font-size: 1.4rem;
  }

  ${props => {
    if (props.selected) {
      return `
        border-bottom: 2px solid ${theme.color.blue};
        background-color: ${theme.backgroundColor.white};
        color: ${theme.color.blue};
      `;
    }
  }}
`;
