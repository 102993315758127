import styled from 'styled-components';
import theme from 'styles/theme';

export const StyledMain = styled.main`
  margin-top: 20px;
`;
export const NoticeSection = styled.div`
  display: block;
  width: 100%;
  padding: 25px 20px;
  border-radius: 10px;
  background: ${theme.backgroundColor.contentBox};
`;

export const TitleBox = styled.p`
  font-size: ${theme.fontSize.h3};
  font-weight: 600;
  margin-bottom: 20px;
`;

export const LinkedCount = styled.span`
  color: ${theme.color.blue};
`;

export const MessageBox = styled.p`
  white-space: pre-wrap;
  font-size: 1.6rem;
  line-height: 2.4rem;

  @media screen and (max-width: 320px) {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

export const MessageDiv = styled.div`
  white-space: pre-wrap;
  font-size: 1.4rem;
  line-height: 2.1rem;
`;
