import React, { useState } from 'react';
import { modifyDataRequest } from 'utils/request';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import TextField from 'components/TextField';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { NoBtnModal } from 'components/Modal';
import SelectManagerModal from 'pages/MyPage/DataLinkage/DataLinkageRequest/SelectManagerModal';
import {
  FirstText,
  ChildTextRow,
  ChildTextContent,
} from 'pages/MyPage/DataLinkage/style';
import { ScrollArea, Contents } from 'components/Modal/style';
import { DataLinkagePaddingWrapper } from 'pages/MyPage/DataLinkage/DataLinkageRequest/style';

const IndividualModifyStep3 = ({
  modifiedDataInterlockInfo,
  setModifiedDataInterlockInfo,
  setStep,
  setIsResetBtnClicked,
  setPossibleInterlockYn,
  isRepresentation,
}) => {
  const {
    mallName,
    mallMainId,
    mallMainPassword,
    secondAuthReleaseYn,
    name,
    mobilePhone,
    dataProviderSeq,
    businessDataProviderMallMappingSeq,
    mallSeq,
    partnerCode,
    secondAuthType,
    secondAuthValue,
  } = modifiedDataInterlockInfo;

  //모달 state
  const [isSelectManagerModalOpen, setIsSelectManagerModalOpen] =
    useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  // '처음으로 돌아가기' 버튼 클릭시
  const onClickReset = () => {
    setStep(1);
    setIsResetBtnClicked(true);
  };

  // '데이터 연동 정보 수정' 버튼 클릭시
  const onClickModifyButton = () => {
    const seq = businessDataProviderMallMappingSeq;
    const url = `/v1/au/data-provider/individual`;

    const data =
      mallSeq === 10017
        ? {
            mallId: mallMainId,
            mallPassword: mallMainPassword,
            secondAuthReleaseYn,
            name,
            mobilePhone,
            dataProviderSeq,
            partnerCode,
            secondAuthType,
            secondAuthValue,
          }
        : mallSeq === 10019
        ? {
            mallId: mallMainId,
            mallPassword: mallMainPassword,
            secondAuthReleaseYn,
            name,
            mobilePhone,
            dataProviderSeq,
            secondAuthType,
            secondAuthValue,
          }
        : {
            mallId: mallMainId,
            mallPassword: mallMainPassword,
            secondAuthReleaseYn,
            name,
            mobilePhone,
            dataProviderSeq,
          };

    const successFnc = data => {
      setStep(4);
      setPossibleInterlockYn(data.possibleInterlockYn);
    };

    modifyDataRequest({
      url: `${url}/${seq}`,
      data: data,
      setModalMessage,
      setShowModal: setIsMessageModalOpen,
      setShowAlertModal: setIsMessageModalOpen,
      successFnc,
    });
  };

  return (
    <DataLinkagePaddingWrapper>
      <FirstText>
        <ChildTextRow style={{ marginBottom: '23px' }}>
          <FeatherIcon icon="chevron-left" size={18} />
          <ChildTextContent
            onClick={onClickReset}
            style={{
              margin: '0px 0px 0px 5px',
              fontSize: '1.4rem',
              cursor: 'pointer',
            }}
          >
            처음으로 돌아가기
          </ChildTextContent>
        </ChildTextRow>
        {mallName} 계정 확인이 완료되었어요. <br />
        마지막 단계예요.
        <br />
        데이터 연동 요청을 진행해 주세요.
      </FirstText>
      {/* 담당자 수정 */}

      <ChildTextRow style={{ marginTop: '23px' }}>
        <ChildTextContent bold>연동 결과 안내 담당자</ChildTextContent>
        {isRepresentation && (
          <StyledActiveButton
            $small
            onClick={() => setIsSelectManagerModalOpen(true)}
          >
            담당자 선택
          </StyledActiveButton>
        )}
      </ChildTextRow>

      <TextField
        name="managerName"
        placeholder="연동 결과를 안내받을 담당자를 선택해 주세요."
        value={name}
        readOnly
        style={{ margin: '0' }}
      />

      {/* 담당자 수정 모달 */}
      <SelectManagerModal
        showSelectManagerModal={isSelectManagerModalOpen}
        setShowSelectManagerModal={setIsSelectManagerModalOpen}
        mallName={mallName}
        setDataInterlockInfo={setModifiedDataInterlockInfo}
        managerName={name}
      />
      <StyledActiveButton
        disabled={mallMainPassword === '' || mallMainId === ''}
        onClick={onClickModifyButton}
      >
        데이터 연동 요청
      </StyledActiveButton>

      {/* alert 메세지 모달*/}
      <NoBtnModal
        showModal={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
      >
        <ScrollArea>
          <Contents>{modalMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </DataLinkagePaddingWrapper>
  );
};

export default IndividualModifyStep3;
