import React from 'react';
import { makeEllipsisString } from 'tools/StringTool';
import {
  ContentBox,
  ContentImg,
  ContentImgNull,
  ContentText,
  ModalSectionTitle,
} from './style';

const MallListSection = ({
  mallListData = {},
  onChangeSelectProvider = () => {},
}) => {
  const changelistToBundleOfThree = list => {
    const mallList = [];
    for (let i = 0; i < list?.length; i += 3) {
      const chunk = list?.slice(i, i + 3);
      mallList.push(chunk);
    }
    return mallList;
  };

  const modifiedMallList = changelistToBundleOfThree(mallListData?.list);

  return (
    mallListData?.list?.length > 0 && (
      <div key={mallListData.name}>
        <ModalSectionTitle>{mallListData.name}</ModalSectionTitle>
        {modifiedMallList?.length > 0 &&
          modifiedMallList?.map((item, index) => (
            <div style={{ display: 'flex' }} key={index}>
              {item?.map(item => (
                <ContentBox
                  key={item.mallName}
                  onClick={() =>
                    onChangeSelectProvider({
                      ...item,
                    })
                  }
                >
                  {item.fileUrl ? (
                    <ContentImgNull>
                      <ContentImg src={item.fileUrl} />
                    </ContentImgNull>
                  ) : (
                    <ContentImgNull />
                  )}
                  <ContentText>
                    {makeEllipsisString(item.mallName, 8)}
                  </ContentText>
                </ContentBox>
              ))}
            </div>
          ))}
      </div>
    )
  );
};

export default MallListSection;
