import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { getDataRequest } from 'utils/request';
import { KEY_DATAPROVIDEAGREESEQ } from 'tools/WebTool';
import { NoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import DataTable from 'components/Table/DataTable';
import {
  LoanCard,
  LoanCardColumn,
  LoanBankName,
  LoanCardRow,
  LoanCardRowSecond,
  DetailModalWrap,
  DetailModalTitle,
  DetailModalTitleTable,
  DetailModalData,
  DetailModalText,
  ButtonWrap,
  RoundBox,
} from '../../../LoanDataProvideManage/LoanManageTab/LoanProductsList/style';
import {
  CardContainer,
  DetailButton,
  DetailButtonContainer,
  Flag,
  NoticeText,
  MallNameSpan,
  DateSpan,
} from './style';
import { DATA_PROVIDE_STATUS_NAME } from './constants';

// table header
const tableHead = ['날짜', '상태'];
const DataProvideAgreeList = ({ item }) => {
  const navigate = useNavigate();
  const [viewDetailModal, setViewDetailModal] = useState(false);
  const [dataProvideAgreeDetailData, setDataProvideAgreeDetailData] = useState({
    dataProvideAgreeSeq: '',
    processDt: '',
    dataProvideTypeName: '',
    creditGoodsName: '',
    dataProvideAgreePossibleYn: '',
    dataProvideAgreeSecuredTypeMallList: [],
  });
  const [tableData, setTableData] = useState([]);
  const [toolTipModal, setToolTipModal] = useState(false);
  const [backendAlertMessage, setBackendAlertMessage] = useState('');
  const [locationReload, setLocationReload] = useState(false);

  const {
    creditRequestSeq,
    financeInstitutionName,
    mallList,
    dataProvideStatus,
    dataProvideTypeName,
    creditGoodsName,
    processDt,
    dataProvideAgreeSeq,
  } = item;

  const isValidBtn =
    (dataProvideStatus === '미확인' || dataProvideStatus === '제공불가') &&
    dataProvideAgreeDetailData?.dataProvideAgreePossibleYn === 'N';

  const mallSeqList =
    !!dataProvideAgreeDetailData?.dataProvideAgreeSecuredTypeMallList &&
    dataProvideAgreeDetailData?.dataProvideAgreeSecuredTypeMallList?.map(
      mall => mall?.mallSeq
    );

  const showToolTipModal = () => {
    setToolTipModal(!toolTipModal);
  };

  // 상세보기 Modal창 관리 함수
  const onClickShowDetailModal = creditRequestSeq => {
    setViewDetailModal(!viewDetailModal);
    getCreditRequestDetail(creditRequestSeq);
  };

  const onCloseShowDetailModal = () => {
    setViewDetailModal(!viewDetailModal);
  };

  // 모달 상세 & 테이블 목록 가져오기
  const getCreditRequestDetail = () => {
    const successFnc = data => {
      setDataProvideAgreeDetailData(data?.result || []);
      if (data?.hisList) {
        setTableData([
          ...data?.hisList
            ?.filter(item => item?.changeAfterStatus !== 'UNIDENTIFIED')
            ?.map(item => ({
              historyDate: item?.processDt,
              dataProvideAgreeStatus:
                DATA_PROVIDE_STATUS_NAME[item?.changeAfterStatus],
            })),
        ]);
      }
    };

    getDataRequest({
      url: `/v1/au/data-provide-agree/detail/${dataProvideAgreeSeq}`,
      successFnc: successFnc,
    });
  };

  const onCloseBackendAlertModal = () => {
    if (locationReload) {
      window.location.reload();
    } else {
      setBackendAlertMessage('');
    }
    setLocationReload(false);
  };

  const onClickDataProvideAgreeBtn = () => {
    if (!!dataProvideAgreeSeq) {
      localStorage.setItem(KEY_DATAPROVIDEAGREESEQ, dataProvideAgreeSeq);
      navigate(ROUTE_PATH.deepLink.agree);
      return;
    }
    setBackendAlertMessage('데이터 제공 동의 seq가 존재하지 않습니다.');
  };

  return (
    <LoanCard>
      <LoanCardColumn>
        <CardContainer>
          <LoanBankName>{financeInstitutionName}</LoanBankName>
          <DetailButtonContainer>
            {dataProvideStatus === '미확인' && <Flag>확인필요</Flag>}
            <DetailButton
              onClick={() => onClickShowDetailModal(creditRequestSeq)}
            >
              {dataProvideStatus + ' >'}
            </DetailButton>
          </DetailButtonContainer>
        </CardContainer>

        <LoanCardRow>
          <LoanCardRowSecond>
            {`${dataProvideTypeName} ${creditGoodsName?.replaceAll('\b', '')}`}
          </LoanCardRowSecond>
        </LoanCardRow>
        <LoanCardRow>
          <LoanCardRowSecond>{mallList}</LoanCardRowSecond>
        </LoanCardRow>
        <LoanCardRow>
          <LoanCardRowSecond>{processDt}</LoanCardRowSecond>
        </LoanCardRow>

        {/* 데이터 제공 상세 모달 */}
        <NoBtnModal
          title={`[${dataProvideStatus}] ${financeInstitutionName}`}
          showModal={viewDetailModal}
          onClose={onCloseShowDetailModal}
        >
          <ScrollArea title="title">
            <Contents>
              {dataProvideStatus === '제공불가' && (
                <NoticeText>
                  데이터 제공 동의 정보가 고객님의 정산예정금 정보와 일치하지
                  않아 데이터 제공이 불가한 상태예요. 금융기관 혹은 고객센터로
                  문의해 주세요.
                </NoticeText>
              )}
              <ButtonWrap>
                <StyledActiveButton
                  onClick={onClickDataProvideAgreeBtn}
                  disabled={!isValidBtn}
                >
                  데이터 제공 동의 확인하기
                </StyledActiveButton>
              </ButtonWrap>
              <DetailModalWrap>
                <DetailModalTitle>신청번호</DetailModalTitle>
                <DetailModalData>
                  <DetailModalText>
                    {`${dataProvideAgreeDetailData?.dataProvideAgreeSeq} | ${dataProvideAgreeDetailData?.processDt}`}
                  </DetailModalText>
                </DetailModalData>
                <DetailModalTitle>데이터 제공 상품</DetailModalTitle>
                <DetailModalData>
                  <DetailModalText>
                    {`${dataProvideAgreeDetailData?.dataProvideTypeName} ${dataProvideAgreeDetailData?.creditGoodsName}`}
                  </DetailModalText>
                </DetailModalData>
                {dataProvideStatus === '승인' && (
                  <>
                    <DetailModalTitle>
                      데이터 제공몰
                      {dataProvideAgreeDetailData?.mallCount > 0 &&
                        `(${dataProvideAgreeDetailData?.mallCount})`}
                    </DetailModalTitle>
                    {dataProvideAgreeDetailData?.dataProvideType ===
                      'UNSECURED' && (
                      <DetailModalData>
                        <DetailModalText>
                          {
                            dataProvideAgreeDetailData?.dataProvideAgreeUnsecuredTypeMallList
                          }
                        </DetailModalText>
                      </DetailModalData>
                    )}
                    {dataProvideAgreeDetailData?.dataProvideType ===
                      'SECURED' && (
                      <DetailModalData>
                        {dataProvideAgreeDetailData?.dataProvideAgreeSecuredTypeMallList?.map(
                          mall => {
                            return (
                              <RoundBox key={mall.mallSeq}>
                                <MallNameSpan>
                                  {`${mall?.mallName}`}
                                </MallNameSpan>
                                <DateSpan>{`${mall?.settlementBeginDt} ~ ${mall?.settlementEndDt}`}</DateSpan>
                              </RoundBox>
                            );
                          }
                        )}
                      </DetailModalData>
                    )}
                  </>
                )}
                {tableData?.length > 0 && (
                  <>
                    <DetailModalTitleTable>
                      데이터 제공 상태
                    </DetailModalTitleTable>
                    <DataTable
                      tableName="DataProvideAgreeRequest"
                      tableHead={tableHead}
                      tableData={tableData}
                    />
                  </>
                )}
              </DetailModalWrap>
            </Contents>
          </ScrollArea>
        </NoBtnModal>

        {/* Alert 모달  */}
        <NoBtnModal
          showModal={!!backendAlertMessage}
          onClose={onCloseBackendAlertModal}
        >
          <ScrollArea>
            <Contents>{backendAlertMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>

        <NoBtnModal
          showModal={toolTipModal}
          onClose={showToolTipModal}
          title="기한만료란?"
        >
          <ScrollArea title="title">
            <Contents>
              대출 신청 3일 내 금융기관 담당자가 확인 안하면 신청을 종료해요.
              다른 금융기관에 대출 신청할 기회를 드리기 위함이에요.
            </Contents>
          </ScrollArea>
        </NoBtnModal>
      </LoanCardColumn>
    </LoanCard>
  );
};

export default DataProvideAgreeList;
