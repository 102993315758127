import styled from 'styled-components';
import theme from 'styles/theme';
import {
  AreaWrapper as _AreaWrapper,
  AreaRow as _AreaRow,
  flexCustom,
} from 'styles/styleArea';

export const AreaWrapper = styled(_AreaWrapper)`
  padding-top: 80px; // topbar + defaultPadding
`;
export const AreaRow = styled(_AreaRow)`
  ${flexCustom('column', 'center', 'center')}
`;

export const H1 = styled.h1`
  line-height: 1.5;
  font-size: 6rem;
  font-weight: 500;
  color: ${theme.color.grey4};
`;

export const H3 = styled.h3`
  margin: 30px 0 17px 0;
  font-weight: 500;
  line-height: 1.2;
  color: ${theme.color.grey4};
  text-transform: uppercase;
`;

export const P = styled.p`
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: ${theme.color.grey9};
`;
