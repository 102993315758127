import React from 'react';
import { BlackLabel, FooterWrapper } from './style';

const FooterInfo = () => {
  return (
    <FooterWrapper>
      <BlackLabel style={{ padding: '0 20px' }}>주요 안내 사항</BlackLabel>
      <ul style={{ padding: '0 30px' }}>
        <li>
          연동된 판매몰은 판매자가 연동된 판매몰의 갯수를 모두 노출합니다.
        </li>
        <li>
          담보 대출 가능 판매몰은 현재 담보로 사용 가능한 판매몰의 판매예정금이
          존재하는 판매몰을 모두 노출합니다.
        </li>
        <li>
          신용 대출 가능 판매몰은 판매예정금이 존재하는 판매몰을 모두
          노출합니다. 현재 담보로 활용되는 판매몰 또한 포함되어 노출합니다.
        </li>
        <li>신용 대출은 대표자 본인만 신청이 가능합니다.</li>
      </ul>
    </FooterWrapper>
  );
};

export default FooterInfo;
