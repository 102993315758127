import { TwoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import React from 'react';
import { deleteDataRequest } from 'utils/request';

const DeleteModal = ({
  showDeleteModal,
  deleteModalInfo,
  toggleDeleteModal,
  successFnc,
  setAlertModal,
  setAlertMessage,
}) => {
  const onClickRightButton = () => {
    const errorFnc = () => {
      toggleDeleteModal();
    };
    deleteDataRequest({
      url: `/v1/au/manager/${deleteModalInfo.businessManagerSeq}`,
      successFnc,
      errorFnc,
      serverErrorFnc: errorFnc,
      setModalMessage: setAlertMessage,
      setShowModal: setAlertModal,
    });
  };

  return (
    !!deleteModalInfo && (
      <TwoBtnModal
        showModal={showDeleteModal}
        onClose={toggleDeleteModal}
        onClick={onClickRightButton}
        leftButton="아니오"
        rightButton="예"
      >
        <ScrollArea>
          <Contents>
            {deleteModalInfo.managerName}님을 직원에서 삭제하시겠어요?
          </Contents>
        </ScrollArea>
      </TwoBtnModal>
    )
  );
};

export default DeleteModal;
