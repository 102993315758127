import React from 'react';
import {
  LoanDetailFooterWrapper,
  AreaBox,
  Label,
  List,
  ListIcon,
  ListText,
} from './style';

const LoanDetailFooter = ({ detailList = [] }) => {
  return (
    <LoanDetailFooterWrapper>
      {detailList.map(item => {
        return (
          <AreaBox key={item.detailTitle}>
            <Label>{item.detailTitleName}</Label>
            {item.contentsList &&
              item.contentsList.map((item, index) => {
                return (
                  <List key={index}>
                    <ListIcon />
                    <ListText>{item.detailContents}</ListText>
                  </List>
                );
              })}
          </AreaBox>
        );
      })}
    </LoanDetailFooterWrapper>
  );
};

export default LoanDetailFooter;
