import { footerInfo } from 'messages/contactInfo';

export const AFFILIATE_FINANCE_CONTENT =
  `<p>㈜에이젠글로벌은 아래와 같은 금융회사와 대출모집 위탁계약을 체결한 금융감독원 등록 (${footerInfo.loanOriginatorRegistrationNumber}) 대출 모집법인입니다.</p>` +
  '<br/>' +
  '<div class="padding">' +
  '<ul>' +
  '<li id="affiliateFinance-item" style="display: none;"><a id="affiliateFinance" role="button">에이젠글로벌과 제휴한 금융기관 보기</a></li>' +
  "<li><a href='https://cdn.sellercredit.co.kr/financial-supervisory-service/Loan_Originator_Registration_Certificate.pdf' target='_blank' rel='noopener noreferrer'>에이젠글로벌의 대출모집인 등록증 보기</a></li>" +
  "<li><a href='https://www.fss.or.kr/fss/bbs/B0000315/view.do?nttId=134932&menuNo=200754&pageIndex=1' target='_blank' rel='noopener noreferrer'>금융감독원 대출모집법인 조회</a></li>" +
  '</ul>' +
  '</div>' +
  '<br/>' +
  '<ul>' +
  '<li>㈜에이젠글로벌이 고의 또는 과실로 금융소비자보호에 관한 법률을 위반하여 금융소비자에게 손해를 발생시킨 경우에는 그 손해를 배상할 책임이 있습니다.</li>' +
  '<li>대출실행결정은 심사를 통하여 금융상품직접판매업자인 금융회사에서 하며, ㈜에이젠글로벌은 대출 계약을 체결할 권한이 없습니다.</li>' +
  '<li>대출 실행시 개인신용평점이 하락될 수 있으며, 이로 인하여 금융거래에 있어서 불이익이 발생할 수 있습니다.</li>' +
  '<li>㈜에이젠글로벌은 대출중개에 있어 금융소비자인 고객에게 별도의 수수료를 요구하거나 수령하지 않습니다.</li>' +
  '<li>본 플랫폼을 통하여 확인되는 금리 및 대출금액 한도는 금융회사에서 가심사한 결과로, 최종 대출 실행시 한도 및 금리가 달라질 수 있습니다.</li>' +
  '</ul>';

export const REQUEST_DOCUMENT_ACCESS_CONTENT =
  '' +
  '<ol>' +
  '<li>금융소비자는 금융소비자 보호에 관한 법률 제28조 제3항에 따라 분쟁조정 및 소송의 수행 등 소비자 권리 구제 목적의 사유로서 회사에 자료 등의 열람을 요구할 수 있으며 회사는 아래와 같은 경우 금융소비자에게 그 사유를 알리고 열람을 제한하거나 거절할 수 있습니다.' +
  '<ul>' +
  '<li>법령에 따라 열람을 제한하거나 거절할 수 있는 경우</li>' +
  '<li>다른 사람의 생명ㆍ신체를 해칠 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</li>' +
  '<li>그 밖에 열람으로 인하여 해당 금융회사의 영업비밀이 현저히 침해되는 등 열람하기 부적절한 경우로서 법령으로 정하는 경우</li>' +
  '<li>열람 대상 자료가 열람 목적과 관련이 없는 경우</li>' +
  '</ul>' +
  '</li>' +
  '<br/>' +
  '<li>자료열람의 대상은 회사가 대출성상품 금융상품 판매 대리ㆍ중개업자로서 제공하는 셀러크레딧커넥트 서비스와 관련한 자료로서 회사가 보유ㆍ관리하는 자료에 한하여 자료 열람의 요구를 할 수 있습니다.</li>' +
  '<br/>' +
  '<li>자료열람 요구의 방법은 증빙서류와 함께 회사의 소정 양식을 작성하여 아래 이메일로 전송합니다.' +
  '<p>consumer@sellercredit.co.kr</p>' +
  '<br/>' +
  '<div class="bold underline"><a href="https://cdn.sellercredit.co.kr/financial-supervisory-service/Document_Request_Forms.docx" target="_blank" rel="noopener noreferrer">자료열람 요구서 다운로드</a></div>' +
  '</li>' +
  '<br/>' +
  '<li>회사는 금융소비자가 열람을 요구하는 경우 법령에서 정하는 바에 따라 수수료와 우송료(사본의 우송을 청구하는 경우만 해당)를 청구할 수 있습니다.</li>' +
  '</ol>';
