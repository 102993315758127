import React from 'react';
import {
  CSTitle,
  Footer,
  Label,
  UnderlinedText,
} from 'layouts/Footer/CustomerServiceFooter/style';
import { AreaBox1, AreaRow } from 'styles/styleArea';

const JoinMemberFooter = ({ onClickQnA }) => {
  return (
    <Footer>
      <AreaRow>
        <AreaBox1>
          <CSTitle>상담원 도움이 필요하신가요?</CSTitle>
        </AreaBox1>
      </AreaRow>
      <AreaRow marginTop={10}>
        <AreaBox1>
          <Label>
            사업자 등록번호 인증이 되지 않을 경우 사업자등록증 사본을 첨부하여{' '}
            <UnderlinedText onClick={onClickQnA}>1:1 문의</UnderlinedText>{' '}
            남겨주세요.
          </Label>
        </AreaBox1>
      </AreaRow>
    </Footer>
  );
};

export default JoinMemberFooter;
