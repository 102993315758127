import styled from 'styled-components';
import theme from 'styles/theme';

export const CardWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding: 20px 0 24px;
  border-bottom: 1px solid ${theme.borderColor.lightGrey};
  border-top: ${props =>
    props.borderTop ? `1px solid ${theme.borderColor.lightGrey}` : 'none'};

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const CardColumn = styled.div`
  position: relative;
  float: right;
  width: 100%;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

export const CardName = styled.div`
  display: ${props => (props.isOpen ? 'block' : '-webkit-box')};
  width: calc(100% - 36px);
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: ${theme.color.black};
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  -webkit-line-clamp: 2; /* 크롬, 사파리 */
  -moz-line-clamp: 2; /* 파이어폭스 */
  -o-line-clamp: 2; /* 오페라 */
  -ms-line-clamp: 2; /* 익스플로러 */
  line-clamp: 2;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
`;

export const CardContent = styled.div`
  display: block;
  width: 100%;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const CardContentText = styled.div`
  display: -webkit-box; /** line-clamp를 사용하기 위해서 설정해야 함 */
  width: calc(100% - 36px);
  margin: 10px 0 18px;

  line-height: 1.6rem;
  font-size: 1.3rem;
  font-weight: 400;
  color: ${theme.color.grey7};

  overflow: hidden; /** line-clamp를 사용하기 위해서 설정해야 함 */
  -webkit-line-clamp: 1; /** 크롬, 사파리 */
  -moz-line-clamp: 1; /** 파이어폭스 */
  -o-line-clamp: 1; /** 오페라 */
  -ms-line-clamp: 1; /** 익스플로러 */
  line-clamp: 1;

  -webkit-box-orient: vertical; /** line-clamp를 사용하기 위해서 설정해야 함 */
  -moz-box-orient: vertical;
`;

export const CardContentDuration = styled.div`
  float: left;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.3rem;
`;

export const CardContentDDay = styled.span`
  float: right;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.3rem;
  color: ${theme.color.grey7};
`;

export const DetailButton = styled.button`
  position: absolute;
  display: block;
  width: 18px;
  height: 18px;
  top: 2px;
  right: 0;
  background: url(/images/icons/dropdown/main_ar_s2.png) right no-repeat;
  background-size: 6px 11px;
`;
