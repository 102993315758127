import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import {
  AreaWrapper as _AreaWrapper,
  AreaRow as _AreaRow,
  AreaBox1 as _AreaBox1,
  AreaBox2 as _AreaBox2,
  AreaBox3 as _AreaBox3,
  AreaBox4 as _AreaBox4,
  AreaContent as _AreaContent,
} from 'styles/styleArea';

export const AreaWrapper = styled(_AreaWrapper)``;
export const AreaRow = styled(_AreaRow)``;
export const AreaBox1 = styled(_AreaBox1)``;
export const AreaBox2 = styled(_AreaBox2)``;
export const AreaBox3 = styled(_AreaBox3)``;
export const AreaBox4 = styled(_AreaBox4)``;
export const AreaContent = styled(_AreaContent)``;

export const LayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: ${theme.layout.minWidth}; /*본 화면의 최소 width를 설정합니다.*/
  max-width: ${theme.layout.maxWidth};
  min-height: calc(
    (var(--vh, 1vh) * 100) + var(--ios-safe-top) + var(--ios-safe-bottom)
  );
  background: ${theme.backgroundColor.white};

  @media only screen and (min-width: 486px) and (max-width: 1199.9px) {
    position: relative;
    z-index: 10;
    max-width: ${theme.layout.maxWidth};
    margin: 0 auto;
    box-shadow: 0 2px 50px 0 rgb(0 0 0 / 13%);
  }

  @media only screen and (min-width: 1200px) {
    position: relative;
    z-index: 10;
    left: calc(50% + 12px);
    margin: 0;
    box-shadow: 0 2px 50px 0 rgb(0 0 0 / 13%);
    overflow-x: hidden;
  }
`;

export const MainContent = styled.div`
  position: relative;
  width: 100%;
  min-width: ${theme.layout.minWidth};
  min-height: 100vh;
  background: ${theme.backgroundColor.white};
  padding: ${props =>
    props.showBanner ? '100px 35px 90px' : '60px 35px 90px'};

  @media screen and (max-width: 485.9px) {
    padding: ${props =>
      props.showBanner ? '100px 27px 60px' : '60px 27px 60px'};

    ${props => {
      return css`
        ${getCssStrPadding(props)}
      `;
    }}
  }

  @media screen and (max-width: 375px) {
    padding: ${props =>
      props.showBanner ? '100px 20px 60px' : '60px 20px 60px'};

    ${props => {
      return css`
        ${getCssStrPadding(props)}
      `;
    }}
  }

  ${props => {
    return css`
      ${getCssStrPadding(props)}
    `;
  }}
`;

const getCssStrPadding = props => {
  let cssStr = '';
  if (props.noPadding === true) {
    cssStr += `padding: 0px;`;
    if (props.showBanner === true) {
      cssStr += `padding: ${theme.layout.topBanner} 0 0;`;
    }
  }
  if (props.paddingBottom === true) {
    cssStr += `padding: 0 0 40px`;
  }
  if (!!props.customPadding === true) {
    cssStr += `${props.customPadding}`;
  }
  return cssStr;
};
