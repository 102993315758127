export const RESULT_CONTENTS_LIST = [
  {
    label: '기간',
    value: 'eventPeriod',
    type: 'text',
  },
  {
    label: '대상',
    value: 'eventTarget',
    type: 'text',
  },
  {
    label: '혜택',
    value: 'eventBenefit',
    type: 'text',
  },
  {
    label: '이벤트 내용',
    value: 'contents',
    type: 'editor',
  },
  {
    label: '결과 발표일',
    value: 'resultPresentationDate',
    type: 'text',
  },
];

export const EVENT_TARGET_CD = [
  {
    name: '기본이벤트',
    code: 'ETC100',
  },
  {
    name: '참여이벤트',
    code: 'ETC200',
  },
];
