import styled from 'styled-components';
import theme from 'styles/theme';

export const BlueBackgroundBox = styled.button`
  display: flex;
  flex-direction: column;

  align-items: center;
  margin-top: 23px;
  padding: 10px;
  width: 100%;

  border-radius: 10px;
  background: ${theme.backgroundColor.blue};
  color: ${theme.color.white};

  cursor: pointer;

  &:disabled {
    background: ${theme.backgroundColor.buttonGrey};
    border: 1px solid ${theme.borderColor.buttonGrey};
    cursor: default;
  }
`;

export const SubText = styled.div`
  font-size: ${theme.fontSize.h5};
  color: ${theme.color.white};
`;

export const Text = styled.div`
  margin: 10px 0 0 0;
  font-size: ${theme.fontSize.h4};
  font-weight: ${theme.fontWeight.normal};
  color: ${theme.color.white};
`;
