import api from 'utils/api';
import qs from 'qs';
// 공통적으로 쓰이는 통신 함수를 정의합니다.
// 함수명은 method 종류에 따라 아래와 같이 통일합니다.
// get -> get
// post -> create
// delete -> delete
// put -> modify
// 중 하나로 시작하고 -Request로 끝납니다.

// businessName, businessRegistrationNumber(사업자번호)는 useApp의 auth에 담긴다.
export const getBusinessNameRequest = ({
  memberSeq,
  setMemberName,
  setBusinessName,
  setBusinessRegistrationNumber,
  setMemberTotalData,
}) => {
  api({
    url: `/v1/au/member/${memberSeq}`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
  })
    .then(res => {
      const { success, data } = res.data;
      if (success) {
        !!setMemberName &&
          typeof setMemberName === 'function' &&
          setMemberName(data.member?.memberName);
        !!setBusinessName &&
          typeof setBusinessName === 'function' &&
          setBusinessName(data.result?.businessName || '');
        !!setBusinessRegistrationNumber &&
          typeof setBusinessRegistrationNumber === 'function' &&
          setBusinessRegistrationNumber(
            data.result?.businessRegistrationNumber || ''
          );
        !!setMemberTotalData &&
          typeof setMemberTotalData === 'function' &&
          setMemberTotalData(data.member || '');
      }
    })
    .catch(err => {
      console.error(err);
    });
};

export const getDataRequest = ({
  url,
  setData,
  params,
  successFnc,
  errorFnc,
  exceptFnc,
  setModalMessage,
  setShowAlertModal,
}) => {
  api({
    url: url,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    params: !!params && params,
  })
    .then(res => {
      const { data, success, message, 'error-no': errorNo } = res.data;
      if (success) {
        !!successFnc && typeof successFnc === 'function' && successFnc(data);
        !!setData && typeof setData === 'function' && setData(data);
        !!setModalMessage &&
          typeof setModalMessage === 'function' &&
          !!message &&
          setModalMessage(message);
      } else {
        !!errorFnc &&
          typeof errorFnc === 'function' &&
          errorFnc(data, message, errorNo);
        !!setModalMessage &&
          typeof setModalMessage === 'function' &&
          !!message &&
          setModalMessage(message);
        !!setShowAlertModal &&
          typeof setShowAlertModal === 'function' &&
          !!message &&
          setShowAlertModal(true);
      }
    })
    .catch(err => {
      console.error(err.message);
      !!setModalMessage &&
        typeof setModalMessage === 'function' &&
        !!err.response?.data?.message &&
        setModalMessage(err.response.data.message);
      !!setShowAlertModal &&
        typeof setShowAlertModal === 'function' &&
        !!err.response?.data?.message &&
        setShowAlertModal(true);
      !!exceptFnc &&
        typeof exceptFnc === 'function' &&
        exceptFnc(err.response.data.message);
    });
};

/* arrayFormat 옵션 */
// 1. indices: 'a[0]=b&a[1]=c'
// 2. brackets: 'a[]=b&a[]=c'
// 3. repeat: 'a=b&a=c'
// 4. comma: 'a=b,c'

export const getListDataRequest = ({
  url,
  params,
  arrayFormat = 'brackets',
  setData,
  setPaginationData,
  setIsLoadingOver,
  successFnc,
  setModalMessage,
  setIsLoading,
}) => {
  api({
    url: url,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    params: !!params && params,
    paramsSerializer: params =>
      qs.stringify(params, { arrayFormat: arrayFormat }), // params를 직렬화(serializing)하는 옵션 함수
  })
    .then(res => {
      const { data, success } = res.data;
      if (success) {
        !!setData && typeof setData === 'function' && setData(data.list || []);
        !!setIsLoadingOver &&
          typeof setIsLoadingOver === 'function' &&
          setIsLoadingOver(true);
        !!setPaginationData &&
          typeof setPaginationData === 'function' &&
          setPaginationData(data.paging || {});
        !!successFnc &&
          typeof successFnc === 'function' &&
          successFnc(data.list || []);
        !!setIsLoading &&
          typeof setIsLoading === 'function' &&
          setIsLoading(false);
      }
    })
    .catch(err => {
      console.error(err.message);
      const { data } = err.response;
      !!setModalMessage &&
        typeof setModalMessage === 'function' &&
        setModalMessage(data.message);
    });
};

export const getResultDataRequest = ({
  url,
  setData,
  params,
  successFnc,
  setModalMessage,
  setShowAlertModal,
}) => {
  api({
    url: url,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    params: !!params && params,
  })
    .then(res => {
      const { data, success, message } = res.data;
      if (success) {
        !!setData && typeof setData === 'function' && setData(data?.result);
        !!successFnc &&
          typeof successFnc === 'function' &&
          successFnc(data?.result);
      } else {
        !!setModalMessage &&
          typeof setModalMessage === 'function' &&
          setModalMessage(message);
        !!setShowAlertModal &&
          typeof setShowAlertModal === 'function' &&
          setShowAlertModal(true);
      }
    })
    .catch(err => {
      console.error(err.message);
      const { data } = err.response;
      !!setModalMessage &&
        typeof setModalMessage === 'function' &&
        setModalMessage(data.message);
      !!setShowAlertModal &&
        typeof setShowAlertModal === 'function' &&
        setShowAlertModal(true);
    });
};

export const deleteDataRequest = ({
  url,
  successFnc = () => {},
  setModalMessage,
  setShowModal,
  params,
}) => {
  api({
    url: url,
    method: 'delete',
    params: !!params && params,
    headers: { 'content-type': 'application/json' },
  })
    .then(res => {
      const { success, message } = res.data;
      if (success) {
        successFnc();
        !!setModalMessage &&
          typeof setModalMessage === 'function' &&
          setModalMessage(message);
        !!setShowModal &&
          typeof setShowModal === 'function' &&
          setShowModal(true);
      }
    })
    .catch(err => {
      console.error(err);
      const { data } = err.response;
      !!setModalMessage &&
        typeof setModalMessage === 'function' &&
        setModalMessage(data.message);
      !!setShowModal &&
        typeof setShowModal === 'function' &&
        setShowModal(true);
    });
};

export const createDataRequest = ({
  url,
  data,
  successFnc = () => {},
  errorFnc = () => {},
  serverErrorFnc = () => {},
  setModalMessage,
  setShowModal,
  setShowAlertModal,
  successMessage,
  setIsLoading,
}) => {
  !!setIsLoading && typeof setIsLoading === 'function' && setIsLoading(true);

  api({
    url: url,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: !!data && data,
  })
    .then(res => {
      const { success, message, data } = res.data;
      if (success) {
        successFnc(data, message);
        !!setModalMessage &&
          typeof setModalMessage === 'function' &&
          setModalMessage(successMessage || message);
        !!setShowModal &&
          typeof setShowModal === 'function' &&
          setShowModal(true);
        !!setIsLoading && setIsLoading(false);
      } else {
        !!setModalMessage &&
          typeof setModalMessage === 'function' &&
          setModalMessage(message);
        !!message &&
          !!setShowAlertModal &&
          typeof setShowAlertModal === 'function' &&
          setShowAlertModal(true);
        !!errorFnc && typeof errorFnc === 'function' && errorFnc(data, message);
      }
    })
    .catch(err => {
      console.error(err);
      !!setModalMessage &&
        typeof setModalMessage === 'function' &&
        setModalMessage(err.response.data.message);
      !!setShowAlertModal &&
        typeof setShowAlertModal === 'function' &&
        setShowAlertModal(true);
      !!serverErrorFnc &&
        typeof serverErrorFnc === 'function' &&
        serverErrorFnc();
    })
    .finally(() => {
      !!setIsLoading &&
        typeof setIsLoading === 'function' &&
        setIsLoading(false);
    });
};

export const validateDataRequest = ({
  url,
  data,
  successFnc = () => {},
  errorFnc = () => {},
  serverErrorFnc = () => {},
  setModalMessage,
  setShowModal,
  setShowAlertModal,
  successMessage,
  errorMessage,
}) => {
  api({
    url: url,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: !!data && data,
  })
    .then(res => {
      const { success, message, data } = res.data;
      if (success) {
        successFnc(data);
        !!setModalMessage &&
        typeof setModalMessage === 'function' &&
        !!successMessage
          ? setModalMessage(successMessage)
          : setModalMessage(message);
        !!setShowModal &&
          typeof setShowModal === 'function' &&
          setShowModal(true);
      } else {
        const data = res.data.data;
        errorFnc(data);
      }
    })
    .catch(err => {
      console.error(err);
      !!setModalMessage &&
        typeof setModalMessage === 'function' &&
        setModalMessage(err.response.data.message);
      !!setShowAlertModal &&
        typeof setShowAlertModal === 'function' &&
        setShowAlertModal(true);
      !!serverErrorFnc &&
        typeof serverErrorFnc === 'function' &&
        serverErrorFnc();
    });
};

export const uploadImageRequest = ({ formData, callback, url }) => {
  const defaultUrl = '/v1/au/file/upload-image-public';
  api({
    url: url || defaultUrl,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: formData,
  })
    .then(res => {
      const { success, data, message } = res.data;
      if (success) {
        callback({ data });
      } else {
        !!message && alert(message);
      }
    })
    .catch(err => {
      console.error(err);
      if (err.response?.status === 400) {
        alert(err.response?.data?.message);
      }
    });
};

export const modifyDataRequest = ({
  url,
  data,
  successFnc = () => {},
  errorFnc = () => {},
  serverErrorFnc = () => {},
  setModalMessage,
  setShowModal,
  setShowAlertModal,
  setIsLoading,
  successMessage,
}) => {
  // 로딩이 필요한 경우
  !!setIsLoading && typeof setIsLoading === 'function' && setIsLoading(true);
  api({
    url: url,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    data: !!data && data,
  })
    .then(res => {
      const { success, message, data } = res.data;

      if (success) {
        !!setModalMessage &&
          typeof setModalMessage === 'function' &&
          setModalMessage(successMessage || message);
        !!setShowModal &&
          typeof setShowModal === 'function' &&
          setShowModal(true);
        !!successFnc &&
          typeof successFnc === 'function' &&
          successFnc(data, message);
      } else {
        if (!!errorFnc) {
          const data = res.data;
          errorFnc(data, message);
        } else {
          !!setModalMessage &&
            typeof setModalMessage === 'function' &&
            setModalMessage(message);
          !!setShowAlertModal &&
            typeof setShowAlertModal === 'function' &&
            setShowAlertModal(true);
        }
      }
    })
    .catch(err => {
      console.error(err);
      !!setModalMessage &&
        typeof setModalMessage === 'function' &&
        setModalMessage(err?.response?.data?.message);
      !!setShowAlertModal &&
        typeof setShowAlertModal === 'function' &&
        setShowAlertModal(true);
      !!serverErrorFnc &&
        typeof serverErrorFnc === 'function' &&
        serverErrorFnc(err);
    })
    .finally(() => {
      !!setIsLoading &&
        typeof setIsLoading === 'function' &&
        setIsLoading(false);
    });
};
