import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createDataRequest, modifyDataRequest } from 'utils/request';
import { checkCharCode } from 'tools/WebTool';
import ROUTE_PATH from 'routes/config';
import {
  leaveOneSpace,
  replaceHyphen,
  replaceNonNumber,
  replaceNumberAndSpecialCharacter,
  replaceSpecialCharacter,
} from 'tools/StringTool';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import TextField from 'components/TextField';
import { NoBtnModal, TwoBtnModal } from 'components/Modal';
import { FIELD_KEYS, REQUIRED_MANAGER_KEYS } from './constants';
import { Contents, ScrollArea } from 'components/Modal/style';

const ManagerRegister = () => {
  const { mypage } = ROUTE_PATH;
  const navigate = useNavigate();
  const location = useLocation();

  const [isTwoBtnModalOpen, setIsTwoBtnModalOpen] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [inputMessages, setInputMessages] = useState({});

  // 직원 목록에서 businessSeq 넘버/ 직원 상세 정보 가져오기
  const businessSeq = location.state.businessSeq;
  const employeeData = location.state.employeeData;
  const isModificationPage = !!employeeData;

  // 직원 수정 or 등록 인 경우 구분하여 input 초기값 설정
  const [saveInput, setSaveInput] = useState({
    managerName: '',
    contactNo: '',
    mobilePhone: '',
    department: '',
    position: '',
  });
  const { managerName, contactNo, mobilePhone, department, position } =
    saveInput;

  useEffect(() => {
    if (!!employeeData) {
      setSaveInput({
        managerName: replaceNumberAndSpecialCharacter(
          employeeData.managerName || ''
        ),
        contactNo: replaceHyphen(employeeData.contactNo || ''),
        mobilePhone: replaceHyphen(employeeData.mobilePhone || ''),
        department: replaceSpecialCharacter(employeeData.department || ''),
        position: replaceSpecialCharacter(employeeData.position || ''),
      });
    }
  }, [employeeData]);

  const onChangeInput = event => {
    const { name, value } = event.target;

    if (name === 'contactNo' || name === 'mobilePhone') {
      // 실제로는 string으로 다루지만 화면에서는 number만 받는 인풋
      setSaveInput(prev => ({ ...prev, [name]: replaceNonNumber(value) }));
    } else if (name === 'managerName') {
      setSaveInput(prev => ({
        ...prev,
        [name]: replaceNumberAndSpecialCharacter(value),
      }));
    } else {
      setSaveInput(prev => ({
        ...prev,
        [name]: replaceSpecialCharacter(value),
      }));
    }
  };

  const onBlur = event => {
    const { name, value } = event.target;
    setSaveInput(prev => ({ ...prev, [name]: leaveOneSpace(value) }));

    if (name === 'mobilePhone') {
      const uniqueArr = [...new Set(value.slice(3, 11).split(''))];
      if (
        value === '' ||
        value.slice(0, 3) !== '010' ||
        uniqueArr.length === 1
      ) {
        setInputMessages({
          [name]: `${checkCharCode(FIELD_KEYS[name])} 정확히 입력해 주세요.`,
        });
      }
    } else {
      value === ''
        ? setInputMessages({
            [name]: `${checkCharCode(FIELD_KEYS[name])} 정확히 입력해 주세요.`,
          })
        : setInputMessages({ [name]: '' });
    }
  };

  const onClickRegisterBtn = () => {
    const resultCheckManagerData = checkManagerData({ params: saveInput });
    if (resultCheckManagerData.success === false) {
      setAlertMessage(resultCheckManagerData.message);
      setIsAlertModalOpen(true);
      return;
    }
    setIsTwoBtnModalOpen(true);
  };

  // 직원 등록/수정 후 페이지 이동
  const goBackToList = cancel => {
    navigate(mypage.manager, {
      state: { managerName, modify: employeeData, cancel },
    });
  };

  const successFnc = () => {
    setIsTwoBtnModalOpen(false);
    goBackToList();
  };

  const errorFnc = () => {
    setIsTwoBtnModalOpen(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const renderTwoBtnModal = employeeData => {
    const onClickRightButton = () => {
      setIsLoading(true);
      isModificationPage
        ? modifyDataRequest({
            url: `/v1/au/manager/${employeeData.businessManagerSeq}`,
            data: saveInput,
            successFnc,
            errorFnc,
            serverErrorFnc: errorFnc,
            setModalMessage: setAlertMessage,
            setShowAlertModal: setIsAlertModalOpen,
          })
        : createDataRequest({
            url: `/v1/au/manager`,
            data: { businessSeq, ...saveInput },
            successFnc,
            errorFnc,
            serverErrorFnc: errorFnc,
            setModalMessage: setAlertMessage,
            setShowAlertModal: setIsAlertModalOpen,
            setIsLoading,
          });
    };

    return (
      <TwoBtnModal
        showModal={isTwoBtnModalOpen}
        onClose={() => setIsTwoBtnModalOpen(!isTwoBtnModalOpen)}
        leftButton="아니오"
        rightButton="예"
        onClick={onClickRightButton}
        disabled={isLoading}
      >
        <ScrollArea>
          <Contents>
            {managerName}님을 {isModificationPage ? '수정' : '등록'}하시겠어요?
          </Contents>
        </ScrollArea>
      </TwoBtnModal>
    );
  };

  return (
    <>
      <TextField
        name="managerName"
        type="text"
        placeholder="이름"
        required
        value={managerName}
        maxLength={15}
        onChange={onChangeInput}
        onBlur={onBlur}
        message={inputMessages.managerName}
        showMessage={!!inputMessages.managerName}
      />
      <TextField
        name="contactNo"
        type="text"
        placeholder="전화번호(선택)"
        required
        value={contactNo}
        maxLength={12}
        onChange={onChangeInput}
        onBlur={onBlur}
        message={inputMessages.contactNo}
        showMessage={!!inputMessages.contactNo}
      />
      <TextField
        name="mobilePhone"
        type="text"
        placeholder="휴대폰번호"
        required
        value={mobilePhone}
        maxLength={11}
        onChange={onChangeInput}
        onBlur={onBlur}
        message={inputMessages.mobilePhone}
        showMessage={!!inputMessages.mobilePhone}
      />
      <TextField
        name="department"
        type="text"
        placeholder="부서(선택)"
        value={department}
        maxLength={20}
        onChange={onChangeInput}
        onBlur={onBlur}
        message={inputMessages.department}
        showMessage={!!inputMessages.department}
      />
      <TextField
        name="position"
        type="text"
        placeholder="직급(선택)"
        value={position}
        maxLength={20}
        onChange={onChangeInput}
        onBlur={onBlur}
        message={inputMessages.position}
        showMessage={!!inputMessages.position}
      />
      <StyledActiveButton
        disabled={!(managerName && mobilePhone)}
        onClick={onClickRegisterBtn}
        tabIndex={0}
      >
        저장
      </StyledActiveButton>
      {isTwoBtnModalOpen && renderTwoBtnModal(employeeData)}
      <NoBtnModal
        showModal={isAlertModalOpen}
        onClose={() => setIsAlertModalOpen(false)}
      >
        <ScrollArea>
          <Contents>{alertMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </>
  );
};

const checkManagerData = ({ params }) => {
  const result = {
    success: true,
    message: '',
  };
  REQUIRED_MANAGER_KEYS.reverse()?.forEach(key => {
    if (!params[key]) {
      result.success = false;
      result.message = `${checkCharCode(FIELD_KEYS[key])} 입력해 주세요.`;
    }
  });

  return result;
};

export default ManagerRegister;
