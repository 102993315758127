import { useEffect } from 'react';

const IdentificationFailCallback = () => {
  useEffect(() => {
    window.opener._identificationFailCallback &&
      window.opener._identificationFailCallback();
    window.close();
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default IdentificationFailCallback;
