import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { getDataRequest } from 'utils/request';
import ROUTE_PATH from 'routes/config';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import WideButton from 'components/Button/WideButton';
import BlueAlertBox from 'components/BlueAlertBox';
import { ChildTextRow } from '../../../GoBackToPrev/style';
import { FirstText, TextBlue } from '../../../style';

const IndividualRequestStep3 = ({
  setStep,
  possibleInterlockYn,
  setDataProviderSeq,
  dataProviderSeq,
  dataInterlockInfo,
  completionGuideText1,
  completionGuideText2,
}) => {
  const navigate = useNavigate();
  const { mallName } = dataInterlockInfo;
  const { mypage } = ROUTE_PATH;
  const [redGuideContents, setRedGuideContents] = useState('');

  useEffect(() => {
    if (dataInterlockInfo.mallSeq && dataProviderSeq) {
      const url = `/v1/au/salesMall/guidance`;
      const params = {
        guidanceExposureLocationCode: 'INTERLOCK',
        mallSeq: dataInterlockInfo.mallSeq,
        dataProviderSeq: dataProviderSeq,
      };
      getDataRequest({ url, params, setData: setRedGuideContents });
    } else {
      return;
    }
  }, [dataInterlockInfo]);

  const onClickAdditionalBtn = event => {
    // 초기화 및 페이지 이동
    setDataProviderSeq(0);
    setStep(1);
  };

  const onClickNavigateBtn = event => {
    navigate(mypage.dataLinkageManage);
  };

  return (
    <div style={{ padding: ' 0 35px 0 35px' }}>
      <ChildTextRow />
      <FirstText>
        <TextBlue>{mallName}</TextBlue> 데이터 연동 요청이 완료되었어요.
        <br />
        {completionGuideText1}
        <br />
        {completionGuideText2}
      </FirstText>
      {redGuideContents?.guidanceContents && (
        <>
          <BlueAlertBox
            background="icyRed"
            title="주요 안내"
            contents={
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(redGuideContents.guidanceContents),
                }}
              />
            }
          />
          <BlueAlertBox
            title="2차 인증 안내 문자가 왔어요."
            contents={
              <div>
                데이터 연동 요청을 위해 2차 인증을 진행해야 하는 경우가
                발생해요.
                <br />
                추가 2차 인증이 필요한 경우, 2차 인증 요청 문자가 발송 될
                예정이며 안내에 따라 2차 인증을 진행해 주세요.
              </div>
            }
            titleJustifyContent="center"
            background="contentBox"
          />
        </>
      )}
      {dataInterlockInfo?.interlockOpenYn === 'Y' && (
        <BlueAlertBox
          title="2차 인증 안내 문자가 왔어요."
          contents={
            <div>
              데이터 연동 요청을 위해 2차 인증을 진행해야 하는 경우가 발생해요.
              추가 2차 인증이 필요한 경우, 2차 인증 요청 문자가 발송 될 예정이며
              안내에 따라 2차 인증을 진행해 주세요.
            </div>
          }
          titleJustifyContent="center"
          background="contentBox"
        />
      )}

      {possibleInterlockYn === 'Y' && (
        <WideButton onClick={onClickAdditionalBtn} />
      )}
      <StyledActiveButton onClick={onClickNavigateBtn} $grey>
        데이터 연동 관리 화면 가기
      </StyledActiveButton>
    </div>
  );
};

export default IndividualRequestStep3;
