import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import ROUTE_PATH from 'routes/config';
import { getDataRequest } from 'utils/request';
import {
  hasAlphabet,
  hasDigit,
  hasKorean,
  hasSpace,
} from 'tools/ValidationTool';

import TextField from 'components/TextField';
import { NoBtnModal, TwoBtnModal } from 'components/Modal';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import JoinMemberFooter from '../JoinMemberFooter';
import { alertMessages } from 'messages/alertMessages';
import { Contents, ScrollArea } from 'components/Modal/style';
import { TextMedium } from '../style';

const JoinMemberStep1 = ({
  setStep,
  setMemberName,
  setCertificationYn,
  setBusinessName,
  setBusinessRegistrationNumber,
  setFoundationDate,
  setBusinessInfo,
  setBusinessSectionType,
  businessValidationInfo = {},
}) => {
  const navigate = useNavigate();
  const { login, serviceCenter } = ROUTE_PATH;
  const [modalAlertMessage, setModalAlertMessage] = useState('');
  const [initNoBtnModalData, setInitNoBtnModalData] = useState({});
  const [showModalAlertMessage, setShowModalAlertMessage] = useState(false);
  const [showModalDuplicatedBizNum, setShowModalDuplicatedBizNum] =
    useState(false);
  const [bizInfo, setBizInfo] = useState({
    businessRegistrationNumber: '',
    foundationDate: '',
    businessName: '',
    memberName: '',
  });
  const [validateMessage, setValidateMessage] = useState({
    businessRegistrationNumber: '',
    foundationDate: '',
    businessName: '',
    memberName: '',
  });
  const [isAbleIdentification, setIsAbleIdentification] = useState(false);
  // maxLength
  const [
    businessRegistrationNumberLength,
    setBusinessRegistrationNumberLength,
  ] = useState(12);
  const [foundationDateLength, setFoundationDateLength] = useState(13);

  // 금융사 회원 연동으로 진입한 케이스
  useEffect(() => {
    if (businessValidationInfo?.bizInfo) {
      const { bizInfo, noBtnModalInfo } = businessValidationInfo;
      if (bizInfo?.businessRegistrationNumber) {
        setBizInfo({ ...bizInfo, memberName: bizInfo?.representativeName });
      }
      if (noBtnModalInfo) {
        setInitNoBtnModalData(noBtnModalInfo);
      }
    }
  }, [businessValidationInfo]);

  useEffect(() => {
    if (
      _validateBusinessRegistrationNumber({
        businessRegistrationNumber: bizInfo.businessRegistrationNumber,
      }).success &&
      _validateFoundationDate({
        foundationDate: bizInfo.foundationDate,
      }).success &&
      _validateBusinessName({
        businessName: bizInfo.businessName,
      }).success &&
      _validateMemberName({
        memberName: bizInfo.memberName,
      }).success
    ) {
      setIsAbleIdentification(true);
    } else {
      setIsAbleIdentification(false);
    }
  }, [bizInfo]);

  const onChange = e => {
    const validateType = 'change';
    const { name, value } = e.target;
    // const { inputType } = e.nativeEvent; // inputType이 insertFromPaste이면 붙여넣기를 한 경우

    if (name === 'businessRegistrationNumber') {
      const bizNumValue = value.replace(/[^0-9]/g, '');
      setBizInfo(prevState => ({
        ...prevState,
        [name]: bizNumValue,
      }));
      // 복사 붙여넣기를 할 수 있기 때문에 붙여넣기 이후에 maxLength를 제한한다.
      setBusinessRegistrationNumberLength(10);
      const resultValidate = _validateBusinessRegistrationNumber({
        validateType: validateType,
        businessRegistrationNumber: bizNumValue,
      });
      setValidateMessage(prevState => ({
        ...prevState,
        [name]: resultValidate.message,
      }));
    } else if (name === 'foundationDate') {
      const foundationDateValue = value.replace(/[^0-9]/g, '');
      setBizInfo(prevState => ({
        ...prevState,
        [name]: foundationDateValue,
      }));
      // 복사 붙여넣기를 할 수 있기 때문에 붙여넣기 이후에 maxLength를 제한한다.
      setFoundationDateLength(8);
      const resultValidate = _validateFoundationDate({
        validateType: validateType,
        foundationDate: foundationDateValue,
      });
      setValidateMessage(prevState => ({
        ...prevState,
        [name]: resultValidate.message,
      }));
    } else if (name === 'businessName') {
      const businessNameValue = value;
      setBizInfo(prevState => ({
        ...prevState,
        [name]: businessNameValue,
      }));
      const resultValidate = _validateBusinessName({
        validateType: validateType,
        businessName: businessNameValue,
      });
      setValidateMessage(prevState => ({
        ...prevState,
        [name]: resultValidate.message,
      }));
    } else if (name === 'memberName') {
      const memberNameValue = value.trim().toUpperCase();
      setBizInfo(prev => ({
        ...prev,
        [name]: memberNameValue,
      }));
      const resultValidate = _validateMemberName({
        validateType: validateType,
        memberName: memberNameValue,
      });
      setValidateMessage(prevState => ({
        ...prevState,
        [name]: resultValidate.message,
      }));
    }
  };

  const onBlur = e => {
    const validateType = 'blur';
    const { name, value } = e.target;
    if (name === 'businessRegistrationNumber') {
      const resultValidate = _validateBusinessRegistrationNumber({
        validateType: validateType,
        businessRegistrationNumber: value,
      });
      setValidateMessage(prevState => ({
        ...prevState,
        [name]: resultValidate.message,
      }));
    } else if (name === 'foundationDate') {
      const resultValidate = _validateFoundationDate({
        validateType: validateType,
        foundationDate: value,
      });
      setValidateMessage(prevState => ({
        ...prevState,
        [name]: resultValidate.message,
      }));
    } else if (name === 'businessName') {
      const resultValidate = _validateBusinessName({
        validateType: validateType,
        businessName: value,
      });
      setValidateMessage(prevState => ({
        ...prevState,
        [name]: resultValidate.message,
      }));
    } else if (name === 'memberName') {
      const resultValidate = _validateMemberName({
        validateType: validateType,
        memberName: value,
      });
      setValidateMessage(prevState => ({
        ...prevState,
        [name]: resultValidate.message,
      }));
    }
  };

  const onClickCertificationButton = () => {
    checkBizNumDB({
      bizInfo: bizInfo,
      setModalAlertMessage,
      setShowModalAlertMessage,
      setShowModalDuplicatedBizNum,
      setStep,
      setParentState,
    });
  };

  const setParentState = ({
    memberName,
    certificationYn,
    businessName,
    businessRegistrationNumber,
    foundationDate,
    businessInfo,
    businessSectionType,
  }) => {
    setMemberName(memberName);
    setCertificationYn(certificationYn);
    setBusinessName(businessName);
    setBusinessRegistrationNumber(businessRegistrationNumber);
    setFoundationDate(foundationDate);
    setBusinessInfo(businessInfo);
    setBusinessSectionType(businessSectionType);
  };

  const onClickQnA = event => {
    navigate(serviceCenter.main + '?tab=QnA');
  };

  return (
    <div>
      <TextMedium>
        사업자 정보를 인증해 주세요.
        <br />
        사업자등록번호가 있는 사업자에 한해
        <br />
        회원가입이 가능합니다.
      </TextMedium>
      <div>
        <TextField
          type="text"
          title="사업자번호 입력"
          name="businessRegistrationNumber"
          placeholder="사업자 번호 (- 없이 숫자 10자리 입력)"
          onChange={onChange}
          onBlur={onBlur}
          message={validateMessage.businessRegistrationNumber}
          value={bizInfo.businessRegistrationNumber}
          readOnly={
            !!businessValidationInfo.bizInfo?.businessRegistrationNumber
          }
          maxLength={businessRegistrationNumberLength}
        />
        <TextField
          type="text"
          title="설립일 입력"
          name="foundationDate"
          placeholder="설립일"
          onChange={onChange}
          onBlur={onBlur}
          message={validateMessage.foundationDate}
          value={bizInfo.foundationDate}
          maxLength={foundationDateLength}
        />
        <TextField
          type="text"
          title="상호명 입력"
          name="businessName"
          placeholder="상호명"
          onChange={onChange}
          onBlur={onBlur}
          guideMessage="상호명은 사업자등록증의 전체 상호명을 입력해 주세요."
          message={validateMessage.businessName}
          value={bizInfo.businessName}
        />
        <TextField
          type="text"
          title="대표자 입력"
          name="memberName"
          placeholder="대표자"
          maxLength={20}
          onChange={onChange}
          onBlur={onBlur}
          guideMessage="공동 대표인 경우 공동사업자 이름을 입력해 주세요."
          message={validateMessage.memberName}
          value={bizInfo.memberName}
        />
        <StyledActiveButton
          disabled={!isAbleIdentification}
          tabIndex={0}
          onClick={onClickCertificationButton}
        >
          사업자정보 인증
        </StyledActiveButton>
      </div>
      <JoinMemberFooter onClickQnA={onClickQnA} />

      {/* 금융사 회원 연동 유입 시 사용되는 모달: 페이지 진입 시 보여짐 */}
      <NoBtnModal
        showModal={initNoBtnModalData?.contentsHTML}
        onClose={() => {
          setInitNoBtnModalData({});
        }}
        title={initNoBtnModalData?.title}
      >
        <ScrollArea>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(initNoBtnModalData?.contentsHTML),
            }}
          ></div>
        </ScrollArea>
      </NoBtnModal>

      {/* 알럿 메세지 모달 */}
      <NoBtnModal
        showModal={showModalAlertMessage}
        onClose={() => {
          setShowModalAlertMessage(false);
          setModalAlertMessage('');
        }}
      >
        <ScrollArea>
          <Contents>{modalAlertMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>

      {/* 사업자 가입 확인 모달*/}
      <TwoBtnModal
        showModal={showModalDuplicatedBizNum}
        onClose={() => setShowModalDuplicatedBizNum(false)}
        onClick={() => navigate(login.searchId)}
        onClickLeftBtn={() => {
          window.location.reload();
        }}
        leftButton="다른 사업자로 가입"
        rightButton="아이디 찾기"
      >
        <ScrollArea>
          <Contents>
            이미 회원으로 가입하셨어요.
            <br />
            아이디를 찾아보세요.
          </Contents>
        </ScrollArea>
      </TwoBtnModal>
    </div>
  );
};

const checkBizNumDB = ({
  bizInfo,
  setModalAlertMessage,
  setShowModalAlertMessage,
  setShowModalDuplicatedBizNum,
  setStep,
  setParentState,
}) => {
  const successFnc = data => {
    // 이미 가입된 회원
    setShowModalDuplicatedBizNum(true);
  };
  const errorFnc = (data, message, errorNo) => {
    // 등록된 계정이 없음
    checkBizNumNTS({
      bizInfo,
      setModalAlertMessage,
      setShowModalAlertMessage,
      setStep,
      setParentState,
    });
  };

  // DB 사업자 번호 체크
  getDataRequest({
    url: `/v1/na/member/businum/check`,
    params: { businessRegistrationNumber: bizInfo.businessRegistrationNumber },
    successFnc,
    errorFnc,
  });
};

const checkBizNumNTS = ({
  bizInfo,
  setModalAlertMessage,
  setShowModalAlertMessage,
  setStep,
  setParentState,
}) => {
  const successFnc = data => {
    const { businessRegistrationNumberValidate = {} } = data;
    if (
      businessRegistrationNumberValidate.representativeName &&
      businessRegistrationNumberValidate.businessRegistrationCertificationYn &&
      businessRegistrationNumberValidate.businessName &&
      businessRegistrationNumberValidate.businessRegistrationNumber &&
      businessRegistrationNumberValidate.businessRegistrationStatus !==
        'SHUTDOWN' &&
      businessRegistrationNumberValidate.businessRegistrationStatus !==
        'CLOSE' &&
      businessRegistrationNumberValidate.businessRegistrationCertificationYn ===
        'Y'
    ) {
      setStep(2);
      setParentState({
        memberName: businessRegistrationNumberValidate.representativeName,
        certificationYn:
          businessRegistrationNumberValidate.businessRegistrationCertificationYn,
        businessName: businessRegistrationNumberValidate.businessName,
        businessRegistrationNumber:
          businessRegistrationNumberValidate.businessRegistrationNumber,
        foundationDate: bizInfo.foundationDate,
        businessInfo:
          businessRegistrationNumberValidate.businessRegistrationStatus ===
          'ACKNOWLEDGE'
            ? 'ACT'
            : '',
        businessSectionType:
          businessRegistrationNumberValidate.businessSectionType || '',
      });
    } else if (
      businessRegistrationNumberValidate.businessRegistrationStatus ===
        'SHUTDOWN' ||
      businessRegistrationNumberValidate.businessRegistrationStatus === 'CLOSE'
    ) {
      setModalAlertMessage(
        `휴폐업 상태입니다.\n상태가 정상화되기 전까지 가입이 불가해요.`
      );
      setShowModalAlertMessage(true);
    } else {
      setModalAlertMessage(
        '사업자 정보 인증에 실패하였습니다.\n사업자 정보를 다시 확인해 주세요.'
      );
      setShowModalAlertMessage(true);
    }
  };
  const errorFnc = (data, message, errorNo) => {
    setModalAlertMessage('오류가 발생하였습니다.\n관리자에게 문의하세요.');
    setShowModalAlertMessage(true);
  };

  // 국세청 사업자 번호 체크
  getDataRequest({
    url: `/v1/na/common/business/validate`,
    params: {
      businessRegistrationNumber: bizInfo.businessRegistrationNumber,
      representativeName: bizInfo.memberName,
      establishmentDt: bizInfo.foundationDate,
      businessName: bizInfo.businessName,
    },
    successFnc,
    errorFnc,
    setModalMessage: setModalAlertMessage,
    setShowAlertModal: setShowModalAlertMessage,
  });
};

const _validateBusinessRegistrationNumber = ({
  validateType,
  businessRegistrationNumber,
}) => {
  const result = {
    success: true,
    message: '',
  };
  if (
    result.success &&
    (validateType === undefined || validateType === 'change')
  ) {
  }
  if (
    result.success &&
    (validateType === undefined || validateType === 'blur')
  ) {
    if (!businessRegistrationNumber) {
      result.success = false;
      result.message = alertMessages.typeBizNum;
    } else if (businessRegistrationNumber.length !== 10) {
      result.success = false;
      result.message = alertMessages.typeBizNumLength;
    }
  }
  return result;
};

const _validateFoundationDate = ({ validateType, foundationDate }) => {
  const result = {
    success: true,
    message: '',
  };
  if (
    result.success &&
    (validateType === undefined || validateType === 'change')
  ) {
  }
  if (
    result.success &&
    (validateType === undefined || validateType === 'blur')
  ) {
    if (!foundationDate) {
      result.success = false;
      result.message = alertMessages.typeFoundationDate;
    } else if (foundationDate.length !== 8) {
      result.success = false;
      result.message = alertMessages.lengthFoundationDate;
    }
  }
  return result;
};

const _validateBusinessName = ({ validateType, businessName }) => {
  const result = {
    success: true,
    message: '',
  };
  if (
    result.success &&
    (validateType === undefined || validateType === 'change')
  ) {
  }
  if (
    result.success &&
    (validateType === undefined || validateType === 'blur')
  ) {
    if (!businessName) {
      result.success = false;
      result.message = alertMessages.typeBizName;
    }
  }
  return result;
};

const _validateMemberName = ({ validateType, memberName }) => {
  const result = {
    success: true,
    message: '',
  };

  if (
    result.success &&
    (validateType === undefined || validateType === 'change')
  ) {
  }
  if (
    result.success &&
    (validateType === undefined || validateType === 'blur')
  ) {
    if (!memberName) {
      result.success = false;
      result.message = alertMessages.typeMemberName;
    } else if (hasKorean(memberName) && hasSpace(memberName)) {
      result.success = false;
      result.message = alertMessages.wrongKorMemberName;
    } else if (
      hasKorean(memberName) &&
      (hasAlphabet(memberName) || hasDigit(memberName))
    ) {
      result.success = false;
      result.message = alertMessages.charMemberName;
    }
  }

  return result;
};

export default JoinMemberStep1;
