import React, { useEffect, useState } from 'react';
import { getListDataRequest } from 'utils/request';
import DataProviderTable from '../../MyPage/LoanLimit/LoanLimitBasicPage/DataProviderTable';
import { TitleWrapper } from './style';

const AffiliateFinancePopupPage = () => {
  const [affiliateFinanceList, setAffiliateFinanceList] = useState([]);
  const tableHead = ['업체명', '고객센터', '제휴 서비스'];

  useEffect(() => {
    getListDataRequest({
      url: '/v1/na/consumer-financial-protection/affiliate-finance',
      setData: setAffiliateFinanceList,
    });
  }, []);

  return (
    affiliateFinanceList?.length > 0 && (
      <div>
        <TitleWrapper>에이젠글로벌과 제휴된 금융기관</TitleWrapper>
        <DataProviderTable
          tableHead={tableHead}
          dataProviderList={affiliateFinanceList}
          tableDataType="custom"
        />
      </div>
    )
  );
};

export default AffiliateFinancePopupPage;
