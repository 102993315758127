import React from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { LOAN_TYPE_PROCESS } from './constants';
import { ChildText, ChildTextContent } from '../style';
import {
  DataBox,
  DataContent,
  DataHeader,
  DataName,
  NextStepBtn,
} from './style';

const LinkedDatatStatus = ({
  onClickDataLinkagePage = () => {},
  getLoanableMall = () => {},
}) => {
  return (
    <div>
      <ChildText style={{ justifyContent: 'left' }}>
        <ChildTextContent
          style={{
            textDecoration: 'underline',
            color: '#02A4FF',
            cursor: 'pointer',
            marginRight: '5px',
          }}
          onClick={onClickDataLinkagePage}
        >
          더 많은 데이터 연동이 필요하신가요?
        </ChildTextContent>
        <FeatherIcon icon="check-square" stroke="#02A4FF" size={14} />
      </ChildText>

      {LOAN_TYPE_PROCESS.map((item, index) => (
        <DataBox key={index}>
          <DataHeader>
            <DataName>{item.title}</DataName>
            <NextStepBtn onClick={() => getLoanableMall(item.type)} />
          </DataHeader>
          <DataContent>{item.context}</DataContent>
        </DataBox>
      ))}
    </div>
  );
};

export default LinkedDatatStatus;
