import React from 'react';
import {
  MainSectionThree,
  Title,
  Unit,
  Subtitle,
  DataProvierName,
  SubtitleSpan,
  Text,
  Button,
  Line,
} from './style';

const FAQSection = ({
  onClickJoinPage,
  onClickDataLinkagePage,
  onClickCheckLoanPage,
}) => {
  const questionList = [
    {
      subTitleSpan: '회원가입',
      subTitle: '은 어떻게 하나요?',
      text: '셀러크레딧커넥트는 현재 온라인 판매몰 데이터 연동이 가능한 사업자 회원만 가입이 가능해요.\n사업자 정보 인증후 회원가입을 할 수 있어요.',
      button: '회원가입 하기',
      onClickButton: onClickJoinPage,
    },
    {
      subTitleSpan: '왜 데이터를 연동',
      subTitle: '해야하나요?',
      text: '셀러 크레딧커넥트에서는 데이터가 곧 신용!\n데이터를 불러오기 위해 데이터 연동이 필요해요.\n운영중인 판매몰을 연동해 주세요.\n등록된 판매몰이 많을수록 연동할 데이터가 많아 대출  신청에 유리해요.',
      button: '데이터 연동하기',
      onClickButton: onClickDataLinkagePage,
    },
    {
      subTitleSpan: '셀러크레딧커넥트의 대출비교 서비스',
      subTitle: '는 어떤건가요?',
      text: '대출 비교 서비스는 곧 오픈 예정이에요.\n운영중인 판매몰의 데이터를 연동하면,\n판매몰 영업 활동 데이터를 기반으로 금융기관에서 대출 한도를 산출해요.\n판매몰을 많이 연동할수록 제공하는 데이터가 많아져 대출한도가 높아져요.\n셀러크레딧커넥트에서는 여러 금융기관의 대출 조건을 비교해서 나에게 맞는 최적의 대출 조건을 선택할 수 있어요.',
      button: '대출 비교하기',
      onClickButton: onClickCheckLoanPage,
    },
  ];

  return (
    <MainSectionThree>
      <Title>자주 묻는 질문</Title>
      {questionList.map((item, index) => {
        return (
          <Unit key={`${item.button}_${index}`}>
            <Subtitle>
              <SubtitleSpan>{item.subTitleSpan}</SubtitleSpan>
              {item.subTitle}
            </Subtitle>
            {item.dataProvierName && (
              <DataProvierName>{item.dataProvierName}</DataProvierName>
            )}
            <Text>{item.text}</Text>
            <Button onClick={item.onClickButton}>{item.button}</Button>
          </Unit>
        );
      })}
      <Line />
    </MainSectionThree>
  );
};

export default FAQSection;
