export const TARGET_URL_BY_POPUP_TYPE = [
  {
    type: 'PTC100',
    adminPageUrl: '/service-center',
    detailPageUrl: `popup/service-center/notice`,
    key: 'notice',
  },
];

export const CONTENTS_TYPE_CD = {
  MALL_LINKAGE_GUIDE: 'CTC100',
  MAIN_CONTENTS: 'CTC200',
};

export const EXPOSURE_AREA_CD = {
  EXTRA_LINK: 'EAC100',
  MIDDLE_AREA: 'EAC200',
  BIG_BANNER: 'EAC300',
};
