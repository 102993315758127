import React from 'react';
import ROUTE_PATH from 'routes/config';
import { createDataRequest } from 'utils/request';
import { OneBtnModal } from 'components/Modal';
import { Contents, NoScrollArea } from 'components/Modal/style';
import { TextBox } from 'pages/Event/EventDetailPage/style';
import { BlueLink } from 'pages/MyPage/DataLinkage/DataLinkageRequest/style';
import { CheckCards } from 'components/SelectMallModal/RenderMall/style';
import {
  CheckCard,
  CheckCardBlueText,
  CheckCardDiv,
  CheckCardText,
  CheckCardTitleText,
} from 'components/SelectManager/SelectManagerRow/style';
import { TextSpan, TextSpanLight } from 'pages/MyPage/MyPageMain/style';

const InviteModal = ({
  showInviteModal,
  inviteModalInfo,
  toggleInviteModal,
  successFnc,
  setAlertModal,
  setAlertMessage,
  gotoModify,
}) => {
  const {
    businessName,
    managerMemberWhetherStatus,
    managerName,
    position,
    department,
    mobilePhone,
  } = inviteModalInfo;

  const onClickButton = () => {
    const errorFnc = () => {
      toggleInviteModal();
    };
    createDataRequest({
      url: `/v1/au/manager/${inviteModalInfo.businessManagerSeq}/member-invite`,
      successFnc,
      errorFnc,
      serverErrorFnc: errorFnc,
      setModalMessage: setAlertMessage,
      setShowModal: setAlertModal,
      setShowAlertModal: setAlertModal,
    });
  };

  const onClickBlueLink = () => {
    toggleInviteModal();
    gotoModify(inviteModalInfo);
  };

  const isMemeber = managerMemberWhetherStatus === 'Y';
  return (
    !!inviteModalInfo && (
      <OneBtnModal
        showModal={showInviteModal}
        onClose={toggleInviteModal}
        onClick={onClickButton}
        btnContent="회원 초대 문자 보내기"
      >
        <NoScrollArea>
          {isMemeber ? (
            <Contents fontWeight={400}>
              <TextSpanLight>{managerName}</TextSpanLight>님에게
              <br />
              회원 초대 문자를 전송하시겠어요?
            </Contents>
          ) : (
            <Contents fontWeight={400}>
              <TextSpanLight>{managerName}</TextSpanLight>
              님에게
              <br />
              회원 초대 문자를 전송하시겠어요?
              <br />
              가입 시 <TextSpanLight>{businessName}</TextSpanLight> <br />
              직원 계정이 별도 생성됩니다.
            </Contents>
          )}

          <CheckCards>
            <CheckCard>
              <CheckCardDiv>
                <CheckCardTitleText style={{ paddingLeft: '15px' }}>
                  {managerName}
                </CheckCardTitleText>
                <CheckCardTitleText style={{ paddingRight: '15px' }}>
                  {!!mobilePhone && mobilePhone}
                </CheckCardTitleText>
              </CheckCardDiv>
              <CheckCardText style={{ paddingLeft: '15px' }}>
                {department}
                {department && position && '/'}
                {position}
              </CheckCardText>

              {isMemeber && (
                <CheckCardBlueText style={{ paddingLeft: '15px' }}>
                  {managerName}님은 기가입된 상태입니다.
                  <br />
                  회원 초대 문자를 전송하시면 다시 회원으로 초대할 수 있습니다.
                </CheckCardBlueText>
              )}
            </CheckCard>
          </CheckCards>
          <TextBox style={{ margin: '25px 10px' }}>
            {isMemeber ? (
              <CheckCardText style={{ padding: '0' }}>
                담당자에게 회원 가입을 위한 초대 문자를 보낼 수 있습니다.
                <br />
                담당자는 초대된 링크를 통해 2일 이내 수락이 가능하며,
                <br />
                2일 이후에는 수락이 불가합니다.
              </CheckCardText>
            ) : (
              <CheckCardText style={{ padding: '0' }}>
                담당자에게 회원 가입을 위한 초대 문자를 보낼 수 있습니다.
                <br />
                담당자는 초대된 링크를 통해 2일 이내 가입이 가능하며,
                <br />
                2일 이후에는 링크가 만료됩니다.
                <br />
                가입 시 해당 사업자의 직원 계정이 별도 생성됩니다.
                <br />
                <br />
                담당자의 휴대폰 번호가 정확한 지 확인해 주세요.
                <br />
                휴대폰 번호가 올바르지 않다면?
                <br />
                <BlueLink
                  onClick={onClickBlueLink}
                  target="_blank"
                  tabIndex={0}
                  title="직원관리 페이지로 이동"
                >
                  휴대폰 번호 수정 바로가기
                </BlueLink>
              </CheckCardText>
            )}
          </TextBox>
        </NoScrollArea>
      </OneBtnModal>
    )
  );
};

export default InviteModal;
