import React, { useState, useEffect } from 'react';
import SelectMallRow from './SelectMallRow';
import { INFO_LIST } from './constants';
import {
  Label,
  CheckboxInput,
  IconSpan,
} from 'pages/MyPage/MyInform/MemberWithdrawal/MemberWithdrawalPage/style';
import { SelectAll, CheckCards, TextBox, InfoTitle, InfoText } from './style';

const SelectMall = ({ availableMallList, setCheckedMallList }) => {
  const [checkStatusList, setCheckStatusList] = useState([]);

  const isAllChecked = checkStatusList.every(item => item.isChecked) || false;

  const onChangeAllCheckBox = e => {
    setCheckStatusList(prev =>
      prev.map(item => ({ ...item, isChecked: e.target.checked }))
    );
  };

  const onChangeCheckBox = e => {
    const { checked, name, value } = e.target;
    setCheckStatusList(prev =>
      prev.map(item =>
        `${item.mallSeq}_${item.mallAccountId}` === `${value}_${name}`
          ? { ...item, isChecked: checked }
          : item
      )
    );
  };

  useEffect(() => {
    setCheckStatusList(
      availableMallList.map(item => {
        return {
          mallSeq: item.mallSeq,
          isChecked: false,
          mallName: item.mallName,
          mallAccountId: item.mallAccountId,
          dataProviderScheduledAmtList: item.dataProviderScheduledAmtList,
        };
      })
    );
  }, [availableMallList]);

  useEffect(() => {
    setCheckedMallList(checkStatusList.filter(item => item.isChecked));
  }, [checkStatusList]);

  return (
    <>
      <TextBox>
        담보 대출 한도 조회에 사용할 판매몰을
        <br />
        선택해 주세요.
      </TextBox>
      <SelectAll>
        <Label>
          <CheckboxInput
            type="checkbox"
            className="check-card"
            checked={isAllChecked}
            onChange={onChangeAllCheckBox}
          />
          전체선택
          <IconSpan className="notice-check-icon" />
        </Label>
      </SelectAll>
      <CheckCards>
        {availableMallList?.map((mall, index) => {
          return (
            <SelectMallRow
              key={`availableMallList_${mall.mallSeq}_${index}`}
              index={index}
              mall={mall}
              checkStatusList={checkStatusList}
              onChangeCheckBox={onChangeCheckBox}
            />
          );
        })}
      </CheckCards>

      <InfoTitle>안내 사항</InfoTitle>
      <ul>
        {INFO_LIST.map((item, index) => (
          <InfoText key={index}>{item}</InfoText>
        ))}
      </ul>
    </>
  );
};

export default SelectMall;
