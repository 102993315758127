import React from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { createMarkup } from 'tools/WebTool';
import ROUTE_PATH from 'routes/config';
import theme from 'styles/theme';
import { StyledReactQuill } from 'styles/StyledReactQuill';
import {
  Card,
  CardColumn,
  CardName,
  CardRow,
  CardRowFirst,
  DataBox,
  DetailButton,
  QuestionMark,
  BlueRightLink,
  BlueText,
  BlackArrowIcon,
  BlueArrowIcon,
  ArrowIcon,
} from './style';

const FoldedCard = ({
  index = 0,
  seq = 0,
  isOpen = false,
  title = '',
  subText = '',
  contents = '',
  onClickDetailBtn = () => {},
  questionMark = false,
  findValue = '',
  name = '',
  borderTop = false,
  style = {},
  blackArrow = false,
  backgroundColor,
}) => {
  const { serviceCenter } = ROUTE_PATH;

  const setTitle = title => {
    if (!!findValue && title.indexOf(findValue) !== -1) {
      return title.replaceAll(findValue, `<strong>${findValue}</strong>`);
    }
    return title;
  };

  const onClickDetatilPage = () => {
    if (name === 'notice') {
      window.open(`${serviceCenter.noticeDetailDefault}/${seq}`, '_blank');
    } else if (name === 'faq') {
      window.open(`${serviceCenter.faqDetailDefault}/${seq}`, '_blank');
    }
  };

  return (
    <Card borderTop={!!borderTop} style={style}>
      <CardColumn>
        <CardName isOpen={isOpen} className="cardName">
          {!!questionMark && <QuestionMark>Q </QuestionMark>}{' '}
          <span dangerouslySetInnerHTML={createMarkup(setTitle(title))} />
        </CardName>
        <CardRow paddingLeft={!!questionMark}>
          <CardRowFirst width="100%">{subText}</CardRowFirst>
        </CardRow>
        {isOpen === true && (
          <DataBox backgroundColor={backgroundColor}>
            {name && (
              <BlueRightLink>
                <FeatherIcon
                  icon="external-link"
                  size={15}
                  strokeWidth={2}
                  stroke={theme.color.blue}
                />
                <BlueText onClick={onClickDetatilPage}>새창 열기</BlueText>
              </BlueRightLink>
            )}
            <StyledReactQuill value={contents} readOnly={true} />
          </DataBox>
        )}

        <ArrowIcon
          icon="chevron-right"
          size={20}
          stroke={blackArrow ? theme.color.grey9 : theme.color.blue}
          onClick={() => onClickDetailBtn(index)}
          rotated={isOpen.toString()} // feather-icon은 rotated 속성을 boolean으로 받지 않는다.
          aria-label={isOpen === true ? '접기' : '펼치기'}
        />
      </CardColumn>
    </Card>
  );
};

export default FoldedCard;
