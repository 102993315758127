import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useApp from 'hooks/useApp';
import { LOGIN_TIME_LIMIT } from 'hooks/useAuth';
import api from 'utils/api';
import qs from 'qs';
import ROUTE_PATH from 'routes/config';
import {
  getEssentialMalls,
  getAccessUrl,
  getGoodsHash,
  KEY_DATAPROVIDEAGREESEQ,
  KEY_LOGIN_TRIAL,
  KEY_MEMBER_LOGIN_FAILURE_DT,
} from 'tools/WebTool';
import { stringifyTime } from 'tools/StringTool';
import { getResultDataRequest, createDataRequest } from 'utils/request';
import LoginBox from 'components/LoginBox';
import { NoBtnModal, OneBtnModal } from 'components/Modal';
import IdentificationBox from 'components/IdentificationBox';
import { alertMessages } from 'messages/alertMessages';
import { Contents, ScrollArea } from 'components/Modal/style';
import { Line, LoginBottomBtn, LoginBottomSection, RedSpan } from './style';

const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { auth } = useApp();
  const { login } = ROUTE_PATH;

  //아이디 저장 체크박스
  const [isCheckedSaveId, setIsCheckedSaveID] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //메시지
  //백엔드와 통신 후 input창에 보여지는 메시지
  const [incorrectInputMessage, setIncorrectInputMessage] = useState('');
  //본인인증 모달 alertMessage
  const [identificaitonAlertMessage, setIdentificaitonAlertMessage] =
    useState('');
  //오류 모달 alertMessage
  const [alertMessage, setAlertMessage] = useState('');
  const [lockLoginMessage, setLockLoginMessage] = useState();
  const [isOpenTimeAlert, setIsOpenTimeAlert] = useState(false);
  const [count, setCount] = useState(LOGIN_TIME_LIMIT);
  const [remainedTime, setRemainedTime] = useState(stringifyTime(count));

  //로그인 정보
  const [loginInfo, setLoginInfo] = useState({ memberId: '', password: '' });
  const [redirectTo, setRedirectTo] = useState('');

  // dataAgreeSeq 생성을 위한 데이터
  const goodsHash = getGoodsHash();
  const accessUrl = getAccessUrl();
  const essentialMalls = getEssentialMalls();

  useEffect(() => {
    //URL 쿼리스트링(query string)에서 'redirect-to' 파라미터 값을 읽어서 redirectTo 상태(state)를 설정
    const search = location.search;
    const qo = !!search ? qs.parse(search, { ignoreQueryPrefix: true }) : {};
    setRedirectTo(typeof qo == 'object' ? qo['redirect-to'] : '');
  }, [location.search]);

  // 통신 후 모달 메시지 set함수
  const onAlertModal = ({ status, message, data }) => {
    if (!!data.remainingTime) {
      setLockLoginMessage(message);
      setCount(data.remainingTime);
      setIsOpenTimeAlert(true);
    } else if (status === 401) {
      setIncorrectInputMessage(alertMessages.wrongIdPw);
    } else if (status === 400) {
      setIdentificaitonAlertMessage(message);
    } else {
      setAlertMessage(message);
    }
  };

  const functionLogin = useCallback(() => {
    const loginSuccessCallback = () => {
      // 금융기관 회원가입 연동 케이스가 아닐 경우
      if (!!goodsHash && !!accessUrl) {
        const createDataProvideAgreeSuccessFnc = (goodsData = {}, message) => {
          // dataProviderAgreeSeq 생성 실패: errorCode - 실패 사유
          // 생성 실패지만 success: true 에서 분기 처리
          if (goodsData.errorCode) {
            navigate(ROUTE_PATH.deepLink.agree, {
              state: {
                goodsData: { ...goodsData, message },
              },
            });
          } else {
            //정적페이지
            const successFnc = data => {
              localStorage.setItem(
                KEY_DATAPROVIDEAGREESEQ,
                goodsData?.dataProvideAgreeSeq
              );
              // 필수몰 충족 o
              // 필수몰이 없거나 타겟몰 중 하나 이상 연동되어 있음
              if (!data || (!!data && data?.isMoveAgreePage)) {
                navigate(ROUTE_PATH.deepLink.agree);
              } else {
                // 필수몰 충족 x
                navigate(ROUTE_PATH.mypage.dataLinkageManage);
              }
            };

            // 회원의 필수판매몰 연동 여부 확인
            getResultDataRequest({
              url: 'v1/au/data-provider/essential/mall/list',
              successFnc,
              setModalMessage: setAlertMessage,
            });
          }
        };

        // 정적 - 제공동의 seq 생성
        createDataRequest({
          url: 'v1/au/data-provide-agree/create',
          data: {
            goodsHash: goodsHash,
            deepLink: accessUrl,
            // 금융사 회원 연동이 아닌 정적 링크는 무조건 빈 리스트
            malls: essentialMalls ?? [],
          },
          successFnc: createDataProvideAgreeSuccessFnc,
          errorFnc: (data, message) => {
            if (!!data.errorCode) {
              navigate(ROUTE_PATH.deepLink.agree, {
                state: {
                  goodsData: { ...data, message },
                },
              });
            }
          },
          serverErrorFnc: err => {
            setAlertMessage(err);
          },
        });
      } else {
        return;
      }
    };

    auth.login({
      params: { memberId: loginInfo.memberId, password: loginInfo.password },
      redirectTo,
      onAlertModal,
      isCheckedSaveId,
      setIsLoading,
      successCallback: loginSuccessCallback,
    });
  }, [loginInfo, redirectTo, isCheckedSaveId, auth]);

  // 본인인증 완료(성공)
  const identificationSuccessCallback = useCallback(
    memberCertificationSeq => {
      const memberInfo = {
        memberId: loginInfo.memberId,
        memberCertificationSeq: memberCertificationSeq,
      };
      checkMemberCertification({
        memberInfo,
        setAlertMessage,
        setIdentificaitonAlertMessage,
        functionLogin,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginInfo]
  );

  // 본인인증 완료(decode 실패)
  const identificationExceptCallback = useCallback(err => {
    console.error(err);
    setAlertMessage(err?.response?.data?.message);
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setCount(count => count - 1);
      setRemainedTime(() => stringifyTime(count - 1));
    }, 1000);

    if (count === 0 || !isOpenTimeAlert) {
      clearInterval(id);
    }
    if (count === 0) {
      sessionStorage.removeItem(KEY_LOGIN_TRIAL);
      sessionStorage.removeItem(KEY_MEMBER_LOGIN_FAILURE_DT);
    }
    return () => clearInterval(id);
  }, [count, isOpenTimeAlert]);

  const onClickCloseTimeAlert = () => {
    setIsOpenTimeAlert(false);
    setRemainedTime(() => stringifyTime(LOGIN_TIME_LIMIT));
  };

  return (
    <>
      <LoginBox
        login={functionLogin}
        setLoginInfo={setLoginInfo}
        loginInfo={loginInfo}
        incorrectInputMessage={incorrectInputMessage}
        setIncorrectInputMessage={setIncorrectInputMessage}
        isCheckedSaveId={isCheckedSaveId}
        setIsCheckedSaveID={setIsCheckedSaveID}
        isLoading={isLoading}
      />
      <Line />
      <LoginBottomSection>
        <LoginBottomBtn
          tabIndex={0}
          title="아이디 찾기로 이동"
          borderLeft={false}
          onClick={() => navigate(login.searchId)}
        >
          아이디 찾기
        </LoginBottomBtn>
        <LoginBottomBtn
          tabIndex={0}
          title="비밀번호 찾기로 이동"
          borderLeft={true}
          onClick={() => navigate(login.searchPassword)}
        >
          비밀번호 찾기
        </LoginBottomBtn>
        <LoginBottomBtn
          tabIndex={0}
          title="회원가입으로 이동"
          borderLeft={true}
          onClick={() => navigate(login.joinMember)}
        >
          회원가입
        </LoginBottomBtn>

        <NoBtnModal
          showModal={!!identificaitonAlertMessage}
          onClose={() => setIdentificaitonAlertMessage('')}
        >
          <ScrollArea>
            <Contents>{identificaitonAlertMessage}</Contents>
            <IdentificationBox
              style={`width: '60%', margin: '20px'`}
              identificationPurposeType="LOGIN"
              identificationSuccessCallback={identificationSuccessCallback}
              identificationExceptCallback={identificationExceptCallback}
            />
          </ScrollArea>
        </NoBtnModal>

        <NoBtnModal
          showModal={!!alertMessage}
          onClose={() => setAlertMessage('')}
        >
          <ScrollArea>
            <Contents>{alertMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>

        {remainedTime !== stringifyTime(LOGIN_TIME_LIMIT) &&
          !!lockLoginMessage && (
            <OneBtnModal
              showModal={isOpenTimeAlert}
              onClose={onClickCloseTimeAlert}
              btnContent="확인"
            >
              <ScrollArea>
                <Contents>
                  {lockLoginMessage}
                  <br />
                  <br />
                  <strong>남은 시간 : </strong>
                  <RedSpan className="bold">{remainedTime}</RedSpan>
                </Contents>
              </ScrollArea>
            </OneBtnModal>
          )}
      </LoginBottomSection>
    </>
  );
};

//대표자 본인확인
const checkMemberCertification = ({
  memberInfo,
  setAlertMessage,
  setIdentificaitonAlertMessage,
  functionLogin,
}) => {
  api({
    url: `/v1/na/member/business/certification`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    data: memberInfo,
  })
    .then(res => {
      const { success } = res.data;
      if (success) {
        functionLogin && functionLogin();
      }
    })
    .catch(err => {
      console.error(err);
      const { status, data } = err.response;
      if (status === 400) {
        setIdentificaitonAlertMessage(data.message);
      } else {
        setAlertMessage(data.message);
      }
    });
};

export default LoginPage;
