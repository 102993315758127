import React, { useEffect, useState } from 'react';
import { Alert } from 'antd';
import { BackgroundCard, PopupButton } from '../NoticeBottomPopup/style';
import {
  SideArea,
  FlexBox,
  Text,
  PopupTitle,
  ButtonSection,
  URLImg,
  URLText,
  KaKaoImg,
  SuccessMessageWrapper,
} from './style';

const ShareBottomPopup = ({
  showModal = false,
  onClose = () => {},
  eventTitle = '',
  eventDesc = '',
}) => {
  const [showMessage, setShowMessage] = useState(false);

  const kakaoIconUrl = process.env.REACT_APP_IMG_HOST + '/image/icon/kakao.png';
  const UrlIconUrl = process.env.REACT_APP_IMG_HOST + '/image/icon/url.png';

  // 팝업이 열려 있는 동안 스크롤 방지
  useEffect(() => {
    if (!!showModal) {
      document.body.style.cssText = `
    position: fixed;
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `overflow-y: auto`;
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
  }, [showModal]);

  const handleShareKaKao = () => {
    if (window.Kakao) {
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
      }

      kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title: eventTitle,
          description: eventDesc,
          imageUrl: 'https://cdn.sellercredit.co.kr/image/mobile_thumbnail.png',
          imageWidth: 800,
          imageHeight: 400,
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
        installTalk: true,
      });
    }
  };

  const handleShareClick = async () => {
    const url = window.location.href;

    try {
      await navigator.clipboard.writeText(url);
      onClose();
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
    } catch (e) {
      console.error(e);
    }
  };

  return !!showModal ? (
    <SideArea onClick={onClose}>
      <BackgroundCard
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <PopupTitle>공유하기</PopupTitle>
        <FlexBox>
          <Text>{eventTitle}</Text>
        </FlexBox>
        <FlexBox padding>
          <KaKaoImg onClick={handleShareKaKao} imageUrl={kakaoIconUrl} />
          <URLImg onClick={handleShareClick} imageUrl={UrlIconUrl}>
            <URLText>URL</URLText>
          </URLImg>
        </FlexBox>
        <ButtonSection onClick={onClose}>
          <PopupButton onClick={onClose}>닫기</PopupButton>
        </ButtonSection>
      </BackgroundCard>
    </SideArea>
  ) : !!showMessage ? (
    <SuccessMessageWrapper style={{ paddingBottom: '20px' }}>
      <Alert
        type="info"
        message="URL복사가 완료되었습니다"
        style={{ width: '100%', height: '48px' }}
      />
    </SuccessMessageWrapper>
  ) : (
    <></>
  );
};

export default ShareBottomPopup;
