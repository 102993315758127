import styled from 'styled-components';

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${props => (props.hasDataProvideStatus ? '200px' : '250px')};

  @media screen and (max-width: 320px) {
    width: ${props => (props.hasDataProvideStatus ? '184px' : '230px')};
  }
`;
