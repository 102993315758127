import styled from 'styled-components';
import theme from 'styles/theme';

export const EventDetailWrapper = styled.div`
  padding-bottom: ${props => (props.paddingBottom ? '60px' : '0')};
`;

export const EventImageBox = styled.div`
  padding: 0;
`;

export const SubjectWrapper = styled.div`
  padding: 28px 20px 36px;
`;
export const Subject = styled.p`
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
  text-align: left;

  white-space: pre-line;
`;

export const Description = styled.p`
  padding: 20px 0 0;
  font-size: 1.3rem;
  line-height: 1.3rem;
  color: ${theme.color.grey4};
`;

export const Line = styled.div`
  width: 100%;
  height: 9px;
  text-align: center;
  border-top: 3px solid ${theme.color.greyA};
`;

export const EventResultSection = styled.section`
  padding: 36px 20px;

  border-top: 6px solid ${theme.backgroundColor.contentBox};
  border-bottom: 6px solid ${theme.backgroundColor.contentBox};
`;

export const TextBox = styled.div`
  margin: 0 0 20px;

  &:last-of-type {
    margin: 0;
  }
`;

export const BlueLabel = styled.label`
  display: block;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 3.6rem;
  color: ${theme.color.blue};
`;

export const BlackLabel = styled.label`
  display: block;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 3.6rem;
  color: ${theme.color.black};
`;

export const Text = styled.p`
  font-size: 1.3rem;
  line-height: 1.3rem;
`;

export const EditorBox = styled.div`
  font-size: 1.3rem;
  line-height: 2.6rem;

  img {
    width: 100%;
  }
`;

export const EventQuestionSection = styled.section`
  padding: 36px 20px 0;
`;

export const DefaultEventDetailSection = styled.section`
  padding: 0 20px;
`;

export const EventFooterWrapper = styled.div`
  padding: 36px 20px;
  margin-top: 23px;
  width: 100%;
  min-width: ${theme.layout.minWidth};
  max-width: ${theme.layout.maxWidth};
  min-height: 180px;

  background: ${theme.backgroundColor.footerBox};
`;
