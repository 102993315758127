import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApp from 'hooks/useApp';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';

// 사용자의 화면 권한에 따라 화면 접근을 허용하는 route
const ProtectedRoute = ({ renderComp }) => {
  const { auth } = useApp();
  const navigate = useNavigate();

  const onCloseNoBtnModal = () => {
    navigate('/');
    setAlertModal(false);
  };

  //alert 모달
  const [alertModal, setAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const businessMappingYn = auth?.memberRoleData?.businessMappingYn;

  useEffect(() => {
    if (businessMappingYn === 'N') {
      setAlertModal(true);
      setAlertMessage('현재 서비스 이용이 불가능합니다.');
    }
  }, [businessMappingYn]);

  return businessMappingYn === 'Y' ? (
    renderComp
  ) : (
    <NoBtnModal showModal={alertModal} onClose={onCloseNoBtnModal}>
      <ScrollArea>
        <Contents>{alertMessage}</Contents>
      </ScrollArea>
    </NoBtnModal>
  );
};

export default ProtectedRoute;
