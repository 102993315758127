import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDataRequest } from 'utils/request';
import { KEY_DATAPROVIDEAGREESEQ, KEY_DEEPLINKHASH } from 'tools/WebTool';
import useApp from 'hooks/useApp';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import CustomerServiceFooter from 'layouts/Footer/CustomerServiceFooter';
import ROUTE_PATH from 'routes/config';
import MyPageNavLink from './MyPageNavLink';
import {
  NAV_LIST_MANAGE,
  NAV_LIST_DATA,
  NAV_LIST_LOAN,
  NAV_LIST_GUIDE,
  NOTICE_BANNER_STYLES,
} from './constants';
import {
  NameWrap,
  NameBox,
  MyPageInfoBox,
  MyInfoBox,
  NoticeBannerMsg,
} from './style';

const MyPageMain = () => {
  const { auth } = useApp();
  const navigate = useNavigate();
  const { memberRoleData, memberName } = auth;
  const { mypage, deepLink } = ROUTE_PATH;
  const [hasNewEvent, setHasNewEvent] = useState(false);
  const [noticeBannerData, setNoticeBannerData] = useState('');
  const [noticeBannerMsg, setNoticeBannerMsg] = useState('');
  // 대표일 때만 직원 관리 페이지 접근 가능
  const isRepresentative = memberRoleData?.representativeYn === 'Y';
  const HasBusiness = memberRoleData?.businessMappingYn === 'Y';

  useEffect(() => {
    getDataRequest({
      url: '/v1/au/common/my-page/notice-banner',
      setData: setNoticeBannerData,
      setModalMessage: setNoticeBannerMsg,
    });
    getDataRequest({
      url: '/v1/na/event/new',
      successFnc: data => {
        setHasNewEvent(data?.newEventYn === 'Y');
      },
    });
  }, []);

  const onClickNoticeBannerHandler = () => {
    switch (noticeBannerData.code) {
      case 'MCR':
        navigate(mypage.manageLoanDataProvide);
        break;
      case 'MDU':
        navigate(mypage.manageLoanDataProvide);
        break;
      case 'MDI':
        navigate(mypage.dataLinkageManage);
        break;
      case 'MLC':
        navigate(mypage.checkLoan);
        break;
      case 'MDA':
        navigate(deepLink.agree);
        localStorage.removeItem(KEY_DEEPLINKHASH);
        localStorage.setItem(
          KEY_DATAPROVIDEAGREESEQ,
          noticeBannerData.dataProvideAgreeSeq
        );
        break;
    }
  };

  return (
    <div>
      <MyPageInfoBox>
        <NameWrap>
          <NameBox>{memberName}</NameBox>님
        </NameWrap>
        <MyInfoBox
          onClick={() => {
            navigate(mypage.myInform);
          }}
        >
          내 정보
        </MyInfoBox>
      </MyPageInfoBox>
      {HasBusiness && (
        <StyledActiveButton
          style={NOTICE_BANNER_STYLES}
          onClick={onClickNoticeBannerHandler}
        >
          <NoticeBannerMsg>{noticeBannerMsg}</NoticeBannerMsg>
          <FeatherIcon
            icon="chevron-right"
            size={24}
            strokeWidth={2}
            stroke="white"
          />
        </StyledActiveButton>
      )}
      {HasBusiness && (
        <div>
          {isRepresentative && (
            <MyPageNavLink
              title="직원 관리"
              menuList={NAV_LIST_MANAGE}
              borderTop="1px solid black"
            />
          )}
          <MyPageNavLink
            title="데이터 연동"
            menuList={NAV_LIST_DATA}
            borderTop={!isRepresentative && '1px solid black'}
          />
          <MyPageNavLink title="대출 신청" menuList={NAV_LIST_LOAN} />
        </div>
      )}
      <MyPageNavLink
        title="안내"
        menuList={NAV_LIST_GUIDE}
        ShowDesc={false}
        hasNewEvent={hasNewEvent}
      />
      <CustomerServiceFooter hasKaKao={true} />
    </div>
  );
};

export default MyPageMain;
