export const FINANCE_SUPERVISORY_SERVICE_TAB = [
  {
    index: 0,
    label: '고지사항',
    title: '금융상품판매대리/중개업자의 고지의무',
  },
  {
    index: 1,
    label: '공고',
    title: '공고',
  },
  {
    index: 2,
    label: '자료열람요구안내',
    title: '자료열람요구권 안내',
  },
];
