import styled from 'styled-components';
import theme from 'styles/theme';

export const SecondAuthInfoWrapper = styled.div`
  margin: 18px 35px 0 35px;
  border: 0.5px solid ${theme.borderColor.lightGrey};
  border-radius: 5px;
`;

export const CheckBoxWrapper = styled.div`
  padding: 0 18px 10px;
  background: ${theme.backgroundColor.icyBlue};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const Card = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding: 6px 18px;
  border-bottom: ${props =>
    props.isOpen ? `0.5px solid ${theme.borderColor.lightGrey}` : '0'};

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const CardColumn = styled.div`
  position: relative;
  float: right;
  width: 100%;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

export const CardName = styled.div`
  display: ${props => (props.isOpen ? 'block' : '-webkit-box')};
  width: calc(100% - 36px);
  margin: 7px 0 4px;
  line-height: 2rem;
  font-size: 1.3rem;
  font-weight: 400;
  color: ${theme.color.blue};
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  white-space: pre-line;

  -webkit-line-clamp: 2; /* 크롬, 사파리 */
  -moz-line-clamp: 2; /* 파이어폭스 */
  -o-line-clamp: 2; /* 오페라 */
  -ms-line-clamp: 2; /* 익스플로러 */
  line-clamp: 2;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
`;

export const DetailButton = styled.button`
  position: absolute;
  display: block;
  width: 36px;
  height: 36px;
  top: ${props => (props.rotated ? '28px' : '-0px')};
  right: 0;
  background: url(/images/icons/dropdown/main_ar_s2.png) right no-repeat;
  background-size: 6px 11px;
  transform: ${props => (props.rotated ? 'rotate(270deg)' : 'rotate(90deg)')};
`;
