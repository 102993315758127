import React from 'react';
import { NoListTextTitle } from './style';

const NoListText = ({ title = '', description = '', border }) => {
  return (
    <div>
      <NoListTextTitle padding border={border}>
        {title}
      </NoListTextTitle>
      <NoListTextTitle>{description}</NoListTextTitle>
    </div>
  );
};

export default NoListText;
