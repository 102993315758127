import styled from 'styled-components';
import theme from 'styles/theme';

export const ChildTextRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 27px 0 0;
`;

export const ChildTextContent = styled.div`
  display: block;
  float: right;
  width: 100%;
  margin: 0 0 0 5px;

  text-align: left;
  line-height: initial;
  font-size: 1.4rem;
  font-weight: ${props => (!!props.bold ? '500' : '400')};

  color: ${theme.color.black};

  white-space: pre-line;
  cursor: pointer;

  &:not(:first-of-type) {
    margin-bottom: 20px;
  }
`;
