import StyledActiveButton from 'components/Button/StyledActiveButton';
import {
  DataBox,
  DataHeader,
  DataName,
  DataKey,
  DataValue,
  DataValueText,
  ModifyEmployee,
  Edit,
  Delete,
  MapWrapper,
  DivFlex,
} from './style';
import { createDataRequest } from 'utils/request';

const EmployeeItem = ({
  data,
  gotoModify,
  toggleDeleteModal,
  toggleInviteModal,
  toggleCancelModal,
  toggleExportModal,
  setAlertModal,
  setAlertMessage,
}) => {
  const {
    businessManagerSeq,
    managerName,
    contactNo,
    mobilePhone,
    department,
    position,
    guideComment,
    invitationYn,
    invitationPeriodExpiredYn,
    invitationCompletionYn,
    invitationJoinResultFailYn,
    managerMemberWhetherStatus,
    managerMemberWhetherStatusName,
  } = data;

  const employeeItemInfo = [
    { name: '연락처', value: contactNo },
    { name: '휴대폰번호', value: mobilePhone },
    { name: '부서', value: department },
    { name: '직급', value: position },
    { name: '회원 상태', value: managerMemberWhetherStatusName },
  ];

  // 초대하기 화면
  const isNotInvited =
    invitationYn === 'N' ||
    (invitationYn === 'Y' &&
      invitationPeriodExpiredYn === 'Y' &&
      invitationCompletionYn === 'N');

  // 회원 내보내기 화면
  const isCompleted =
    managerMemberWhetherStatus === 'Y' &&
    invitationYn === 'Y' &&
    invitationCompletionYn === 'Y';

  const onClickEditBtn = () => {
    if (!isNotInvited) {
      alert(
        '현재 직원 정보 수정이 불가합니다. [초대 취소] 후 직원 정보를 수정해주세요.'
      );
      return;
    }
    gotoModify(data);
  };

  const onClickDeleteBtn = () => {
    toggleDeleteModal({ businessManagerSeq, managerName });
  };

  const onClickInviteBtn = () => {
    // 원버튼 모달 창 열기
    toggleInviteModal(data);
  };

  const onClickCancelInvitationBtn = () => {
    // 원버튼 모달 창 열기
    toggleCancelModal(data);
  };

  const onClickResendMessageBtn = () => {
    // 문자 재전송 api 통신
    createDataRequest({
      url: `/v1/au/manager/${businessManagerSeq}/member-invite-resend`,
      setModalMessage: setAlertMessage,
      setShowModal: setAlertModal,
      setShowAlertModal: setAlertModal,
    });
  };

  const onClickExportBtn = () => {
    // 원버튼 모달 창 열기
    toggleExportModal(data);
  };

  const isFailed = invitationJoinResultFailYn === 'Y';

  return (
    <DataBox>
      <DataHeader>
        <DataName>{managerName}</DataName>
        {isFailed ? (
          <ModifyEmployee>
            <Edit disabled>수정</Edit>
            <Delete onClick={onClickDeleteBtn}>삭제</Delete>
          </ModifyEmployee>
        ) : isNotInvited ? (
          <ModifyEmployee>
            <Edit onClick={onClickEditBtn}>수정</Edit>
            <Delete onClick={onClickDeleteBtn}>삭제</Delete>
          </ModifyEmployee>
        ) : !isCompleted ? (
          <ModifyEmployee>
            <Edit onClick={onClickEditBtn}>수정</Edit>
            <Delete disabled>삭제</Delete>
          </ModifyEmployee>
        ) : null}
      </DataHeader>

      {employeeItemInfo.map((data, index) => (
        <MapWrapper key={`EmployeeItemInfo_${businessManagerSeq}_${index}`}>
          <DataKey>{data.name}</DataKey>
          <DataValue>
            <DataValueText>{data.value || '-'}</DataValueText>
          </DataValue>
        </MapWrapper>
      ))}
      <MapWrapper>
        <DataValue>
          <DataValueText description>{guideComment}</DataValueText>
        </DataValue>
      </MapWrapper>

      {isFailed ? null : isNotInvited ? (
        <StyledActiveButton onClick={onClickInviteBtn}>
          회원 초대하기
        </StyledActiveButton>
      ) : isCompleted ? (
        <StyledActiveButton onClick={onClickExportBtn}>
          회원 내보내기
        </StyledActiveButton>
      ) : (
        <DivFlex>
          <StyledActiveButton onClick={onClickCancelInvitationBtn}>
            초대 취소
          </StyledActiveButton>
          <StyledActiveButton halfButton onClick={onClickResendMessageBtn}>
            문자 재전송
          </StyledActiveButton>
        </DivFlex>
      )}
    </DataBox>
  );
};
export default EmployeeItem;
