import React from 'react';
import { moneyFormat } from 'tools/MoneyTool';
import { ColoredStatusSpan } from 'styles/ColoredStatusSpan';
import {
  Img,
  ListTop,
  MainBox,
  MainBoxBtn,
  MainBoxColumn,
  MainBoxImgDiv,
  MainBoxSection,
  MainBoxText,
  MainBoxTextBlue,
  MainBoxTextDiv,
  MainBoxTitle,
  MainBoxTooltip,
} from '../style';

const imgHost = process.env.REACT_APP_FINANCE_IMG_HOST;

const MainLoanProduct = ({
  title = '',
  data = {},
  onClickProductPage = () => {},
}) => {
  const {
    creditGoodsMasterEventSeq,
    creditGoodsMasterEventName,
    financeInstitutionName,
    financeImgPath = '',
    maximumLimit,
    lowestInterest,
    badge,
    loansLimitInquirySeq,
  } = data;
  const financeImgUrl = imgHost + financeImgPath;
  const maxLimitAmount = moneyFormat(maximumLimit);

  return (
    <MainBox>
      <MainBoxTitle>{title}</MainBoxTitle>
      <MainBoxSection>
        <MainBoxImgDiv>
          <Img src={financeImgUrl} alt={financeInstitutionName} />
        </MainBoxImgDiv>

        <MainBoxColumn>
          <ListTop>
            <MainBoxTextDiv>
              <MainBoxTextBlue>
                {maxLimitAmount}, {lowestInterest}%
              </MainBoxTextBlue>
              <MainBoxText>{data?.financeInstitutionName}</MainBoxText>
            </MainBoxTextDiv>

            {badge !== '대출 불가' ? (
              <MainBoxTooltip>
                <ColoredStatusSpan
                  badge={badge}
                  onClick={event => event.stopPropagation()}
                >
                  {badge}
                </ColoredStatusSpan>
              </MainBoxTooltip>
            ) : (
              <MainBoxBtn
                onClick={() =>
                  onClickProductPage(
                    creditGoodsMasterEventSeq,
                    loansLimitInquirySeq
                  )
                }
              >
                <ColoredStatusSpan badge={badge}>
                  {badge || ''}
                </ColoredStatusSpan>
              </MainBoxBtn>
            )}
          </ListTop>
        </MainBoxColumn>
      </MainBoxSection>
    </MainBox>
  );
};

export default MainLoanProduct;
