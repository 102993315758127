import React, { useCallback, useEffect, useState } from 'react';
import useApp from 'hooks/useApp';
import qs from 'qs';
import api from 'utils/api';
import ROUTE_PATH from 'routes/config';
import WideButton from 'components/Button/WideButton';
import StyledActiveButton from 'components/Button/StyledActiveButton';

const IdentificationBox = ({
  returnUrl = `${window.location.origin}${ROUTE_PATH.identificationSuccessCallback}`,
  errorUrl = `${window.location.origin}${ROUTE_PATH.identificationFailCallback}`,
  identificationPurposeType,
  identificationSuccessCallback,
  identificationFailCallback,
  identificationExceptCallback,
  modalButton,
  btnMessage = '휴대폰 본인인증',
  subText = '',
  text = '',
  disabled = false,
  btnStyle = {},
}) => {
  const {
    auth: { authenticated },
  } = useApp();

  const [identificationQuery, setIdentificationQuery] = useState({
    authType: 'M',
    deviceType: '',
    returnUrl: '',
    errorUrl: '',
  });

  useEffect(() => {
    setIdentificationQuery(prevState => ({
      ...prevState,
      authType: 'M',
      deviceType: detectDeviceType(),
      returnUrl: returnUrl,
      errorUrl: errorUrl,
    }));
  }, [returnUrl, errorUrl]);

  const onClickIdentification = useCallback(() => {
    openPopupIdentification({ identificationQuery, authenticated });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificationQuery, authenticated]);

  window._identificationPurposeType = identificationPurposeType;

  window._identificationSuccessCallback =
    identificationSuccessCallback ?? (() => {});

  window._identificationFailCallback = identificationFailCallback ?? (() => {});

  window._identificationExceptCallback =
    identificationExceptCallback ?? (() => {});

  return modalButton ? (
    <div
      role="button"
      tabIndex={0}
      onClick={onClickIdentification}
      style={{
        textAlign: 'center',
        color: '#fff',
      }}
    >
      {btnMessage}
    </div>
  ) : !!subText && !!text ? (
    <WideButton
      onClick={onClickIdentification}
      disabled={disabled}
      subText={subText}
      text={text}
    />
  ) : (
    <StyledActiveButton
      tabIndex={0}
      onClick={onClickIdentification}
      disabled={disabled}
      style={btnStyle}
    >
      {btnMessage}
    </StyledActiveButton>
  );
};

const openPopupIdentification = ({ identificationQuery, authenticated }) => {
  const apiUrl = authenticated
    ? '/v1/au/nice-id/encode'
    : '/v1/na/nice-id/encode';
  api({
    url: apiUrl + '?' + qs.stringify({ ...identificationQuery }),
    method: 'get',
  })
    .then(res => {
      // encode data
      const { data } = res.data;
      // <form> 태그의 action 속성은 폼 데이터(form data)를 서버로 보낼 때 해당 데이터가 도착할 URL을 명시합니다.
      const identificationPopup = window.open(
        '/identification',
        'popupChk',
        'width=480, height=780, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
      );

      // Safari, FF, IE, Opera 감지 기능
      if (
        typeof identificationPopup === 'undefined' ||
        identificationPopup === null
      ) {
        alert('팝업이 차단 되었습니다. 팝업을 허용하고 다시 시도해주세요.');
      } else {
        // 팝업창의 window 객체에 encodeData 넣어준다.
        identificationPopup.window._encodeData = data.encodeData;
      }
    })
    .catch(err => {
      console.error(err);
    });
};

// User-Agent 문자열에 각 기기 종류를 검색하고 해당 기기 종류를 반환합니다.
const detectDeviceType = () => {
  // 브라우저의 User-Agent 문자열을 가져옵니다.
  const userAgent = navigator.userAgent;
  // 감지할 기기의 종류를 정의합니다.
  const mobileDevices = [
    'iPhone',
    'iPad',
    'iPod',
    'Android',
    'webOS',
    'BlackBerry',
    'Windows Phone',
    'iPad',
    'Android',
    'webOS',
  ];
  const desktopDevices = ['Windows', 'Macintosh', 'Linux'];
  const isMobile = mobileDevices.some(mobileDevice =>
    userAgent.includes(mobileDevice)
  );
  const isDesktop = desktopDevices.some(desktopDevice =>
    userAgent.includes(desktopDevice)
  );

  return isMobile ? 'Mobile' : isDesktop ? '' : '';
};

export default IdentificationBox;
