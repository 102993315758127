import React, { useState, useEffect } from 'react';
import useApp from 'hooks/useApp';
import api from 'utils/api';
import { getDataRequest } from 'utils/request';
import { TwoBtnModal, NoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import DataTable from 'components/Table/DataTable';
import SelectManagerModal from './SelectManagerModal';
import {
  LoanCard,
  LoanCardColumn,
  LoanBankName,
  LoanCardRow,
  LoanCardRowSecond,
  DetailModalWrap,
  DetailModalTitle,
  DetailModalTitleTable,
  DetailModalData,
  DetailModalText,
  ButtonWrap,
  RoundBox,
  CardContainer,
  DetailButton,
  NoticeText,
  MallNameSpan,
  DateSpan,
} from './style';

// table header
const tableHead = ['날짜', '상태'];
const LoanProductsList = ({
  item,
  validManagerList,
  financeInstitutionList,
  locationState = {},
}) => {
  const { auth } = useApp();
  const representativeYn = auth?.memberRoleData?.representativeYn;
  const [viewDetailModal, setViewDetailModal] = useState(false);
  const [creditRequestDetailData, setCreditRequestDetailData] = useState({
    creditRequestSeq: '',
    creditStatusName: '',
    creditStatus: '',
    processDt: '',
    creditGoodsName: '',
    financeInstitutionName: '',
    requestAmt: '',
    mallCount: '',
    managerName: '',
    department: '',
    position: '',
    creditRequestSecuredTypeMallList: [],
  });
  const [tableData, setTableData] = useState([]);
  const [toolTipModal, setToolTipModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  // 담당자 변경에 필요한 상태값들
  const [showSelectManagerModal, setShowSelectManagerModal] = useState(false);
  const [selectedManager, setSelectedManager] = useState({});

  const [backendAlertModal, setBackendAlertModal] = useState(false);
  const [backendAlertMessage, setBackendAlertMessage] = useState('');
  const [locationReload, setLocationReload] = useState(false);

  const [financeInstitutionSeq, setFinanceInstitutionSeq] = useState(0);
  // 도이치파이낸셜의 seq 는 10003 이다.
  const isFinanceInstDeutsch = financeInstitutionSeq === 10003;
  const mallSeqList =
    !!creditRequestDetailData?.creditRequestSecuredTypeMallList &&
    creditRequestDetailData?.creditRequestSecuredTypeMallList?.map(
      mall => mall?.mallSeq
    );

  const {
    creditRequestSeq,
    creditStatus,
    creditStatusName,
    financeInstitutionName,
    mallList,
    requestCompletionDt,
    creditRequestTypeName,
    creditGoodsName,
  } = item;

  const isDisabledChangeManager =
    creditRequestDetailData?.creditStatus !== 'REQ' ||
    isFinanceInstDeutsch ||
    creditRequestDetailData?.dataUseType === 'CREDIT_LOAN_CMPRS' ||
    representativeYn === 'N';

  // 회원 탈퇴 프로세스로 진입한 케이스
  const isMemberWithdrawalProcess =
    locationState?.parentPage === 'memberWithdrawalPage' &&
    locationState?.creditRequestSeq === creditRequestSeq;

  useEffect(() => {
    setFinanceInstitutionSeq(
      financeInstitutionList?.find(
        item => item?.label === financeInstitutionName
      )?.value || 0
    );
  }, [financeInstitutionList]);

  useEffect(() => {
    if (isMemberWithdrawalProcess) {
      onClickShowDetailModal(locationState?.creditRequestSeq);
    }
  }, []);

  const showManagerChangeModal = () => {
    setShowSelectManagerModal(!showSelectManagerModal);
    setViewDetailModal(!viewDetailModal);
  };

  const showToolTipModal = () => {
    setToolTipModal(!toolTipModal);
  };

  // 상세보기 Modal창 관리 함수
  const onClickShowDetailModal = creditRequestSeq => {
    setViewDetailModal(!viewDetailModal);
    getCreditRequestDetail(creditRequestSeq);
  };

  const onCloseShowDetailModal = () => {
    setViewDetailModal(!viewDetailModal);
    if (isMemberWithdrawalProcess) {
      // location.state 초기화
      window.history.replaceState({}, '');
    }
    // 대출 상태 업데이트 확인: 목록 새로고침
    if (creditRequestDetailData?.creditStatusName !== creditStatusName) {
      window.location.reload();
    }
  };

  // 대출 신청 취소 버튼 클릭시
  const onClickBtnCancelLoan = () => {
    setShowConfirmModal(true);
  };

  const onClickBtnCancelLoanFinalRequest = () => {
    onCloseShowDetailModal();
    deleteCreditRequest();
  };

  // 담당자 변경 버튼 클릭시
  const onClickChangeManager = () => {
    showManagerChangeModal();
  };

  const onClickSelectManager = () => {
    if (selectedManager && selectedManager.businessManagerSeq) {
      changeManager();
      setShowSelectManagerModal(false);
      setViewDetailModal(!viewDetailModal);
    }
  };

  // 모달 상세 & 테이블 목록 가져오기
  const getCreditRequestDetail = creditRequestSeq => {
    const successFnc = data => {
      setCreditRequestDetailData(data?.result || []);
      if (data?.hisList) {
        setTableData([
          ...data?.hisList.map(item => ({
            historyDate: item?.historyDt,
            creditStatus: item?.creditStatusName,
          })),
        ]);
      }
    };

    getDataRequest({
      url: `/v1/au/credit-request/${creditRequestSeq}`,
      successFnc: successFnc,
    });
  };

  // 대출신청취소 api
  const deleteCreditRequest = () => {
    api({
      url: `/v1/au/credit-request/${creditRequestSeq}`,
      method: 'delete',
      headers: { 'content-type': 'application/json' },
    })
      .then(res => {
        const { success, message } = res.data;
        if (success) {
          setBackendAlertModal(true);
          setBackendAlertMessage(
            `대출 신청 취소가 완료되었어요.\n 대출 신청을 다시 진행해 주세요.`
          );
          setShowConfirmModal(false);
          setLocationReload(true);
        } else {
          setBackendAlertModal(true);
          setBackendAlertMessage(message);
        }
      })
      .catch(err => {
        console.error(err);
        const { data } = err.response;
        setBackendAlertModal(true);
        setBackendAlertMessage(data.message);
      })
      .finally(() => {
        if (isMemberWithdrawalProcess) {
          // location.state 초기화
          window.history.replaceState({}, '');
        }
      });
  };

  // 직원 변경 api
  const changeManager = () => {
    api({
      url: `/v1/au/credit-request/manager/${creditRequestSeq}`,
      method: 'put',
      headers: { 'content-type': 'application/json' },
      data: {
        businessManagerSeq: selectedManager?.businessManagerSeq,
        managerName: selectedManager?.managerName,
        managerContact: selectedManager?.mobilePhone,
      },
    })
      .then(res => {
        const { success, message } = res.data;
        if (success) {
          setBackendAlertModal(true);
          setBackendAlertMessage('담당자를 변경했어요.');
          setLocationReload(true);
        } else {
          setBackendAlertModal(true);
          setBackendAlertMessage(message);
        }
      })
      .catch(err => {
        console.error(err);
        const { data } = err.response;
        setBackendAlertModal(true);
        setBackendAlertMessage(data.message);
      });
  };
  const onCloseBackendAlertModal = () => {
    if (locationReload) {
      window.location.reload();
    } else {
      setBackendAlertModal(false);
      setShowConfirmModal(false);
    }
    setLocationReload(false);
  };

  return (
    <LoanCard>
      <LoanCardColumn>
        <CardContainer>
          <LoanBankName>{financeInstitutionName}</LoanBankName>
          <DetailButton
            onClick={() => onClickShowDetailModal(creditRequestSeq)}
          >
            {creditStatusName + ' >'}
          </DetailButton>
        </CardContainer>
        <LoanCardRow>
          <LoanCardRowSecond>
            {`${creditRequestTypeName} ${creditGoodsName}`}
          </LoanCardRowSecond>
        </LoanCardRow>
        <LoanCardRow>
          <LoanCardRowSecond>{mallList}</LoanCardRowSecond>
        </LoanCardRow>
        <LoanCardRow>
          <LoanCardRowSecond>{requestCompletionDt}</LoanCardRowSecond>
        </LoanCardRow>

        {/* 대출 상세 모달 */}
        <NoBtnModal
          title={`[${creditRequestDetailData?.creditStatusName}] ${creditRequestDetailData?.financeInstitutionName}`}
          showModal={viewDetailModal}
          onClose={onCloseShowDetailModal}
        >
          <ScrollArea title="title">
            <Contents>
              {creditRequestDetailData?.creditStatus === 'EXPI' && (
                <NoticeText>
                  금융기관 담당자가 기한 내 신청내역을 확인하지 않아 대출이
                  중단된 상태예요. 해당 금융기관에 문의하거나 다른 대출 상품을
                  신청해 보세요.
                </NoticeText>
              )}
              <ButtonWrap>
                <StyledActiveButton
                  onClick={onClickChangeManager}
                  disabled={isDisabledChangeManager}
                >
                  담당자 변경
                </StyledActiveButton>
                <StyledActiveButton
                  halfButton="halfButton"
                  onClick={onClickBtnCancelLoan}
                  disabled={creditStatus !== 'REQ'}
                >
                  대출 신청 취소
                </StyledActiveButton>
              </ButtonWrap>
              <DetailModalWrap>
                <DetailModalTitle>신청번호</DetailModalTitle>
                <DetailModalData>
                  <DetailModalText>
                    {`${creditRequestDetailData?.creditRequestSeq} | ${creditRequestDetailData?.processDt}`}
                  </DetailModalText>
                </DetailModalData>
                <DetailModalTitle>대출 상품</DetailModalTitle>
                <DetailModalData>
                  <DetailModalText>
                    {creditRequestDetailData?.creditGoodsName}
                  </DetailModalText>
                </DetailModalData>
                <DetailModalTitle>대출 신청 금액</DetailModalTitle>
                <DetailModalData>
                  <DetailModalText>
                    {Number(
                      creditRequestDetailData?.requestAmt
                    ).toLocaleString()}
                    원
                  </DetailModalText>
                </DetailModalData>
                <DetailModalTitle>
                  판매몰({creditRequestDetailData?.mallCount})
                </DetailModalTitle>
                {creditRequestDetailData?.dataProvideType === 'UNSECURED' && (
                  <DetailModalData>
                    <DetailModalText>
                      {
                        creditRequestDetailData?.creditRequestUnsecuredTypeMallList
                      }
                    </DetailModalText>
                  </DetailModalData>
                )}
                {creditRequestDetailData?.dataProvideType === 'SECURED' && (
                  <DetailModalData>
                    {creditRequestDetailData?.creditRequestSecuredTypeMallList?.map(
                      mall => {
                        return (
                          <RoundBox key={mall?.mallSeq}>
                            <MallNameSpan>{`${mall?.mallName}`}</MallNameSpan>
                            <DateSpan>{`${mall?.settlementBeginDt} ~ ${mall?.settlementEndDt}`}</DateSpan>
                          </RoundBox>
                        );
                      }
                    )}
                  </DetailModalData>
                )}
                <DetailModalTitleTable>대출상태</DetailModalTitleTable>
                <DataTable
                  tableName="CreditRequest"
                  tableHead={tableHead}
                  tableData={tableData}
                />
                <DetailModalTitle>담당 직원</DetailModalTitle>
                <DetailModalData>
                  <DetailModalText>
                    {creditRequestDetailData?.managerName}
                    {!!creditRequestDetailData?.department && (
                      <> | {creditRequestDetailData?.department}</>
                    )}
                    {!!creditRequestDetailData?.position && (
                      <> | {creditRequestDetailData?.position}</>
                    )}
                  </DetailModalText>
                </DetailModalData>
              </DetailModalWrap>
            </Contents>
          </ScrollArea>
        </NoBtnModal>

        {/* 매니저 선택 모달 */}
        <SelectManagerModal
          showSelectManagerModal={showSelectManagerModal}
          setShowSelectManagerModal={setShowSelectManagerModal}
          onClickSelectManager={onClickSelectManager}
          bankName={creditRequestDetailData?.financeInstitutionName}
          validManagerList={validManagerList}
          setSelectedManager={setSelectedManager}
          selectedManager={selectedManager}
        />

        {/* 대출 신청 취소 확인 모달  */}
        <TwoBtnModal
          showModal={showConfirmModal}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          onReset={() => {
            setShowConfirmModal(false);
          }}
          onClick={onClickBtnCancelLoanFinalRequest}
          leftButton="닫기"
          rightButton="대출 신청 취소"
        >
          <ScrollArea>
            <Contents>
              대출 신청을 취소하시겠어요? <br />
              해당 신청건은 담당자가 아직 확인 전이며, <br />
              취소 후 재신청이 가능해요.
            </Contents>
          </ScrollArea>
        </TwoBtnModal>

        {/* Alert 모달  */}
        <NoBtnModal
          showModal={backendAlertModal}
          onClose={onCloseBackendAlertModal}
        >
          <ScrollArea>
            <Contents>{backendAlertMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>

        <NoBtnModal
          showModal={toolTipModal}
          onClose={showToolTipModal}
          title="기한만료란?"
        >
          <ScrollArea title="title">
            <Contents>
              대출 신청 3일 내 금융기관 담당자가 확인 안하면 신청을 종료해요.
              다른 금융기관에 대출 신청할 기회를 드리기 위함이에요.
            </Contents>
          </ScrollArea>
        </NoBtnModal>
      </LoanCardColumn>
    </LoanCard>
  );
};

export default LoanProductsList;
