import styled from 'styled-components';
import theme from 'styles/theme';

export const SelectAll = styled.div`
  padding: 20px 0 16px;
`;

export const CheckCards = styled.div`
  display: block;
  width: 100%;
  margin: 26px 0 0;
  border-top: 1px solid ${theme.color.grey4};
`;

export const CheckCard = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding: 12px 0 18px;
  border-bottom: 1px solid ${theme.borderColor.lightGrey};

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  @media screen and (max-width: 375px) {
    padding: 36px 0 18px;
  }
`;

export const CheckCardDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px 0 0 0;
  width: 100%;

  @media screen and (max-width: 375px) {
    padding: 36px 0 18px;
  }
`;

export const CheckCardSpan = styled.span`
  @media screen and (max-width: 320px) {
    font-size: 1.4rem;
  }
`;

export const CheckCardText = styled.div`
  padding-left: 29px;
  line-height: 2.1rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.grey7};
`;

export const CheckCardStatus = styled.div`
  display: block;
  position: absolute;
  top: 21px;
  right: 0;
  width: 64px;
  height: 17px;
  line-height: 1.7rem;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  border-radius: 5px;

  color: ${({ color }) => theme.color[color || 'blue']};
  background: ${({ backgroundColor }) =>
    theme.backgroundColor[backgroundColor || 'checkBlue']};
`;
