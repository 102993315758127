import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import useApp from 'hooks/useApp';
import {
  getInflowChannel,
  KEY_DEEPLINK_TYPE,
  KEY_INFLOW_CHANNEL,
  KEY_INTERLOCK_HASH,
} from 'tools/WebTool';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import JoinMemberStep1 from './JoinMemberStep1';
import JoinMemberStep2 from './JoinMemberStep2';
import { JoinMemberWrapper } from './style';
import { DEEPLINK_TYPE } from 'pages/DeepLink/constants';

const JoinMemberPage = () => {
  const hasInflowChannel = !!getInflowChannel();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { auth } = useApp();
  const [step, setStep] = useState(1);
  const [memberName, setMemberName] = useState('');
  const [certificationYn, setCertificationYn] = useState();
  const [businessRegistrationNumber, setBusinessRegistrationNumber] =
    useState();
  const [foundationDate, setFoundationDate] = useState();
  const [businessName, setBusinessName] = useState();
  const [businessInfo, setBusinessInfo] = useState();
  const [businessSectionType, setBusinessSectionType] = useState('');
  const [noBtnModalData, setNoBtnModalData] = useState({
    isOpen: false,
    message: '',
  });

  useEffect(() => {
    if (auth.authenticated && step === 1) {
      // 로그인 되어있는 경우 home 이동
      navigate('/', { replace: true });
    }
  }, [auth.authenticated, navigate, step]);

  // 금융사 회원 연동 케이스(DeepLinkFinanceAppInterlockPage)
  useEffect(() => {
    if (location?.state) {
      const { businessValidationInfo = {} } = location?.state;
      const { bizInfo = {} } = businessValidationInfo;
      // 사업자 인증 성공 & 휴폐업 x
      if (
        bizInfo?.businessRegistrationCertificationYn === 'Y' &&
        bizInfo?.active
      ) {
        setCertificationYn(bizInfo?.businessRegistrationCertificationYn);
        setBusinessInfo(
          bizInfo?.businessRegistrationStatus === 'ACKNOWLEDGE' ? 'ACT' : ''
        );
        setBusinessSectionType(bizInfo?.businessSectionType);
        setStep(2);
      }
      // 사업자 인증 성공/실패: 공통 작업
      setBusinessRegistrationNumber(bizInfo?.businessRegistrationNumber);
      setFoundationDate(bizInfo?.foundationDate);
      setBusinessName(bizInfo?.businessName);
      setMemberName(bizInfo?.representativeName);
    }
  }, [location?.state]);

  // 유입 채널
  useEffect(() => {
    // 금융기관 / 그 외 유입 분리
    if (!!document.referrer) {
      const [domainPath, queryString] = document.referrer.split('?');
      if (queryString?.includes('utm')) {
        // 외부 채널
        localStorage.setItem(KEY_INFLOW_CHANNEL, queryString);
      } else {
        // 포털 유입, 소셜 미디어
        localStorage.setItem(KEY_INFLOW_CHANNEL, domainPath);
      }
    } else {
      if (!hasInflowChannel) {
        localStorage.setItem(KEY_INFLOW_CHANNEL, '');
      } else {
        // 딥링크 유입 케이스 - 각각 deeplinkAgreeEntryPage, deeplinkSecuredPage, deeplinkUnsecuredPage 에서 저장함
      }
    }
    if (!!location.search) {
      const deepLinkType = searchParams.get('type');
      // 금융사 회원가입 안내 유입일 때
      if (!!deepLinkType && deepLinkType === DEEPLINK_TYPE.joinMember) {
        const interlockHash = searchParams.get('financialInstitution');
        localStorage.setItem(KEY_INTERLOCK_HASH, interlockHash);
        localStorage.setItem(KEY_DEEPLINK_TYPE, deepLinkType);
        sessionStorage.setItem(KEY_INFLOW_CHANNEL, interlockHash);
      }
    }
  }, [hasInflowChannel, location.search]);

  return (
    <JoinMemberWrapper>
      {/* step1: 사업자 정보 인증 페이지 */}
      {!auth.authenticated && step === 1 && (
        <JoinMemberStep1
          setStep={setStep}
          setMemberName={setMemberName}
          setCertificationYn={setCertificationYn}
          setBusinessName={setBusinessName}
          setBusinessRegistrationNumber={setBusinessRegistrationNumber}
          setFoundationDate={setFoundationDate}
          setBusinessInfo={setBusinessInfo}
          setBusinessSectionType={setBusinessSectionType}
          businessValidationInfo={location?.state?.businessValidationInfo}
        />
      )}
      {/* step2: 회원 가입 페이지 */}
      {step === 2 && (
        <JoinMemberStep2
          memberName={memberName}
          certificationYn={certificationYn}
          businessRegistrationNumber={businessRegistrationNumber}
          foundationDate={foundationDate}
          businessName={businessName}
          businessInfo={businessInfo}
          businessSectionType={businessSectionType}
        />
      )}

      {/* 알럿 메세지 모달 */}
      <NoBtnModal
        showModal={noBtnModalData.isOpen}
        onClose={() => setNoBtnModalData({ isOpen: false, message: '' })}
      >
        <ScrollArea>
          <Contents>{noBtnModalData.message}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </JoinMemberWrapper>
  );
};

export default JoinMemberPage;
