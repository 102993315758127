import styled from 'styled-components';
import theme from 'styles/theme';

// 메인섹션 스타일
export const MainSectionThree = styled.div`
  position: relative;
  width: 100%;
  padding: 70px 35px 0;

  @media screen and (max-width: 375px) {
    padding: 50px 20px 0;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  text-align: center;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: ${theme.color.black};
  @media screen and (max-width: 375px) {
    font-size: 1.9rem;
    line-height: 2.8rem;
  }
`;

export const Unit = styled.div`
  width: 100%;
  padding: 63px 0 0;
  @media screen and (max-width: 375px) {
    padding: 52px 0 0;
  }
`;

export const Subtitle = styled.div`
  width: 100%;
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 2.9rem;
  color: ${theme.color.black};
  text-align: center;
  @media screen and (max-width: 375px) {
    font-size: 1.6rem;
    line-height: 2.9rem;
  }
`;

export const SubtitleSpan = styled.span`
  font-weight: 700;
`;

export const DataProvierName = styled.div`
  width: 100%;
  padding: 15px 0 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 2.2rem;
  color: ${theme.color.blue};
  text-align: center;
`;

export const Text = styled.div`
  width: 100%;
  padding: 15px 0 0;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 400;
  color: ${theme.color.black};
  text-align: center;
  letter-spacing: -0.5px;
  white-space: pre-line;

  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
    line-height: 2rem;
  }
`;

export const Button = styled.button`
  display: block;
  width: 220px;
  height: 45px;
  margin: 29px auto 0;
  border: 1px solid ${theme.color.blue};
  border-radius: 23px;
  text-align: center;
  color: ${theme.color.blue};
  font-size: 1.8rem;
  font-weight: bold;

  @media screen and (max-width: 375px) {
    margin: 20px auto 0;
    width: 180px;
    height: 40px;
    font-size: 1.4rem;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 70px 0 0;
  background: ${theme.backgroundColor.input};
`;
