import styled from 'styled-components';

export const StipulationWrapper = styled.div`
  display: block;
  width: 100%;
  margin: 20px 0 0;
  padding: 9px 0 10px 14px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;
