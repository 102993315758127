import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}
  html {
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    font-size: 10px;
    height: 100%;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;
    color: #111;
    letter-spacing: -0.1px;
    text-align: left;
    font-weight: 400;
    font-size: 1.4rem;
    font-family: 'Noto Sans KR', sans-serif;
  }

  body {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
    word-wrap: break-word;
    word-break: break-all;
    color: #222;
    background: #fff;
    font-family: 'Noto Sans KR', sans-serif;
  }

  div {
    white-space: normal;
  }

  nav,
  menu,
  ul,
  ol,
  li {
    list-style: none;
  }

  ol>li {
    list-style-type: decimal;
    margin-left: 10px;
  }
  ul>li {
    list-style: initial;
    margin-left: 10px;
  }
  ul.pagination>li {
      margin-left: 0;
  }
  
  a {
    margin: 0;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
  }

  button {
    text-transform: uppercase;
    border: none;
    background: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: default;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
  }

  em,
  address {
    font-style: normal;
  }

  input,
  img,
  fieldset {
    border: none;
  }

  div,
  section,
  article,
  header,
  footer,
  nav,
  aside,
  hgroup {
    display: block;
  }

  select {
    cursor: pointer;
  }

  legend,
  caption {
    overflow: hidden;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    line-height: 0;
  }

  li,
  object {
    vertical-align: top;
  }

  input,
  select,
  textarea,
  form {
    vertical-align: middle;
    color: #222;
    font-family: 'Noto Sans KR', sans-serif;
  }

  svg.feather-help-circle {
    background: #fff;
    border-radius: 50%;

    g {
      circle {
        color: #aaa;
      }

      path, line {
        color: #555;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  /* font */
  h1 {
    font-size: 3.2rem;
    line-height: 3.2rem;
  }

  h2 {
    font-size: 2.4rem;
    line-height: 2.4rem;
    display: block;
    cursor: default;
  }

  h3 {
    font-size: 2rem;
    line-height: 2rem;
  }

  h4 {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  h5 {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }

  h6 {
    font-size: 1.1rem;
    line-height: 1.1rem;
  }

  /* skip */
  legend,
  hr,
  .skip,
  .script_skip {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
  }

  caption {
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    padding: 0px;
    margin: 0px;
    visibility: hidden;
    line-height: 0rem;
    overflow: hidden;
  }

  select::-ms-expand {
    display: none;
  }

  .bold, strong {
    font-weight: 700;
  }

  .hidden {
    display: none;
    text-indent: -9999px;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    visibility: hidden;
    line-height: 0;
  }

  /*fix*/
  .clearfix {
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .ib-fix {
    line-height: 0;
  }

  .page-fix {
    overflow: hidden;
  }

  .hidden-fix {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    line-height: 0;
    text-indent: -10000px;
  }

  .lb-hide {
    overflow: hidden;
    position: absolute;
    width: 0;
    height: 0;
    line-height: 0;
    text-indent: -9999px;
  }

  .t-center {
    text-align: center;
  }

  .l-cont {
    float: left;
  }

  .l-cont {
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .r-cont {
    float: right;

    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .inside {
    display: inline-block;

    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .hide {
    font-size: 0;
  }

  .mt30 {
    margin-top: 30px;
  }

  *:focus {
    outline: 0;
  }

  #root {
    height: 100%;
  }

  .App {
    height: 100%;
  }
`;

export default GlobalStyle;
