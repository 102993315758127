import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const TextDiv = styled.div`
  display: block;
  width: 100%;
  padding: 41px 0 18px;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: ${theme.color.black};
`;

export const ContentBox = styled.div`
  display: block;
  width: 100%;
  padding: 13px 19px;
  margin: 23px 0 0;
  border-radius: 10px;
  background: ${theme.backgroundColor.contentBox};
`;

export const ContentTitle = styled.div`
  display: block;
  width: 100%;
  margin: 19px 0 0;
  line-height: 2.1rem;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.navyBlue};

  &:first-child {
    margin: 0;
  }
`;

export const ContentData = styled.div`
  display: block;
  width: 100%;
  margin: 3px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ContentDataText = styled.div`
  display: block;
  float: left;
  line-height: 2.1rem;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  color: ${theme.color.black};

  ${props => {
    if (props.blue) {
      return css`
        color: ${theme.color.blue};
      `;
    }
  }}
`;
