import React, { useEffect } from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { createMarkup } from 'tools/WebTool';
import {
  FilterModalWrapper,
  ModalHeader,
  Title,
  ModalClose,
  FilterSideArea,
  Wrapper,
  ScrollArea,
  Contents,
} from '../style';
import { StipulationContents, StipulationName } from './style';

const FilterPolicyModal = ({
  title = '',
  showModal = false,
  onClose = () => {},
  stipulation = {},
}) => {
  useEffect(() => {
    document.body.style.overflowY = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  return (
    showModal && (
      <Wrapper>
        <FilterModalWrapper $policy>
          <ModalHeader $policy>
            <Title>{title || '약관 및 정책'}</Title>
            <ModalClose onClick={onClose} $policy>
              <FeatherIcon icon="x" size={20} strokeWidth={2.2} />
            </ModalClose>
          </ModalHeader>
          <ScrollArea>
            <Contents>
              <StipulationName>{stipulation.stipulationCdName}</StipulationName>
              <StipulationContents
                dangerouslySetInnerHTML={createMarkup(
                  stipulation.stipulationContents
                )}
              />
            </Contents>
          </ScrollArea>
        </FilterModalWrapper>
        <FilterSideArea onClick={onClose} />
      </Wrapper>
    )
  );
};

export default FilterPolicyModal;
