import React, { useState } from 'react';
import api from 'utils/api';
import { alertMessages } from 'messages/alertMessages';
import TextField from 'components/TextField';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { NoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import { TextDiv } from './style';

const CheckPassword = ({ setStep }) => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showNoBtnModal, setShowNoBtnModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const onChange = e => {
    const { value } = e.target;
    value ? setMessage('') : setMessage(message);
    setPassword(value);
  };

  const onBlur = () => {
    password ? setMessage('') : setMessage(alertMessages.typePw);
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      validatePwRequest();
    }
  };

  const validatePwRequest = () => {
    const passwordValue = { memberPassword: password };
    api({
      url: '/v1/au/member/password/check',
      method: 'post',
      headers: { 'content-type': 'application/json' },
      data: passwordValue,
    })
      .then(res => {
        const { success, message } = res.data;
        if (success) {
          setStep(2);
        } else {
          setMessage(message);
        }
      })
      .catch(err => {
        console.error(err);
        setModalMessage(err?.response?.data?.message);
        setShowNoBtnModal(true);
      });
  };

  return (
    <>
      <TextDiv>
        정보 보호를 위해
        <br />
        비밀번호를 다시 한번 입력해주세요.
      </TextDiv>

      <div>
        <TextField
          type="password"
          title="비밀번호 입력"
          placeholder="비밀번호"
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          showMessage={!!message}
          message={message}
        />
        <StyledActiveButton tabIndex={0} onClick={validatePwRequest}>
          확인
        </StyledActiveButton>

        <NoBtnModal
          showModal={showNoBtnModal}
          onClose={() => {
            setShowNoBtnModal(false);
          }}
        >
          <ScrollArea>
            <Contents>{modalMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>
      </div>
    </>
  );
};

export default CheckPassword;
