import React from 'react';
import { useNavigate } from 'react-router-dom';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { AreaWrapper, AreaRow, H1, H3, P } from './style';

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <AreaWrapper>
      <AreaRow>
        <H1>404</H1>
        <H3>찾을수 없는 페이지 입니다.</H3>
        <P>요청하신 페이지가 사라졌거나, 잘못된 경로를 이용하셨어요.</P>
        <StyledActiveButton
          onClick={() => {
            navigate('/');
          }}
        >
          홈으로 이동
        </StyledActiveButton>
      </AreaRow>
    </AreaWrapper>
  );
};

export default Error404;
