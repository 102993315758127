import styled from 'styled-components';
import theme from 'styles/theme';

export const TotalCount = styled.div`
  padding: 16px 0;
  line-height: 3.4rem;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;

  border-bottom: 1px solid ${theme.color.black};
  color: ${theme.color.black};
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: calc(100vh - 201px); /** Topbar와 본문 padding 제외한 값*/

  font-size: 1.5rem;
`;

export const EmptyTitle = styled.div`
  margin-top: 24px;
  color: ${theme.color.black};
  font-size: 1.6rem;
  font-weight: 500;
`;

export const FileImage = styled.div`
  width: 122.5px;
  height: 68.25px;

  background-image: url('/images/file_illo.png');
  background-size: contain;
`;

export const Text = styled.p`
  margin-top: 24px;
  color: ${theme.color.grey6};
`;
