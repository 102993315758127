import React from 'react';
import { GreyInfoBoxWrap } from './style';
import { TextBold } from '../../style';

const GreyInfoBox = ({
  title = '',
  totalMallCount = 0,
  totalScheduledSettlementAmt,
}) => {
  return (
    <GreyInfoBoxWrap>
      <div>{title}</div>
      <div>
        <TextBold>{totalMallCount}</TextBold>
        <span>개</span>
        {totalScheduledSettlementAmt && (
          <>
            <span> | </span>
            <TextBold>{totalScheduledSettlementAmt}</TextBold>
            <span>원</span>
          </>
        )}
      </div>
    </GreyInfoBoxWrap>
  );
};

export default GreyInfoBox;
