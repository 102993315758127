import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const StyledReactQuill = styled(ReactQuill)`
  width: 100%;
  height: 100%;

  .ql-toolbar {
    display: none;
  }
  .ql-container.ql-snow {
    border: none;
  }
  .ql-editor {
    padding: 0;
    ol,
    ul {
      padding-left: 0;
      li {
        margin-left: 0;
      }
    }
  }
`;
