import styled from 'styled-components';
import theme from 'styles/theme';

export const MainImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin: 10px 0 0;
  border-radius: 20px;

  @media screen and (max-width: 375px) {
    border-radius: 15px;
  }
`;

export const MainText = styled.div`
  display: block;
  width: 100%;
  padding: 58px 0 27px;
  color: ${theme.color.black};
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.2rem;
  text-align: left;

  @media screen and (max-width: 375px) {
    font-size: 2rem;
    line-height: 3rem;
    padding: 34px 0 18px;
  }
`;

export const MainBlueText = styled.span`
  color: ${theme.color.aizenBlue};
  font-size: 2.4rem;
  font-weight: 500;

  @media screen and (max-width: 375px) {
    font-size: 20px;
  }
`;

export const SubTextWrapper = styled.div`
  display: block;
  width: 100%;
  color: ${theme.color.black};
`;

export const SubText = styled.div`
  display: block;
  width: 100%;
  padding: 23px 0 0px;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-align: left;

  @media screen and (max-width: 375px) {
    font-size: 14px;
    line-height: 2.2rem;
  }
`;

export const SubBoldText = styled.span`
  color: ${theme.color.black};
  font-size: 1.6rem;
  font-weight: 500;

  @media screen and (max-width: 375px) {
    font-size: 1.4rem;
  }
`;

export const ServiceLine = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  margin: 73px 0 55px;
  background: ${theme.backgroundColor.buttonLightGrey};

  @media screen and (max-width: 375px) {
    margin: 53px 0 43px;
  }
`;

export const ServiceTitle = styled.div`
  margin: 0 0 50px;
  color: ${theme.color.black};
  font-weight: 500;
  font-size: 2.2rem;
  text-align: left;
  line-height: 3rem;

  @media screen and (max-width: 375px) {
    font-size: 2rem;
    line-height: 2.8rem;
  }
`;

export const ServiceImage = styled.img`
  display: block;
  width: 326px;
  height: auto;
  margin: 10px auto 0;
  transform: translateX(-16px);

  @media screen and (max-width: 375px) {
    width: 90%;
    transform: translateX(-4px);
  }
`;

export const ServiceBox = styled.div`
  display: block;
  width: 100%;
  padding: 21px 27px 23px;
  margin: 50px 0 0;
  border-radius: 10px;
  background: ${theme.backgroundColor.footerBox};

  @media screen and (max-width: 375px) {
    padding: 15px 16px 17px;
  }
`;

export const TotalScore = styled.div`
  display: block;
  width: 100%;
  color: ${theme.color.black};
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 2.5rem;
  text-align: left;

  @media screen and (max-width: 375px) {
    font-size: 1.6rem;
  }
`;

export const Description = styled.div`
  display: block;
  width: 100%;
  margin: 8px 0 0;
  color: #333;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.3rem;
  text-align: left;

  @media screen and (max-width: 375px) {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

export const FinanceProducts = styled.div`
  display: block;
  width: 100%;
  margin: 34px 0 0;
  color: ${theme.color.black};
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 2.5rem;
  text-align: left;

  @media screen and (max-width: 375px) {
    font-size: 1.6rem;
  }
`;

export const ProductA = styled.div`
  display: block;
  width: 100%;
  margin: 8px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ProductB = styled.div`
  display: block;
  width: 100%;
  margin: 2px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ProductTitle = styled.div`
  float: left;
  width: 38px;
  color: ${theme.color.black};
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  line-height: 2.1rem;
`;

export const ProductContents = styled.div`
  float: left;
  width: calc(100% - 38px);
  color: #333;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: left;
  line-height: 2.1rem;
`;
