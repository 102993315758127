import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { getDataRequest, getListDataRequest } from 'utils/request';
import { replaceSpecialCharacter } from 'tools/StringTool';
import { createMarkup } from 'tools/WebTool';
import TextField from 'components/TextField';
import BlueAlertBox from 'components/BlueAlertBox';
import FoldedCard from 'components/FoldedCard';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { NoBtnModal } from 'components/Modal';
import MallListSection from './MallListSection';
import DataInterlockStipulation from './DataInterlockStipulation';
import LinkageGuideBox from 'pages/MyPage/DataLinkage/LinkageGuideBox';
import { Contents, ScrollArea } from 'components/Modal/style';
import CheckBox from 'components/CheckBox';
import { CaptchaImage } from 'pages/MallSecondAuthPage/style';
import { Card, CardName } from 'components/FoldedCard/style';
import {
  CapchaQuestion,
  DataLinkagePaddingWrapper,
  SelectProviderBtn,
} from '../../../style';
import {
  DataFnqWrap,
  SecondAuthAlertText,
  SecondAuthArea,
  SecondAuthCheckBoxArea,
} from './style';

const DataInterlockLinkInfo = ({
  dataInterlockInfo = {},
  setDataInterlockInfo = () => {},
  mallListData = {},
  idPlaceholder = '아이디',
  pwPlaceholder = '비밀번호',
  isChecked = false,
  setIsChecked,
  captchaData = {},
  setCaptchaData,
  onClickActiveBtn = () => {},
  dataProviderSeq = 0,
  btnText = '',
}) => {
  const {
    mallName = '',
    mallId = '',
    mallPassword = '',
    secondAuthReleaseType = '',
    secondAuthReleaseMessage = '',
    dataProviderMallSeq = 0,
    mallSeq = 0,
    agreeStipulationList = [],
    partnerCode,
    secondAuthType = 'SAT111',
    secondAuthValue,
  } = dataInterlockInfo;

  const {
    corporateClosedShoppingMallList = [],
    generalMallList = [],
    homeShoppingMallList = [],
    notOpenMallList = [],
    openMarketList = [],
    paymentGateWayMallList = [],
    specialtyMallList = [],
  } = mallListData;

  const mallListGroup = [
    { name: '오픈마켓', list: openMarketList },
    { name: '종합몰', list: generalMallList },
    { name: '홈쇼핑', list: homeShoppingMallList },
    { name: '전문몰', list: specialtyMallList },
    { name: 'PG사', list: paymentGateWayMallList },
    { name: '폐쇄몰', list: corporateClosedShoppingMallList },
    { name: '오픈 예정', list: notOpenMallList },
  ];

  const [showSelectMallModal, setShowSelectMallModal] = useState(false);
  const [guidanceContents, setGuidanceContents] = useState('');
  const [faqList, setFaqList] = useState([]);
  const [stipulationList, setStipulationList] = useState([]);

  const onClickSelectProvider = () => {
    setShowSelectMallModal(true);
  };

  useEffect(() => {
    if (mallSeq && dataProviderSeq && dataProviderMallSeq) {
      const params = {
        guidanceExposureLocationCode: 'ACCOUNT',
        mallSeq,
        dataProviderSeq,
      };
      getDataRequest({
        url: `/v1/au/salesMall/guidance`,
        params,
        successFnc: data => {
          setGuidanceContents(data?.guidanceContents || '');
        },
      });

      getDataRequest({
        url: `/v1/au/data-provider/mall/stipulation/${dataProviderMallSeq}`,
        successFnc: data => {
          setStipulationList(data.stipulationList || []);
        },
      });

      // 판매몰의 자주묻는 질문
      getListDataRequest({
        url: '/v1/au/faq/interlock/list',
        params: {
          mallSeq,
        },
        successFnc: list => {
          setFaqList(list.map(item => ({ ...item, isOpen: false })));
        },
      });
    }
  }, [mallSeq, dataProviderSeq]);

  const onChangeSelectProvider = ({ ...dataInterlockInfo }) => {
    !!setDataInterlockInfo &&
      setDataInterlockInfo({
        mallId: '',
        mallPassword: '',
        ...dataInterlockInfo,
      });
    // 선택되는 판매몰 변경될때 아래값 초기화

    setCaptchaData({});
    setIsChecked(false);
    setShowSelectMallModal(false);
  };

  // 버튼 활성화
  const activateButton = () => {
    let isDisabled = true;
    //아이디, 비밀번호 입력
    if (!!mallId && !!mallPassword) {
      if (mallSeq === 10017 && (!partnerCode || !secondAuthValue)) {
        isDisabled = true;
      } else if (mallSeq === 10019 && !secondAuthValue) {
        isDisabled = true;
      } else {
        isDisabled = false;
      }
      //2차 인증 필수
      if (secondAuthReleaseType === 'REQUIRED') {
        isDisabled = !isChecked;
        //캡처데이터의 에러코드
        if (captchaData?.errorCode) {
          isDisabled = !(isChecked && captchaData?.captchaValue);
        }
      }
    }
    return isDisabled;
  };

  const checkStipulation = () => {
    let isDisabled = true;
    const firstStipulation = agreeStipulationList[0];
    // 약관이 없을 때
    if (!firstStipulation) {
      isDisabled = false;
      //텍스트 약관 : 추후 수정 (텍스트 약관 필수값 조건 추가)
    } else if (firstStipulation?.stipulationCd === 'STC200') {
      isDisabled = false;
      //약관타입
    } else if (
      agreeStipulationList
        ?.filter(item => item.required === true)
        ?.every(item => item.agreeYn === 'Y')
    ) {
      isDisabled = false;
    }
    return isDisabled;
  };

  const onChangeTextField = event => {
    const { name, value } = event.target;
    const phoneNumValue = value.replace(/[^0-9]/g, '');
    if (name === 'secondAuthValue' && secondAuthType === 'SAT111') {
      !!setDataInterlockInfo &&
        setDataInterlockInfo(prev => ({ ...prev, [name]: phoneNumValue }));
    } else {
      !!setDataInterlockInfo &&
        setDataInterlockInfo(prev => ({ ...prev, [name]: value }));
    }
  };

  const onChangeSecondAuthType = value => {
    !!setDataInterlockInfo &&
      setDataInterlockInfo(prev => ({
        ...prev,
        secondAuthType: value,
        secondAuthValue: '',
      }));
  };

  const onChangeCaptchaTextField = event => {
    const value = replaceSpecialCharacter(event.target.value?.trim());
    setCaptchaData(prev => ({ ...prev, captchaValue: value }));
  };

  const onChangeCheckBox = event => {
    const { checked } = event.target;
    setIsChecked(checked);
    const secondAuthReleaseYn = checked === true ? 'Y' : 'N';
    setDataInterlockInfo(prev => ({ ...prev, secondAuthReleaseYn }));
  };

  const onClickDetailBtn = seq => {
    setFaqList(prev =>
      prev.map((item, index) =>
        index === seq
          ? { ...item, isOpen: !item.isOpen }
          : { ...item, isOpen: false }
      )
    );
  };

  return (
    <div>
      <DataLinkagePaddingWrapper>
        <SelectProviderBtn
          onClick={onClickSelectProvider}
          noAfter={true}
          placeholder="판매몰 선택"
        >
          {mallName || '판매몰 선택'}
        </SelectProviderBtn>
      </DataLinkagePaddingWrapper>
      {!!mallSeq && (
        <div>
          <DataLinkagePaddingWrapper>
            {/* 몰 안내 */}
            {!!guidanceContents && (
              <BlueAlertBox
                background="icyRed"
                title="주요 안내"
                contents={
                  <div
                    dangerouslySetInnerHTML={createMarkup(guidanceContents)}
                  />
                }
              />
            )}

            {/* 아이디 */}
            <TextField
              name="mallId"
              placeholder={idPlaceholder}
              value={mallId}
              onChange={onChangeTextField}
              required
            />

            {/* 비밀번호 */}
            <TextField
              type="password"
              name="mallPassword"
              placeholder={pwPlaceholder}
              value={mallPassword}
              onChange={onChangeTextField}
              required
            />

            {mallSeq === 10017 && (
              <TextField
                name="partnerCode"
                placeholder="협력사코드"
                type="text"
                value={partnerCode || ''}
                onChange={onChangeTextField}
              />
            )}
          </DataLinkagePaddingWrapper>

          {/* 문자/이메일 인증 */}
          {(mallSeq === 10017 || mallSeq === 10019) && (
            <SecondAuthArea>
              <SecondAuthAlertText>
                <FeatherIcon
                  icon="alert-circle"
                  size={14}
                  stroke="red"
                  style={{ margin: '5px 3px -2px 0' }}
                />
                해당 판매몰의 경우 로그인 시 문자 또는 이메일 인증이 필요해요.
                인증번호를 받을 문자 또는 이메일을 선택한 후, 해당 정보를 입력해
                주세요.
              </SecondAuthAlertText>
              <SecondAuthCheckBoxArea>
                <CheckBox
                  type="radio"
                  name="secondAuthType"
                  value="SAT111"
                  text="문자 인증"
                  id="SAT111"
                  isChecked={secondAuthType === 'SAT111'}
                  onChangeCheckBox={() => onChangeSecondAuthType('SAT111')}
                />
                <CheckBox
                  type="radio"
                  name="secondAuthType"
                  value="SAT112"
                  id="SAT112"
                  text="이메일 인증"
                  isChecked={secondAuthType === 'SAT112'}
                  onChangeCheckBox={() => onChangeSecondAuthType('SAT112')}
                />
              </SecondAuthCheckBoxArea>
              <TextField
                name="secondAuthValue"
                placeholder={
                  secondAuthType === 'SAT111'
                    ? '휴대폰번호(-제외)'
                    : '이메일(@까지 입력)'
                }
                type="text"
                value={secondAuthValue || ''}
                onChange={onChangeTextField}
              />
            </SecondAuthArea>
          )}
          {/* 2차인증 */}
          {(secondAuthReleaseType === 'REQUIRED' ||
            secondAuthReleaseType === 'RECOMMENDED') && (
            <LinkageGuideBox
              secondAuthReleaseMessage={secondAuthReleaseMessage}
              secondAuthReleaseType={secondAuthReleaseType}
              isChecked={isChecked}
              onChangeCheckBox={onChangeCheckBox}
            />
          )}
          {/* 캡쳐 */}
          {!!captchaData.captchaUrl && (
            <SecondAuthArea>
              <SecondAuthAlertText>
                <FeatherIcon
                  icon="alert-circle"
                  size={14}
                  stroke="red"
                  style={{ margin: '5px 3px -2px 0' }}
                />
                해당 판매몰의 경우 자동 등록 방지를 위한 캡차 인증이 필요해요.
                캡차 답변 입력 후 다시 한번 [판매몰 계정 확인]버튼을 눌러주세요.
              </SecondAuthAlertText>

              <CaptchaImage
                src={captchaData.captchaUrl || ''}
                alt="captchaAuth"
              />

              {!!captchaData.captchaQuestion ? (
                <>
                  <CapchaQuestion>
                    질문 : {captchaData.captchaQuestion}
                  </CapchaQuestion>
                  <TextField
                    name="captchaImageCode"
                    placeholder="정답을 입력해 주세요."
                    value={captchaData.captchaValue || ''}
                    onChange={onChangeCaptchaTextField}
                  />
                </>
              ) : (
                <TextField
                  name="captchaImageCode"
                  placeholder="이미지의 문자와 숫자를 입력해 주세요."
                  value={captchaData.captchaValue || ''}
                  onChange={onChangeCaptchaTextField}
                />
              )}
            </SecondAuthArea>
          )}

          <DataLinkagePaddingWrapper>
            {/* 약관 동의  */}
            {stipulationList?.length > 0 && (
              <DataInterlockStipulation
                stipulationList={stipulationList}
                setStipulationList={setStipulationList}
                setPostData={setDataInterlockInfo}
              />
            )}

            <StyledActiveButton
              onClick={onClickActiveBtn}
              margin="23px 0 46px"
              disabled={activateButton() || checkStipulation()}
            >
              {btnText}
            </StyledActiveButton>
          </DataLinkagePaddingWrapper>
          {faqList?.length > 0 && (
            <DataFnqWrap>
              <Card style={{ borderBottom: 'none', padding: '4px 0' }}>
                <CardName style={{ fontWeight: 500 }}>
                  데이터 연동 시 자주 찾는 질문이에요.
                </CardName>
              </Card>
              {faqList.map((item, index) => {
                return (
                  <FoldedCard
                    key={`FoldedCard_${index}`}
                    index={index}
                    isOpen={item.isOpen}
                    title={item.subject}
                    contents={item.contents}
                    onClickDetailBtn={onClickDetailBtn}
                    style={{ padding: '8px 0' }}
                    blackArrow={true}
                    backgroundColor={true}
                  />
                );
              })}
            </DataFnqWrap>
          )}
        </div>
      )}

      {/* 판매몰 선택 모달 */}
      <NoBtnModal
        title="판매몰을 선택해주세요"
        showModal={!!showSelectMallModal}
        onClose={() => {
          setShowSelectMallModal(false);
        }}
      >
        <ScrollArea>
          <Contents textAlign="left">
            {mallListGroup?.map(mallListData => {
              return (
                <MallListSection
                  key={mallListData.name}
                  mallListData={mallListData}
                  onChangeSelectProvider={onChangeSelectProvider}
                />
              );
            })}
          </Contents>
        </ScrollArea>
      </NoBtnModal>
    </div>
  );
};

export default DataInterlockLinkInfo;
