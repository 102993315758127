import React from 'react';
import { createMarkup } from 'tools/WebTool';
import { formatStringDate } from 'tools/DateTool';
import {
  CardColumn,
  CardContent,
  CardContentDDay,
  CardContentDuration,
  CardContentText,
  CardName,
  CardWrapper,
  DetailButton,
} from './style';

const Card = ({
  isEventOpen = true,
  index = 0,
  seq = 0,
  title = '',
  contents = '',
  beginDt = '',
  endDt = '',
  dDay = 0,
  onClickDetailBtn = () => {},
}) => {
  return (
    <CardWrapper>
      <CardColumn>
        <CardName className="cardName">
          <span dangerouslySetInnerHTML={createMarkup(title)} />
        </CardName>
        <CardContent>
          <CardContentText>{contents}</CardContentText>
          <CardContentDuration>
            {formatStringDate(beginDt, 'dot')} ~{' '}
            {formatStringDate(endDt, 'dot')}
          </CardContentDuration>
          <CardContentDDay>
            {isEventOpen ? (dDay > 0 ? `D-${dDay}` : 'D-DAY') : '종료'}
          </CardContentDDay>
        </CardContent>

        {isEventOpen && (
          <DetailButton
            onClick={() => onClickDetailBtn(seq)}
            aria-label="이벤트 상세로 이동"
          />
        )}
      </CardColumn>
    </CardWrapper>
  );
};

export default Card;
