import styled from 'styled-components';
import theme from 'styles/theme';

// 메인섹션 스타일
export const MainSectionOne = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 520px;
  margin-top: 60px;
`;

export const MainTextWrap = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 6;
  width: 100%;
  height: 520px;
`;

// text - left padding: 35px
export const TextOne = styled.div`
  padding: 200px 35px 15px;
  color: ${theme.color.white};
  text-align: left;
  font-size: 3rem;
  font-weight: 500;
  line-height: 2.8rem;
  text-shadow: 0 1px 4px ${theme.color.textShadow};
  min-height: 3.3rem;
`;

// text - left padding: 35px
export const TextTwo = styled.div`
  color: ${theme.color.white};
  text-align: left;
  font-size: 3rem;
  padding: 0 35px 25px;
  font-weight: 500;
  line-height: 2.8rem;
  text-shadow: 0 1px 4px ${theme.color.textShadow};
  min-height: 3.3rem;
`;

// text - left padding: 35px
export const TextThree = styled.div`
  color: ${theme.color.white};
  text-align: left;
  font-size: 2rem;
  font-weight: 300;
  padding: 0 35px;
  line-height: 3.2rem;
  text-shadow: 0 1px 4px ${theme.color.textShadow};
  min-height: 3.2rem;
`;

export const PageCounter = styled.span`
  position: absolute;
  bottom: 35px;
  right: 35px;
  z-index: 99;
  font-size: 1.5rem;
  color: white;
  letter-spacing: 3px;
  padding: 7px 13px 6px 15px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
`;

export const SlideContainer = styled.div`
  position: relative;
  width: 100%;
  height: 520px;
  overflow: hidden;
`;

export const SlideVideoContainer = styled.div`
  position: relative;
  background-image: url(${props => props.bgUrl});
  background-size: cover;
  width: 100%;
  height: 520px;
  overflow: hidden;
`;

export const SlideImg = styled.img`
  width: 486px;
  height: 520px;
  object-fit: cover;
  object-position: top left;
`;

export const SlideVideo = styled.video`
  width: 100%;
  height: 520px;
  object-fit: cover;
  object-position: top left;
`;

export const SlideSource = styled.source``;
