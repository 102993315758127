import styled from 'styled-components';
import theme from 'styles/theme';

export const Container = styled.div`
  background-color: ${theme.backgroundColor.black};
  opacity: 0.4;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  width: 100%;
  color: ${theme.color.white};
  font-size: 25px;
  line-height: 38px;
  text-align: left;

  @media screen and (max-width: 375px) {
    font-size: 21px;
  }
`;
