import React, { useEffect } from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import {
  ModalWrapper,
  ModalHeader,
  Title,
  ModalClose,
  SideArea,
  ModalBtnWrap,
  ModalOneBtn,
  Wrapper,
} from '../style';

const OneBtnModal = ({
  title,
  showModal,
  onClose,
  onClick,
  btnContent,
  children,
  border,
  disabled,
}) => {
  useEffect(() => {
    document.body.style.overflowY = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  return (
    showModal && (
      <Wrapper>
        <ModalWrapper border={!!border}>
          <ModalHeader>
            <Title>{title}</Title>
            <ModalClose onClick={onClose}>
              <FeatherIcon icon="x" size={20} strokeWidth={2.2} />
            </ModalClose>
          </ModalHeader>
          {children}
          <ModalBtnWrap>
            <ModalOneBtn
              onClick={onClick ? onClick : onClose}
              disabled={disabled}
            >
              {btnContent}
            </ModalOneBtn>
          </ModalBtnWrap>
        </ModalWrapper>
        <SideArea onClick={onClose} />
      </Wrapper>
    )
  );
};

export default OneBtnModal;
