import styled from 'styled-components';

export const PolicyDetailContent = styled.div`
  margin: 20px 0px;
  line-height: 2rem;
  table {
    margin-bottom: 10px;
  }
  th {
    border: 1px solid black;
    padding: 5px;
  }
  td {
    border: 1px solid black;
    padding: 5px;
  }
  img {
    max-width: 100%;
  }
  em {
    font-style: italic;
  }
  ul {
    margin-left: 7px;
  }
`;

export const PolicyDiv = styled.div`
  margin: 20px 0px;
  font-size: 13px;
  line-height: 23px;
  text-align: left;
  font-weight: 700;
`;

export const PolicyTitle = styled.div`
  margin: ${props =>
    props.showBanner ? '0px 0px 40px 0px' : '40px 0px 40px 0px'};
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  font-weight: 700;
`;

export const PolicyBottom = styled.div`
  margin-top: 40px;
`;
