import styled from 'styled-components';
import theme from 'styles/theme';

export const TextDiv = styled.div`
  display: block;
  width: 100%;
  padding: 41px 0 18px;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: ${theme.color.black};
`;
