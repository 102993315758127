import {
  DataKey,
  DataValue,
  DataValueText,
} from 'pages/MyPage/Manager/EmployeeItem/style';
import React from 'react';
import DataLinkageStatusMessageBox from '../DataLinkageStatusMessageBox';
import { LINKAGE_STATUS_COLOR } from '../constants';

const DataLinkageStatusBox = ({
  essentialMallList = [],
  dataProvideAgreeSeq = 0,
  deepLinkHash = '',
}) => {
  return (
    essentialMallList.length > 0 && (
      <div>
        <DataKey>필수 연동 판매몰</DataKey>
        <DataValue>
          <DataValueText>
            {essentialMallList.map((item, DataLinkageStatusBox) => {
              const isLast =
                DataLinkageStatusBox + 1 === essentialMallList?.length;
              return (
                <span key={DataLinkageStatusBox}>
                  <span>{item.mallName}</span>
                  <span>{` (`}</span>
                  <span
                    style={{
                      color: LINKAGE_STATUS_COLOR[item.mallInterlockStatus],
                    }}
                  >
                    {item.mallInterlockStatusNm}
                  </span>
                  <span>{`)`}</span>
                  {!isLast && <span>{`, `}</span>}
                </span>
              );
            })}
          </DataValueText>
        </DataValue>
        <DataLinkageStatusMessageBox
          essentialMallList={essentialMallList}
          dataProvideAgreeSeq={dataProvideAgreeSeq}
          deepLinkHash={deepLinkHash}
        />
      </div>
    )
  );
};

export default DataLinkageStatusBox;
