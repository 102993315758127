import React, { useEffect } from 'react';
import ROUTE_PATH from 'routes/config';
import CheckableTypeStipulation from 'components/CheckableTypeStipulation';
import TextTypeStipulation from 'components/TextTypeStipulation';

const DataInterlockStipulation = ({
  stipulationList = [],
  setPostData,
  setStipulationList,
}) => {
  const getStipulationSeq = list => {
    setPostData(prevState => ({
      ...prevState,
      agreeStipulationList: list?.map(item => {
        return {
          dataProviderMallStipulationMappingSeq:
            item?.dataProviderMallStipulationMappingSeq,
          stipulationSubject: item?.stipulationCdName,
          stipulationContents: item?.stipulationContents,
          agreeYn: item?.checked ? 'Y' : 'N',
          required: item?.required,
        };
      }),
    }));
  };

  //postData 심어주기
  useEffect(() => {
    if (stipulationList?.length > 0) {
      getStipulationSeq(stipulationList);
    }
  }, [stipulationList]);

  //약관 전체보기
  const onClickViewBtn = dataProviderMallStipulationMappingSeq => {
    if (!dataProviderMallStipulationMappingSeq) {
      return;
    }
    window._stipulationData = stipulationList?.find(
      item =>
        item.dataProviderMallStipulationMappingSeq ===
        dataProviderMallStipulationMappingSeq
    );
    window.open(
      `${ROUTE_PATH.policyDefault}/${dataProviderMallStipulationMappingSeq}`,
      '_blank'
    );
  };

  const isCheckableTypeStipulation =
    stipulationList[0]?.stipulationTypeCd === 'STC100';

  return (
    <div>
      {isCheckableTypeStipulation ? (
        <CheckableTypeStipulation
          stipulationList={stipulationList}
          setStipulationList={setStipulationList}
          onClickViewBtn={onClickViewBtn}
          stipulationSeq="dataProviderMallStipulationMappingSeq"
        />
      ) : (
        <TextTypeStipulation
          stipulationList={stipulationList}
          setStipulationList={setStipulationList}
        />
      )}
    </div>
  );
};

export default DataInterlockStipulation;
