import React from 'react';
import qs from 'qs';
import ROUTE_PATH from 'routes/config';
import {
  CardColumn,
  CardContent,
  CardName,
  CardNameStatus,
  CardWrapper,
  DetailButton,
} from './style';

const Card = ({ item = {}, showInWaitMallModal = false, index }) => {
  const {
    mallName,
    mallSeq,
    status,
    dataProviderName,
    dataProviderSeq,
    mallAccountId,
    settlementScheduledAmt,
  } = item;

  const onClickDetailBtn = () => {
    if (!!mallSeq && !!dataProviderSeq && !!mallAccountId && !!mallName) {
      const params = { mallSeq, mallName, dataProviderSeq, mallAccountId };
      window.open(
        `${ROUTE_PATH.mypage.estimatedAmountDetail}?${qs.stringify(params)}`,
        '_blank'
      );
    }
  };

  return (
    <CardWrapper index={index}>
      <CardColumn>
        <CardName>
          <div>{mallName}</div>
          {showInWaitMallModal ? null : settlementScheduledAmt ? (
            <DetailButton
              onClick={() => onClickDetailBtn()}
              aria-label="상세로 이동"
            >
              {settlementScheduledAmt?.toLocaleString()}원
            </DetailButton>
          ) : (
            <CardNameStatus>{status}</CardNameStatus>
          )}
        </CardName>
        <CardContent>{mallAccountId}</CardContent>
        <CardContent>{dataProviderName}</CardContent>
      </CardColumn>
    </CardWrapper>
  );
};

export default Card;
