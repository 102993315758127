import React from 'react';
import { BackgroundImg } from './style';

const Background = () => {
  return (
    <BackgroundImg
      src="/images/pc-bg.png"
      alt="Seller CreditConnerct - 상품소싱 및 사입, 상품마케팅 데이터, 주문 및 배송 데이터, 재고 및 물류 데이터, 매출 및 정산예정금 데이터, 회계 및 재무 데이터"
    />
  );
};

export default Background;
