import styled from 'styled-components';
import theme from 'styles/theme';

export const CheckCard = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding: 12px 0 18px;
  border-bottom: 1px solid ${theme.borderColor.lightGrey};

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  @media screen and (max-width: 375px) {
    padding: 20px 0 18px;
  }
`;

export const CheckCardDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px 0 0 0;
  width: 100%;

  @media screen and (max-width: 375px) {
    padding: 0;
  }
`;

export const CheckCardTitleText = styled.div`
  padding-left: 29px;
  line-height: 2.2rem;
  font-size: 1.4rem;
  font-weight: 400;
`;
export const CheckCardSpan = styled.span`
  @media screen and (max-width: 320px) {
    font-size: 1.4rem;
  }
`;

export const CheckCardText = styled.div`
  padding-left: 29px;
  line-height: 2rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.grey4};
`;

export const CheckCardBlueText = styled.div`
  margin-top: 10px;
  padding: 0px 10px;
  line-height: 1.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.blue};
`;

export const RadioInput = styled.input`
  position: absolute;
  margin: 4px 0 0;
  padding: 0;
  line-height: normal;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &:focus + .notice-check-icon {
    outline: 2px solid ${theme.color.label};
    outline-offset: -2px;
  }

  &:not(:checked) + .notice-check-icon {
    background: url(/images/icons/checkbox/radio-off2.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }

  &:checked + .notice-check-icon {
    background: url(/images/icons/checkbox/radio-a-on.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }
`;

export const IconSpan = styled.span`
  position: absolute;
  top: 7px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: url(/images/icons/checkbox/radio-off2.png) no-repeat;
  background-size: 20px 20px;
  content: '';
`;
