import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApp from 'hooks/useApp';
import ROUTE_PATH from 'routes/config';
import { getDataRequest, getListDataRequest } from 'utils/request';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import Tab from 'components/Tab';
import DataLinkageStatusBox from './DataLinkageStatusBox';
import LinkedDataProvider from './LinkedDataProvider';
import LinkedMall from './LinkedMall';
import {
  TAB_OPTIONS,
  DATA_INTERLOCK_INDEX,
  DATA_PROVIDER_INDEX,
  DATA_INTERLOCK_NOTICE_MESSAGE,
  DATA_PROVIDER_NOTICE_MESSAGE,
  LINKAGE_STATUS_COLOR,
} from './constants';
import { ContentBox } from '../style';
import {
  StyledMain,
  NoticeSection,
  TitleBox,
  LinkedCount,
  MessageDiv,
} from './style';

const DataLinkageManage = () => {
  const navigate = useNavigate();
  const { auth } = useApp();
  const [businessName, setBusinessName] = useState('');
  const [financeInstitutionName, setFinanceInstitutionName] = useState('');
  const [creditGoodsMasterEventName, setCreditGoodsMaseterEventName] =
    useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [tabValue, setTabValue] = useState('');
  const [dataInterlockSeqList, setDataInterlockSeqList] = useState([]);
  const [dataInterlockMallList, setDataInterlockMallList] = useState([]);
  const [dataProviderList, setDataProviderList] = useState([]);
  const [essentialMallList, setEssentialMallList] = useState([]);
  const [dataProvideAgreeSeq, setDataProvideAgreeSeq] = useState(0);
  const [deepLinkHash, setDeepLinkHash] = useState('');
  const dataProviders = {
    dataInterlock: dataInterlockMallList,
    dataProvider: dataProviderList,
  };

  useEffect(() => {
    const { businessName } = auth;
    !!businessName && setBusinessName(businessName);
  }, [auth]);

  useEffect(() => {
    setTabValue(TAB_OPTIONS[tabIndex].value);

    if (tabIndex === DATA_INTERLOCK_INDEX) {
      getListDataRequest({
        url: `/v1/au/data-provider/list/individual`,
        setData: setDataInterlockSeqList,
      });
    }
    if (tabIndex === DATA_PROVIDER_INDEX) {
      getListDataRequest({
        url: `/v1/au/data-provider`,
        setData: setDataProviderList,
      });
    }
  }, [tabIndex]);

  useEffect(() => {
    const seq =
      dataInterlockSeqList && dataInterlockSeqList[0]?.dataProviderSeq;
    if (!!seq) {
      const successFnc = () => {
        const getEssentialMallSuccessFnc = data => {
          setEssentialMallList(data?.result?.essentialMallList || []);
          setFinanceInstitutionName(data?.result?.financeInstitutionName || '');
          setCreditGoodsMaseterEventName(
            data?.result?.creditGoodsMasterEventName || ''
          );
          setDataProvideAgreeSeq(data?.result?.dataProvideAgreeSeq || 0);
          setDeepLinkHash(data?.result?.deepLinkHash || '');
        };
        getDataRequest({
          url: `/v1/au/data-provider/essential/mall/list`,
          successFnc: getEssentialMallSuccessFnc,
        });
      };
      getListDataRequest({
        url: `/v1/au/data-provider/individual`,
        setData: setDataInterlockMallList,
        params: { dataProviderSeq: seq },
        successFnc,
      });
    }
  }, [dataInterlockSeqList]);

  const onClickLinkData = () => {
    if (tabIndex === DATA_INTERLOCK_INDEX) {
      goToDataLinkageMallPage();
    } else if (tabIndex === DATA_PROVIDER_INDEX) {
      goToDataLinkageDataProviderPage();
    }
  };

  const goToDataLinkageMallPage = () => {
    navigate(ROUTE_PATH.mypage.dataLinkageDataInterlockRequest);
  };

  const goToDataLinkageDataProviderPage = () => {
    navigate(ROUTE_PATH.mypage.dataLinkageDataProviderRequest);
  };

  // additionalData: 특정 케이스에 따라 필요한 데이터
  const tabContentComponents = [
    <LinkedMall
      key={`LinkedMall_${DATA_INTERLOCK_INDEX}`}
      type={'dataInterlock'}
      data={dataProviders['dataInterlock']}
      additionalData={{
        dataProviderSeq:
          dataInterlockSeqList && dataInterlockSeqList[0]?.dataProviderSeq,
      }}
      tabValue={tabValue}
    />,
    <LinkedDataProvider
      key={`LinkedDataProvider_${DATA_PROVIDER_INDEX}`}
      type={'dataProvider'}
      data={dataProviders['dataProvider']}
      tabValue={tabValue}
    />,
  ];

  const setNoticeMessage = () => {
    if (
      essentialMallList.length > 0 &&
      financeInstitutionName &&
      creditGoodsMasterEventName
    ) {
      return `${financeInstitutionName}의 ${creditGoodsMasterEventName} 대출을 진행 중입니다.\n필수 연동 판매몰을 확인해 주세요.`;
    } else {
      if (tabIndex === DATA_INTERLOCK_INDEX) {
        return DATA_INTERLOCK_NOTICE_MESSAGE;
      } else {
        return DATA_PROVIDER_NOTICE_MESSAGE;
      }
    }
  };

  return (
    <StyledMain>
      {/* 연동 안내 및 연동 버튼 */}
      <NoticeSection>
        <TitleBox style={{ fontSize: '1.8rem', lineHeight: '2.4rem' }}>
          연동 {tabIndex === DATA_INTERLOCK_INDEX ? '판매몰' : '데이터 제공사'}{' '}
          <LinkedCount>
            {tabIndex === DATA_INTERLOCK_INDEX
              ? dataInterlockMallList.length
              : dataProviderList.length}
          </LinkedCount>
          건
        </TitleBox>
        <MessageDiv>{setNoticeMessage()}</MessageDiv>
        <DataLinkageStatusBox
          essentialMallList={essentialMallList}
          dataProvideAgreeSeq={dataProvideAgreeSeq}
          deepLinkHash={deepLinkHash}
        />
        <StyledActiveButton onClick={onClickLinkData}>
          데이터 연동
        </StyledActiveButton>
      </NoticeSection>
      {/* 데이터 연동 리스트 (탭) */}
      <ContentBox>
        <Tab
          options={TAB_OPTIONS}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          contentComponents={tabContentComponents}
        />
      </ContentBox>
    </StyledMain>
  );
};

export default DataLinkageManage;
