import styled from 'styled-components';
import theme from 'styles/theme';

export const MainSectionTwo = styled.div`
  position: relative;
  width: 100%;
  padding: 60px 0px 0px 0px;

  @media screen and (max-width: 485.9px) {
    padding: 60px 0px 0px 0px;
  }

  @media screen and (max-width: 375px) {
    padding: 60px 0px 0px 0px;
  }
`;

export const LoanDetailBox = styled.div`
  padding: 40px;
`;

export const TextBox = styled.div`
  padding: 5px 0 0px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
`;

export const TextMedium = styled.div`
  padding: 11px 0 2px;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 2.4rem;
  color: ${theme.color.black};
`;

export const TextContent = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.color.blue};
  font-size: 2.2rem;
  font-weight: 400;
`;

export const Status = styled.span`
  height: 22px;
  padding: 0 7px;
  margin-left: 10px;
  border-radius: 5px;

  font-size: 1.2rem;
  font-weight: 400;

  background: ${theme.backgroundColor.checkBlue};
  color: ${theme.color.blue};
`;

export const ImgDiv = styled.div`
  float: left;
  width: 40px;
  height: 40px;
  line-height: 0;
`;

export const Img = styled.img`
  width: 40px;
  height: 40px;
`;

export const LoanName = styled.div`
  padding-left: 50px;
  width: 100%;
  height: 18px;
  line-height: 1.5rem;
  font-size: 1.3rem;
  font-weight: 400;
  color: ${theme.color.grey7};
`;

export const BankName = styled.div`
  padding-left: 50px;
  width: 100%;
  line-height: 2.5rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${theme.color.black};
  word-wrap: break-word;
  word-break: break-all;

  @media screen and (max-width: 375px) {
    font-size: 1.7rem;
  }
`;
