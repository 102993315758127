import styled from 'styled-components';
import theme from 'styles/theme';

export const TextSpan = styled.span`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${theme.color.blue};
`;

export const TextSpanLight = styled.span`
  font-size: 1.5rem;
  font-weight: 400;
  color: ${theme.color.blue};
`;

export const NameBox = styled.span`
  font-size: 1.8rem;
  font-weight: 500;
  color: ${theme.color.blue};
`;

export const NameWrap = styled.div`
  text-align: center;
  line-height: 3.4rem;
`;

export const MyPageInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.8rem;
  text-align: center;
  margin-top: 30px;
  color: ${theme.color.black};
`;

export const MyInfoBox = styled.div`
  width: 74px;
  height: 34px;
  line-height: 3.4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: ${theme.color.black};
  background-color: ${theme.color.grey11};
  border-radius: 17px;
  cursor: pointer;
`;

export const UserBanner = styled.button`
  background-color: ${theme.color.blue};
  color: ${theme.color.white};
  width: 100%;
`;

export const NoticeBannerMsg = styled.div`
  color: ${theme.color.white};
  line-height: 1.4rem;
`;
