import ROUTE_PATH from 'routes/config';
const {
  serviceView,
  event,
  eventDetailDefault,
  serviceCenter,
  policyDefault,
  login,
  mypage,
  deepLink,
  financialSupervisoryService,
  affiliateFinance,
} = ROUTE_PATH;

export const PAGE_TITLE = {
  [serviceView]: '서비스소개',
  [event]: '이벤트',
  [eventDetailDefault]: '이벤트 상세',
  [login.joinMemberPolicyDefault]: '약관 및 정책',
  [login.login]: '로그인',
  [login.searchId]: '아이디 찾기',
  [login.searchIdIdentification]: '아이디 찾기',
  [login.searchIdResultFail]: '아이디 찾기',
  [login.searchIdResultSuccess]: '아이디 찾기',
  [login.searchPassword]: '비밀번호 찾기',
  [login.searchPasswordIdentification]: '비밀번호 찾기',
  [login.searchPasswordResultFail]: '비밀번호 찾기',
  [login.searchPasswordResetPassword]: '비밀번호 찾기',
  [login.joinMember]: '회원가입',
  [login.joinMemberCheckBusinum]: '회원가입',
  [login.joinMemberInput]: '회원가입',
  [deepLink.joinMember]: '회원가입',
  [mypage.noAuth]: '마이페이지',
  [mypage.main]: '마이페이지',
  [mypage.myInform]: '내 정보',
  [mypage.updateBusiness]: '사업자정보 변경',
  [mypage.updatePassword]: '비밀번호 변경',
  [mypage.updateEmail]: '이메일 변경',
  [mypage.withdrawal]: '회원 탈퇴',
  [mypage.manager]: '직원 관리',
  [mypage.managerRegister]: '직원 등록 / 수정',
  [mypage.managerModify]: '직원 등록 / 수정',
  [mypage.checkLoan]: '대출 비교하기',
  [mypage.checkLoanList]: '담보 대출 비교',
  [mypage.checkCreditLoanList]: '신용 대출 비교',
  [mypage.checkLoanDetail]: '담보 대출 비교',
  [mypage.checkCreditLoanDetail]: '신용 대출 비교',
  [mypage.requestLoan]: '담보 대출 신청',
  [mypage.requestCreditLoan]: '신용 대출 신청',
  [mypage.manageLoanDataProvide]: '대출/데이터 제공 관리',
  [mypage.dataLinkageManage]: '데이터 연동 관리',
  [mypage.dataLinkageDataInterlockRequest]: '데이터 연동',
  [mypage.dataLinkageDataProviderRequest]: '데이터 연동',
  [mypage.dataLinkageModify]: '데이터 연동 정보 수정',
  [mypage.dataLinkageGuide]: '판매몰 연동 등록 가이드',
  [mypage.estimatedAmountManage]: '정산예정금 관리',
  [mypage.estimatedAmountDetail]: '정산예정금 상세',
  [serviceCenter.main]: '고객 센터',
  [serviceCenter.noAuth.create]: '고객 센터',
  [serviceCenter.auth.create]: '고객 센터',
  [financialSupervisoryService]: '금융소비자보호',
  [affiliateFinance]: '제휴 금융기관',
};

export const PAGE_TITLE_PREFIX = {
  [policyDefault]: '약관 및 정책',
  [serviceCenter.noticeDetailDefault]: '공지사항',
  [serviceCenter.faqDetailDefault]: '자주 묻는 질문',
  [eventDetailDefault]: '이벤트 상세',
};

export const LOGO_PATH = ['/', serviceView, login.joinMemberResult];
export const MYPAGE_PATH = [mypage.noAuth, mypage.main];
export const PAGE_LEAVE_PATH = [
  login.joinMember,
  mypage.updateBusiness,
  // mypage.dataLinkageRequest, // TODO: 원래는 데이터 연동 등록 페이지에서 뒤로 가기 클릭시 메세지 떴는데, 기획 변경됐는지 질문하기
];
export const POPUP_PATH_PREFIX = [
  '/popup/',
  mypage.dataLinkageGuide,
  mypage.estimatedAmountDetail,
];
export const DEEPLINK_PATH_PREFIX = [
  '/data-provide-agree',
  deepLink.serviceGuide,
];
