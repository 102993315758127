import styled from 'styled-components';
import theme from 'styles/theme';

export const JoinPolicyWrapper = styled.div`
  display: block;
  padding: ${props => (props.showBanner ? '0px' : '30px 0')};
  width: 100%;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  color: ${theme.color.grey3};
  line-height: 21px;
`;

export const TabSection = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  color: ${theme.color.grey3};
  line-height: 21px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const TabButton = styled.button`
  float: left;
  width: calc(50% - 3px);
  height: 55px;
  margin: 0 0 6px 6px;
  line-height: 53px;
  font-size: 13px;
  border-radius: 6px;
  border: 1px solid ${theme.borderColor.lightGrey};
  background: ${theme.backgroundColor.white};
  text-align: center;
  color: ${props =>
    props.active ? `${theme.color.blue}` : `${theme.color.grey4}`};
  border: ${props =>
    props.active
      ? `1px solid ${theme.borderColor.blue};`
      : `1px solid ${theme.borderColor.lightGrey};`};
  font-weight: ${props => (props.active ? 700 : 500)};

  &:nth-child(2n - 1) {
    margin: 0 0 6px 0;
  }
`;

export const TextMedium = styled.div`
  padding: 0px 0 18px;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
  text-align: center;
`;

export const ContentBox = styled.div`
  width: 100%;
  padding: 13px 19px;
  background: ${theme.backgroundColor.contentBox};
  border-radius: 10px;
  margin: 23px 0 0;
`;

export const ContentTitle = styled.div`
  margin: 19px 0 0;
  width: 100%;
  font-size: 1.3rem;
  line-height: 2.1rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.navyBlue};

  &:first-child {
    margin: 0;
  }
`;

export const ContentData = styled.div`
  width: 100%;
  margin: 3px 0 0;
`;

export const ContentText = styled.div`
  width: 100%;
  margin: 3px 0 0;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  line-height: 21px;
  color: ${theme.color.black};
`;
