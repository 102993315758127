import React from 'react';
import { AlertIcon, AlertText, AlertTextWrapper } from './style';

const GuideBox = ({ guideMessage = '' }) => {
  return (
    <AlertTextWrapper>
      <AlertIcon icon="alert-circle" stroke="#555" />
      <AlertText>{guideMessage}</AlertText>
    </AlertTextWrapper>
  );
};

export default GuideBox;
