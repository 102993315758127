import styled from 'styled-components';
import theme from 'styles/theme';

export const Contents = styled.div`
  margin-top: 20px;
  width: 100%;
  line-height: 2.5rem;
  font-size: 1.5rem;

  li {
    margin-left: 19px;
  }

  a {
    text-decoration: underline;
  }

  img {
    max-width: 320px;

    @media screen and (max-width: 320px) {
      max-width: 100%;
    }
  }

  p {
    line-height: 1.8rem;
  }
`;

export const NoBtnModalContent = styled.p`
  height: 50px;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0 5px 10px;
`;
