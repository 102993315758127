import styled from 'styled-components';
import theme from 'styles/theme';

export const ModalSectionTitle = styled.div`
  margin: 20px 0px;
  margin-bottom: 10px;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100px;
  background: ${theme.borderColor.contentBox};
  margin: 5px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
`;

export const ContentImg = styled.img`
  width: 51px;
  max-height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentImgNull = styled.div`
  margin: 20px 10px 0px 10px;
  width: 51px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  white-space: nowrap;
  color: ${theme.color.white};

  @media screen and (max-width: 485px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 375px) {
    font-size: 1rem;
  }
`;

export const ContentText = styled.div`
  width: 100%;
  height: 40%;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;

  @media screen and (max-width: 485px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 375px) {
    font-size: 1rem;
  }
`;
