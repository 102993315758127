import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from 'utils/api';
import { getBusinessNameRequest, getDataRequest } from 'utils/request';
import {
  getSession,
  setCookie,
  getMemberCertificationSeq,
  getLoginTrial,
  KEY_REFRESH,
  KEY_SESSION,
  KEY_MEMBERCERTIFICATIONSEQ,
  KEY_EXPIRETIME,
  KEY_DEEPLINKHASH,
  KEY_ACCESSID,
  KEY_ACCESS_URL,
  KEY_DATAPROVIDETYPE,
  KEY_DATAPROVIDEAGREESEQ,
  KEY_DEEPLINK_TYPE,
  KEY_INTERLOCK_HASH,
  KEY_INFLOW_CHANNEL,
  KEY_LOGIN_TRIAL,
  KEY_MEMBER_LOGIN_FAILURE_DT,
  getLoginFailureDt,
  KEY_GOODSHASH,
  KEY_ESSENTIAL_MALLS,
} from 'tools/WebTool';

const forceAuthenticate = process.env.REACT_APP_FORCE_AUTHENTICATE === 'true';
export const LOGIN_TIME_LIMIT = 300;

const useAuth = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [user, setUser] = useState();
  const [memberName, setMemberName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessRegistrationNumber, setBusinessRegistrationNumber] =
    useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [bannerMessage, setBannerMessage] = useState('');
  const [bannerData, setBannerData] = useState({});
  const [memberRoleData, setMemberRoleData] = useState({});
  const [memberTotalData, setMemberTotalData] = useState({});
  const session = getSession();

  // onMount
  useEffect(() => {
    if (forceAuthenticate) {
      localStorage.setItem(KEY_SESSION, '----- session string -----');
      setUser({
        memberId: 'admin',
        memberSeq: 10001,
        memberTypeCd: 'MBT110',
        roles: ['ROLE_ADMIN'],
      });
      setIsLoading(false);
    } else {
      getSession() ? fetchAuth() : setIsLoading(false);
    }
  }, []);

  // user data 받아오는 API
  const fetchAuth = async redirectTo => {
    try {
      const { data } = await api({
        url: '/v1/au/users/me',
        method: 'get',
        headers: { 'content-type': 'application/json' },
      });
      data && setUser(data.data);
      getDataRequest({
        url: '/v1/au/common/member-role',
        setData: setMemberRoleData,
      });
      getBusinessNameRequest({
        memberSeq: data.data.memberSeq,
        setMemberName,
        setBusinessName,
        setBusinessRegistrationNumber,
        setMemberTotalData,
      });
      !!redirectTo && navigate(redirectTo, { replace: true });
      setIsLoading(false);
    } catch (err) {
      logout();
      setIsLoading(false);
    }
  };

  // 페이지 별 권한 체크 API
  const fetchAuthorization = async () => {
    try {
      const { data } = await api({
        url: '/v1/au/sfc/page',
        method: 'get',
        headers: { 'content-type': 'application/json' },
      });
      if (data.success) {
        return data.data.pageElementList;
      }
      if (!data.success) {
        alert(`${data.message}`);
        window.location.replace('/');
      }
    } catch (err) {
      logout();
    }
  };

  const deepLinkValidate = () => {
    api({
      url: 'v1/au/common/notice-banner',
      method: 'get',
      headers: { 'content-type': 'application/json' },
    })
      .then(res => {
        const { data, message } = res.data;
        setBannerData(data);
        setBannerMessage(message);
      })
      .catch(err => {
        console.error(err.message);
      });
  };

  useEffect(() => {
    // getSession() 대신에 !!user를 사용하고 있었는데 둘다 '로그인된 유저'를 판별하는 값이지만,
    // getSession()은 로컬스토리지의 session 값이고
    // !!user는 state 값이라 한스텝 늦게 발동된다.
    // 따라서 로그아웃을 했는데도 !!user가 true로 잡힐 수 있어 더 정확한 session값을 사용함
    if (!!session) {
      deepLinkValidate();
    } else {
      setBannerData({});
      setBannerMessage('');
    }
  }, [pathname, session]);

  const errorCallback = (status, message, data, callbackFnc) => {
    const defaultCount = '0';
    const loginTrial = getLoginTrial() || defaultCount;

    if (Number(loginTrial) === 0) {
      // 최초 1회 부터 60초 이내 10회 연속 로그인 시도할때만 제어하기 때문에, 10회 되기 전에 60초가 지나면 세션스토리지 초기화
      // 이미 5분 카운팅이 되고 있을 때는 초기화 해주지 않는다. (이 케이스는 5분이 지나야 초기화)
      setTimeout(() => {
        if (!getLoginFailureDt()) {
          sessionStorage.removeItem(KEY_LOGIN_TRIAL);
        }
      }, 60000);
    }

    if (Number(loginTrial) === 9) {
      // 10번째 시도때 현재시각 세션스토리지에 저장
      if (!getLoginFailureDt()) {
        sessionStorage.setItem(
          KEY_MEMBER_LOGIN_FAILURE_DT,
          new Date().toString()
        );
      }
      const loginFailureDt = getLoginFailureDt(); // 세션스토리지 값 (이미 저장 되어있거나 방금 만들어준)
      // endTime(종료시각)은 loginFailureDt 으로부터 5분뒤
      const endTime =
        new Date(loginFailureDt).getTime() + LOGIN_TIME_LIMIT * 1000;
      // 종료시각까지 현재기준 남은 시간 초로 환산
      const remainingTime = Math.round((endTime - new Date().getTime()) / 1000);

      callbackFnc({
        status,
        message: `아이디 또는 비밀번호 입력이 10회 이상 틀려 계정이 보호(잠금) 처리되었습니다.\n안전한 서비스 이용을 위해 5분 후 로그인을 다시 시도할 수 있습니다.\n잠시 기다려 주시기 바랍니다.`,
        data: {
          remainingTime: remainingTime,
        },
      });
      return;
    }

    callbackFnc({
      status,
      message,
      data,
    });

    return sessionStorage.setItem(
      KEY_LOGIN_TRIAL,
      (Number(loginTrial) + 1).toString()
    );
  };

  const login = ({
    params,
    redirectTo,
    onAlertModal,
    isCheckedSaveId,
    successCallback,
    setIsLoading,
  }) => {
    !!setIsLoading && setIsLoading(true);
    const request = async () => {
      try {
        const { headers } = await api({
          url: '/v1/na/auth/login',
          method: 'post',
          data: params,
          crossDomain: true,
          withCredentials: true,
        });
        const { authorization: session, 'authorization-refresh': refresh } =
          headers;
        if (!!session && !!refresh) {
          session && localStorage.setItem(KEY_SESSION, session);
          refresh && localStorage.setItem(KEY_REFRESH, refresh);
          session && successCallback && successCallback();
          session && fetchAuth(successCallback ? undefined : redirectTo || '/');
          if (isCheckedSaveId) {
            setCookie('userId', params.memberId, 30);
          }
          sessionStorage.removeItem(KEY_LOGIN_TRIAL);
          sessionStorage.removeItem(KEY_MEMBER_LOGIN_FAILURE_DT);
        }
        !!setIsLoading && setIsLoading(false);
      } catch (err) {
        console.error(err);
        const { status, data } = err.response;
        errorCallback(status, data.message, data.data, onAlertModal);
        !!setIsLoading && setIsLoading(false);
      }
    };

    request();
  };

  const logout = ({ moveToHome = true } = { moveToHome: true }) => {
    localStorage.removeItem(KEY_SESSION);
    localStorage.removeItem(KEY_REFRESH);
    localStorage.removeItem(KEY_MEMBERCERTIFICATIONSEQ);
    localStorage.removeItem(KEY_EXPIRETIME);
    localStorage.removeItem(KEY_DATAPROVIDETYPE);
    localStorage.removeItem(KEY_DEEPLINKHASH);
    localStorage.removeItem(KEY_ACCESSID);
    localStorage.removeItem(KEY_GOODSHASH);
    localStorage.removeItem(KEY_ACCESS_URL);
    localStorage.removeItem(KEY_DATAPROVIDEAGREESEQ);
    localStorage.removeItem(KEY_DEEPLINK_TYPE);
    localStorage.removeItem(KEY_INTERLOCK_HASH);
    localStorage.removeItem(KEY_INFLOW_CHANNEL);
    localStorage.removeItem(KEY_ESSENTIAL_MALLS);
    localStorage.removeItem(KEY_ACCESS_URL);
    sessionStorage.removeItem(KEY_LOGIN_TRIAL);
    sessionStorage.removeItem(KEY_MEMBER_LOGIN_FAILURE_DT);
    setBannerMessage('');
    setBannerData({});
    setUser(undefined);
    if (moveToHome) {
      navigate('/');
    }
  };

  const savedMemberCertificationSeq = getMemberCertificationSeq();

  return {
    isLoading,
    user,
    memberName,
    businessName,
    businessRegistrationNumber,
    savedMemberCertificationSeq,
    bannerMessage,
    bannerData,
    memberRoleData,
    memberTotalData,
    authenticated: !!user,
    refresh: fetchAuth,
    fetchAuthorization,
    login,
    logout,
  };
};

export default useAuth;
