import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useApp from 'hooks/useApp';
import api from 'utils/api';
import ROUTE_PATH from 'routes/config';
import { validateEmail } from 'tools/ValidationTool';
import { alertMessages } from 'messages/alertMessages';
import TextField from 'components/TextField';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { NoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import {
  ContentBox,
  ContentTitle,
  ContentData,
  ContentDataText,
} from '../UpdateBusiness/style';

const UpdateEmail = () => {
  const navigate = useNavigate();
  const { auth } = useApp();
  const { mypage } = ROUTE_PATH;
  const [memberEmail, setMemberEmail] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showNoBtnModal, setShowNoBtnModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const {
    user: { memberSeq },
  } = auth;

  const onChange = e => {
    const { value } = e.target;
    value ? setMessage('') : setMessage(message);
    setEmail(value);
  };

  const onBlur = () => {
    email
      ? validateEmail(email)
        ? setMessage('')
        : setMessage(alertMessages.validEmail)
      : setMessage(alertMessages.newEmail);
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      onClickUpdateEmail();
    }
  };

  const onClickUpdateEmail = () => {
    const emailValues = {
      orgMemberEmail: memberEmail,
      newMemberEmail: email,
    };
    api({
      url: '/v1/au/member/email',
      method: 'put',
      headers: { 'content-type': 'application/json' },
      data: emailValues,
    })
      .then(res => {
        const { message } = res.data;
        setModalMessage(message);
        setShowNoBtnModal(true);
        setTimeout(
          () => navigate(mypage.myInform, { state: { step: 2 } }),
          1000
        );
      })
      .catch(err => {
        console.error(err);
        const { data } = err.response;
        setModalMessage(data.message);
        setShowNoBtnModal(true);
      });
  };

  useEffect(() => {
    const getMemberEmail = () => {
      api({
        url: `/v1/au/member/${memberSeq}`,
        method: 'get',
        headers: { 'content-type': 'application/json' },
      })
        .then(res => {
          const { success, data } = res.data;
          if (success) {
            setMemberEmail(data.member?.memberEmail);
          }
        })
        .catch(err => {
          console.error(err);
        });
    };

    getMemberEmail();
  }, [memberSeq]);

  return (
    <>
      <ContentBox>
        <ContentTitle>현재 이메일</ContentTitle>
        <ContentData>
          <ContentDataText blue>{memberEmail}</ContentDataText>
        </ContentData>
      </ContentBox>
      <div>
        <TextField
          type="text"
          id="email"
          title="새 이메일 입력"
          placeholder="새 이메일"
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          showMessage={!!message}
          message={message}
        />

        <StyledActiveButton tabIndex={0} onClick={onClickUpdateEmail}>
          이메일 변경
        </StyledActiveButton>

        <NoBtnModal
          showModal={showNoBtnModal}
          onClose={() => {
            setShowNoBtnModal(false);
          }}
        >
          <ScrollArea>
            <Contents>{modalMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>
      </div>
    </>
  );
};

export default UpdateEmail;
