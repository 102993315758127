import styled from 'styled-components';
import theme from 'styles/theme';

export const Table = styled.table`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const TableHead = styled.thead``;

export const TableHeadRow = styled.tr`
  width: 100%;
`;

export const TableHeadCell = styled.th`
  padding: 11px 2px;
  line-height: 2.3rem;
  font-size: ${props => (props.smallFont ? '1.2rem' : '1.3rem')};
  font-weight: 500;
  text-align: center;
  border-left: 1px solid ${theme.color.white};
  color: ${theme.color.black};
  background: ${theme.backgroundColor.icyBlue};

  &:first-child {
    border-left: none;
  }

  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }
`;

export const TableBody = styled.tbody``;

export const TableDataRow = styled.tr`
  width: 100%;
  margin: 2px 0 0;
  border-top: 1px solid ${theme.borderColor.white};
`;

export const TableDataCell = styled.td`
  padding: 11px 2px;
  line-height: 2.3rem;
  font-size: ${props => (props.smallFont ? '1.2rem' : '1.3rem')};
  font-weight: 500;
  text-align: center;
  vertical-align: top;
  border-left: 1px solid ${theme.color.white};
  color: ${theme.color.black};
  background: ${theme.backgroundColor.table};

  &:first-child {
    border-left: none;
  }

  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }
`;
