import styled from 'styled-components';
import theme from 'styles/theme';

export const StyledTextArea = styled.textarea`
  display: block;
  width: 100%;
  min-height: 80px;

  text-align: left;
  line-height: 40px;
  font-size: ${theme.fontSize.h5};

  border: 1px solid ${theme.borderColor.lightGrey};
  border-radius: 5px;

  resize: none;
`;
