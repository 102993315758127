import React from 'react';
import { modifyDataRequest } from 'utils/request';
import { OneBtnModal } from 'components/Modal';
import { Contents, NoScrollArea } from 'components/Modal/style';
import { TextBox } from 'pages/Event/EventDetailPage/style';
import { CheckCards } from 'components/SelectMallModal/RenderMall/style';
import {
  CheckCard,
  CheckCardDiv,
  CheckCardText,
  CheckCardTitleText,
} from 'components/SelectManager/SelectManagerRow/style';
import { TextSpanLight } from 'pages/MyPage/MyPageMain/style';

const CancelModal = ({
  showCancelModal,
  cancelModalInfo,
  toggleCancelModal,
  successFnc,
  setAlertModal,
  setAlertMessage,
}) => {
  const { managerName, position, department, mobilePhone } = cancelModalInfo;
  const onClickButton = () => {
    const errorFnc = () => {
      toggleCancelModal();
    };
    modifyDataRequest({
      url: `/v1/au/manager/${cancelModalInfo.businessManagerSeq}/member-invite-cancel`,
      successFnc,
      errorFnc,
      serverErrorFnc: errorFnc,
      setModalMessage: setAlertMessage,
      setShowModal: setAlertModal,
    });
  };

  return (
    !!cancelModalInfo && (
      <OneBtnModal
        showModal={showCancelModal}
        onClose={toggleCancelModal}
        onClick={onClickButton}
        btnContent="회원 초대 취소하기"
      >
        <NoScrollArea>
          <Contents fontWeight={400}>
            <TextSpanLight>{managerName}</TextSpanLight>님에게
            <br />
            보낸 회원 초대를 취소하시겠어요?
          </Contents>

          <CheckCards>
            <CheckCard>
              <CheckCardDiv>
                <CheckCardTitleText style={{ paddingLeft: '15px' }}>
                  {managerName}
                </CheckCardTitleText>
                <CheckCardTitleText style={{ paddingRight: '15px' }}>
                  {!!mobilePhone && mobilePhone}
                </CheckCardTitleText>
              </CheckCardDiv>
              <CheckCardText style={{ paddingLeft: '15px' }}>
                {department}
                {department && position && '/'}
                {position}
              </CheckCardText>
            </CheckCard>
          </CheckCards>
          <TextBox style={{ margin: '25px 10px' }}>
            <CheckCardText style={{ padding: '0' }}>
              회원 초대 취소 시 초대된 링크는 즉시 만료된니다. 담당자의 연락처가
              잘못된 경우, 수정 후 회원 초대하기를 진행해 주세요.
            </CheckCardText>
          </TextBox>
        </NoScrollArea>
      </OneBtnModal>
    )
  );
};

export default CancelModal;
