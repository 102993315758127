import React from 'react';
import TextField from 'components/TextField';
import { GreenQuestion } from 'pages/MyPage/DataLinkage/DataLinkageRequest/style';
import {
  CaptchaImage,
  ContentSectionContentWrapper,
  ContentSectionTextFieldAdjustment,
  ContentSectionTitle,
  ContentSectionWrapper,
  TextFiledDescription,
} from '../style';

const MallSecondAuthContent = ({
  isGeneralSecondAuthType = true,
  mallSecondAuthObj = {},
  mallName = '',
  captchaImageUrl = '',
  secondAuthQuestion = '',
  mallSecondAuthValue = '',
  onChangeTextField = () => {},
  isDisabledInput = true,
}) => {
  return (
    <ContentSectionWrapper>
      <ContentSectionTitle>
        {isGeneralSecondAuthType === true ? '2차 인증 정보' : '인증 정보'}
      </ContentSectionTitle>
      <ContentSectionContentWrapper>
        <ContentSectionTextFieldAdjustment>
          <TextField
            name="description"
            value="판매몰 개별 연동"
            readOnly={true}
          />
          <TextField name="mallName" value={mallName} readOnly={true} />

          {mallSecondAuthObj?.requirement.includes('CAPTCHA') && (
            <CaptchaImage src={captchaImageUrl} alt="captchaAuth" />
          )}

          {!!secondAuthQuestion &&
            mallSecondAuthObj.requirement === 'CAPTCHA_QUESTION' && (
              <GreenQuestion>{secondAuthQuestion}</GreenQuestion>
            )}

          {!!mallSecondAuthObj.placeholder && (
            <TextField
              name={mallSecondAuthObj.requirement}
              placeholder={mallSecondAuthObj.placeholder}
              value={mallSecondAuthValue}
              onChange={onChangeTextField}
              readOnly={isDisabledInput}
            />
          )}

          {!!mallSecondAuthObj.textDescription && (
            <TextFiledDescription>
              {mallSecondAuthObj.textDescription}
            </TextFiledDescription>
          )}
        </ContentSectionTextFieldAdjustment>
      </ContentSectionContentWrapper>
    </ContentSectionWrapper>
  );
};

export default MallSecondAuthContent;
