import React, { useEffect, useState } from 'react';
import { getListDataRequest } from 'utils/request';
import ROUTE_PATH from 'routes/config';
import { FINANCE_SUPERVISORY_SERVICE_TAB } from '../constants';
import {
  AFFILIATE_FINANCE_CONTENT,
  REQUEST_DOCUMENT_ACCESS_CONTENT,
} from './constants';
import {
  JoinMemberPolicyPopupDetailContent,
  JoinMemberPolicyPopupDetailLine,
  JoinMemberPolicyPopupDetailWrapper,
  JoinPolicyTitle,
} from 'pages/Login/JoinMemberPolicyPopupPage/JoinMemberPolicyPopupDetail/style';

const FinancialSupervisoryServiceDetail = ({ index = 0 }) => {
  const [announceListData, setAnnounceListData] = useState('');

  const detailContentList = [
    AFFILIATE_FINANCE_CONTENT,
    announceListData,
    REQUEST_DOCUMENT_ACCESS_CONTENT,
  ];

  useEffect(() => {
    const affiliateFinanceButton = document.getElementById('affiliateFinance');
    affiliateFinanceButton?.addEventListener('click', event => {
      window.open(`${ROUTE_PATH.affiliateFinance}`, '_blank');
    });
  }, [index]);

  useEffect(() => {
    getListDataRequest({
      url: '/v1/na/consumer-financial-protection/public-announce',
      successFnc: data => {
        let string = '';
        data.forEach(item => {
          string =
            string +
            `<p class="title">${item.title}</p>` +
            `<p class="paddingTop">${item.beginDate}</p>` +
            `<div class="paddingTop">${item.contents}</div>` +
            `<div class="line"></div>`;
        });
        setAnnounceListData(string);
      },
    });

    getListDataRequest({
      url: '/v1/na/consumer-financial-protection/affiliate-finance',
      successFnc: list => {
        if (list.length > 0) {
          return (document.getElementById('affiliateFinance-item').className =
            'list-item');
        }
      },
    });
  }, []);

  return (
    <div>
      <JoinMemberPolicyPopupDetailLine />
      <JoinMemberPolicyPopupDetailWrapper>
        <JoinPolicyTitle>
          {FINANCE_SUPERVISORY_SERVICE_TAB.find(item => item.index === index)
            ?.title || ''}
        </JoinPolicyTitle>
        <JoinMemberPolicyPopupDetailContent
          dangerouslySetInnerHTML={{
            __html:
              detailContentList[index] ||
              '<div style="display: flex; justify-content: center; align-items: center; min-height: 100px;">Not Available<div>',
          }}
        />
      </JoinMemberPolicyPopupDetailWrapper>
    </div>
  );
};

export default FinancialSupervisoryServiceDetail;
