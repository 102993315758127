import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { getDataRequest } from 'utils/request';
import { NoBtnModal } from 'components/Modal';
import { Card, CardName, CardRowFirst } from 'components/FoldedCard/style';
import { Contents, NoBtnModalContent } from './style';
import { StyledReactQuill } from 'styles/StyledReactQuill';

const NoticeDetailPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const paramsSeq = parseInt(params.seq);
  const [data, setData] = useState({});
  const [isNoBtnModalOpen, setIsNoBtnModalOpen] = useState(false);

  useEffect(() => {
    if (paramsSeq) {
      getDataRequest({
        url: `/v1/na/notice/${paramsSeq}`,
        setData: setData,
      });
    } else {
      alert('잘못된 접근 입니다.');
      window.close();
    }
  }, [paramsSeq]);

  useEffect(() => {
    if (!data?.result) {
      setIsNoBtnModalOpen(true);
    }
  }, [data]);

  const onCloseNoBtnModal = () => {
    navigate(ROUTE_PATH.serviceCenter.main);
    setIsNoBtnModalOpen(false);
  };

  if (!data?.result)
    return (
      <NoBtnModal showModal={isNoBtnModalOpen} onClose={onCloseNoBtnModal}>
        <NoBtnModalContent>
          해당 공지가 존재하지 않습니다. 공지 사항 목록으로 이동합니다.
        </NoBtnModalContent>
      </NoBtnModal>
    );

  return (
    data &&
    data.result && (
      <div>
        <Card>
          <CardName>{data.result.subject}</CardName>
          <CardRowFirst>{data.result.notiPeriodStartDt}</CardRowFirst>
        </Card>
        <Contents>
          <StyledReactQuill value={data.result.contents} readOnly={true} />
        </Contents>
      </div>
    )
  );
};

export default NoticeDetailPage;
