import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDataRequest, getListDataRequest } from 'utils/request';
import ROUTE_PATH from 'routes/config';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import LoanProductsList from './LoanProductsList';
import {
  EmptyContainer,
  FilterContainer,
  FilterMenu,
  LoanBtnContainer,
  LoanListWrap,
  TotalQuantity,
} from './style';
import { useInView } from 'react-intersection-observer';
import { EmptyContent, FileImage, SelectWrapper, Text } from '../style';
import SelectBox from 'components/SelectBox';

const LoanManageTab = ({ financeInstitutionList, creditStatusList }) => {
  const navigate = useNavigate();
  const [loanTotalCount, setLoanTotalCount] = useState(null);
  const [paginationCount, setPaginationCount] = useState({});
  const [validManagerList, setValidManagerList] = useState([]);
  const [financeInstitutionSeq, setFinanceInstitutionSeq] = useState();
  const [creditStatus, setCreditStatus] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  // 무한스크롤
  const [loanProductsList, setLoanProductsList] = useState([]);
  const [oldList, setOldList] = useState([]);
  const [newList, setNewList] = useState([]);
  const [ref, inView] = useInView();

  // 대출 관리 목록 조회
  useEffect(() => {
    getLoanProductsRequest(1);
    setPageNo(1);
    setOldList([]);
  }, [financeInstitutionSeq, creditStatus]);

  useEffect(() => {
    if (inView === true && hasMore === true) {
      setTimeout(() => getLoanProductsRequest(), 300);
    }
  }, [inView, hasMore]);

  useEffect(() => {
    const paging = paginationCount;

    // 데이터가 없을 때
    if (paging?.totalCount === 0) {
      setHasMore(false);
      setLoanProductsList([]);
      setOldList([]);
    } else {
      // pageNo: 현재 페이지 / endPageNo: 마지막 페이지
      if (paging?.pageNo === 1) {
        setLoanProductsList([...newList]);
        setOldList([...newList]);
        if (paging?.endPageNo === 1) {
          setHasMore(false);
        } else if (paging?.endPageNo > 1) {
          setHasMore(true);
          setPageNo(prev => prev + 1);
        }
      } else {
        setLoanProductsList([...oldList, ...newList]);
        setOldList([...oldList, ...newList]);
        if (paging?.pageNo < paging?.endPageNo) {
          setHasMore(true);
          setPageNo(prev => prev + 1);
        } else if (paging?.pageNo === paging?.endPageNo) {
          setHasMore(false);
        }
      }
    }
  }, [paginationCount, newList]);

  // 직원 목록 조회
  useEffect(() => {
    const successFnc = dataList => {
      const validDataList = dataList?.filter(
        managerData =>
          managerData.useYn === 'Y' &&
          (managerData.representationYn === 'Y' ||
            managerData.invitationCompletionYn === 'Y')
      );
      setValidManagerList(validDataList);
    };
    getListDataRequest({ url: '/v1/au/manager', successFnc });
  }, []);

  const getLoanProductsRequest = initPageNo => {
    // 값이 없는 인자를 제외함
    const exceptEmptyParam = (params = {}) => {
      let result = {};
      const keyList = Object.keys(params);
      keyList.forEach(key => {
        if (!params[key]) {
          return;
        }
        result[key] = params[key];
      });
      return result;
    };

    const params = exceptEmptyParam({
      pageNo: initPageNo ? 1 : pageNo,
      creditStatus,
      financeInstitutionSeq,
    });

    const successFnc = data => {
      setLoanTotalCount(data?.totalCount);
      setNewList(data?.list);
      setPaginationCount(data?.paging || {});
    };

    getDataRequest({
      url: '/v1/au/credit-request',
      params: params,
      successFnc: successFnc,
    });
  };

  return (
    <>
      {loanTotalCount !== null ? (
        loanTotalCount > 0 ? (
          <>
            <FilterMenu>
              <TotalQuantity>총 {paginationCount?.totalCount}개</TotalQuantity>
              <FilterContainer>
                <SelectWrapper width="100px">
                  <SelectBox
                    optionList={[
                      { label: '금융기관 전체', value: '' },
                      ...financeInstitutionList,
                    ]}
                    optionHandleChange={event => {
                      setFinanceInstitutionSeq(event.target.value);
                    }}
                    name="selectStatus"
                    value={financeInstitutionSeq}
                    isNotDisabled={true}
                    noPadding={true}
                    $gray
                    $grayPadding="0 22px 0 0"
                  />
                </SelectWrapper>
                <SelectWrapper width="100px">
                  <SelectBox
                    optionList={[
                      { label: '대출상태 전체 ', value: '' },
                      ...creditStatusList,
                    ]}
                    optionHandleChange={event => {
                      setCreditStatus(event.target.value);
                    }}
                    name="selectFinancial"
                    value={creditStatus}
                    isNotDisabled={true}
                    noPadding={true}
                    $gray
                    $grayPadding="0 22px 0 0"
                  />
                </SelectWrapper>
              </FilterContainer>
            </FilterMenu>
            {paginationCount?.totalCount > 0 && (
              <>
                <LoanListWrap>
                  {loanProductsList.map((item, index) => {
                    return (
                      <LoanProductsList
                        item={item}
                        key={`CreditRequest_${item.creditRequestSeq}_${index}`}
                        financeInstitutionList={financeInstitutionList}
                        validManagerList={validManagerList}
                      />
                    );
                  })}
                </LoanListWrap>
                <div ref={ref} />
              </>
            )}
            {paginationCount?.totalCount === 0 && (
              <EmptyContent>
                <FileImage />
                <Text>검색 결과가 없습니다.</Text>
              </EmptyContent>
            )}
          </>
        ) : (
          <EmptyContainer>
            <EmptyContent>
              <FileImage />
              <Text>대출 신청건이 없습니다.</Text>
              <LoanBtnContainer>
                <StyledActiveButton
                  onClick={() => {
                    navigate(ROUTE_PATH.mypage.checkLoan);
                  }}
                >
                  대출 비교하기
                </StyledActiveButton>
              </LoanBtnContainer>
            </EmptyContent>
          </EmptyContainer>
        )
      ) : null}
    </>
  );
};

export default LoanManageTab;
