import styled from 'styled-components';
import theme from 'styles/theme';

export const TextMedium = styled.div`
  width: 100%;
  padding: 41px 0 18px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  text-align: center;
  color: ${theme.color.black};
`;

export const TextBlue = styled.span`
  color: ${theme.color.blue};
`;

export const TextBox = styled.div`
  padding: 10px 0px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
`;

export const TextBoxDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const TextBoxDivTextField = styled.div`
  width: 100%;
  height: 45px;

  padding: 0 14px 0 14px;
  line-height: 4.5rem;
  font-size: 1.3rem;
  font-weight: 500;
  vertical-align: middle;

  border-radius: 10px;
  background: ${theme.backgroundColor.contentBox};
  color: ${props =>
    props.value ? `${theme.color.black}` : `${theme.color.placeholder}`};

  pointer-events: none;
`;

export const TextBoxTitle = styled.div`
  margin: 10px 0;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: ${theme.color.black};
`;

export const TextBoxContents = styled.div`
  width: 100%;
  font-size: 1.5rem;

  line-height: 2.4rem;
  color: ${theme.color.black};
`;

export const TextBoxContentsHighlight = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.color.blue};
  font-size: 2.2rem;
  font-weight: 400;
`;

export const ChildText = styled.div`
  width: 100%;
  margin: 20px 0 0;
`;

export const ChildTextContent = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  line-height: 2.1rem;
  color: ${theme.color.black};
`;

export const Contents = styled.div`
  display: block;
  width: 100%;
  padding: 20px 0 0;
  line-height: 2.4rem;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.black};

  white-space: pre-line;
`;
