// footer information
export const footerInfo = {
  ceo: '강정석',
  address: '서울시 영등포구 은행로 30 KBIZ 중소기업중앙회 본관 8층',
  phoneContact: '02-761-0171',
  emailContact: 'contact@sellercredit.co.kr',
  businessNum: '803-87-00360',
  loanOriginatorRegistrationNumber: '제 2024-002호',
};

// customer service information
export const customerService = {
  phoneContact: '02-761-0171 / 02-761-0172',
  emailContact: 'contact@sellercredit.co.kr',
  businessHour: '평일 10:00 ~ 18:00',
  lunchHour: '평일 12:00 ~ 13:30',
};
