import { useEffect } from 'react';
import StipulationAgreeRow from 'components/StipulationAgreeRow';
import { StipulationWrapper } from 'pages/MyPage/DataLinkage/DataLinkageRequest/IndividualRequestPage/IndividualRequestStep1/DataInterlockLinkInfo/DataInterlockStipulation/style';
import {
  PolicyAgreeAllCheckbox,
  PolicyAgreeAllCheckboxIcon,
  PolicyAgreeAllCheckboxLabel,
  PolicyAgreeAllWrapper,
} from 'pages/Login/JoinMemberPage/JoinMemberStep2/style';

const CheckableTypeStipulation = ({
  stipulationList = [],
  setStipulationList,
  onClickViewBtn,
  stipulationSeq = 'stipulationSeq',
}) => {
  // 모두 동의합니다 체크 영역
  const isAllChecked = stipulationList.every(item => item.checked);

  // 전체 동의
  const onChangeAll = event => {
    const { checked } = event.target;

    setStipulationList(prev =>
      prev.map(item => {
        return { ...item, checked: checked };
      })
    );
  };

  //동의 약관 개별 체크
  const onChangeCheckBox = e => {
    const { value, checked } = e.target;

    setStipulationList(list =>
      list.map(item => {
        return +item[stipulationSeq] === +value
          ? { ...item, checked: checked }
          : item;
      })
    );
  };

  useEffect(() => {
    if (stipulationList && stipulationList.length > 0) {
      // 약관타입
      setStipulationList(
        stipulationList
          .map(item => ({
            ...item,
            required: item.essentialYn === 'Y',
            checked: false,
          }))
          .sort((a, b) => b.required - a.required) // '필수'를 앞에 정렬
      );
    }
  }, []);

  return (
    <div>
      <PolicyAgreeAllWrapper>
        <PolicyAgreeAllCheckboxLabel>
          <PolicyAgreeAllCheckbox
            type="checkbox"
            onChange={onChangeAll}
            checked={isAllChecked}
            readOnly
          />
          모두 동의합니다.
          <PolicyAgreeAllCheckboxIcon />
        </PolicyAgreeAllCheckboxLabel>
      </PolicyAgreeAllWrapper>

      <StipulationWrapper>
        {stipulationList.map((item, index) => (
          <StipulationAgreeRow
            key={`checkable_stipulation_${item[stipulationSeq]}_${index}`}
            item={item}
            index={index}
            onChangeCheckBox={onChangeCheckBox}
            onClickViewBtn={onClickViewBtn}
            stipulationSeq={stipulationSeq}
            lastRequiredIndex={stipulationList
              .map(({ required }) => required)
              .lastIndexOf(true)}
          />
        ))}
      </StipulationWrapper>
    </div>
  );
};

export default CheckableTypeStipulation;
