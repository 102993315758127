import React from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { BlueBox, Title, Contents, TitleText, TitleIcon } from './style';

const BlueAlertBox = ({
  title,
  titleJustifyContent,
  contents,
  background,
  center,
  children,
  margin = '11px 0 0',
}) => {
  return (
    <BlueBox background={background} margin={margin}>
      {title ? (
        <div>
          <Title titleJustifyContent={titleJustifyContent} center={center}>
            <TitleIcon>
              <FeatherIcon icon="alert-circle" size={14} strokeWidth={3} />
            </TitleIcon>
            {/* title이 컴포넌트일 경우 그대로 사용됨 (TitleText css 사용 x) */}
            {typeof title === 'string' ? <TitleText>{title}</TitleText> : title}
          </Title>
          <Contents center={center}>{children ?? contents}</Contents>
        </div>
      ) : (
        <Title
          titleJustifyContent={titleJustifyContent}
          center={center}
          fontNormal={true}
        >
          <TitleIcon>
            <FeatherIcon icon="alert-circle" size={14} strokeWidth={3} />
          </TitleIcon>
          <Contents center={center}>{children ?? contents}</Contents>
        </Title>
      )}
    </BlueBox>
  );
};

export default BlueAlertBox;
