import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from 'utils/api';
import { replaceNonNumber, replaceSpecialCharacter } from 'tools/StringTool';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import MallSecondAuthContent from './MallSecondAuthContent';
import AlertMessageModal from './AlertMessageModal';
import AuthTimeoutModal from './AuthTimeoutModal';
import { MALL_SECOND_AUTH_TYPE } from './constants';
import {
  ContentSectionContent,
  ContentSectionContentWrapper,
  ContentSectionTitle,
  ContentSectionWrapper,
  ContentWrapper,
  TitleText,
  TitleWrapper,
} from './style';

const MallSecondAuthPage = () => {
  const [searchParams] = useSearchParams();
  const [hash, setHash] = useState('');
  const [bizRegNo, setBizRegNo] = useState('');
  const [mallName, setMallName] = useState('');
  const [mallSecondAuthType, setMallSecondAuthType] = useState('');
  const [mallSecondAuthValue, setMallSecondAuthValue] = useState('');
  const [captchaImageUrl, setCaptchaImageUrl] = useState('');
  const [secondAuthQuestion, setSecondAuthQuestion] = useState('');
  const [isDisabledInput, setIsDisabledInput] = useState(true);
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);
  const [failModalMessage, setFailModalMessage] = useState('');
  const [twoBtnModalMessage, setTwoBtnModalMessage] = useState('');

  const isGeneralSecondAuthType =
    MALL_SECOND_AUTH_TYPE[mallSecondAuthType]?.requirement === 'CODE';

  useEffect(() => {
    if (searchParams.get('hash')?.trim()) {
      setHash(searchParams.get('hash')?.trim());
    } else {
      setShowFailModal(true);
      setFailModalMessage(
        '올바른 경로가 아닙니다.\n문자로 전달 받은 링크를 통해 다시 한번 접속해 주세요.'
      );
    }
  }, []);

  useEffect(() => {
    if (!!hash) {
      getHashInfo({ hash });
    }
  }, [hash]);

  useEffect(() => {
    if (
      !!mallSecondAuthType &&
      MALL_SECOND_AUTH_TYPE[mallSecondAuthType]?.requirement === 'BROWSER'
    ) {
      setMallSecondAuthValue('CONFIRM');
      setIsDisabledButton(false);
    }
  }, [mallSecondAuthType]);

  const onChangeTextField = event => {
    const value =
      event.target.name === 'OTP' || event.target.name === 'PHONE_NUMBER'
        ? replaceNonNumber(event.target.value)
        : replaceSpecialCharacter(event.target.value.trim());
    setMallSecondAuthValue(value);

    if (event.target.value.trim().length > 0) {
      setIsDisabledButton(false);
    } else {
      setIsDisabledButton(true);
    }
  };

  const onClickConfirmBtn = () => {
    setIsDisabledInput(true);
    setIsDisabledButton(true);
    sendMallSecondAuth();
  };

  const getHashInfo = ({ hash }) => {
    api({
      url: `/v1/au/second-auth/mall?secondAuthHash=${hash}`,
      method: 'get',
      headers: { 'content-type': 'application/json' },
    })
      .then(res => {
        const { success, message, data, 'error-no': errorNo } = res.data;
        if (success) {
          setBizRegNo(data.mallInfo.bizRegNo ?? '');
          setMallName(data.mallInfo.mallName);
          setMallSecondAuthType(data.mallInfo.secondAuthType);
          setCaptchaImageUrl(data.mallInfo.secondAuthDetail);
          setSecondAuthQuestion(data.mallInfo.secondAuthQuestion || '');
          setIsDisabledInput(false);
        } else {
          if (errorNo === 10000) {
            setTwoBtnModalMessage(message);
          } else {
            setShowFailModal(true);
            setFailModalMessage(message);
          }
        }
      })
      .catch(err => {
        console.error(err);
        const { data } = err.response;
        setShowFailModal(true);
        setFailModalMessage(data.message);
      });
  };

  const sendMallSecondAuth = () => {
    const data = {
      secondAuthHash: hash,
      authValue: mallSecondAuthValue,
    };

    api({
      url: '/v1/au/second-auth/value',
      method: 'post',
      headers: { 'content-type': 'application/json' },
      data: data,
    })
      .then(res => {
        const { success, message, 'error-no': errorNo } = res.data;
        if (success) {
          setShowModal(true);
        } else {
          if (errorNo === 10000) {
            setTwoBtnModalMessage(message);
          } else {
            setIsDisabledInput(false);
            setShowFailModal(true);
          }
        }
      })
      .catch(err => {
        console.error(err);
        setIsDisabledInput(false);
        setShowFailModal(true);
      });
  };

  if (!bizRegNo) {
    return (
      <div>
        <AlertMessageModal
          mallSecondAuthType={mallSecondAuthType}
          showFailModal={showFailModal}
          setShowFailModal={setShowFailModal}
          failModalMessage={failModalMessage}
          isGeneralSecondAuthType={isGeneralSecondAuthType}
          showModal={showModal}
          setShowModal={setShowModal}
          mallName={mallName}
        />

        {/*2차 인증 시간 만료시 뜨는 버튼 두개짜리 모달*/}
        <AuthTimeoutModal
          twoBtnModalMessage={twoBtnModalMessage}
          setTwoBtnModalMessage={setTwoBtnModalMessage}
        />
      </div>
    );
  }

  return (
    <div>
      <TitleWrapper>
        <TitleText>
          {isGeneralSecondAuthType === true
            ? '연동 판매몰의 2차 인증을\n진행해 주세요.'
            : '연동 판매몰의 보안 인증을\n진행해 주세요.'}
        </TitleText>
      </TitleWrapper>
      <ContentWrapper>
        <ContentSectionWrapper>
          <ContentSectionTitle>사업자 번호</ContentSectionTitle>
          <ContentSectionContentWrapper>
            <ContentSectionContent>{bizRegNo}</ContentSectionContent>
          </ContentSectionContentWrapper>
        </ContentSectionWrapper>

        {MALL_SECOND_AUTH_TYPE[mallSecondAuthType]?.code && (
          <MallSecondAuthContent
            isGeneralSecondAuthType={isGeneralSecondAuthType}
            mallSecondAuthObj={MALL_SECOND_AUTH_TYPE[mallSecondAuthType]}
            mallName={mallName}
            captchaImageUrl={captchaImageUrl}
            secondAuthQuestion={secondAuthQuestion}
            mallSecondAuthValue={mallSecondAuthValue}
            onChangeTextField={onChangeTextField}
            isDisabledInput={isDisabledInput}
          />
        )}

        <StyledActiveButton
          onClick={onClickConfirmBtn}
          margin="63px 0 0"
          disabled={isDisabledButton}
        >
          {isGeneralSecondAuthType === true
            ? '2차 인증 완료'
            : '보안 인증 완료'}
        </StyledActiveButton>
      </ContentWrapper>

      <AlertMessageModal
        mallSecondAuthType={mallSecondAuthType}
        showFailModal={showFailModal}
        setShowFailModal={setShowFailModal}
        failModalMessage={failModalMessage}
        isGeneralSecondAuthType={isGeneralSecondAuthType}
        showModal={showModal}
        setShowModal={setShowModal}
        mallName={mallName}
      />

      {/*2차 인증 시간 만료시 뜨는 버튼 두개짜리 모달*/}
      <AuthTimeoutModal
        twoBtnModalMessage={twoBtnModalMessage}
        setTwoBtnModalMessage={setTwoBtnModalMessage}
      />
    </div>
  );
};

export default MallSecondAuthPage;
