import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDataRequest } from 'utils/request';
import useApp from 'hooks/useApp';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import JoinMemberStep2 from 'pages/Login/JoinMemberPage/JoinMemberStep2';
import JoinMemberStep3 from 'pages/Login/JoinMemberPage/JoinMemberStep3';
import { JoinMemberWrapper } from 'pages/Login/JoinMemberPage/style';

const DeepLinkJoinMemberPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const businessManagerHash = params.hash;
  const { auth } = useApp();
  const [step, setStep] = useState(1);
  const [businessInfo, setBusinessInfo] = useState({
    businessName: '',
    businessRegistrationNumber: 0,
    invitationPeriodExpiredYn: '',
  });
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    if (!!businessManagerHash) {
      const successFnc = data => {
        if (data && data.result) {
          setBusinessInfo(data.result);
          setStep(2);
          return;
        }
      };
      getDataRequest({
        url: `/v1/na/manager/invite-expired/${businessManagerHash}`,
        successFnc,
        setModalMessage,
        setShowAlertModal,
      });
    } else {
      // hash값 없는 경우 home 이동
      navigate('/');
    }
  }, [businessManagerHash]);

  return (
    <JoinMemberWrapper>
      {/* step1: 사업자 정보 인증 페이지 */}
      {!auth.authenticated && step === 1 && null}

      {/* step2: 회원 가입 페이지 */}
      {step === 2 && !!businessInfo && (
        <JoinMemberStep2
          //  직원은 항상 사업자 인증 여부 N
          certificationYn="N"
          businessRegistrationNumber={businessInfo.businessRegistrationNumber}
          businessName={businessInfo.businessName}
          businessManagerHash={businessManagerHash}
        />
      )}

      {/* 알럿 메세지 모달 */}
      <NoBtnModal showModal={showAlertModal} onClose={() => navigate('/')}>
        <ScrollArea>
          <Contents>{modalMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </JoinMemberWrapper>
  );
};

export default DeepLinkJoinMemberPage;
