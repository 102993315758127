import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import qs from 'qs';
import useApp from 'hooks/useApp';
import { getSession } from 'tools/WebTool';
import ROUTE_PATH from './config';

const AuthRouteOutlet = ({ children, withoutAuth, otherwise, ...rest }) => {
  const { auth } = useApp();
  const location = useLocation();
  const loginUrl = ROUTE_PATH.login.login;
  const homeUrl = '/';
  const authRequired = !otherwise && !withoutAuth && !auth.authenticated;
  const shouldRedirect = !otherwise && !!withoutAuth && !!auth.authenticated;

  // 인증이 있어야 하는 경우
  if (authRequired) {
    if (getSession()) {
      return <>{auth.authenticated ? <Outlet {...rest} /> : <></>}</>;
    } else {
      const search = ['', '/', loginUrl].includes(location.pathname)
        ? ''
        : '?' +
          qs.stringify({
            'redirect-to': location.pathname + location.search,
          });
      return <Navigate to={loginUrl + search} replace={true} />;
    }
    // 인증이 없어야 하는 경우
  } else if (shouldRedirect) {
    const search = location.search;
    const qo = !!search ? qs.parse(search, { ignoreQueryPrefix: true }) : {};
    const redirectTo = typeof qo == 'object' ? qo['redirect-to'] : '';
    if (location.pathname === loginUrl && !!redirectTo) {
      return <Navigate to={redirectTo} />;
    } else if (!['', '/', homeUrl].includes(location.pathname)) {
      return <Navigate to={homeUrl} />;
    }
  } else {
    // 세션이 있으면 authenticated 를 기다리고 없으면 그냥 통과
    if ((getSession() && auth.authenticated) || !getSession()) {
      return <Outlet {...rest} />;
    } else {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
  }
};

export { AuthRouteOutlet };
