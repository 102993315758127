import React from 'react';
import TextField from 'components/TextField';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import { DetailModalTitle, DetailModalWrap, TextFieldWrap } from './style';

const EmailAuthModal = ({
  openAuthModal,
  onCloseAuthModal,
  emailAuthParamsOne,
  onChangeAuthInput,
  incorrectEmailInputMessage,
  isClickedRequestAuthBtn,
  onClickRequestAuth,
  onClickRequestAgain,
  emailAuthParamsTwo,
  time,
  incorrectAuthNumInputMessage,
  onClickCompleteAuth,
  authRequestBtnStatus,
}) => {
  return (
    <NoBtnModal
      title="이메일 인증"
      showModal={openAuthModal}
      onClose={onCloseAuthModal}
    >
      <ScrollArea title="title">
        <Contents>
          <DetailModalWrap>
            <DetailModalTitle>
              비회원의 경우 이메일 인증 이후에 작업하실 수 있습니다.
            </DetailModalTitle>
            <TextFieldWrap>
              <TextField
                placeholder="이메일을 입력해주세요."
                name="email"
                title="이메일"
                value={emailAuthParamsOne.email}
                onChange={onChangeAuthInput}
                maxLength={50}
                style={{ marginTop: '20px' }}
                showMessage={!!incorrectEmailInputMessage}
                message={incorrectEmailInputMessage}
              />
              {!isClickedRequestAuthBtn ? (
                <StyledActiveButton
                  onClick={onClickRequestAuth}
                  disabled={!emailAuthParamsOne.email}
                  title="인증"
                  style={{
                    width: '120px',
                    fontSize: '1.3rem',
                    margin: '20px 0px 20px 10px',
                  }}
                >
                  인증 요청
                </StyledActiveButton>
              ) : (
                <StyledActiveButton
                  onClick={onClickRequestAgain}
                  disabled={!emailAuthParamsOne.email}
                  title="재전송"
                  style={{
                    width: '120px',
                    fontSize: '1.3rem',
                    margin: '20px 0px 20px 10px',
                  }}
                >
                  재전송
                </StyledActiveButton>
              )}
            </TextFieldWrap>
            <TextField
              placeholder="인증번호를 입력해주세요."
              name="certificationNumber"
              type="number"
              title="인증번호"
              value={emailAuthParamsTwo.certificationNumber}
              onChange={onChangeAuthInput}
              maxLength={50}
              count={time}
              style={{ marginTop: '0px' }}
              showMessage={!!incorrectAuthNumInputMessage}
              message={incorrectAuthNumInputMessage}
            />
            <StyledActiveButton
              onClick={onClickCompleteAuth}
              disabled={
                authRequestBtnStatus ||
                !emailAuthParamsOne.email ||
                !emailAuthParamsTwo.certificationNumber
              }
              style={{
                margin: '20px 0px 10px 0px',
              }}
            >
              인증 완료
            </StyledActiveButton>
          </DetailModalWrap>
        </Contents>
      </ScrollArea>
    </NoBtnModal>
  );
};

export default EmailAuthModal;
