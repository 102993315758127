import theme from 'styles/theme';

export const QNA_CARD_STATUS_SETTING = [
  {
    type: '확인중',
    color: `${theme.color.green}`,
    backgroundColor: `${theme.backgroundColor.checkGreen}`,
    text: `확인중`,
  },
  {
    type: '답변대기',
    color: `${theme.color.grey7}`,
    backgroundColor: `${theme.backgroundColor.contentBox}`,
    text: `답변대기`,
  },
  {
    type: '답변완료',
    color: `${theme.color.blue}`,
    backgroundColor: `${theme.backgroundColor.checkBlue}`,
    text: `답변완료`,
  },
];

export const QNA_LIST_ARTICLE_COUNT = 30;
