export const ORDER_OPTION = {
  interest: 'interest',
  limit: 'limit',
};

export const BADGE_BACKGROUND_COLOR = {
  '오늘 입금': 'checkGreen',
  '모바일로 끝': 'checkBlue',
  '대출 불가': 'grey73',
  '전용 상품': 'purple',
};

export const BADGE_COLOR = {
  '오늘 입금': 'green',
  '모바일로 끝': 'blue',
  '대출 불가': 'white',
  '전용 상품': 'purple',
};

export const DATA_USE_TYPE_KR = {
  CREDIT_LOAN_CMPRS: '신용',
  LOAN_CMPRS: '담보',
};
