import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const StipulationName = styled.p`
  margin: 30px 0;
  font-size: 1.7rem;
  line-height: 25px;
  text-align: center;
  font-weight: 700;
`;

export const StipulationContents = styled.div`
  line-height: 2.4rem;
`;
