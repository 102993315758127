import React from 'react';
import { StyledTextArea } from './style';

const TextArea = ({
  name = '',
  value = '',
  onChange = () => {},
  onKeyDown = () => {},
  disabled = false,
  placeholder = '',
  maxLength = 1000,
  showCount = false,
  style = {},
}) => {
  return (
    <StyledTextArea
      name={name}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      disabled={disabled}
      placeholder={placeholder}
      maxLength={maxLength}
      showCount={showCount}
      style={style}
    />
  );
};

export default TextArea;
