import styled from 'styled-components';
import theme from 'styles/theme';

export const FileImage = styled.div`
  width: 122.5px;
  height: 68.25px;

  background-image: url('/images/file_illo.png');
  background-size: contain;
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: calc(100vh - 201px); /** Topbar와 본문 padding 제외한 값*/
  font-size: 1.5rem;
`;

export const Text = styled.p`
  margin-top: 24px;
  color: ${theme.color.grey6};
`;

export const SelectWrapper = styled.div`
  width: ${props => (props.width ? props.width : '140px')};
`;
