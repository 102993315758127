import styled from 'styled-components';
import theme from 'styles/theme';
import { Pagination as _Pagination } from 'react-headless-pagination';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
export const Pagination = styled(_Pagination)``;

export const PageSpan = styled.span``;

export const PageButton = styled(_Pagination.PageButton)``;

export const PrevButton = styled(_Pagination.PrevButton)`
  display: inline-block;
  text-align: center;

  margin-right: 16px;

  width: 32px;
  height: 32px;

  font-weight: 600;
  border-radius: 50px;
  background: ${theme.backgroundColor.table};

  &:disabled {
    color: ${theme.color.policyGrey};
  }
`;

export const NextButton = styled(_Pagination.NextButton)`
  display: inline-block;
  text-align: center;

  margin-left: 16px;

  width: 32px;
  height: 32px;

  font-weight: 600;
  border-radius: 50px;
  background: ${theme.backgroundColor.table};

  &:disabled {
    color: ${theme.color.policyGrey};
  }
`;
