import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import theme from 'styles/theme';
import qs from 'qs';
import { getDataRequest } from 'utils/request';
import {
  Contents,
  RoundBox,
  Section,
  Table,
  TableData,
  TableHeader,
  TableRow,
  TableSection,
  Title,
} from '../style';

const EstimatedAmountDetailPopupPage = () => {
  const location = useLocation();
  const params = location.search;

  const [estimatedAmountData, setEstimatedAmountData] = useState({});
  const [urlData, setUrlData] = useState({});

  const { mallName = '', mallAccountId = '' } = urlData;

  useEffect(() => {
    if (params) {
      getDataRequest({
        url: `/v1/au/data-provider/manage/mall/settlement-scheduled${params}`,
        setData: setEstimatedAmountData,
      });
      const paramsToObj = qs.parse(params.slice(1, params.length));
      setUrlData(paramsToObj);
    }
  }, [params]);

  const totalPrice =
    estimatedAmountData?.dataCollectionSettlementScheduledInfo?.reduce(
      (total, item) => {
        return total + item.scheduledSettlementAmt;
      },
      0
    );

  return (
    !!mallName &&
    !!mallAccountId &&
    !!estimatedAmountData?.dataCollectionSettlementScheduledInfo?.length >
      0 && (
      <div>
        <Section>
          <Title>판매몰 정보</Title>
          <Contents>
            {mallName} | {mallAccountId}
          </Contents>
        </Section>
        <Section>
          <Title>정산예정금 정보</Title>
          <Contents>총 {totalPrice.toLocaleString()} 원</Contents>
          <div>
            {estimatedAmountData.dataCollectionSettlementScheduledInfo.map(
              item => (
                <RoundBox key={item.scheduledSettlementDate}>
                  <div>{item.scheduledSettlementDate}</div>
                  <div>{item.scheduledSettlementAmt.toLocaleString()} 원</div>
                </RoundBox>
              )
            )}
          </div>
        </Section>
        {!!estimatedAmountData?.dataCollectionSettlementInfo?.length > 0 && (
          <Section>
            <Title>정산금 정보</Title>
            <TableSection>
              <Table>
                <tbody>
                  <TableRow
                    style={{
                      position: 'sticky',
                      top: '0',
                      zIndex: '10',
                    }}
                  >
                    <TableHeader
                      style={{
                        position: 'sticky',
                        left: '0',
                      }}
                    >
                      정산 일자
                    </TableHeader>
                    <TableHeader>정산 금액(원)</TableHeader>
                    <TableHeader>판매 금액</TableHeader>
                    <TableHeader>
                      공제합계 <br />
                      (①+②+③)
                    </TableHeader>
                    <TableHeader>배송비</TableHeader>
                    <TableHeader>유보금(+)</TableHeader>
                    <TableHeader>유보금(-)</TableHeader>
                    <TableHeader>①마켓수수료</TableHeader>
                    <TableHeader>②광고수수료</TableHeader>
                    <TableHeader>③기타공제</TableHeader>
                  </TableRow>
                  {estimatedAmountData.dataCollectionSettlementInfo.map(
                    item => (
                      <TableRow key={item?.baseMonth}>
                        <TableData
                          style={{
                            background: `${theme.backgroundColor.table}`,
                            position: 'sticky',
                            left: '0',
                            textAlign: 'center',
                          }}
                        >
                          {item?.baseMonth}
                        </TableData>
                        <TableData>
                          {item?.settlementAmt?.toLocaleString()}
                        </TableData>
                        <TableData>{item?.saleAmt?.toLocaleString()}</TableData>
                        <TableData>
                          {item?.deductionSummation?.toLocaleString()}
                        </TableData>
                        <TableData>
                          {item?.deliveryFee?.toLocaleString()}
                        </TableData>
                        <TableData>
                          {item?.plusReservationAmt?.toLocaleString()}
                        </TableData>
                        <TableData>
                          {item?.minusReservationAmt?.toLocaleString()}
                        </TableData>
                        <TableData>
                          {item?.marketFee?.toLocaleString()}
                        </TableData>
                        <TableData>
                          {item?.advertisementFee?.toLocaleString()}
                        </TableData>
                        <TableData>
                          {item?.etcDeduction?.toLocaleString()}
                        </TableData>
                      </TableRow>
                    )
                  )}
                </tbody>
              </Table>
            </TableSection>
          </Section>
        )}
      </div>
    )
  );
};

export default EstimatedAmountDetailPopupPage;
