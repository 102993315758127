import React, { useEffect, useState } from 'react';
import useApp from 'hooks/useApp';
import { getListDataRequest } from 'utils/request';
import { languageOrder } from 'tools/SortTool';
import CustomerServiceFooter from 'layouts/Footer/CustomerServiceFooter';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import DataProviderLinkInfo from './DataProviderLinkInfo';
import { FirstText, TextBlue } from '../../style';
import {
  BlueLink,
  ChildText,
  ChildTextContent,
  ChildTextContent2,
  ContentBox,
  SelectOption,
  SelectProvider,
} from '../style';

const DataLinkageDataProviderRequestPage = () => {
  const { auth } = useApp();
  const [businessName, setBusinessName] = useState('');
  const [businessRegistrationNumber, setBusinessRegistrationNumber] =
    useState('');
  const [_dataProviderList, _setDataProviderList] = useState([
    {
      dataProviderSeq: 0,
      dataProviderId: '',
      dataProviderName: '',
      dataProviderPassword: '',
    },
  ]);
  const [dataProviderList, setDataProviderList] = useState([]);
  const [selectedDataProvider, setSelectedDataProvider] = useState({});
  const [dataProviderInfo, setDataProviderInfo] = useState({});
  const isDisabled = true;

  useEffect(() => {
    const { businessName, businessRegistrationNumber } = auth;
    !!businessName && setBusinessName(businessName);
    !!businessRegistrationNumber &&
      setBusinessRegistrationNumber(businessRegistrationNumber);
  }, [auth]);

  useEffect(() => {
    getListDataRequest({
      url: '/v1/au/data-provider',
      setData: _setDataProviderList,
    });
  }, []);

  useEffect(() => {
    setDataProviderList([
      {
        label: '데이터 제공사 선택',
        value: '0',
      },
      ..._dataProviderList.sort(languageOrder)?.map(item => {
        return {
          label: `${item.dataProviderName}`,
          value: `${item.dataProviderSeq}`,
        };
      }),
    ]);
  }, [_dataProviderList]);

  const onChangeSelectProvider = event => {
    const { value } = event.target;
    setSelectedDataProvider(
      dataProviderList.find(item => item.value === value)
    );
  };

  return (
    <div>
      <FirstText>
        <TextBlue>{businessName}</TextBlue>님 데이터 제공사 정보를 입력 후<br />
        데이터 연동을 진행해 주세요.
      </FirstText>

      <ContentBox>
        <ChildText>
          <ChildTextContent bold>사업자 번호</ChildTextContent>
          <ChildTextContent>{businessRegistrationNumber}</ChildTextContent>
        </ChildText>

        <ChildText>
          <ChildTextContent bold>연동 정보</ChildTextContent>
          <ChildTextContent>데이터 제공사 연동</ChildTextContent>
        </ChildText>

        <SelectProvider
          onChange={onChangeSelectProvider}
          value={selectedDataProvider?.value}
        >
          {dataProviderList?.map((item, index) => (
            <SelectOption
              key={`SelectOption_${item.value}_${index}`}
              value={item.value}
            >
              {item.label}
            </SelectOption>
          ))}
        </SelectProvider>

        {selectedDataProvider && selectedDataProvider.value && (
          <>
            <DataProviderLinkInfo
              dataProviderInfo={dataProviderInfo}
              setDataProviderInfo={setDataProviderInfo}
              idPlaceholder={`${selectedDataProvider.label} 아이디`}
              pwPlaceholder={`${selectedDataProvider.label} 비밀번호`}
            />
            <ChildText>
              <ChildTextContent2>
                아직 {selectedDataProvider.label} 회원이 아니라면?{' '}
                <BlueLink
                  href="/"
                  target="_blank"
                  tabIndex={0}
                  title="데이터제공사 사이트의 회원가입 팝업창 오픈"
                >
                  {selectedDataProvider.label} 회원가입
                </BlueLink>
              </ChildTextContent2>
            </ChildText>

            <StyledActiveButton
              onClick={() => {}}
              margin="0 0 36px"
              disabled={isDisabled}
            >
              판매몰 계정 확인
            </StyledActiveButton>
          </>
        )}
      </ContentBox>

      <CustomerServiceFooter hasOneToOne={true} hasInfo={false} />
    </div>
  );
};

export default DataLinkageDataProviderRequestPage;
