import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import RenderMallRow from './RenderMallRow';
import {
  ChildText,
  ChildTextContent,
} from 'pages/MyPage/LoanLimit/LoanLimitBasicPage/style';
import { CheckCards, TextBox } from './style';
import { InfoText, InfoTitle } from '../SelectMall/style';
import { INFO_LIST } from './constants';

const RenderMall = ({
  availableMallList,
  setCheckedMallList,
  onClickDataLinkagePage,
}) => {
  const [checkStatusList, setCheckStatusList] = useState([]);

  useEffect(() => {
    setCheckStatusList(
      availableMallList.map(item => {
        return {
          mallSeq: item.mallSeq,
          isChecked: true,
          mallName: item.mallName,
          mallAccountId: item.mallAccountId,
          dataProviderScheduledAmtList: item.dataProviderScheduledAmtList,
        };
      })
    );
  }, [availableMallList]);

  useEffect(() => {
    setCheckedMallList(checkStatusList.filter(item => item.isChecked));
  }, [checkStatusList]);

  return (
    <>
      <TextBox>
        신용 대출 한도 조회에 사용할 판매몰을
        <br />
        확인해 주세요.
      </TextBox>

      <CheckCards>
        {availableMallList?.map((mall, index) => {
          return (
            <RenderMallRow
              key={`availableMallList_${mall.mallSeq}_${index}`}
              index={index}
              mall={mall}
            />
          );
        })}
      </CheckCards>

      <ChildText style={{ justifyContent: 'left' }}>
        <ChildTextContent
          style={{
            textDecoration: 'underline',
            color: '#02A4FF',
            cursor: 'pointer',
            marginRight: '5px',
          }}
          onClick={onClickDataLinkagePage}
        >
          더 많은 데이터 연동이 필요하신가요?
        </ChildTextContent>
        <FeatherIcon icon="check-square" stroke="#02A4FF" size={14} />
      </ChildText>

      <InfoTitle>안내 사항</InfoTitle>
      <ul>
        {INFO_LIST.map((item, index) => (
          <InfoText key={index}>{item}</InfoText>
        ))}
      </ul>
    </>
  );
};

export default RenderMall;
