import React, { useState } from 'react';
import DataLinkageFooter from 'layouts/Footer/DataLinkageFooter';
import IndividualModifyStep1 from './IndividualModifyStep1';
import IndividualModifyStep2 from './IndividualModifyStep2';
import IndividualModifyStep3 from './IndividualModifyStep3';
import IndividualModifyStep4 from './IndividualModifyStep4';

const IndividualModifyPage = ({
  dataInterlockInfo,
  setDataInterlockInfo,
  businessRegistrationNumber,
  secondAuthReleaseMessage,
  redGuideContents,
  isRepresentation,
}) => {
  const [step, setStep] = useState(1);
  const [isResetBtnClicked, setIsResetBtnClicked] = useState(false);
  const [possibleInterlockYn, setPossibleInterlockYn] = useState('Y');
  const [modifiedDataInterlockInfo, setModifiedDataInterlockInfo] = useState({
    ...dataInterlockInfo,
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 60px)',
      }}
    >
      <div>
        {step === 1 ? (
          <IndividualModifyStep1
            modifiedDataInterlockInfo={modifiedDataInterlockInfo}
            setModifiedDataInterlockInfo={setModifiedDataInterlockInfo}
            setStep={setStep}
            isResetBtnClicked={isResetBtnClicked}
            businessRegistrationNumber={businessRegistrationNumber}
            isRepresentation={isRepresentation}
          />
        ) : step === 2 ? (
          <IndividualModifyStep2
            modifiedDataInterlockInfo={modifiedDataInterlockInfo}
            setModifiedDataInterlockInfo={setModifiedDataInterlockInfo}
            setDataInterlockInfo={setDataInterlockInfo}
            setStep={setStep}
            redGuideContents={redGuideContents}
            secondAuthReleaseMessage={secondAuthReleaseMessage}
            businessRegistrationNumber={businessRegistrationNumber}
          />
        ) : step === 3 ? (
          <IndividualModifyStep3
            modifiedDataInterlockInfo={modifiedDataInterlockInfo}
            setModifiedDataInterlockInfo={setModifiedDataInterlockInfo}
            setStep={setStep}
            setIsResetBtnClicked={setIsResetBtnClicked}
            setPossibleInterlockYn={setPossibleInterlockYn}
            isRepresentation={isRepresentation}
          />
        ) : (
          <IndividualModifyStep4
            dataInterlockInfo={dataInterlockInfo}
            setDataInterlockInfo={setDataInterlockInfo}
            possibleInterlockYn={possibleInterlockYn}
            redGuideContents={redGuideContents}
            setStep={setStep}
          />
        )}
      </div>
      <DataLinkageFooter hasOneToOne={true} hasInfo={false} />
    </div>
  );
};

export default IndividualModifyPage;
