import React, { useEffect } from 'react';
import {
  ModalWrapper,
  ModalHeader,
  Title,
  ModalClose,
  SideArea,
  Wrapper,
} from '../style';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';

const NoBtnModal = ({ title, showModal, onClose, children }) => {
  useEffect(() => {
    document.body.style.overflowY = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  return (
    !!showModal && (
      <Wrapper>
        <ModalWrapper>
          <ModalHeader>
            <Title>{title}</Title>
            <ModalClose onClick={onClose}>
              <FeatherIcon icon="x" size={20} strokeWidth={2.2} />
            </ModalClose>
          </ModalHeader>
          {children}
        </ModalWrapper>
        <SideArea onClick={onClose} />
      </Wrapper>
    )
  );
};

export default NoBtnModal;
