import React from 'react';
import DOMPurify from 'dompurify';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import TextField from 'components/TextField';
import BlueAlertBox from 'components/BlueAlertBox';
import CheckBox from 'components/CheckBox';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import FoldedCard from 'components/FoldedCard';
import LinkageGuideBox from 'pages/MyPage/DataLinkage/LinkageGuideBox';
import { CaptchaImage } from 'pages/MallSecondAuthPage/style';
import {
  CapchaQuestion,
  DataLinkagePaddingWrapper,
} from 'pages/MyPage/DataLinkage/DataLinkageRequest/style';
import { Card, CardName } from 'components/FoldedCard/style';
import {
  DataFnqWrap,
  SecondAuthAlertText,
  SecondAuthArea,
  SecondAuthCheckBoxArea,
} from 'pages/MyPage/DataLinkage/DataLinkageRequest/IndividualRequestPage/IndividualRequestStep1/DataInterlockLinkInfo/style';

const DataInterlockLinkInfo = ({
  redGuideContents = '',
  scrapingCompletedYn = '',
  secondAuthReleaseType = '',
  captchaData = {},
  onClickValidateMallAuth = () => {},
  faqList = [],
  mallName = '',
  mallSeq,
  partnerCode,
  secondAuthType,
  secondAuthValue,
  mallMainId = '',
  onChangeTextField = () => {},
  mallMainPassword = '',
  secondAuthReleaseMessage = '',
  isChecked = false,
  onChangeCheckBox = () => {},
  onChangeCaptchaTextField = () => {},
  activateButton = () => {},
  onClickDetailBtn = () => {},
  onChangeSecondAuthType = () => {},
  mallInterlockStatus,
}) => {
  return (
    <>
      <DataLinkagePaddingWrapper>
        <TextField
          name="name"
          placeholder="이름"
          readOnly
          value={mallName || ''}
          style={{ margin: 0, backgroundColor: '#D9F1FF' }}
        />
        {redGuideContents && (
          <BlueAlertBox
            background="icyRed"
            title="주요 안내"
            contents={
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(redGuideContents),
                }}
              />
            }
          />
        )}

        <TextField
          name="mallMainId"
          placeholder={`${mallName} 아이디`}
          type="text"
          value={mallMainId || ''}
          onChange={onChangeTextField}
          readOnly={
            scrapingCompletedYn === 'Y' || mallInterlockStatus === 'FINISH'
          }
        />
        <TextField
          name="mallMainPassword"
          placeholder={`${mallName} 비밀번호`}
          type="password"
          value={mallMainPassword || ''}
          onChange={onChangeTextField}
        />
        {mallSeq === 10017 && (
          <TextField
            name="partnerCode"
            placeholder="협력사코드"
            type="text"
            value={partnerCode || ''}
            onChange={onChangeTextField}
          />
        )}
      </DataLinkagePaddingWrapper>
      {/* 문자/이메일 인증 */}
      {(mallSeq === 10017 || mallSeq === 10019) && (
        <SecondAuthArea>
          <SecondAuthAlertText>
            <FeatherIcon
              icon="alert-circle"
              size={14}
              stroke="red"
              style={{ margin: '5px 3px -2px 0' }}
            />
            해당 판매몰의 경우 로그인 시 문자 또는 이메일 인증이 필요해요.
            인증번호를 받을 문자 또는 이메일을 선택한 후, 해당 정보를 입력해
            주세요.
          </SecondAuthAlertText>
          <SecondAuthCheckBoxArea>
            <CheckBox
              type="radio"
              name="secondAuthType"
              value="SAT111"
              text="문자 인증"
              id="SAT111"
              isChecked={secondAuthType === 'SAT111'}
              onChangeCheckBox={() => onChangeSecondAuthType('SAT111')}
            />
            <CheckBox
              type="radio"
              name="secondAuthType"
              value="SAT112"
              id="SAT112"
              text="이메일 인증"
              isChecked={secondAuthType === 'SAT112'}
              onChangeCheckBox={() => onChangeSecondAuthType('SAT112')}
            />
          </SecondAuthCheckBoxArea>
          <TextField
            name="secondAuthValue"
            placeholder={
              secondAuthType === 'SAT111'
                ? '휴대폰번호(-제외)'
                : '이메일(@까지 입력)'
            }
            type="text"
            value={secondAuthValue || ''}
            onChange={onChangeTextField}
          />
        </SecondAuthArea>
      )}

      {(secondAuthReleaseType === 'REQUIRED' ||
        secondAuthReleaseType === 'RECOMMENDED') && (
        <LinkageGuideBox
          secondAuthReleaseMessage={secondAuthReleaseMessage}
          secondAuthReleaseType={secondAuthReleaseType}
          isChecked={isChecked}
          onChangeCheckBox={onChangeCheckBox}
        />
      )}
      {captchaData?.captchaUrl && (
        <SecondAuthArea>
          <SecondAuthAlertText>
            <FeatherIcon
              icon="alert-circle"
              size={14}
              stroke="red"
              style={{ margin: '5px 3px -2px 0' }}
            />
            해당 판매몰의 경우 자동 등록 방지를 위한 캡차 인증이 필요해요. 캡차
            답변 입력 후 다시 한번 [판매몰 계정 확인]버튼을 눌러주세요.
          </SecondAuthAlertText>
          <CaptchaImage src={captchaData.captchaUrl} alt="captchaImage" />
          {captchaData?.captchaQuestion ? (
            <>
              <CapchaQuestion>
                질문 : {captchaData.captchaQuestion}
              </CapchaQuestion>
              <TextField
                name="captchaValue"
                placeholder="정답을 입력해 주세요."
                value={captchaData.captchaValue}
                onChange={onChangeCaptchaTextField}
              />
            </>
          ) : (
            <TextField
              name="captchaValue"
              placeholder="이미지의 문자와 숫자를 입력해 주세요."
              value={captchaData.captchaValue}
              onChange={onChangeCaptchaTextField}
            />
          )}
        </SecondAuthArea>
      )}
      <DataLinkagePaddingWrapper>
        <StyledActiveButton
          disabled={activateButton()}
          onClick={onClickValidateMallAuth}
          margin="23px 0px 46px"
        >
          판매몰 계정 확인
        </StyledActiveButton>
      </DataLinkagePaddingWrapper>
      {faqList?.length > 0 && (
        <DataFnqWrap>
          <Card style={{ borderBottom: 'none', padding: '4px 0' }}>
            <CardName style={{ fontWeight: 500 }}>
              데이터 연동 시 자주 찾는 질문이에요.
            </CardName>
          </Card>
          {faqList.map((item, index) => {
            return (
              <FoldedCard
                key={`FoldedCard_${index}`}
                index={index}
                isOpen={item.isOpen}
                title={item.subject}
                contents={item.contents}
                onClickDetailBtn={onClickDetailBtn}
                style={{ padding: '8px 0' }}
                blackArrow={true}
                backgroundColor={true}
              />
            );
          })}
        </DataFnqWrap>
      )}
    </>
  );
};

export default DataInterlockLinkInfo;
