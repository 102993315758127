import React from 'react';
import { TwoBtnModal, OneBtnModal } from 'components/Modal';
import RenderMall from './RenderMall';
import SelectMall from './SelectMall';
import { ScrollArea, Contents } from 'components/Modal/style';

const SelectMallModal = ({
  showSelectMallModal,
  setShowSelectMallModal,
  onClickResetMall,
  onClickSelectMall,
  checkedMallCount,
  availableMallList,
  setCheckedMallList,
  rightButtonDisabled,
  mallModalType = '',
  onClickDataLinkagePage = () => {},
}) => {
  return mallModalType === 'credit' ? (
    <OneBtnModal
      showModal={showSelectMallModal}
      onClose={() => {
        setShowSelectMallModal(false);
      }}
      onClick={onClickSelectMall}
      btnContent={`${checkedMallCount}개몰 대출 한도 조회`}
    >
      <ScrollArea>
        <Contents>
          <RenderMall
            availableMallList={availableMallList}
            setCheckedMallList={setCheckedMallList}
            onClickDataLinkagePage={onClickDataLinkagePage}
          />
        </Contents>
      </ScrollArea>
    </OneBtnModal>
  ) : (
    <TwoBtnModal
      showModal={showSelectMallModal}
      onClose={() => {
        setShowSelectMallModal(false);
      }}
      onReset={onClickResetMall}
      onClick={onClickSelectMall}
      leftButton="초기화"
      rightButton={`${checkedMallCount}개몰 대출 한도 조회`}
      rightButtonDisabled={rightButtonDisabled}
    >
      <ScrollArea>
        <Contents>
          <SelectMall
            availableMallList={availableMallList}
            setCheckedMallList={setCheckedMallList}
          />
        </Contents>
      </ScrollArea>
    </TwoBtnModal>
  );
};

export default SelectMallModal;
