import React from 'react';
import Card from '../Card';
import { EmptyContent, FileImage, Text } from '../style';

const CardMain = ({ listData, onClickDetailBtn, noDataText }) => {
  return (
    <div>
      {listData?.length > 0 ? (
        listData.map((item, index) => (
          <Card
            key={`FoldedCard_mall_${item.mallSeq}_${index}`}
            index={index}
            item={item}
            onClickDetailBtn={onClickDetailBtn}
          />
        ))
      ) : (
        <EmptyContent>
          <FileImage />
          <Text> {noDataText}</Text>
        </EmptyContent>
      )}
    </div>
  );
};

export default CardMain;
