import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import React from 'react';

const RegisterModal = ({
  showRegisterModal,
  registeredModalInfo,
  onClickSaveEmployeeData,
}) => {
  return (
    <NoBtnModal showModal={showRegisterModal} onClose={onClickSaveEmployeeData}>
      <ScrollArea>
        <Contents>
          {registeredModalInfo.managerName}님이{' '}
          {registeredModalInfo.modify ? '수정' : '등록'}
          {registeredModalInfo.cancel ? ' 취소되었습니다.' : '되었습니다.'}
        </Contents>
      </ScrollArea>
    </NoBtnModal>
  );
};

export default RegisterModal;
