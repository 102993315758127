import React from 'react';
import BasicTable from 'components/Table/BasicTable';
import { ColoredStatusSpan } from 'styles/ColoredStatusSpan';
import {
  MainSectionTwo,
  TextOne,
  TextOneSpan,
  TableRowSection,
  ImgDiv,
  Img,
  Column,
  LoanName,
  BankName,
  RowTwo,
  TextDiv,
  Button,
} from './style';

const imgHost = process.env.REACT_APP_FINANCE_IMG_HOST;

const SectionTwo = ({ onClickProductPage, creditGoodsList }) => {
  const tableLayout = item => {
    const {
      creditGoodsMasterEventName,
      creditGoodsDescription,
      financeInstitutionName,
      financeImgPath,
    } = item;

    const financeImgUrl = imgHost + financeImgPath;

    return (
      <TableRowSection>
        <ImgDiv>
          <Img src={financeImgUrl} alt={financeInstitutionName} />
        </ImgDiv>
        <Column>
          <LoanName>{creditGoodsMasterEventName}</LoanName>
          <BankName>{financeInstitutionName}</BankName>
          <RowTwo>
            <TextDiv>{creditGoodsDescription}</TextDiv>
          </RowTwo>
          <Button onClick={() => onClickProductPage()}>
            <ColoredStatusSpan>대출 알아보기</ColoredStatusSpan>
          </Button>
        </Column>
      </TableRowSection>
    );
  };

  return (
    <MainSectionTwo>
      <TextOne>
        정산예정금을 담보로
        <br />
        <TextOneSpan>대출 가능한 상품</TextOneSpan>
      </TextOne>

      <BasicTable tableLayout={tableLayout} tableData={creditGoodsList} />
    </MainSectionTwo>
  );
};

export default SectionTwo;
