import React, { useEffect } from 'react';
import {
  TextBox,
  TextSpan,
} from 'pages/MyPage/CreditProduct/CreditProductHasMall/style';
import { CheckCards } from 'pages/MyPage/CreditProduct/SelectMallModal/SelectMall/style';
import SelectManagerRow from './SelectManagerRow';

const SelectManager = ({
  bankName = '',
  mallName = '',
  managerList = [],
  setSelectedManager,
  checkStatusList,
  setCheckStatusList,
}) => {
  const onChangeRadioButton = e => {
    const { value } = e.target;

    setCheckStatusList(prev =>
      prev.map(item =>
        +value === item.businessManagerSeq
          ? { ...item, isChecked: true }
          : { ...item, isChecked: false }
      )
    );
  };

  useEffect(() => {
    if (checkStatusList) {
      !!setSelectedManager &&
        setSelectedManager(checkStatusList.find(item => item.isChecked));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkStatusList]);

  return (
    <>
      <TextBox>
        {!!bankName && (
          <>
            <TextSpan>{bankName}</TextSpan> 대출을
            <br />
            담당할 직원을 선택해주세요.
          </>
        )}
        {!!mallName && (
          <>
            <TextSpan>{mallName}</TextSpan> 데이터 연동 결과를
            <br />
            안내받을 담당 직원을 선택해주세요.
          </>
        )}
      </TextBox>

      <CheckCards>
        {managerList &&
          managerList.length > 0 &&
          managerList.map((manager, index) => {
            const {
              businessManagerSeq,
              managerName,
              mobilePhone,
              department,
              position,
            } = manager;

            return (
              <SelectManagerRow
                key={`managerList_${businessManagerSeq}_${index}`}
                businessManagerSeq={businessManagerSeq}
                managerName={managerName}
                mobilePhone={mobilePhone}
                department={department}
                position={position}
                checkStatusList={checkStatusList}
                onChangeRadioButton={onChangeRadioButton}
              />
            );
          })}
      </CheckCards>
    </>
  );
};

export default SelectManager;
