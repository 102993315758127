import React, { useEffect } from 'react';
import ROUTE_PATH from 'routes/config';
import CheckableTypeStipulation from 'components/CheckableTypeStipulation';
import TextTypeStipulation from 'components/TextTypeStipulation';

const EventStipulation = ({
  stipulationList,
  setStipulationList,
  setPostData,
}) => {
  const onClickViewBtn = stipulationSeq => {
    if (!stipulationSeq) {
      return;
    }
    window._stipulationData = stipulationList?.find(
      item => item.stipulationSeq === stipulationSeq
    );
    window.open(`${ROUTE_PATH.policyDefault}/${stipulationSeq}`, '_blank');
  };

  useEffect(() => {
    const getStipulationSeq = list => {
      setPostData(prevState => ({
        ...prevState,
        agreedEventStipulationMappingSeqList: list
          .filter(item => item.checked === true)
          .map(({ eventStipulationMappingSeq }) => eventStipulationMappingSeq),
        disagreedEventStipulationMappingSeqList: list
          .filter(item => !item.checked)
          .map(({ eventStipulationMappingSeq }) => eventStipulationMappingSeq),
      }));
    };

    if (stipulationList.length > 0) {
      getStipulationSeq(stipulationList);
      return;
    }
  }, [stipulationList]);

  const isCheckableTypeStipulation =
    stipulationList[0]?.stipulationTypeCd === 'STC100';

  return (
    <div>
      {isCheckableTypeStipulation ? (
        <CheckableTypeStipulation
          stipulationList={stipulationList}
          setStipulationList={setStipulationList}
          onClickViewBtn={onClickViewBtn}
          stipulationSeq="eventStipulationMappingSeq"
        />
      ) : (
        <TextTypeStipulation
          stipulationList={stipulationList}
          setStipulationList={setStipulationList}
        />
      )}
    </div>
  );
};

export default EventStipulation;
