import React from 'react';
import SelectBox from 'components/SelectBox';
import { Header, Filter, TotalCount } from '../style';
import { TextBold } from '../../style';
import { SelectWrapper } from './style';

const CardHeader = ({
  mallCount,
  mallListInFilter,
  dataProviderListInFilter,
  mallSeq,
  dataProviderSeq,
  onClickSelect,
  onChangeSelect,
}) => {
  return (
    <Header>
      <TotalCount style={{ minWidth: '40px' }}>
        총<TextBold style={{ marginLeft: '2px' }}>{mallCount}</TextBold>개
      </TotalCount>
      <Filter>
        {dataProviderListInFilter?.length > 2 && (
          <SelectWrapper width="140px">
            <SelectBox
              onClickSelect={onClickSelect}
              optionHandleChange={onChangeSelect}
              optionList={dataProviderListInFilter}
              name="dataProvider"
              value={dataProviderSeq}
              isNotDisabled={true}
              noPadding={true}
              $gray
              style={{
                padding: '0 22px 0 0',
                fontSize: '1.3rem',
              }}
            />
          </SelectWrapper>
        )}
        {mallListInFilter?.length > 0 && (
          <SelectWrapper width="85px">
            <SelectBox
              onClickSelect={onClickSelect}
              optionHandleChange={onChangeSelect}
              optionList={mallListInFilter}
              name="mall"
              value={mallSeq}
              isNotDisabled={true}
              noPadding={true}
              $gray
              style={{
                padding: '0 22px 0 3px',
                fontSize: '1.3rem',
              }}
            />
          </SelectWrapper>
        )}
      </Filter>
    </Header>
  );
};

export default CardHeader;
