export const tooltipMessages = {
  IRC100: <></>,
  IRC200: (
    <>
      <p>대출 한도 금액이 해당 대출 상품의 신청 가능 한도 금액보다 낮습니다.</p>
      <br />
      <ul>
        <li>
          대출 한도 금액은 연동된 판매몰의 정산예정금을 기준으로 계산됩니다.
        </li>
        <li>
          연동 가능한 판매몰이 더 있으신 경우, 데이터를 연동해 주세요. 연동하는
          데이터가 많을수록 대출 한도가 상향될 수 있습니다.
        </li>
      </ul>
    </>
  ),
  IRC300: (
    <>
      <p>본 상품은 대상 판매몰의 셀러 전용 상품 입니다.</p>
      <br />
      <ul>
        <li>대상 판매몰을 확인 후 해당 판매몰을 연동해 주세요.</li>
      </ul>
    </>
  ),
  IRC400: (
    <>
      <p>대출 한도가 확인되지 않습니다.</p>
      <br />
      <ul>
        <li>
          금융기관과 통신이 원활하지 않거나 한도 심사 시간이 아닐 수 있습니다.
          잠시 후 다시 시도해 주세요.
        </li>
      </ul>
    </>
  ),
};
