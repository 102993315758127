import styled, { css } from 'styled-components';
import theme from 'styles/theme';

const StyledLinkButton = styled.button`
  display: block;
  width: 100%;
  height: 45px;
  margin: 23px 0 0;

  line-height: 4.3rem;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;

  border: 1px solid ${theme.borderColor.blue};
  border-radius: 10px;
  background: ${theme.backgroundColor.white};
  color: ${theme.color.blue};
  cursor: pointer;

  ${props => {
    return css`
      ${getCssStr(props)}
    `;
  }}
`;

export default StyledLinkButton;

const getCssStr = props => {
  let cssStr = '';
  if (props.$small) {
    if (props.height) {
      cssStr += `width: 150px; height: ${props.height}; margin: 0; border-radius: 50px; line-height: ${props.height}; font-size: 1.5rem; font-weight: 500;`;
    } else {
      cssStr += `width: 150px; height: 40px; margin: 0; border-radius: 50px; line-height: 3.8rem; font-size: 1.5rem; font-weight: 500; `;
    }
  }
  if (props.width) {
    cssStr += `width: ${props.width}`;
  }
  if (props.height) {
    cssStr += `height: ${props.height}`;
  }
  if (props.margin) {
    cssStr += `margin: ${props.margin}`;
  }
  if (props.halfButton) {
    cssStr += `margin-left: 8px;`;
  }
  if (props.round) {
    cssStr += `border-radius: 20px`;
  }
  if (props.$grey) {
    cssStr += `background: ${theme.backgroundColor.contentBox}; color: ${theme.color.black};`;
  }
  return cssStr;
};
