import styled from 'styled-components';
import theme from 'styles/theme';

export const TextBox = styled.div`
  display: block;
  width: 100%;
  padding: 0;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: ${theme.color.black};
`;

export const TextSpan = styled.span`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${theme.color.blue};
`;

export const SubTextBox = styled.div`
  display: block;
  width: 100%;
  padding: 28px 0 30px;
  line-height: 2.1rem;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  color: ${theme.color.black};
`;
