import React from 'react';
import { modifyDataRequest } from 'utils/request';
import { TwoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';

// 데이터 연동 중지, 중지 해지 모달
const StopLinkageModal = ({
  status,
  modifiedDataInterlockInfo,
  isStopLinkageModalOpen,
  setIsStopLinkageModalOpen,
  setModalMessage,
  setIsOneBtnModalOpen,
  setIsMessageModalOpen,
  setModifiedDataInterlockInfo,
}) => {
  const rightButtonText =
    status === 'STOP' ? '데이터 연동 중지 해지하기' : '데이터 연동 중지하기';
  let stopLinkageText = '';
  let url = '';

  if (status === 'STOP') {
    stopLinkageText = `${modifiedDataInterlockInfo.mallName}의 데이터 연동 중지를 해지 하시겠어요?\n해지시 해당 판매몰의 정산예정금을 활용한 대출이 가능합니다.`;
    url = `/v1/au/data-provider/interlock/${modifiedDataInterlockInfo.businessDataProviderMallMappingSeq}/unlock`;
  } else {
    stopLinkageText = `${modifiedDataInterlockInfo.mallName}의 데이터 연동 중지를 하시겠어요?\n데이터 연동을 중지한 경우 해당 판매몰의 정산예정금을 활용한 대출은 불가해요.`;
    url = `/v1/au/data-provider/interlock/${modifiedDataInterlockInfo.businessDataProviderMallMappingSeq}/stop`;
  }

  const onClick = () => {
    setIsStopLinkageModalOpen(false);
    const successFnc = () => {
      if (status === 'STOP') {
        setModifiedDataInterlockInfo(prevState => ({
          ...prevState,
          mallInterlockStatus: 'WAIT',
        }));
      } else {
        setModifiedDataInterlockInfo(prevState => ({
          ...prevState,
          mallInterlockStatus: 'STOP',
        }));
      }
    };
    modifyDataRequest({
      url: url,
      setModalMessage: setModalMessage,
      setShowModal: setIsOneBtnModalOpen,
      setShowAlertModal: setIsMessageModalOpen,
      successFnc: successFnc,
    });
  };

  return (
    <div>
      <TwoBtnModal
        showModal={isStopLinkageModalOpen}
        onClose={() => setIsStopLinkageModalOpen(false)}
        onReset={() => setIsStopLinkageModalOpen(false)}
        onClick={onClick}
        leftButton="닫기"
        rightButton={rightButtonText}
      >
        <ScrollArea>
          <Contents>{stopLinkageText}</Contents>
        </ScrollArea>
      </TwoBtnModal>
    </div>
  );
};
export default StopLinkageModal;
