import styled from 'styled-components';
import theme from 'styles/theme';

export const LoanDetailFooterWrapper = styled.div`
  padding: 20px;
  width: 100%;
  min-height: 400px;
  min-width: ${theme.layout.minWidth};
  max-width: ${theme.layout.maxWidth};
  background: ${theme.backgroundColor.footerBox};
`;

export const AreaBox = styled.div`
  border-radius: 10px;
  margin-bottom: 20px;
`;

export const List = styled.div`
  display: flex;
`;
export const ListIcon = styled.div`
  width: 6px;
  height: 6px;
  margin: 10px 10px 0px 20px;
  border-radius: 3px;
  background: ${theme.backgroundColor.greyA};
`;

export const ListText = styled.div`
  width: calc(100% - 16px);
  text-align: left;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.4rem;
  color: ${theme.color.grey5};
`;

export const Label = styled.div`
  display: block;
  line-height: 3.4rem;
  padding: 0 0 0 10px;
  font-size: 1.4rem;
  font-weight: 800;
  color: ${theme.color.grey5};
  text-align: left;
`;
