import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import GuideBox from '../GuideBox';
import {
  InputDiv,
  InputNormal,
  InputMessage,
  InputLabel,
  EyeIcon,
} from './style';

/* 주의: TextField에 style로 padding이나 margin 넣을시 EyeIcon 위치에 영향이 갈 수 있습니다. */

const TextField = ({
  name,
  value,
  type = 'text',
  title,
  placeholder,
  readOnly = false,
  required = false,
  uppercase = false,
  maxLength,
  onChange = () => {},
  onKeyDown = () => {},
  onFocus = () => {},
  onBlur = () => {},
  // showMessage = false,
  guideMessage = '',
  message = '',
  count = false,
  style,
  step,
}) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const toggleEyeIcon = e => {
    setIsPasswordShown(!isPasswordShown);
  };

  return (
    <InputDiv>
      <InputNormal
        name={name}
        value={value}
        type={isPasswordShown ? 'text' : type}
        title={title}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        uppercase={uppercase}
        maxLength={maxLength}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        showMessage={!!message}
        style={style}
        step={step}
      />
      {!!count && <InputLabel>{count}</InputLabel>}
      {!!message && <InputMessage>{message}</InputMessage>}
      {!!guideMessage && <GuideBox guideMessage={guideMessage} />}
      {!!value && type === 'password' && (
        <EyeIcon onClick={toggleEyeIcon}>
          <FeatherIcon
            icon={isPasswordShown ? 'eye' : 'eye-off'}
            stroke="#aaa"
            strokeWidth={3}
            size={16}
          />
        </EyeIcon>
      )}
    </InputDiv>
  );
};

export default TextField;
