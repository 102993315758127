import React from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import PhoneService from './PhoneService';
import KaKaoService from './KaKaoService';
import OneToOneService from './OneToOneService';
import { customerService } from 'messages/contactInfo';
import { AreaRow, AreaBox1 } from 'styles/styleArea';
import {
  Footer,
  CSFooterWrapper,
  CSTitle,
  Label,
  UnderlinedText,
} from './style';

const CustomerServiceFooter = ({
  hasPhone = true,
  hasKaKao = false,
  hasOneToOne = false,
  hasInfo = true,
}) => {
  const navigate = useNavigate();
  const { serviceCenter } = ROUTE_PATH;
  const { phoneContact, businessHour, lunchHour } = customerService;

  const onClickQnA = event => {
    navigate(serviceCenter.main + '?tab=QnA');
  };

  return (
    <Footer>
      <CSFooterWrapper>
        <AreaRow>
          <AreaBox1>
            <CSTitle>상담원 도움이 필요하신가요?</CSTitle>
          </AreaBox1>
        </AreaRow>

        <AreaRow>
          {hasPhone && <PhoneService />}
          {hasKaKao && <KaKaoService />}
          {hasOneToOne && <OneToOneService onClick={onClickQnA} />}
        </AreaRow>

        {hasInfo && (
          <AreaRow>
            <AreaBox1>
              <Label>고객센터 : {phoneContact}</Label>
              <Label>운영시간 : {businessHour}</Label>
              <Label>점심시간 : {lunchHour}</Label>
              <Label>점심 시간, 주말 및 공휴일은 상담이 어렵습니다.</Label>
              <Label>
                <UnderlinedText onClick={onClickQnA}>1:1문의</UnderlinedText>{' '}
                남겨주세요.
              </Label>
            </AreaBox1>
          </AreaRow>
        )}
      </CSFooterWrapper>
    </Footer>
  );
};

export default CustomerServiceFooter;
