import React from 'react';
import TextField from 'components/TextField';

const DataProviderLinkInfo = ({
  dataProviderInfo,
  setDataProviderInfo,
  idPlaceholder = '아이디',
  pwPlaceholder = '비밀번호',
}) => {
  const onChangeTextField = event => {
    const { name, value } = event.target;
    setDataProviderInfo(prev => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <TextField
        name="dataProviderId"
        placeholder={idPlaceholder}
        value={dataProviderInfo.dataProviderId}
        onChange={onChangeTextField}
        required
      />
      <TextField
        type="password"
        name="dataProviderPassword"
        placeholder={pwPlaceholder}
        value={dataProviderInfo.dataProviderPassword}
        onChange={onChangeTextField}
        required
      />
    </>
  );
};

export default DataProviderLinkInfo;
