export const MEMBER_WITHDRAWAL_REASON_TITLE = '회원 탈퇴 사유를 선택해주세요.';

export const MEMBER_WITHDRAWAL_NOTICE_DATA = {
  title: '회원 탈퇴 전 유의사항을 확인해주세요.',
  content:
    '회원 탈퇴 시 회원 정보와 서비스 이용 기록은 모두 삭제되며 삭제된 데이터는 복구할 수 없습니다.\n다만 법령 또는 회사 내부 정책에 따라 보관해야 하는 정보는 탈퇴 후에도 일정 기간 보관됩니다.\n자세한 사항은 개인정보 처리 방침에서 확인하실 수 있습니다.\n회원 탈퇴 후 동일 사업자 번호로 재가입 하더라도 탈퇴 전 회원정보와 서비스 이용 기록은 복구되지 않습니다.',
  checkMessage: '탈퇴 유의사항을 확인하였으며 탈퇴에 동의합니다.',
};

export const ETC_REASON_TEXT = '기타 탈퇴 사유를 입력해 주세요.(30자 이내)';

export const MEMBER_WITHDRAWAL_SUCCESS_MESSAGE =
  '탈퇴가 완료되었습니다.\n셀러크레딧커넥트를 이용해주셔서 감사합니다.';
