import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { getListDataRequest, getDataRequest } from 'utils/request';
import useApp from 'hooks/useApp';
import { NoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import MallSection from './MallSection';
import FAQSection from './FAQSection';
import { CONTENTS_TYPE_CD, EXPOSURE_AREA_CD } from './constants';
const { MALL_LINKAGE_GUIDE, MAIN_CONTENTS } = CONTENTS_TYPE_CD;
const { EXTRA_LINK, MIDDLE_AREA, BIG_BANNER } = EXPOSURE_AREA_CD;

const Main = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticated } = useApp().auth;
  const [creditGoodsList, setCreditGoodsList] = useState([]);
  const [uiContents, setUiContents] = useState({});
  const middleAreaContents = uiContents.middleArea?.find(
    data => data.exposureAreaCd === 'EAC200'
  )?.contents;
  const [isMemberCheckModalOpen, setIsMemberCheckModalOpen] = useState(false);

  useEffect(() => {
    getCreditGoodsRequest();
    getMallLinkageInfoContentsRequest();
  }, []);

  useEffect(() => {
    if (!!location.state) {
      setIsMemberCheckModalOpen(true);
    }
  }, [location.state]);

  const getMallLinkageInfoContentsRequest = () => {
    getDataRequest({
      url: '/v1/na/common/ui-contents/list',
      params: {
        contentsTypeCd: MAIN_CONTENTS,
      },
      setData: setUiContents,
    });
  };

  const getCreditGoodsRequest = () => {
    getListDataRequest({
      url: '/v1/na/credit-goods',
      setData: setCreditGoodsList,
    });
  };

  const onClickProductPage = () => {
    navigate(ROUTE_PATH.mypage.checkLoan);
  };

  const onClickJoinPage = () => {
    !!authenticated
      ? setIsMemberCheckModalOpen(true)
      : navigate(ROUTE_PATH.login.joinMember);
  };

  const onClickDataLinkagePage = () => {
    navigate(ROUTE_PATH.mypage.dataLinkageManage);
  };

  const onClickCheckLoanPage = () => {
    navigate(ROUTE_PATH.mypage.checkLoan);
  };

  const renderNoBtnModal = () => {
    return (
      <NoBtnModal
        showModal={isMemberCheckModalOpen}
        onClose={() => setIsMemberCheckModalOpen(false)}
      >
        <ScrollArea>
          <Contents>이미 셀러 크레딧커넥트 회원이예요.</Contents>
        </ScrollArea>
      </NoBtnModal>
    );
  };

  return (
    <div>
      {uiContents.bigBanner?.length > 0 && (
        <SectionOne
          onClickProductPage={onClickProductPage}
          bigBannerContents={uiContents.bigBanner}
        />
      )}
      {creditGoodsList?.length > 0 && (
        <SectionTwo
          onClickProductPage={onClickProductPage}
          creditGoodsList={creditGoodsList}
        />
      )}
      {!!middleAreaContents && (
        <MallSection middleAreaContents={middleAreaContents} />
      )}
      <FAQSection
        onClickJoinPage={onClickJoinPage}
        onClickDataLinkagePage={onClickDataLinkagePage}
        onClickCheckLoanPage={onClickCheckLoanPage}
      />
      {renderNoBtnModal()}
    </div>
  );
};

export default Main;
