import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'utils/api';
import ROUTE_PATH from 'routes/config';
import useApp from 'hooks/useApp';
import { encodeToBase64String } from 'tools/StringTool';
import { createMarkup, KEY_DATAPROVIDEAGREESEQ } from 'tools/WebTool';
import {
  createDataRequest,
  getDataRequest,
  modifyDataRequest,
} from 'utils/request';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import CheckBox from 'components/CheckBox';
import { TwoBtnModal, NoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import {
  TextDiv2,
  JoinAgreeAll,
  TextTitle,
  WithdrawalReasonSection,
  ReasonLabel,
  RadioIconSpan,
  RadioInput,
  TextArea,
  WithdrawalNoticeSection,
  ReasonTitle,
  ReasonExplain,
  ReasonBox,
  NoticeContainer,
  NoticeTitle,
  NoticeContents,
  BulletSpan,
} from './style';
import {
  MEMBER_WITHDRAWAL_NOTICE_DATA,
  MEMBER_WITHDRAWAL_SUCCESS_MESSAGE,
  ETC_REASON_TEXT,
  MEMBER_WITHDRAWAL_REASON_TITLE,
} from './constants';

const MemberWithdrawalPage = () => {
  const navigate = useNavigate();
  const { auth } = useApp();
  const [memberWithdrawalReasonList, setMemberWithdrawalReasonList] = useState(
    []
  );

  const [reasonCode, setReasonCode] = useState('');
  const [etcReasonText, setEtcReasonText] = useState('');
  const [isNoticeChecked, setIsNoticeChecked] = useState(false);
  const [noBtnData, setNoBtnModalData] = useState({
    isOpen: false,
    message: '',
  });
  const [twoBtnModalData, setTwoBtnModalData] = useState({
    isOpen: false,
    message: '',
    rightButton: '',
    leftButton: '',
    rightBtnFnc: () => {},
  });
  const buttonCondition = isNoticeChecked && reasonCode;
  const isEtcReason = reasonCode === 'WRC500';

  useEffect(() => {
    getSysCodesRequest();
  }, []);

  const onClickWithdrawalBtn = () => {
    // 기타 사유 - 필수 조건 검증
    if (isEtcReason && !etcReasonText) {
      setNoBtnModalData({
        isOpen: true,
        message: '기타 사유를 입력해 주세요.',
      });
    } else {
      getCreditRequestCheckRequest();
    }
  };

  const onChangeRadioInput = event => {
    if (event.target.value !== 'WRC500') {
      setEtcReasonText('');
    }
    setReasonCode(event.target.value);
  };

  const onChangeTextArea = event => {
    if (event.target.value?.length > 30) {
      return;
    }
    setEtcReasonText(event.target.value);
  };

  // 시스템 코드 - 회원 탈퇴 사유 리스트 조회
  const getSysCodesRequest = () => {
    const successFnc = data =>
      setMemberWithdrawalReasonList(
        data?.codes?.WRC?.filter(codeData => codeData?.useYn === 'Y')
      );
    getDataRequest({
      url: '/v1/na/sys/codes',
      params: { codes: 'WRC' },
      successFnc: successFnc,
    });
  };

  // 회원 탈퇴 가능 여부 확인
  const getCreditRequestCheckRequest = () => {
    api({
      url: '/v1/au/member/creditRequest/check',
      method: 'get',
      headers: { 'content-type': 'application/json' },
    })
      .then(res => {
        const { success, data = {}, message, 'error-no': errorNo } = res.data;
        // 회원 탈퇴가 즉시 가능한 상태
        if (success) {
          setTwoBtnModalData({
            message: '셀러 크레딧커넥트 회원을 탈퇴하시겠어요?',
            rightButton: '예',
            leftButton: '아니오',
            rightBtnFnc: deleteMemberRequest,
            isOpen: true,
          });
        } else {
          // 진행 중인 데이터 제공건이 있음 (100)
          if (errorNo === 100) {
            setTwoBtnModalData({
              message: message,
              rightBtnFnc: () => {
                goToDeeplinkAgreePage(data);
              },
              rightButton: '데이터 제공 거절하기',
              leftButton: '닫기',
              isOpen: true,
            });
          } else if (errorNo === 200) {
            // 데이터이용 (200) - 판매몰 연동 x : 데이터 제공 철회 api 이용
            setTwoBtnModalData({
              message: message,
              rightBtnFnc: modifyDataProvideAgreeWithdrawalRequest,
              rightButton: '데이터 제공 철회하기',
              leftButton: '닫기',
              isOpen: true,
            });
            // 데이터이용 (300) - 판매몰 연동건 존재 : 데이터 및 대출 중지 요청 모달 (이용중인 데이터 n건)
          } else if (errorNo === 300) {
            setTwoBtnModalData({
              message: message,
              rightBtnFnc: () => {
                createStopDataRequest(data);
              },
              rightButton: '데이터 및 대출 중지 요청',
              leftButton: '닫기',
              isOpen: true,
              // 다른 케이스가 생길 수 있기에 type을 추가하여 구분함
              addContentsData: { ...data, type: 'stopRequest' },
            });
          } else if (errorNo === 400) {
            // 대출건 - 신청 완료 상태 (400)
            setTwoBtnModalData({
              message: message,
              rightBtnFnc: () => {
                goToLoanManagePage(data?.requestCreditRequest);
              },
              rightButton: '대출신청 취소하기',
              leftButton: '닫기',
              isOpen: true,
            });
          } else if (errorNo === 500) {
            // 대표자 직원 유무 (500)
            setTwoBtnModalData({
              message: message,
              rightBtnFnc: () => {
                navigate(ROUTE_PATH.mypage.manager);
              },
              rightButton: '직원관리 화면가기',
              leftButton: '아니오',
              isOpen: true,
            });
          }
        }
      })
      .catch(err => {
        console.error(err);
        const { data } = err.response;
        setNoBtnModalData({
          message: data.message,
          isOpen: true,
        });
      });
  };

  // 회원 탈퇴하기
  const deleteMemberRequest = () => {
    api({
      url: '/v1/au/member',
      method: 'delete',
      params: {
        withdrawReasonCd: reasonCode,
        withdrawReason: encodeToBase64String(etcReasonText),
      },
      headers: { 'content-type': 'application/json' },
    })
      .then(res => {
        const { success, message } = res.data;
        if (success) {
          setTwoBtnModalData({ ...twoBtnModalData, isOpen: false });
          setNoBtnModalData({
            isOpen: true,
            message: MEMBER_WITHDRAWAL_SUCCESS_MESSAGE,
          });
          setTimeout(() => {
            auth?.logout();
            navigate('/', {
              state: message,
            });
          }, 2000);
        }
      })
      .catch(err => {
        console.error(err);
        const { data } = err.response;
        setTwoBtnModalData({ ...twoBtnModalData, isOpen: false });
        setNoBtnModalData({
          isOpen: true,
          message: data.message,
        });
      });
  };

  // 데이터 제공 동의 페이지로 이동 (error-no: 100)
  const goToDeeplinkAgreePage = data => {
    // '데이터 제공 동의' 상단 배너 클릭 프로세스와 동일
    if (!!data?.dataProvideAgreeSeq) {
      localStorage.setItem(KEY_DATAPROVIDEAGREESEQ, data?.dataProvideAgreeSeq);
      navigate(ROUTE_PATH.deepLink.agree);
    } else {
      setNoBtnModalData({
        isOpen: true,
        message:
          '데이터 제공 동의 일련 번호가 없습니다. \n고객센터로 문의해주세요.',
      });
    }
  };

  // 데이터 제공 철회하기 (error-no: 200)
  const modifyDataProvideAgreeWithdrawalRequest = () => {
    modifyDataRequest({
      url: `/v1/au/member/dataProviderAgree/withdrawal`,
      setModalMessage: message => {
        setNoBtnModalData({
          isOpen: !!message,
          message: message,
        });
        setTwoBtnModalData({
          isOpen: false,
          message: '',
          rightButton: '',
          rightBtnFnc: () => {},
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
    });
  };

  // 데이터 및 대출 중지 요청하기 (error-no: 300)
  const createStopDataRequest = totalData => {
    const rawData = {
      dataUseSeqList: totalData?.usingDataInfo?.map(data => data?.dataUseSeq),
      confirmCreditRequestSeqList: totalData?.creditRequestConfirmInfo?.map(
        data => data?.creditRequestSeq
      ),
      approveCreditRequestList: totalData?.creditRequestApproveInfo?.map(
        data => data?.dataUseSeq
      ),
    };

    // 값이 없는 인자를 제외함
    const exceptEmptyParam = (params = {}) => {
      let result = {};
      const keyList = Object.keys(params);
      keyList.forEach(key => {
        if (!params[key] || params[key]?.length === 0) {
          return;
        }
        result[key] = params[key];
      });
      return result;
    };

    createDataRequest({
      url: 'v1/au/member/data-stop-request',
      data: {
        businessSeq: totalData?.businessSeq,
        ...exceptEmptyParam(rawData),
      },
      setModalMessage: message => {
        setTwoBtnModalData({
          isOpen: false,
          message: '',
          rightButton: '',
          rightBtnFnc: () => {},
        });
        setNoBtnModalData({
          isOpen: !!message,
          message: message,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
    });
  };

  // 대출 관리 페이지로 이동 (error-no: 400)
  const goToLoanManagePage = requestCreditRequest => {
    if (requestCreditRequest.length === 1) {
      // 상세 모달도 띄우기
      navigate(ROUTE_PATH.mypage.manageLoan, {
        state: {
          parentPage: 'memberWithdrawalPage',
          creditRequestSeq: requestCreditRequest[0],
        },
      });
    } else {
      navigate(ROUTE_PATH.mypage.manageLoan);
    }
  };

  return (
    <div>
      {/* 회원 탈퇴 사유 선택 */}
      <TextTitle>{MEMBER_WITHDRAWAL_REASON_TITLE}</TextTitle>
      <WithdrawalReasonSection>
        {memberWithdrawalReasonList.map(withdrawalReason => {
          return (
            <ReasonBox>
              <ReasonLabel className="check-card">
                <RadioInput
                  type="radio"
                  className="check-card"
                  value={withdrawalReason.code}
                  checked={withdrawalReason.code === reasonCode}
                  onChange={onChangeRadioInput}
                />
                <RadioIconSpan className="notice-check-icon" />
                <ReasonTitle>{withdrawalReason.codeName}</ReasonTitle>
                <ReasonExplain>{withdrawalReason.description}</ReasonExplain>
              </ReasonLabel>
            </ReasonBox>
          );
        })}
        <TextArea
          placeholder={ETC_REASON_TEXT}
          onChange={onChangeTextArea}
          disabled={!isEtcReason}
          value={etcReasonText}
          maxLength={30}
        />
      </WithdrawalReasonSection>
      {/* 회원 탈퇴 안내문 */}
      <TextTitle>{MEMBER_WITHDRAWAL_NOTICE_DATA.title}</TextTitle>
      <WithdrawalNoticeSection>
        <TextDiv2>{MEMBER_WITHDRAWAL_NOTICE_DATA.content}</TextDiv2>
        {/* 회원 탈퇴 안내문 확인 체크박스 */}
        <JoinAgreeAll>
          <CheckBox
            id="check-box-0"
            isChecked={isNoticeChecked}
            onChangeCheckBox={event => setIsNoticeChecked(event.target.checked)}
            value="secondAuth"
            text={MEMBER_WITHDRAWAL_NOTICE_DATA.checkMessage}
            style={{ fontSize: '1.5rem' }}
          />
        </JoinAgreeAll>
      </WithdrawalNoticeSection>
      {/* 회원 탈퇴 버튼 */}
      <StyledActiveButton
        tabIndex={0}
        onClick={onClickWithdrawalBtn}
        disabled={!buttonCondition}
      >
        회원 탈퇴
      </StyledActiveButton>
      {/* 버튼 포함 안내 모달 */}
      <TwoBtnModal
        showModal={twoBtnModalData.isOpen}
        onClose={() => {
          setTwoBtnModalData({ ...twoBtnModalData, isOpen: false });
        }}
        onClick={twoBtnModalData.rightBtnFnc}
        leftButton={twoBtnModalData.leftButton}
        rightButton={twoBtnModalData.rightButton}
      >
        <ScrollArea>
          <Contents
            dangerouslySetInnerHTML={createMarkup(twoBtnModalData.message)}
          ></Contents>
          {!!twoBtnModalData.addContentsData &&
            getSubContents(twoBtnModalData.addContentsData)}
        </ScrollArea>
      </TwoBtnModal>
      {/* 단순 안내 모달 */}
      <NoBtnModal
        showModal={noBtnData.isOpen}
        onClose={() => {
          setNoBtnModalData({ ...noBtnData, isOpen: false });
        }}
      >
        <ScrollArea>
          <Contents>{noBtnData.message}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </div>
  );
};

const getSubContents = (addContentsData = {}) => {
  switch (addContentsData.type) {
    case 'stopRequest':
      const {
        usingDataInfo,
        usingDataCount,
        creditRequestConfirmInfo,
        creditRequestConfirmCount,
        creditRequestApproveInfo,
        creditRequestApproveCount,
      } = addContentsData;

      const StopRequestNotice = ({ title = '', contentsList = [] }) => (
        <NoticeContainer>
          <NoticeTitle>{title}</NoticeTitle>
          {contentsList?.map((contents, index) => {
            return (
              (contents?.dataUseSeq || contents?.creditRequestSeq) && (
                <NoticeContents
                  key={`${
                    contents?.dataUseSeq || contents?.creditRequestSeq
                  }_${index}`}
                >
                  <BulletSpan>•</BulletSpan>
                  {`${contents?.financeName} | ${contents?.creditGoodsName}`}
                </NoticeContents>
              )
            );
          })}
        </NoticeContainer>
      );

      return (
        <>
          {usingDataCount > 0 && (
            <StopRequestNotice
              title={`이용중인 데이터 ${usingDataCount}건`}
              contentsList={usingDataInfo}
            />
          )}
          {creditRequestConfirmCount > 0 && (
            <StopRequestNotice
              title={`확인중인 대출 ${creditRequestConfirmCount}건`}
              contentsList={creditRequestConfirmInfo}
            />
          )}
          {creditRequestApproveCount > 0 && (
            <StopRequestNotice
              title={`이용중인 대출 ${creditRequestApproveCount}건`}
              contentsList={creditRequestApproveInfo}
            />
          )}
        </>
      );
  }
};

export default MemberWithdrawalPage;
