import React, { useState, useEffect } from 'react';
import useApp from 'hooks/useApp';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { getDataRequest } from 'utils/request';
import JoinMemberPolicyPopupDetail from './JoinMemberPolicyPopupDetail';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import { TabSection, TabButton, JoinPolicyWrapper } from './style';

const JoinMemberPolicyPopupPage = () => {
  const params = useParams();
  const { auth } = useApp();
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState('');
  //약관 탭 리스트
  const [stipulationList, setStipulationList] = useState([]);
  //active 탭 seq
  const [activeTabSeq, setActiveTabSeq] = useState(0);
  //약관 seq
  const [stipulationSeq, setStipulationSeq] = useState(0);

  useEffect(() => {
    if (params.seq) {
      setStipulationSeq(parseInt(params.seq || 0, 10));
      if (activeTabSeq === 0) {
        setActiveTabSeq(parseInt(params.seq || 0, 10));
      }
    } else {
      navigate('/');
    }
  }, [params.seq]);

  useEffect(() => {
    getDataRequest({
      url: `/v1/na/stipulation?stipulationClassificationCd=SCC100`,
      successFnc: data => {
        setStipulationList(data.result);
      },
      errorFnc: (data, message, errorNo) => {
        setAlertMessage(message);
      },
      setModalMessage: setAlertMessage,
    });
  }, []);

  useEffect(() => {
    const sortedStipulationList = stipulationList.sort(
      (a, b) => a.stipulationOrder - b.stipulationOrder
    );
    // 초기에 디폴트 stipulationSeq 목록 내 첫번째꺼로 설정
    if (stipulationList.length > 0 && stipulationSeq === 0) {
      setStipulationSeq(sortedStipulationList[0].stipulationSeq);
    }
  }, [stipulationList, stipulationSeq]);

  const onClickTab = stipulationSeq => {
    setActiveTabSeq(stipulationSeq);
    navigate(`${ROUTE_PATH.login.joinMemberPolicyDefault}/${stipulationSeq}`);
  };

  return (
    <JoinPolicyWrapper showBanner={auth.bannerMessage}>
      <TabSection>
        {stipulationList &&
          stipulationList.map(item => {
            return (
              <TabButton
                key={`JoinPolicy_${item.stipulationSeq}`}
                onClick={() => onClickTab(item.stipulationSeq)}
                active={+activeTabSeq === +item.stipulationSeq}
              >
                {item.stipulationCdName}
              </TabButton>
            );
          })}
      </TabSection>
      {stipulationSeq !== 0 && (
        <JoinMemberPolicyPopupDetail stipulationSeq={stipulationSeq} />
      )}

      <NoBtnModal showModal={!!alertMessage} onClose={() => window.close()}>
        <ScrollArea>
          <Contents>{alertMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </JoinPolicyWrapper>
  );
};

export default JoinMemberPolicyPopupPage;
