import React from 'react';
import { ToggleWrap, ToggleTitle, ToggleInput, ToggleLabel } from './style';

const Toggle = ({
  toggleTitle,
  toggleName,
  toggleChecked,
  onChangeToggle,
  style,
}) => {
  return toggleTitle ? (
    <ToggleWrap>
      <ToggleTitle>{toggleTitle}</ToggleTitle>
      <ToggleInput
        type="checkbox"
        className="input-toggle"
        id={`toggled002-${toggleName}`}
        title={toggleTitle}
        name={toggleName}
        checked={toggleChecked}
        onChange={onChangeToggle}
      />
      <ToggleLabel
        tabIndex={0}
        htmlFor={`toggled002-${toggleName}`}
        className="label-toggle"
        checked={toggleChecked}
      />
    </ToggleWrap>
  ) : (
    <>
      <ToggleInput
        type="checkbox"
        className="input-toggle"
        id={`toggled002-${toggleName}`}
        title={toggleTitle}
        name={toggleName}
        checked={toggleChecked}
        onChange={onChangeToggle}
      />
      <ToggleLabel
        style={style}
        tabIndex={0}
        htmlFor={`toggled002-${toggleName}`}
        className="label-toggle"
        checked={toggleChecked}
      />
    </>
  );
};

export default Toggle;
