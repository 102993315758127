import styled from 'styled-components';
import theme from 'styles/theme';

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #d0d0d0;
  margin: 20px 0 23px;
`;

export const LoginBottomSection = styled.div`
  display: block;
  width: 100%;
`;

export const LoginBottomBtn = styled.button`
  display: block;
  width: calc(100% / 3);
  float: left;
  text-align: center;
  position: relative;
  height: 30px;
  line-height: 30px;
  color: #111;
  font-size: 13px;
  font-weight: 500;

  &::before {
    content: '';
    display: ${props => (props.borderLeft ? 'block' : 'none')};
    position: absolute;
    width: 1px;
    height: 18px;
    background: #d9d9d9;
    left: 0;
    top: 6px;
  }
`;

export const RedSpan = styled.span`
  color: ${theme.color.red};
`;
