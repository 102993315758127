import React from 'react';
import { Table, TableBody, TableRow, TableData } from './style';

const BasicTable = ({ tableLayout, tableData }) => {
  return (
    <Table>
      <TableBody>
        {tableData &&
          tableData.map((item, index) => {
            return (
              <TableRow key={`TableRow_${index}`}>
                <TableData key={`TableData_${index}`}>
                  {tableLayout(item)}
                </TableData>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default BasicTable;
