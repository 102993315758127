import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import Marker from 'components/Marker';
import { Nav, NavTitle, NavA, NavADesc, NavAWrap, NavALinkWrap } from './style';

const MyPageNavLink = ({
  title,
  menuList,
  borderTop,
  ShowDesc = true,
  hasNewEvent,
}) => {
  const navigate = useNavigate();

  return (
    <Nav style={{ borderTop }}>
      <NavTitle>{title}</NavTitle>
      {menuList.map(item => {
        return (
          <NavAWrap
            key={item.id}
            onClick={() => {
              navigate(item.link);
            }}
          >
            <NavALinkWrap>
              <NavA tabIndex={0} title={item.title}>
                {item.label}
                {item.label === '이벤트' && hasNewEvent ? <Marker /> : null}
              </NavA>
              <FeatherIcon
                icon="chevron-right"
                size={20}
                strokeWidth={2}
                stroke="black"
              />
            </NavALinkWrap>
            {ShowDesc && <NavADesc>{item.desc}</NavADesc>}
          </NavAWrap>
        );
      })}
    </Nav>
  );
};

export default MyPageNavLink;
