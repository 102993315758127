import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import api from 'utils/api';
import { getResultDataRequest } from 'utils/request';
import ROUTE_PATH from 'routes/config';
import { encodeToBase64String, stringifyTime } from 'tools/StringTool';
import EmailAuthModal from 'pages/ServiceCenter/EmailAuthModal';
import LoadingIndicator from 'components/LoadingIndicator';
import TextField from 'components/TextField';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import PolicyAgreeRow from 'components/PolicyAgreeRow';
import CreateQuestionNoAuthImgRow from './CreatQuestionNoAuthImgRow';
import { Line } from 'pages/DeepLink/DeepLinkAgreePage/style';
import { AGREE_LIST } from './constants';
import {
  TextMedium,
  TextareaDiv,
  TextareaField,
  InputLabel,
  ChildText,
  ChildTextContent,
  Icon,
} from './style';
import {
  ImageDiv,
  ImageField,
  ImageIcon,
  ImageInput,
  ImageTitle,
} from '../CreateQuestionAuthPage/style';

const CreateQuestionNoAuthPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [authRequestBtnStatus, setAuthRequestBtnStatus] = useState(false);
  // 약관 동의
  const [stipulationList, setStipulationList] = useState(AGREE_LIST);
  const [checkBoxList, setCheckBoxList] = useState([]);
  const [textStipulationList, setTextStipulationList] = useState([]);
  // 파일 리스트
  const [fileList, setFileList] = useState([]);
  // 이메일 '인증 완료' 버튼 params (delete에서도 사용됨)
  const [emailAuthParamsTwo, setEmailAuthParamsTwo] = useState({
    email: '',
    certificationNumber: '',
    memberCertificationSeq: 0,
    manToManInquirySeq: 0,
  });
  // 이메일 오류 메시지
  const [incorrectEmailInputMessage, setIncorrectEmailInputMessage] =
    useState('');
  // 인증 번호 오류 메시지
  const [incorrectAuthNumInputMessage, setIncorrectAuthNumInputMessage] =
    useState('');
  // alert 모달 메시지
  const [alertMessage, setAlertMessage] = useState('');
  // 이메일 '인증' 버튼 Params
  const [emailAuthParamsOne, setEmailAuthParamsOne] = useState({
    email: '',
    certificationPurposeType: '',
    manToManInquirySeq: 0,
  });
  // 이메일 인증 모달 상태
  const [openAuthModal, setOpenAuthModal] = useState(false);
  // 추후에 이메일 인증이 완료되면 seq를 받아서 모달을 닫아주고 상세 보기가 열리도록 함
  const [isClickedRequestAuthBtn, setIsClickedRequestAuthBtn] = useState(false);
  const [count, setCount] = useState(150);
  const [time, setTime] = useState('02 : 30');
  // 백엔드에 보낼 params
  const [qnaData, setQnaData] = useState({
    inquirySubject: '',
    inquiryContents: '',
  });
  // 내용 글자수 count
  const [textCount, setTextCount] = useState(0);

  useEffect(() => {
    getPolicies();
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setCount(count => count - 1);
      setTime(() => stringifyTime(count - 1));
    }, 1000);

    if (count === 0 || !isClickedRequestAuthBtn) {
      clearInterval(id);
    }
    return () => clearInterval(id);
  }, [count, isClickedRequestAuthBtn]);

  useEffect(() => {
    if (stipulationList.length > 0) {
      setCheckBoxList(
        stipulationList
          .map(item => ({
            ...item,
            required: item.essentialYn === 'Y',
            checked: false,
          }))
          .sort((a, b) => b.required - a.required) // '필수'를 앞에 정렬
      );
      setTextStipulationList(
        stipulationList.filter(item => item.stipulationTypeCd === 'STC200')
      );
    } else {
    }
  }, [stipulationList]);

  const getPolicies = () => {
    getResultDataRequest({
      url: '/v1/na/stipulation',
      params: { stipulationClassificationCd: 'SCC300' },
      setData: setStipulationList,
    });
  };

  const onChangeQnAInput = e => {
    const { name, value } = e.target;
    if (name === 'inquiryContents') {
      setTextCount(value.length);
    }
    setQnaData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  // 이메일 인증 onChange
  const onChangeAuthInput = event => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmailAuthParamsOne(prevState => ({
        ...prevState,
        [name]: value,
      }));
      setEmailAuthParamsTwo(prevState => ({
        ...prevState,
        [name]: value,
      }));
      setIncorrectEmailInputMessage('');
    } else if (name === 'certificationNumber') {
      setEmailAuthParamsTwo(prevState => ({
        ...prevState,
        [name]: value,
      }));
      setIncorrectAuthNumInputMessage('');
    }
  };

  // 문의 등록하기 버튼
  const onClickPostBtn = () => {
    setAlertMessage('');
    onClickAuthModal('MAN_TO_MAN_REQ');
  };

  // 이메일 인증 Modal open
  const onClickAuthModal = certificationPurposeType => {
    setOpenAuthModal(true);
    setEmailAuthParamsOne({
      email: '',
      certificationPurposeType: certificationPurposeType,
      manToManInquirySeq: 0,
    });
  };

  // 인증 요청 버튼 클릭시
  const onClickRequestAuth = () => {
    getAuthEmailOne({
      emailAuthParamsOne,
      setEmailAuthParamsTwo,
      setIncorrectEmailInputMessage,
    });
    setIsClickedRequestAuthBtn(true);
  };

  // 재인증 클릭시
  const onClickRequestAgain = () => {
    setCount(151);
    getAuthEmailOne({
      emailAuthParamsOne,
      setEmailAuthParamsTwo,
      setIncorrectEmailInputMessage,
    });
  };

  const navigateToListPage = () => {
    navigate(ROUTE_PATH.serviceCenter.main + '?tab=QnA');
  };

  const uploadImgFile = event => {
    const tmpFileList = [];
    const files = event.target.files;
    if (!!files) {
      if (files.length + fileList.length < 5) {
        for (let i = 0; i < files.length; i++) {
          const previewURL = URL.createObjectURL(files[i]);
          tmpFileList.push({ fileObject: files[i], previewURL: previewURL });
        }
        setFileList([...fileList, ...tmpFileList]);
        event.target.value = '';
      } else {
        setAlertMessage('이미지는 4개 이하로 업로드해주세요.');
      }
    }
  };

  // 인증 완료 클릭시
  const onClickCompleteAuth = () => {
    setAuthRequestBtnStatus(true);
    const agreeList = [
      ...checkBoxList
        .filter(item => item.checked === true)
        .map(({ stipulationSeq }) => stipulationSeq),
    ];
    const disagreeList = [
      ...checkBoxList
        .filter(item => item.checked === false)
        .map(({ stipulationSeq }) => stipulationSeq),
    ];

    const qnaDataParams = {
      ...qnaData,
      agreedStipulationSeq: agreeList,
      disagreedStipulationSeq: disagreeList,
    };

    getAuthEmailTwo({
      emailAuthParamsTwo,
      setAlertMessage,
      setIncorrectAuthNumInputMessage,
      setIsClickedRequestAuthBtn,
      onCloseAuthModal,
      navigateToListPage,
      setIsLoading,
      qnaDataParams,
      fileList,
      setAuthRequestBtnStatus,
    });
  };

  // 이메일 인증 Modal close
  const onCloseAuthModal = () => {
    setEmailAuthParamsOne({
      email: '',
      certificationPurposeType: '',
      manToManInquirySeq: 0,
    });
    setEmailAuthParamsTwo({
      email: '',
      certificationNumber: '',
      memberCertificationSeq: 0,
      manToManInquirySeq: 0,
    });
    setOpenAuthModal(false);
    setIsClickedRequestAuthBtn(false);
    setIncorrectEmailInputMessage('');
    setIncorrectAuthNumInputMessage('');
    setTime(() => stringifyTime(150));
  };

  const onChangeCheckBox = e => {
    const { value, checked } = e.target;
    setCheckBoxList(prevList =>
      prevList.map(item => {
        return parseInt(item.stipulationSeq) === parseInt(value)
          ? { ...item, checked: checked }
          : item;
      })
    );
  };

  const onClickViewBtn = stipulationSeq => {
    if (!!stipulationSeq) {
      window.open(
        `${ROUTE_PATH.serviceCenter.qnaPolicyDefault}/${stipulationSeq}`,
        '_blank'
      );
    } else {
    }
  };

  // 동의하기 버튼 비활성화 여부
  const isNotAllChecked =
    textStipulationList.length === 0 &&
    (checkBoxList.filter(item => item.required === true).length === 0 ||
      checkBoxList
        .filter(item => item.required === true)
        .some(item => item.checked === false));

  return (
    <div>
      <TextMedium>문의 내용을 입력해주세요.</TextMedium>
      <TextField
        type="text"
        title="제목"
        name="inquirySubject"
        maxLength={50}
        placeholder="제목을 입력해주세요."
        onChange={onChangeQnAInput}
        value={qnaData.inquirySubject}
      />
      <TextareaDiv>
        <TextareaField
          type="text"
          title="내용"
          name="inquiryContents"
          placeholder="내용을 입력해주세요."
          maxLength={1500}
          onChange={onChangeQnAInput}
          value={qnaData.inquiryContents}
          style={{ height: '200px' }}
        />
        <InputLabel>({textCount.toLocaleString()}/1,500)</InputLabel>
      </TextareaDiv>
      {/* 이미지 업로드 파트 */}
      <ImageTitle>
        <ImageIcon htmlFor="input-file">
          <ImageInput
            type="file"
            accept="image/*"
            id="input-file"
            multiple
            onChange={uploadImgFile}
          />
          <FeatherIcon icon="camera" size={4} width="35px" height="35px" />
        </ImageIcon>
        <ImageDiv>사진 {fileList?.length}/4</ImageDiv>
      </ImageTitle>
      <ImageField>
        {fileList &&
          fileList.map((file, index) => {
            return (
              <CreateQuestionNoAuthImgRow
                file={file}
                key={`createQuestionNoAuthImgRow_${index}`}
                setFileList={setFileList}
              />
            );
          })}
      </ImageField>
      {/* 약관 동의 */}
      {stipulationList?.length > 0 &&
        stipulationList[0]?.stipulationSeq > 0 && <Line />}
      {checkBoxList?.length > 0 && checkBoxList[0]?.stipulationSeq > 0
        ? checkBoxList.map((item, index) => {
            const lastRequiredIndex = checkBoxList
              .map(({ required }) => required)
              .lastIndexOf(true);
            return (
              <PolicyAgreeRow
                key={`checkBoxList_${index}_${item.stipulationSeq}_${item.checked}`}
                item={item}
                index={index}
                lastRequiredIndex={lastRequiredIndex}
                onChangeCheckBox={onChangeCheckBox}
                onClickViewBtn={onClickViewBtn}
              />
            );
          })
        : textStipulationList.map((item, index) => (
            <Contents
              key={`textStipulationList_${index}_${item.stipulationSeq}`}
              textAlign="left"
            >
              {item.stipulationContents}
            </Contents>
          ))}
      {/* 문의 등록 버튼 */}
      <StyledActiveButton
        tabIndex={0}
        disabled={
          !qnaData.inquirySubject || !qnaData.inquiryContents || isNotAllChecked
        }
        onClick={onClickPostBtn}
      >
        문의 등록하기
      </StyledActiveButton>
      <ChildText>
        <Icon>
          <FeatherIcon icon="alert-circle" size={14} strokeWidth={3} />
        </Icon>
        <ChildTextContent>
          비회원의 경우 문의 등록하기 처리시 이메일 인증이 필요합니다.
        </ChildTextContent>
      </ChildText>
      {/* 이메일 인증 모달창 */}
      <EmailAuthModal
        openAuthModal={openAuthModal}
        onCloseAuthModal={onCloseAuthModal}
        emailAuthParamsOne={emailAuthParamsOne}
        onChangeAuthInput={onChangeAuthInput}
        incorrectEmailInputMessage={incorrectEmailInputMessage}
        isClickedRequestAuthBtn={isClickedRequestAuthBtn}
        onClickRequestAuth={onClickRequestAuth}
        onClickRequestAgain={onClickRequestAgain}
        emailAuthParamsTwo={emailAuthParamsTwo}
        time={time}
        isLoading={isLoading}
        incorrectAuthNumInputMessage={incorrectAuthNumInputMessage}
        onClickCompleteAuth={onClickCompleteAuth}
        authRequestBtnStatus={authRequestBtnStatus}
      />
      {/* API 통신 에러 모달창 */}
      <NoBtnModal
        showModal={!!alertMessage}
        onClose={() => {
          setAlertMessage('');
        }}
      >
        <ScrollArea>
          <Contents>{alertMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
      {isLoading && <LoadingIndicator message="문의를 등록하고 있습니다." />}
    </div>
  );
};

const getAuthEmailOne = ({
  emailAuthParamsOne,
  setEmailAuthParamsTwo,
  setIncorrectEmailInputMessage,
}) => {
  api({
    url: '/v1/na/qna/email/certi/request',
    method: 'get',
    headers: { 'content-type': 'application/json' },
    params: emailAuthParamsOne,
  })
    .then(res => {
      const { success, message, data } = res.data;
      if (success) {
        setEmailAuthParamsTwo(prevState => ({
          ...prevState,
          memberCertificationSeq: data.memberCertificationSeq,
          manToManInquirySeq: 0,
        }));
      } else {
        setIncorrectEmailInputMessage(message);
      }
    })
    .catch(err => {
      console.error(err);
      const { data } = err.response;
      setIncorrectEmailInputMessage(data.message);
    });
};

const getAuthEmailTwo = ({
  emailAuthParamsTwo,
  setAlertMessage,
  setIncorrectAuthNumInputMessage,
  setIsClickedRequestAuthBtn,
  onCloseAuthModal,
  navigateToListPage,
  setIsLoading,
  qnaDataParams,
  fileList,
  setAuthRequestBtnStatus,
}) => {
  api({
    url: '/v1/na/qna/email/certi',
    method: 'get',
    headers: { 'content-type': 'application/json' },
    params: emailAuthParamsTwo,
  })
    .then(res => {
      const { success, data, message } = res.data;
      if (success) {
        const postParams = {
          ...qnaDataParams,
          inquirySubject: encodeToBase64String(qnaDataParams.inquirySubject),
          inquiryContents: encodeToBase64String(qnaDataParams.inquiryContents),
          memberCertificationSeq: data.memberCertificationSeq,
        };
        if (fileList.length > 0) {
          const formData = new FormData();
          fileList.forEach(item => {
            formData.append(`imageFileList`, item.fileObject);
          });
          createQnAWithImgRequest({
            formData,
            setAlertMessage,
            postParams,
            navigateToListPage,
            setIsLoading,
          });
        } else {
          const timeoutId = setTimeout(() => {
            createQnARequest({
              postParams,
              setAlertMessage,
              navigateToListPage,
              setIsLoading,
            });
            onCloseAuthModal();
            setIsClickedRequestAuthBtn(false);
          }, 1800);
          return () => clearTimeout(timeoutId);
        }
      } else {
        setIncorrectAuthNumInputMessage(message);
        setAuthRequestBtnStatus(false);
      }
    })
    .catch(err => {
      console.error(err);
      const { data } = err.response;
      setIncorrectAuthNumInputMessage(data.message);
      setAuthRequestBtnStatus(false);
    });
};

// 데이터 보내기
const createQnARequest = ({
  postParams,
  setAlertMessage,
  navigateToListPage,
  setIsLoading,
}) => {
  api({
    url: `/v1/na/qna`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: postParams,
  })
    .then(res => {
      const { success, message } = res.data;
      if (success) {
        setIsLoading(false);
        setAlertMessage(message);
        const timeoutId = setTimeout(() => navigateToListPage(), 1800);
        return () => clearTimeout(timeoutId);
      } else {
        setIsLoading(false);
        setAlertMessage(message);
      }
    })
    .catch(err => {
      console.error(err);
      const { data } = err.response;
      setAlertMessage(data.message);
      setIsLoading(false);
    });
};

// 이미지 파일이 있는 QnA 등록
const createQnAWithImgRequest = ({
  formData,
  setAlertMessage,
  postParams,
  navigateToListPage,
  setIsLoading,
}) => {
  api({
    url: `/v1/na/file/upload-image-public-list`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: formData,
  })
    .then(res => {
      const { success, data } = res.data;

      if (success) {
        const fileList = data.fileList;
        const fileInfoSeq = fileList.map(item => {
          return item.fileSeq;
        });

        createQnARequest({
          postParams: {
            ...postParams,
            fileInfoSeqList: fileInfoSeq,
          },
          navigateToListPage: navigateToListPage,
          setAlertMessage: setAlertMessage,
          setIsLoading: setIsLoading,
        });
      }
    })
    .catch(err => {
      console.error(err);
      const { status, data } = err.response;
      if (status === 400) {
        setAlertMessage(data.message);
      } else {
        setAlertMessage(
          '이미지가 업로드 되지 않았습니다. 다시 한 번 시도해 주세요.'
        );
      }
      setIsLoading(false);
    });
};

export default CreateQuestionNoAuthPage;
