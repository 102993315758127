import styled from 'styled-components';
import theme from 'styles/theme';

export const Nav = styled.nav`
  display: block;
  width: 100%;
  border-top: 1px solid ${theme.borderColor.lightGrey};
  margin: 35px 0 35px;
`;

export const NavTitle = styled.div`
  display: block;
  width: 100%;
  height: 60px;
  margin-top: 10px;
  line-height: 6.2rem;
  text-align: left;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0;
`;

export const NavA = styled.a`
  display: block;
  width: 95%;
  height: 52px;
  line-height: 5.2rem;
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0;
  margin-left: 10px;

  &:link,
  &:visited {
    color: ${theme.color.black};
  }

  @media screen and (max-width: 375px) {
    height: 52px;
    line-height: 5.2rem;
    font-size: 1.3rem;
  }
`;

export const NavADesc = styled.div`
  width: 100%;
  text-align: left;
  font-size: 1.3rem;
  line-height: 2.2rem;
  margin-left: 15px;
  color: ${theme.color.greyA};
`;

export const NavAWrap = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const NavALinkWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
