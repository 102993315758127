import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import styled from 'styled-components';
import theme from 'styles/theme';

export const AlertTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px 8px;
`;

export const AlertIcon = styled(FeatherIcon)`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  stroke-width: 2;

  color: ${theme.color.grey5};

  @media screen and (max-width: 320px) {
    width: 12px;
  }
`;

export const AlertText = styled.div`
  width: 100%;
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: left;
  padding: 0 0 0 4px;
  font-weight: 500;
  color: ${theme.color.grey5};

  @media screen and (max-width: 320px) {
    font-size: 1.2rem;
  }
`;
