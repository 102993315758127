// requirement가 'CODE'인 방식이 기획서에서 '일반 2차인증'입니다. (수진)
import { ContentBlueText } from './style';

export const MALL_SECOND_AUTH_TYPE = {
  SAT111: {
    code: 'SAT111',
    type: '문자 인증번호 입력방식',
    requirementName: '인증코드',
    requirement: 'CODE',
    placeholder: '2차 인증코드를 입력해 주세요.',
    textDescription: <>이메일 혹은 문자로 전송된 인증번호를 확인해 주세요.</>,
  },
  SAT112: {
    code: 'SAT112',
    type: '이메일 인증번호 입력방식',
    requirementName: '인증코드',
    requirement: 'CODE',
    placeholder: '2차 인증코드를 입력해 주세요.',
    textDescription: <>이메일 혹은 문자로 전송된 인증번호를 확인해 주세요.</>,
  },
  SAT113: {
    code: 'SAT113',
    type: '캡차 인증번호 입력방식',
    requirementName: '캡차입력',
    requirement: 'CAPTCHA',
    placeholder: '이미지의 문자와 숫자를 입력해 주세요.',
    textDescription: (
      <>
        해당 판매몰의 경우 자동 등록 방지를 위한 캡차 인증이 진행 됩니다. <br />
        캡차 인증 후 판매몰의 방침에 따라 2차 인증 절차를 진행할 수 있습니다.
      </>
    ),
  },
  SAT114: {
    code: 'SAT114',
    type: '캡차 인증(질문포함) 답변 입력방식',
    requirementName: '캡차입력',
    requirement: 'CAPTCHA_QUESTION',
    placeholder: '정답을 입력해 주세요.',
    textDescription: (
      <>
        해당 판매몰의 경우 자동 등록 방지를 위한 캡차 인증이 진행 됩니다. <br />
        캡차 인증 후 판매몰의 방침에 따라 2차 인증 절차를 진행할 수 있습니다.
      </>
    ),
  },
  SAT115: {
    code: 'SAT115',
    type: 'OTP 번호 입력방식',
    requirementName: 'OTP 인증',
    requirement: 'OTP',
    placeholder: 'OTP 인증 번호를 입력해 주세요.',
    textDescription: (
      <>
        해당 판매몰의 경우 보안설정이 켜짐으로 설정되어있는 경우, 보안 인증이
        필요합니다. <br />
        <ContentBlueText>
          네이버 > 더보기 > 설정 > 네이버 OTP > OTP 인증번호
        </ContentBlueText>
        를 입력 후 '보안 인증 완료'를 눌러주세요.
      </>
    ),
  },
  SAT116: {
    code: 'SAT116',
    type: '회원정보 매칭 입력방식',
    requirementName: '휴대폰번호',
    requirement: 'PHONE_NUMBER',
    placeholder: '네이버에 등록된 휴대폰 번호를 입력해 주세요.',
    textDescription: (
      <>
        해당 판매몰의 경우 보안설정이 켜짐으로 설정되어있는 경우, 보안 인증이
        필요합니다. <br />
        <ContentBlueText>
          네이버 > 더보기 > 설정 > 내정보.보안기능 > 내프로필에 등록되어 있는
          휴대폰 번호
        </ContentBlueText>
        를 입력 후 '보안 인증 완료'를 눌러주세요.
      </>
    ),
  },
  SAT121: {
    code: 'SAT121',
    type: '앱 푸시 인증방식',
    requirementName: '브라우저 인증',
    requirement: 'BROWSER',
    placeholder: '',
    textDescription: (
      <>
        해당 판매몰의 경우 보안설정이 켜짐으로 설정되어있는 경우, 보안 인증이
        필요합니다. <br />
        <ContentBlueText>네이버 앱에서 인증 알림 승인 후 </ContentBlueText>
        '보안 인증 완료'를 눌러주세요.
      </>
    ),
  },
  SAT122: {
    code: 'SAT122',
    type: '서버(이메일) 인증방식',
    requirementName: '이메일 인증',
    requirement: 'BROWSER',
    placeholder: '',
    textDescription: (
      <>
        해당 판매몰의 경우 신규기기 로그인시 2차 인증 설정이 사용으로
        설정되어있는 경우, 보안 인증이 필요합니다. <br />
        <ContentBlueText>
          판매몰에서 발송된 2차 인증 안내 메일을 확인 후 '인증 활성화'
        </ContentBlueText>
        를 눌러주세요.
      </>
    ),
  },
};
