import React from 'react';
import {
  CheckboxInput,
  Label,
} from 'pages/MyPage/MyInform/MemberWithdrawal/MemberWithdrawalPage/style';
import {
  Button,
  ButtonSpan,
  ColorSpan,
  IconSpan,
  Line,
  RequiredCheckboxWrapper,
} from './style';

const PolicyAgreeRow = ({
  item,
  index,
  lastRequiredIndex,
  onChangeCheckBox,
  onClickViewBtn,
}) => {
  return (
    <div>
      <RequiredCheckboxWrapper>
        <Label className="check-card" htmlFor={`check-card_${index}`}>
          <CheckboxInput
            type="checkbox"
            className="check-card"
            id={`check-card_${index}`}
            checked={item.checked || false}
            onChange={onChangeCheckBox}
            value={item.stipulationSeq}
          />
          <IconSpan className="notice-check-icon" />
          <div>
            <ColorSpan required={item.required}>
              {item.required === true ? `[필수]` : `[선택]`}
            </ColorSpan>{' '}
            {item.stipulationCdName}
          </div>
        </Label>
        <Button onClick={() => onClickViewBtn(item.stipulationSeq)}>
          <ButtonSpan>보기</ButtonSpan>
        </Button>
      </RequiredCheckboxWrapper>
      {index === lastRequiredIndex && <Line />}
    </div>
  );
};

export default PolicyAgreeRow;
