import React from 'react';
import SelectManager from './SelectManager';
import { OneBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';

const SelectManagerModal = ({
  showSelectManagerModal,
  setShowSelectManagerModal,
  onClickSelectManager,
  bankName,
  setSelectedManager,
  selectedManager,
  managerList,
}) => {
  return (
    <OneBtnModal
      showModal={showSelectManagerModal}
      onClose={() => setShowSelectManagerModal(false)}
      onClick={onClickSelectManager}
      btnContent="담당자 선택"
      disabled={!selectedManager?.businessManagerSeq}
    >
      <ScrollArea>
        <Contents>
          <SelectManager
            bankName={bankName}
            setSelectedManager={setSelectedManager}
            _managerList={managerList}
          />
        </Contents>
      </ScrollArea>
    </OneBtnModal>
  );
};

export default SelectManagerModal;
