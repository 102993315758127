import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import qs from 'qs';
import ROUTE_PATH from 'routes/config';
import { getDataRequest } from 'utils/request';
import {
  createMarkup,
  getInterlockHash,
  KEY_DEEPLINK_TYPE,
  KEY_INFLOW_CHANNEL,
  KEY_INTERLOCK_HASH,
} from 'tools/WebTool';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import FoldedCard from 'components/FoldedCard';
import StyledLinkButton from 'components/Button/StyledLinkButton';
import DeepLinkNoBtnModal from '../DeepLinkNoBtnModal';
import {
  ChildTextContent2,
  TextButton,
} from 'pages/DeepLink/DeepLinkSecuredPage/style';
import {
  BlueBox,
  BlueBoxContents,
  BlueBoxTitle,
} from 'components/BlueAlertBox/style';
import { Card, CardName } from 'components/FoldedCard/style';
import { PreviewSection } from 'styles/styleArea';
import { DEEPLINK_TYPE } from 'pages/DeepLink/constants';

const DeepLinkJoinMemberGuidePage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [deepLinkContentsData, setDeepLinkContentsData] = useState({});
  const [guideList, setGuideList] = useState([]);
  const [noBtnModalMessage, setNoBtnModalMessage] = useState('');

  const interlockHash = getInterlockHash();

  useEffect(() => {
    const deepLinkType = !!searchParams?.get && searchParams.get('type');
    if (!!deepLinkType && deepLinkType === DEEPLINK_TYPE.joinMember) {
      const interlockHash = searchParams.get('financialInstitution');
      localStorage.setItem(KEY_INTERLOCK_HASH, interlockHash);
      localStorage.setItem(KEY_DEEPLINK_TYPE, deepLinkType);
      localStorage.setItem(KEY_INFLOW_CHANNEL, interlockHash);

      const getDeeplinkContentsSuccessFnc = data => {
        setDeepLinkContentsData(data?.result);
        setGuideList(data?.result?.guidanceResultList);
        setIsLoading(false);
      };
      getDataRequest({
        url: `/v1/na/deeplink-contents`,
        params: {
          deeplinkSectionCd: DEEPLINK_TYPE.joinMember,
          interlockKey: interlockHash,
        },
        successFnc: getDeeplinkContentsSuccessFnc,
        setModalMessage: setNoBtnModalMessage,
      });
    }
  }, []);

  const onClickDetailBtn = seq => {
    setGuideList(list =>
      list.map((item, index) =>
        index === seq
          ? { ...item, isOpen: !item.isOpen }
          : { ...item, isOpen: false }
      )
    );
  };

  const onClickLoginBtn = () => {
    navigate(ROUTE_PATH.login.login);
  };

  const onClickJoinMemberBtn = () => {
    navigate(
      `${ROUTE_PATH.login.joinMember}?financialInstitution=${interlockHash}&type=${DEEPLINK_TYPE.joinMember}`
    );
  };

  const onClickCustomBtn = () => {
    window.open(deepLinkContentsData.customButtonUrl, '_blank');
  };

  if (!!noBtnModalMessage) {
    return (
      <DeepLinkNoBtnModal
        alertMessage={noBtnModalMessage}
        setAlertMessage={noBtnModalMessage}
      />
    );
  }

  if (isLoading) return <div />;

  //1. 딥링크 회원가입 안내 페이지
  return (
    deepLinkContentsData && (
      <div>
        {/* 컨텐츠 1번 영역 */}
        <div style={{ marginTop: '20px' }}>
          <PreviewSection
            dangerouslySetInnerHTML={createMarkup(
              deepLinkContentsData.deeplinkContents
            )}
          />
        </div>

        {/* 컨텐츠 2번 영역 : 추가 안내*/}
        {deepLinkContentsData.additionGuidanceExposureYn === 'Y' && (
          <BlueBox>
            <BlueBoxTitle>추가 안내</BlueBoxTitle>
            <BlueBoxContents>
              <PreviewSection
                dangerouslySetInnerHTML={createMarkup(
                  deepLinkContentsData.additionGuidanceContents
                )}
              />
            </BlueBoxContents>
          </BlueBox>
        )}

        {/* 버튼 영역*/}
        {deepLinkContentsData.buttonSectionCd === '로그인' && (
          <div>
            <StyledActiveButton tabIndex={0} onClick={onClickLoginBtn}>
              로그인
            </StyledActiveButton>
            {deepLinkContentsData.customButtonExposureYn === 'Y' && (
              <StyledLinkButton onClick={onClickCustomBtn}>
                {deepLinkContentsData.customButtonName}
              </StyledLinkButton>
            )}
            <ChildTextContent2>
              아직 셀러크레딧커넥트 회원이 아니신가요?
              <TextButton
                tabIndex={0}
                title="회원가입"
                onClick={onClickJoinMemberBtn}
              >
                회원가입
              </TextButton>
            </ChildTextContent2>
          </div>
        )}

        {deepLinkContentsData.buttonSectionCd === '회원가입' && (
          <div>
            <StyledActiveButton tabIndex={0} onClick={onClickJoinMemberBtn}>
              회원가입
            </StyledActiveButton>
            {deepLinkContentsData.customButtonExposureYn === 'Y' && (
              <StyledLinkButton onClick={onClickCustomBtn}>
                {deepLinkContentsData.customButtonName}
              </StyledLinkButton>
            )}
            <ChildTextContent2>
              이미 셀러크레딧커넥트 회원이신가요?
              <TextButton tabIndex={0} title="로그인" onClick={onClickLoginBtn}>
                로그인
              </TextButton>
            </ChildTextContent2>
          </div>
        )}

        {/* 그 외 안내 사항(아코디언) */}
        <Card>
          <CardName style={{ fontWeight: 500 }}>주요 안내 사항</CardName>
        </Card>
        {guideList &&
          guideList.length > 0 &&
          guideList.map((item, index) => {
            return (
              <FoldedCard
                key={`FoldedCard_${index}`}
                index={index}
                borderTop={false}
                isOpen={item.isOpen}
                title={item.subject}
                contents={item.contents}
                onClickDetailBtn={onClickDetailBtn}
              />
            );
          })}
      </div>
    )
  );
};

export default DeepLinkJoinMemberGuidePage;
