import React from 'react';
import { AreaBox1 } from 'styles/styleArea';
import { KaKaoAreaRow, KaKaoLink } from '../style';

const KaKaoService = ({ url = 'https://pf.kakao.com/_xdxixigxj/chat' }) => {
  return (
    <AreaBox1>
      <KaKaoAreaRow>
        <KaKaoLink href={url} rel="noopener noreferrer">
          카카오톡 상담
        </KaKaoLink>
      </KaKaoAreaRow>
    </AreaBox1>
  );
};

export default KaKaoService;
