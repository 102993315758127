import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const InputDiv = styled.div`
  position: relative;
  width: 100%;
`;

export const InputNormal = styled.input`
  position: relative;
  width: 100%;
  height: 45px;
  margin: 23px 0 0;
  padding: 0 14px;
  line-height: 4.5rem;
  font-size: 1.3rem;
  font-weight: 500;
  vertical-align: middle;
  border: ${props =>
    props.showMessage ? `1px solid ${theme.borderColor.blue}` : 'transparent'};
  border-radius: 10px;
  background: ${theme.backgroundColor.form};
  color: ${theme.color.black};

  ${props => {
    if (props.uppercase) {
      return css`
        text-transform: uppercase;
      `;
    }
    if (props.readOnly) {
      return css`
        pointer-events: none;
        background: ${theme.backgroundColor.contentBox};
      `;
    }
  }}

  &:focus {
    border: 1px solid ${theme.borderColor.blue};
  }

  ::placeholder {
    color: ${theme.color.placeholder};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputLabel = styled.label`
  position: absolute;
  top: 12px;
  right: 20px;
  color: ${theme.color.blue};
  font-size: 1.3rem;
  line-height: 2rem;
`;

export const InputMessage = styled.div`
  width: 100%;
  padding: 8px;
  color: ${theme.color.blue};
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: left;
`;

export const EyeIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 37.5px;
  right: 5%;
`;
