import styled from 'styled-components';
import theme from 'styles/theme';

export const NoListTextTitle = styled.div`
  display: block;
  width: 100%;
  padding: ${props => (props.padding ? `100px 0 32px` : '0')};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
  text-align: center;
  white-space: pre-line;

  ${props => {
    if (!!props.border) {
      return `border-top: 1px solid #d0d0d0;`;
    }
  }}
`;
