import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { SERVICE_TEXT } from './constants';
import {
  MainBlueText,
  MainImage,
  MainText,
  SubText,
  SubBoldText,
  SubTextWrapper,
  ServiceLine,
  ServiceTitle,
  ServiceImage,
  ServiceBox,
  TotalScore,
  Description,
  FinanceProducts,
  ProductA,
  ProductB,
  ProductTitle,
  ProductContents,
} from './style';

const ServiceView = () => {
  const location = useLocation();
  const serviceRef = useRef();

  useEffect(() => {
    const moveToServiceView = () => {
      serviceRef?.current?.scrollIntoView({
        behavior: 'instant', // optional
      });
    };

    if (location.state === 'moveScroll') {
      moveToServiceView();
    }
  }, [location]);

  return (
    <>
      <MainImage src="/images/service_s0.png" alt="추천이미지" />
      <MainText>
        우리에게는 <MainBlueText>셀러의 데이터가 신용</MainBlueText>입니다.
        정산예정금 데이터를 시작으로
        <MainBlueText> 셀러의 모든 데이터를 금융과 연결</MainBlueText>
        하겠습니다.
      </MainText>
      <SubTextWrapper>
        <SubText>
          에이젠글로벌은 머신러닝 자동화 엔진
          <SubBoldText> ABACUS(아바커스)</SubBoldText>&nbsp;를 기반으로 금융
          의사결정의 ‘자율주행’을 이끄는
          <SubBoldText> 금융AI 전문기업</SubBoldText>
          입니다.
        </SubText>
        <SubText>
          아시아시장에서 에이젠글로벌만의 기술과 노하우로 데이터 융합 기반의
          새로운 ‘서비스형 뱅킹'을 제공하고 있습니다.
        </SubText>
        <SubText>
          에이젠글로벌의 AI 기술력을 이커머스 영역으로 확장합니다.
        </SubText>
        <SubText>
          <SubBoldText>
            셀러 크레딧커넥트는 셀러의 데이터와 금융을 연결합니다.&nbsp;
          </SubBoldText>
          정산예정금 데이터를 시작으로 매출, 거래규모, 재판매율, 재고 회전율,
          반품율 등 셀러의 모든 활동을 데이터화 합니다. 그리고 이&nbsp;
          <SubBoldText>
            데이터를 AI가 분석해 셀러에게 가장 적합한 금융 서비스를 추천
          </SubBoldText>
          합니다.
        </SubText>
      </SubTextWrapper>
      {SERVICE_TEXT.map(text => {
        return (
          <div
            key={`service_${text.id}`}
            ref={text.id === 1 ? serviceRef : null}
          >
            <ServiceLine />
            <ServiceTitle>{text.title}</ServiceTitle>
            <ServiceImage src={text.imgSrc} alt="추천이미지" />
            <ServiceBox>
              <TotalScore>Total Score : {text.score}</TotalScore>
              <Description>{text.description}</Description>
              <FinanceProducts>추천 금융 상품</FinanceProducts>
              <ProductA>
                <ProductTitle>A사</ProductTitle>
                <ProductContents>{text.productA}</ProductContents>
              </ProductA>
              <ProductB>
                <ProductTitle>B사</ProductTitle>
                <ProductContents>{text.productB}</ProductContents>
              </ProductB>
            </ServiceBox>
          </div>
        );
      })}
    </>
  );
};

export default ServiceView;
