import React, { useState, useEffect } from 'react';
import { modifyDataRequest } from 'utils/request';
import { OneBtnModal, NoBtnModal } from 'components/Modal';
import TextField from 'components/TextField';
import Toggle from 'components/Toggle';
import { ScrollArea, Contents } from 'components/Modal/style';
import SelectManagerModal from 'pages/MyPage/DataLinkage/DataLinkageRequest/SelectManagerModal';
import StopLinkageModal from './StopLinkageModal';
import {
  FirstText,
  ChildText,
  ChildTextContent,
  BlueModifySection,
  BlueModifyText,
  BlackModifyText,
  BackgroundTextBox,
  BackgroundTextBoxTitle,
  BackgroundTextBoxContents,
} from 'pages/MyPage/DataLinkage/style';

const IndividualModifyStep1 = ({
  modifiedDataInterlockInfo,
  setModifiedDataInterlockInfo,
  setStep,
  isResetBtnClicked,
  businessRegistrationNumber,
  isRepresentation,
}) => {
  //data state
  const { mallName, mallMainId, mallMainPassword, name, mallInterlockStatus } =
    modifiedDataInterlockInfo;

  const [initData, setInitData] = useState({
    mallName: mallName,
    mallMainId: mallMainId,
    mallMainPassword: mallMainPassword,
    name: name,
  });

  // 담당자 정보 get state
  const [postApiManagerList, setPostApiManagerList] = useState(false);

  //모달 status state
  const [isSelectManagerModalOpen, setIsSelectManagerModalOpen] =
    useState(false);
  const [isStopLinkageModalOpen, setIsStopLinkageModalOpen] = useState(false);

  //모달 메시지 state
  const [isOneBtnModalOpen, setIsOneBtnModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  //toggle status state
  const isToggleChecked = mallInterlockStatus === 'STOP';

  //처음으로 돌아가기 버튼 클릭한 경우
  useEffect(() => {
    if (isResetBtnClicked) {
      setModifiedDataInterlockInfo(prevState => ({
        ...prevState,
        mallMainId: initData.mallMainId,
        mallMainPassword: initData.mallMainPassword,
        name: initData.name,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetBtnClicked]);

  //토글 클릭 시, 연동 중지, 해지 모달 오픈
  const onChangeToggle = () => {
    setIsStopLinkageModalOpen(true);
  };

  //담당자 정보 변경 버튼 클릭
  const onClickManagerModal = () => {
    setIsSelectManagerModalOpen(true);
    setPostApiManagerList(!postApiManagerList);
  };

  //매니저 변경
  const additionalFnc = selectedManager => {
    modifyDataRequest({
      url: `/v1/au/data-provider/individual/businessManagerInfo/${modifiedDataInterlockInfo.businessDataProviderMallMappingSeq}`,
      data: {
        managerName: selectedManager.managerName,
        managerMobilePhone: selectedManager.mobilePhone,
      },
      setModalMessage: setModalMessage,
      setShowModal: setIsOneBtnModalOpen,
      setShowAlertModal: setIsMessageModalOpen,
    });
  };

  return (
    modifiedDataInterlockInfo && (
      <div style={{ padding: '0 35px 30px 35px' }}>
        <FirstText>
          데이터 연동 정보 수정이 필요한 경우
          <br />
          수정하기를 눌러주세요.
        </FirstText>
        <ChildText>
          <ChildTextContent bold>사업자 번호</ChildTextContent>
          <ChildTextContent>
            {businessRegistrationNumber || '-'}
          </ChildTextContent>
        </ChildText>
        <ChildText>
          <ChildTextContent bold>연동 정보</ChildTextContent>
          <ChildTextContent>판매몰 개별 연동</ChildTextContent>
        </ChildText>
        <TextField
          name="name"
          placeholder="이름"
          readOnly
          value={mallName || ''}
          style={{ margin: 0, backgroundColor: '#D9F1FF' }}
        />
        <BlueModifySection>
          <BlueModifyText onClick={() => setStep(2)}>
            계정 정보 수정하기
          </BlueModifyText>
        </BlueModifySection>
        <TextField
          style={{ margin: 0 }}
          name="mallMainId"
          placeholder={`${mallName} 아이디`}
          type="text"
          value={mallMainId || ''}
          readOnly
        />
        <TextField
          name="mallMainPassword"
          placeholder={`${mallName} 비밀번호`}
          type="password"
          value={mallMainPassword || ''}
          readOnly
        />

        {/* 담당자 수정 */}
        <BlueModifySection justifyContent="space-between">
          <BlackModifyText bold>연동 결과 안내 담당자</BlackModifyText>
          {isRepresentation && (
            <BlueModifyText onClick={onClickManagerModal}>
              담당자 정보 변경하기
            </BlueModifyText>
          )}
        </BlueModifySection>
        <TextField
          name="name"
          placeholder="데이터 연동 담당자"
          value={name || ''}
          readOnly
          style={{ margin: '0 0 23px' }}
        />

        {/* 담당자 수정 모달 */}
        <SelectManagerModal
          showSelectManagerModal={isSelectManagerModalOpen}
          setShowSelectManagerModal={setIsSelectManagerModalOpen}
          mallName={mallName}
          setDataInterlockInfo={setModifiedDataInterlockInfo}
          setInitData={setInitData}
          additionalFnc={additionalFnc}
          managerName={name}
        />

        {/* 데이터 연동 중지 및 중지해지 */}
        <BackgroundTextBox>
          <BackgroundTextBoxTitle>
            데이터 연동 중지가 필요하신가요?
            <Toggle
              toggleName="stopDataLinkage"
              toggleChecked={isToggleChecked}
              onChangeToggle={onChangeToggle}
              // style={{ width: '35px', height: '20px' }}
            />
          </BackgroundTextBoxTitle>

          <BackgroundTextBoxContents>
            판매몰의 연동 정보를 잠시 중지할 수 있습니다. 단, 데이터 연동을
            중지한 경우 해당 판매몰의 정산예정금을 활용한 대출은 불가하며,
            금융기관에 데이터를 제공할 수 없습니다.
          </BackgroundTextBoxContents>
        </BackgroundTextBox>

        {/* 데이터 연동 중지 및 중지해지 모달 */}
        <StopLinkageModal
          status={mallInterlockStatus}
          modifiedDataInterlockInfo={modifiedDataInterlockInfo}
          isStopLinkageModalOpen={isStopLinkageModalOpen}
          setIsStopLinkageModalOpen={setIsStopLinkageModalOpen}
          setModalMessage={setModalMessage}
          setIsOneBtnModalOpen={setIsOneBtnModalOpen}
          setIsMessageModalOpen={setIsMessageModalOpen}
          setModifiedDataInterlockInfo={setModifiedDataInterlockInfo}
        />

        {/* 필수값 필드 누락시 or 데이터 연동 중지 실패 후 메세지 모달*/}
        <OneBtnModal
          showModal={isOneBtnModalOpen}
          onClose={() => setIsOneBtnModalOpen(false)}
          btnContent="확인"
        >
          <ScrollArea>
            <Contents>{modalMessage}</Contents>
          </ScrollArea>
        </OneBtnModal>

        {/* alert 메세지 모달*/}
        <NoBtnModal
          showModal={isMessageModalOpen}
          onClose={() => setIsMessageModalOpen(false)}
        >
          <ScrollArea>
            <Contents>{modalMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>
      </div>
    )
  );
};

export default IndividualModifyStep1;
