import { hasKorean, hasAlphabet, hasDigit } from './ValidationTool';

export const sortMallByInterest = (a, b) => {
  // 금리 낮은순
  return (
    a.lowestInterest - b.lowestInterest ||
    b.creditGoodsMasterEventSeq - a.creditGoodsMasterEventSeq
  );
};

export const sortMallByLimit = (a, b) => {
  // 한도 높은순
  return (
    b.maximumLimit - a.maximumLimit ||
    b.creditGoodsMasterEventSeq - a.creditGoodsMasterEventSeq
  );
};

export const providerOrder = (a, b) => {
  // individual -> provider 순서
  if (a.dataProviderType < b.dataProviderType) {
    return -1;
  }
  return 1;
};

export const languageOrder = (a, b) => {
  if (hasKorean(a.dataProviderName[0]) || hasKorean(b.dataProviderName[0])) {
    if (hasKorean(a.dataProviderName[0])) {
      return -1;
    } else {
      return 1;
    }
  } else if (
    hasAlphabet(a.dataProviderName[0]) ||
    hasAlphabet(b.dataProviderName[0])
  ) {
    if (hasAlphabet(a.dataProviderName[0])) {
      return -1;
    } else {
      return 1;
    }
  } else if (
    hasDigit(a.dataProviderName[0]) ||
    hasDigit(b.dataProviderName[0])
  ) {
    if (hasDigit(a.dataProviderName[0])) {
      return -1;
    } else {
      return 1;
    }
  }
  return 1;
};

export const orderLanguage = list => {
  const compareLocale = (a, b) => a.mallName.localeCompare(b.mallName);

  return [
    ...list.filter(item => hasKorean(item.mallName[0]))?.sort(compareLocale),
    ...list.filter(item => hasAlphabet(item.mallName[0]))?.sort(compareLocale),
    ...list.filter(item => hasDigit(item.mallName[0]))?.sort(compareLocale),
  ];
};
