import styled, { css } from 'styled-components';

export const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0 0 0;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
`;

export const CheckBoxInput = styled.input`
  position: absolute;
  margin: 4px 0 0;
  padding: 0;
  line-height: normal;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &:focus + .notice-check-icon {
    outline-offset: -2px;
  }

  &:not(:checked) + .notice-check-icon {
    background: url(/images/icons/checkbox/checkbox-square-empty.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }

  &:checked + .notice-check-icon {
    background: url(/images/icons/checkbox/checkbox-square.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }
`;

export const RadioInput = styled.input`
  position: absolute;
  margin: 4px 0 0;
  padding: 0;
  line-height: normal;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &:focus + .notice-check-icon {
    outline-offset: -2px;
  }

  &:not(:checked) + .notice-check-icon {
    background: url(/images/icons/checkbox/radio-off2.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }

  &:checked + .notice-check-icon {
    background: url(/images/icons/checkbox/radio-a-on.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }
`;

export const Label = styled.label`
  display: inline-block;
  position: relative;
  float: left;

  margin-bottom: 0;
  padding: 6px 16px 5px 26px;
  width: 100%;
  max-width: 100%;

  line-height: 2.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;

  ${props => {
    if (props.className) {
      if (props.className === 'check-box') {
        return css`
          font-size: 1.5rem;
          font-weight: 400;
        `;
      }
    }
  }}
`;

export const IconSpan = styled.span`
  position: absolute;
  top: 7px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  background: url(/images/icons/checkbox/checkbox-square-empty.png) no-repeat;
  background-size: 20px 20px;
  content: '';
`;
