import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { QnAImg, QnAImgDeleteBtn, QnAImgDiv } from '../style';

const CreateQuestionNoAuthImgRow = ({ file, setFileList }) => {
  // X 버튼 클릭 시 이미지 삭제
  const onClickDeleteImage = previewURL => {
    setFileList(prev => prev.filter(item => item.previewURL !== previewURL));
  };

  return (
    <QnAImgDiv>
      <QnAImg src={file.previewURL} />
      <QnAImgDeleteBtn onClick={() => onClickDeleteImage(file.previewURL)}>
        <FeatherIcon
          icon="x-circle"
          size={4}
          width="20px"
          height="20px"
          fill="white"
        />
      </QnAImgDeleteBtn>
    </QnAImgDiv>
  );
};

export default CreateQuestionNoAuthImgRow;
