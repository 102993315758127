import React from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { TwoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';

const AuthTimeoutModal = ({
  twoBtnModalMessage = '',
  setTwoBtnModalMessage,
}) => {
  const navigate = useNavigate();

  return (
    <TwoBtnModal
      showModal={!!twoBtnModalMessage}
      onClose={() => navigate('/')}
      onClick={() => {
        setTwoBtnModalMessage('');
        navigate(ROUTE_PATH.mypage.dataLinkageManage);
      }}
      leftButton="확인"
      rightButton="데이터 연동 화면 가기"
    >
      <ScrollArea>
        <Contents>{twoBtnModalMessage}</Contents>
      </ScrollArea>
    </TwoBtnModal>
  );
};

export default AuthTimeoutModal;
