import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useApp from 'hooks/useApp';
import ROUTE_PATH from 'routes/config';
import { getListDataRequest } from 'utils/request';
import EmployeeItem from './EmployeeItem';
import { NoBtnModal } from 'components/Modal';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { Contents, ScrollArea } from 'components/Modal/style';
import {
  DataBox,
  DataHeader,
  DataName,
  DataKey,
  DataValue,
  DataValueText,
  Position,
  MapWrapper,
  DivFlex,
} from './EmployeeItem/style';
import DeleteModal from './DeleteModal';
import CancelModal from './CancelModal';
import InviteModal from './InviteModal';
import RegisterModal from './RegisterModal';
import ExportModal from './ExportModal';

const Manager = () => {
  const { mypage } = ROUTE_PATH;
  const location = useLocation();
  const navigate = useNavigate();
  const { auth } = useApp();

  const representativeYn = auth?.memberRoleData?.representativeYn;

  //데이터
  const [managerList, setManagerList] = useState([]);
  const [representativeData, setRepresentativeData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);

  //삭제 모달
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalInfo, setDeleteModalInfo] = useState({
    businessManagerSeq: '',
    managerName: '',
  });

  //초대 모달
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteModalInfo, setInviteModalInfo] = useState({});

  //취소 모달
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelModalInfo, setCancelModalInfo] = useState({});

  //내보내기 모달
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportModalInfo, setExportModalInfo] = useState({});

  //등록 모달
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [registeredModalInfo, setRegisteredModalInfo] = useState('');

  //alert 모달
  const [alertModal, setAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isAuth, setIsAuth] = useState(true);

  useEffect(() => {
    if (location.state) {
      setRegisteredModalInfo(location.state);
      onClickSaveEmployeeData();
      navigate(location.pathname, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  // 권한 체크 후 직원 목록 조회 API 통신
  useEffect(() => {
    if (representativeYn === 'N') {
      setIsAuth(false);
      setAlertModal(true);
      setAlertMessage('대표인 회원만 직원관리 화면 사용이 가능합니다.');
    }
    if (representativeYn === 'Y') {
      getListDataRequest({ url: '/v1/au/manager', setData: setManagerList });
    }
  }, [representativeYn]);

  useEffect(() => {
    // 직원목록 데이터 순서 재생성(가나다순)
    const regenerateEmployeeData = employeeData => {
      const nonReprensentative = employeeData.filter(
        item => item.representationYn !== 'Y'
      );
      nonReprensentative.sort((a, b) => {
        if (a.managerName < b.managerName) {
          return -1;
        }
        if (a.managerName > b.managerName) {
          return 1;
        }
        return 0;
      });
      return nonReprensentative;
    };
    setEmployeeData(regenerateEmployeeData(managerList));

    // 직원목록 데이터 중 대표만 따로 분리
    setRepresentativeData(
      managerList.filter(item => item.representationYn === 'Y')
    );
  }, [managerList]);

  // 직원 상세정보 직원수정 페이지로 보내기
  const gotoModify = data => {
    navigate(mypage.managerRegister, {
      state: {
        employeeData: data,
      },
    });
  };

  // 삭제시 알림 팝업 모달 핸들 & 모달 내용 관리
  const toggleDeleteModal = data => {
    setDeleteModalInfo(data);
    setShowDeleteModal(!showDeleteModal);
  };

  // 초대시 알림 팝업 모달 핸들 & 모달 내용 관리
  const toggleInviteModal = data => {
    setInviteModalInfo(data);
    setShowInviteModal(!showInviteModal);
  };

  // 초대 취소시 알림 팝업 모달 핸들 & 모달 내용 관리
  const toggleCancelModal = data => {
    setCancelModalInfo(data);
    setShowCancelModal(!showCancelModal);
  };

  // 초대 취소시 알림 팝업 모달 핸들 & 모달 내용 관리
  const toggleExportModal = data => {
    setExportModalInfo(data);
    setShowExportModal(!showExportModal);
  };

  // businessSeq 넘버 직원등록 페이지로 보내기
  const gotoRegister = () => {
    const businessSeq = representativeData[0]?.businessSeq;

    navigate(mypage.managerRegister, {
      state: {
        businessSeq,
      },
    });
  };

  const onClickSaveEmployeeData = () => {
    setShowRegisterModal(!showRegisterModal);
  };

  //모달

  const deleteSuccessFnc = () => {
    getListDataRequest({ url: '/v1/au/manager', setData: setManagerList });
    toggleDeleteModal();
  };

  const inviteSuccessFnc = () => {
    getListDataRequest({ url: '/v1/au/manager', setData: setManagerList });
    toggleInviteModal();
  };

  const cancelSuccessFnc = () => {
    getListDataRequest({ url: '/v1/au/manager', setData: setManagerList });
    toggleCancelModal();
  };

  const exportSuccessFnc = () => {
    getListDataRequest({ url: '/v1/au/manager', setData: setManagerList });
    toggleExportModal();
  };

  const onCloseNoBtnModal = () => {
    if (!isAuth) {
      navigate('/');
      return;
    }
    setAlertModal(false);
  };

  return (
    <>
      {managerList && managerList.length > 0 && (
        <div>
          <StyledActiveButton onClick={gotoRegister}>
            직원 등록
          </StyledActiveButton>
          <DataBox>
            <DataHeader>
              <DivFlex>
                <DataName>{representativeData[0]?.managerName}</DataName>
                <Position>대표</Position>
              </DivFlex>
            </DataHeader>
            <MapWrapper>
              <DataKey>휴대폰번호</DataKey>
              <DataValue>
                <DataValueText>
                  {representativeData[0]?.mobilePhone}
                </DataValueText>
              </DataValue>
              <DataKey>직급</DataKey>
              <DataValue>
                <DataValueText>{representativeData[0]?.position}</DataValueText>
              </DataValue>
            </MapWrapper>
          </DataBox>
        </div>
      )}

      {employeeData.map((data, index) => (
        <EmployeeItem
          key={`EmployeeItem_${index}`}
          data={data}
          gotoModify={gotoModify}
          toggleDeleteModal={toggleDeleteModal}
          toggleInviteModal={toggleInviteModal}
          toggleCancelModal={toggleCancelModal}
          toggleExportModal={toggleExportModal}
          setAlertModal={setAlertModal}
          setAlertMessage={setAlertMessage}
        />
      ))}

      {deleteModalInfo && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          deleteModalInfo={deleteModalInfo}
          toggleDeleteModal={toggleDeleteModal}
          successFnc={deleteSuccessFnc}
          setAlertModal={setAlertModal}
          setAlertMessage={setAlertMessage}
        />
      )}

      {inviteModalInfo && (
        <InviteModal
          showInviteModal={showInviteModal}
          inviteModalInfo={inviteModalInfo}
          toggleInviteModal={toggleInviteModal}
          successFnc={inviteSuccessFnc}
          setAlertModal={setAlertModal}
          setAlertMessage={setAlertMessage}
          gotoModify={gotoModify}
        />
      )}

      {cancelModalInfo && (
        <CancelModal
          showCancelModal={showCancelModal}
          cancelModalInfo={cancelModalInfo}
          toggleCancelModal={toggleCancelModal}
          successFnc={cancelSuccessFnc}
          setAlertModal={setAlertModal}
          setAlertMessage={setAlertMessage}
        />
      )}

      {exportModalInfo && (
        <ExportModal
          showExportModal={showExportModal}
          exportModalInfo={exportModalInfo}
          toggleExportModal={toggleExportModal}
          successFnc={exportSuccessFnc}
          setAlertModal={setAlertModal}
          setAlertMessage={setAlertMessage}
        />
      )}

      {registeredModalInfo && (
        <RegisterModal
          showRegisterModal={showRegisterModal}
          registeredModalInfo={registeredModalInfo}
          onClickSaveEmployeeData={onClickSaveEmployeeData}
        />
      )}

      {/* alert modal */}
      <NoBtnModal showModal={alertModal} onClose={onCloseNoBtnModal}>
        <ScrollArea>
          <Contents>{alertMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </>
  );
};

export default Manager;
