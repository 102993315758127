import styled from 'styled-components';
import theme from 'styles/theme';

export const MainSectionTwo = styled.div`
  position: relative;
  width: 100%;
  padding: 54px 30px 0;

  @media screen and (max-width: 375px) {
    padding: 54px 20px 0;
  }
`;

export const TextOne = styled.div`
  width: 100%;
  padding: 0 0 24px;
  text-align: center;
  font-size: 2.3rem;
  line-height: 3.1rem;
  font-weight: 400;
  color: ${theme.color.black};

  @media screen and (max-width: 375px) {
    padding: 0 0 16px;
    line-height: 2.9rem;
    font-size: 1.9rem;
  }
`;

export const TextOneSpan = styled.div`
  font-weight: 700;
  text-align: center;
`;

export const TableRowSection = styled.div`
  display: flex;
`;

export const ImgDiv = styled.div`
  margin-right: 12px;
  width: 60px;
  height: 60px;
  line-height: 0;

  @media screen and (max-width: 375px) {
    display: none;
  }
`;

export const Img = styled.img`
  width: 60px;
  height: 60px;
`;

export const Column = styled.div`
  width: calc(100% - 80px);
  position: relative;

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

export const LoanName = styled.div`
  width: 100%;
  max-width: calc(100% - 100px);
  margin: -4px 0px;
  font-size: 1.8rem;
  line-height: 2.5rem;
  color: ${theme.color.black};
  font-weight: 700;
  word-wrap: break-word;
  word-break: break-all;

  @media screen and (max-width: 375px) {
    max-width: calc(100% - 80px);
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

export const BankName = styled.div`
  width: 100%;
  margin: 7px 0 0;
  font-size: 1.3rem;
  font-weight: 400;
  color: ${theme.color.grey7};

  @media screen and (max-width: 375px) {
    margin: 10px 0 0;
    font-size: 1.1rem;
  }
`;

export const RowTwo = styled.div`
  width: 100%;
  margin: 13px 0 0;
`;

export const TextDiv = styled.div`
  line-height: 2.2rem;
  font-size: 1.4rem;
  color: ${theme.color.grey10};

  @media screen and (max-width: 375px) {
    line-height: 2rem;
    font-size: 1.2rem;
  }
`;

export const Button = styled.button`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background: url(/images/icons/dropdown/main_ar_s2.png) right no-repeat;
  background-size: 6px 11px;
  padding: 0 14px 0 0;
`;
