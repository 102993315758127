import React from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import Checkbox from 'components/CheckBox';
import {
  PopupSection,
  ImgSection,
  PopupImg,
  PopupText,
  PopupTitle,
  CheckboxSection,
  Contents,
  PopupBtnLeft,
  PopupBtnRight,
  PopupBtnWrap,
  PopupClose,
  PopupHeader,
  PopupWrapper,
} from './style';

const TwoBtnPopup = ({
  data = {},
  showPopup = false,
  onClickCheckBox = () => {},
  isChecked = false,
  onClose = () => {},
  onClickLeftBtn = () => {},
  onClickRightBtn = () => {},
  leftBtnText = '닫기',
  rightBtnText = '자세히 보기',
  rightButtonDisabled = false,
  buttonWidth,
}) => {
  const { imageFilePath, subject, contents } = data;

  return (
    !!showPopup && (
      <PopupSection>
        <PopupWrapper>
          <PopupHeader>
            <PopupClose onClick={onClose}>
              <FeatherIcon icon="x" size={20} strokeWidth={2.2} />
            </PopupClose>
          </PopupHeader>
          <Contents>
            {!!imageFilePath && (
              <ImgSection>
                <PopupImg src={imageFilePath} alt="공지 팝업" />
              </ImgSection>
            )}
            <PopupTitle>{subject}</PopupTitle>
            <PopupText>{contents}</PopupText>
            <CheckboxSection>
              <Checkbox
                id="notShowingPopupToday"
                onChangeCheckBox={onClickCheckBox}
                text="오늘 하루 보지 않기"
                isChecked={isChecked}
              />
            </CheckboxSection>
          </Contents>
          <PopupBtnWrap>
            <PopupBtnLeft onClick={onClickLeftBtn}>{leftBtnText}</PopupBtnLeft>
            <PopupBtnRight
              onClick={onClickRightBtn}
              disabled={rightButtonDisabled}
              width={buttonWidth}
            >
              {rightBtnText}
            </PopupBtnRight>
          </PopupBtnWrap>
        </PopupWrapper>
      </PopupSection>
    )
  );
};

export default TwoBtnPopup;
