import React, { useEffect } from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import {
  FilterModalWrapper,
  ModalHeader,
  Title,
  ModalBtnLeft,
  ModalBtnRight,
  ModalBtnWrap,
  ModalClose,
  FilterSideArea,
  Wrapper,
} from '../style';

const FilterModal = ({
  title,
  showModal,
  onClose,
  onReset,
  onClick,
  leftButton,
  rightButton,
  children,
  alert,
}) => {
  useEffect(() => {
    document.body.style.overflowY = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  return (
    showModal && (
      <Wrapper>
        <FilterModalWrapper>
          <ModalHeader>
            <Title>{title}</Title>
            <ModalClose onClick={onClose}>
              <FeatherIcon icon="x" size={20} strokeWidth={2.2} />
            </ModalClose>
          </ModalHeader>
          {children}
          {!onClick ? null : (
            <ModalBtnWrap>
              <ModalBtnLeft onClick={onReset ? onReset : onClose}>
                {leftButton}
              </ModalBtnLeft>
              <ModalBtnRight onClick={onClick}>{rightButton}</ModalBtnRight>
            </ModalBtnWrap>
          )}
        </FilterModalWrapper>
        <FilterSideArea onClick={onClose} />
      </Wrapper>
    )
  );
};

export default FilterModal;
