import styled from 'styled-components';
import theme from 'styles/theme';

export const BizNumberContainer = styled.div`
  width: 100%;
  padding: 28px 0 10px;
  max-width: 240px;
  display: flex;
`;
export const LinkageGuideLink = styled.a`
  margin-bottom: 15px;
  font-size: 1.3rem;
  color: ${theme.color.blue};
  text-decoration: underline;
`;

export const LinkContainer = styled.div`
  text-align: right;
  margin-bottom: 15px;
`;
