import styled from 'styled-components';
import theme from 'styles/theme';

export const ContentBox = styled.div`
  display: block;
  width: 100%;
  margin: 26px 0 0;
  padding: 32px 0 100px;
  border-top: 1px solid ${theme.color.grey4};
`;

export const CheckCards = styled.div`
  display: block;
  width: 100%;
  margin: 26px 0 0;
  border-top: 1px solid ${theme.color.grey4};
`;

export const ContentTitle = styled.div`
  display: block;
  width: 100%;
  margin: 19px 0 0;
  line-height: 2.1rem;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.navyBlue};

  &:first-child {
    margin: 0;
  }
`;

export const ContentData = styled.div`
  display: block;
  width: 100%;
  margin: 3px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ContentDataText = styled.div`
  display: block;
  float: left;
  line-height: 2.1rem;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  color: ${theme.color.black};
`;

export const TextBox = styled.div`
  display: block;
  width: 100%;
  padding: 0;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: ${theme.color.black};
`;

export const TextSpan = styled.span`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${theme.color.blue};
`;

export const TextBoxSpan = styled.span`
  margin-left: 10px;
  font-size: 1.4rem;
  font-weight: 500;
  color: ${theme.color.blue};
  text-decoration: underline;
`;
