import React from 'react';
import { TwoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';

const TwoBtnAlertModal = ({
  showTwoBtnAlertModal,
  showTwoBtnAlertModalText,
  showTwoBtnAlertModalBtnText,
  onClose,
  onClickLeftBtn,
  onClickRightBtn,
}) => {
  return (
    <TwoBtnModal
      showModal={showTwoBtnAlertModal}
      onClose={onClose}
      onClickLeftBtn={onClickLeftBtn}
      onClick={onClickRightBtn}
      leftButton={showTwoBtnAlertModalBtnText.left}
      rightButton={showTwoBtnAlertModalBtnText.right}
    >
      <ScrollArea>
        <Contents>{showTwoBtnAlertModalText}</Contents>
      </ScrollArea>
    </TwoBtnModal>
  );
};

export default TwoBtnAlertModal;
