import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getResultDataRequest } from 'utils/request';
import ROUTE_PATH from 'routes/config';
import { moneyFormat } from 'tools/MoneyTool';
import { NoBtnModal } from 'components/Modal';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import LoanDetailFooter from './LoanDetailFooter';
import { Contents, ScrollArea } from 'components/Modal/style';
import {
  MainSectionTwo,
  LoanDetailBox,
  TextMedium,
  TextBox,
  ImgDiv,
  Img,
  LoanName,
  BankName,
  TextContent,
  Status,
} from './style';

const imgHost = process.env.REACT_APP_FINANCE_IMG_HOST;

const LoanProductDetailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const { mypage } = ROUTE_PATH;
  const [isLoading, setIsLoading] = useState(true);

  // 상세 페이지
  const [loanDetailData, setLoanDetailData] = useState({});

  // alert 메시지
  const [isNoBtnModalOpen, setIsNoBtnModalOpen] = useState(false); // 오류 메시지 모달
  const [alertModalMessage, setAlertModalMessage] = useState(''); // 오류 메시지

  const financeImgUrl = imgHost + loanDetailData.financeImgPath;
  const maxLimitAmount = moneyFormat(loanDetailData.maximumLimit);
  const productMaxLimitAmount = moneyFormat(loanDetailData.productMaximumLimit);

  useEffect(() => {
    if (!!state) {
      getLoanDetailData({
        creditGoodsMasterEventSeq: state.creditGoodsMasterEventSeq,
        loansLimitInquirySeq: state.loansLimitInquirySeq,
      });
    } else {
      // navigate(mypage.checkLoan);
    }
  }, [state]);

  // 대출한도 상품 상세 조회
  const getLoanDetailData = ({
    creditGoodsMasterEventSeq,
    loansLimitInquirySeq,
  }) => {
    getResultDataRequest({
      url: `/v1/au/loans-limit/credit-goods/${creditGoodsMasterEventSeq}`,
      params: { loansLimitInquirySeq: loansLimitInquirySeq },
      successFnc: result => {
        const { midstreamRepaymentFeeSection } = result;
        const midstreamRepaymentFeeSectionText =
          midstreamRepaymentFeeSection === 'FEE'
            ? ''
            : midstreamRepaymentFeeSection === 'NO_FEE'
            ? '중도 상환 수수료 무료'
            : midstreamRepaymentFeeSection === 'CONDITION_NO_FREE'
            ? '중도 상환 수수료 조건부 무료 '
            : null;

        const modifiedLoanDetailData = {
          ...result,
          repaymentMeansMethodTypeList: result.repaymentMeansMethodTypeList.map(
            item => {
              return item.repaymentMeansMethodTypeName;
            }
          ),
          mallList: result.mallList.map(item => {
            return item.mallName;
          }),
          midstreamRepaymentFeeSectionName: midstreamRepaymentFeeSectionText,
        };

        setLoanDetailData(modifiedLoanDetailData);
        setIsLoading(false);
      },
      setModalMessage: setAlertModalMessage,
      setShowAlertModal: setIsNoBtnModalOpen,
    });
  };

  // 대출 신청
  const onClickLoanRequestBtn = () => {
    location.pathname.includes('credit-loan')
      ? navigate(mypage.requestCreditLoan, {
          state: {
            loanDetailData,
            creditGoodsMasterEventSeq: state.creditGoodsMasterEventSeq,
            memberCertificationSeq: state.memberCertificationSeq,
            usedMallList: state.usedMallList,
            loansLimitInquirySeq: state.loansLimitInquirySeq,
          },
        })
      : navigate(mypage.requestLoan, {
          state: {
            loanDetailData,
            creditGoodsMasterEventSeq: state.creditGoodsMasterEventSeq,
            memberCertificationSeq: state.memberCertificationSeq,
            usedMallList: state.usedMallList,
            loansLimitInquirySeq: state.loansLimitInquirySeq,
          },
        });
  };

  /* 오류 메시지 모달 */
  if (isLoading === true) {
    return (
      <NoBtnModal
        showModal={isNoBtnModalOpen}
        onClose={() => {
          setIsNoBtnModalOpen(false);
        }}
      >
        <ScrollArea>
          <Contents>{alertModalMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    );
  }

  return (
    <MainSectionTwo>
      <LoanDetailBox>
        <ImgDiv>
          <Img
            src={financeImgUrl}
            alt={loanDetailData.financeInstitutionName}
          />
        </ImgDiv>

        <LoanName>{loanDetailData.creditGoodsMasterEventName}</LoanName>
        <BankName>{loanDetailData.financeInstitutionName}</BankName>

        <TextBox>
          <TextMedium>금리</TextMedium>

          <TextContent>
            {loanDetailData.lowestInterest >= loanDetailData.maximumInterest
              ? `${loanDetailData.lowestInterest}%`
              : `${loanDetailData.lowestInterest} ~ 
                ${loanDetailData.maximumInterest}%`}
            {loanDetailData.privilegedInterest !== 0 ? (
              <Status>{loanDetailData.privilegedInterest}% 우대</Status>
            ) : null}
          </TextContent>
        </TextBox>

        <TextBox>
          <TextMedium>대출 신청 가능 최대 한도</TextMedium>
          <TextContent>{maxLimitAmount}</TextContent>
        </TextBox>

        <TextBox>
          <TextMedium>상품 최대 한도</TextMedium>
          <TextContent style={{ fontSize: '1.4rem' }}>
            {!!productMaxLimitAmount
              ? `${productMaxLimitAmount}`
              : `해당 금융사 내부 심사에 따라 상이`}
          </TextContent>
        </TextBox>

        {loanDetailData.targetMallNameList?.length > 0 ? (
          <TextBox>
            <TextMedium>대상 판매몰</TextMedium>
            <TextContent style={{ fontSize: '1.4rem' }}>
              {loanDetailData.targetMallNameList?.join(', ')}
            </TextContent>
            <TextContent style={{ fontSize: '1.4rem' }}>
              해당 판매몰의 셀러 전용 상품입니다.
            </TextContent>
          </TextBox>
        ) : null}

        {/* 우대받을 수 있는 판매몰이 없으면 null */}
        {loanDetailData.mallList?.length > 0 ? (
          <TextBox>
            <TextMedium>한도 우대 받을 수 있는 판매몰</TextMedium>
            <TextContent style={{ fontSize: '1.4rem' }}>
              {loanDetailData.mallList?.join(', ')}
            </TextContent>
          </TextBox>
        ) : null}

        {/* 중도 상환 수수료 있으면 null */}
        {loanDetailData.midstreamRepaymentFeeSectionName && (
          <TextBox>
            <TextMedium>중도 상환 수수료</TextMedium>
            <TextContent style={{ fontSize: '1.4rem' }}>
              {loanDetailData.midstreamRepaymentFeeSectionName}
            </TextContent>
          </TextBox>
        )}

        {/* 상환 방법 데이터가 없으면 null */}
        {loanDetailData.repaymentMeansMethodTypeList?.length > 0 && (
          <TextBox>
            <TextMedium>상환 방법</TextMedium>
            <TextContent style={{ fontSize: '1.4rem' }}>
              {loanDetailData.repaymentMeansMethodTypeList.length > 1
                ? `${loanDetailData.repaymentMeansMethodTypeList?.join(
                    ', '
                  )} 택 1`
                : loanDetailData.repaymentMeansMethodTypeList[0]}
            </TextContent>
          </TextBox>
        )}

        <TextBox>
          <TextMedium>상품 설명</TextMedium>
          <TextContent style={{ fontSize: '1.4rem' }}>
            {loanDetailData.creditGoodsDescription}
          </TextContent>
        </TextBox>

        <StyledActiveButton onClick={onClickLoanRequestBtn}>
          {location.pathname.includes('credit-loan')
            ? '신용 대출 신청'
            : '담보 대출 신청'}
        </StyledActiveButton>
      </LoanDetailBox>

      <LoanDetailFooter detailList={loanDetailData.detailList} />
    </MainSectionTwo>
  );
};

export default LoanProductDetailPage;
