import React from 'react';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';

const LinkFailedModal = ({ isOpenModal, setIsOpenModal }) => {
  const title = '연동 실패';
  return (
    <NoBtnModal
      showModal={isOpenModal}
      onClose={() => setIsOpenModal(false)}
      title={title}
    >
      <ScrollArea title={title}>
        <Contents>
          연동 실패 안내 문자가 담당자에게 발송되었어요.
          <br />
          문자를 확인 후 '수정' 버튼을 클릭하여 연동을
          <br />
          재진행해 주세요. 문자를 수신 받지 못한 경우,
          <br />
          고객센터로 문의해 주세요.
        </Contents>
      </ScrollArea>
    </NoBtnModal>
  );
};

export default LinkFailedModal;
