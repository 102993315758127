import React from 'react';
import { getKoreanFormatDate } from 'tools/DateTool';
import { TabSection } from 'pages/Login/JoinMemberPolicyPopupPage/style';
import {
  PolicyDetailContent,
  PolicyDiv,
  PolicyTitle,
  PolicyBottom,
} from './style';

const stipulation = window.opener?._stipulationData;

const DefaultPolicyPopupPage = () => {
  return (
    <TabSection>
      <PolicyTitle>{stipulation?.stipulationCdName}</PolicyTitle>
      <PolicyDetailContent
        dangerouslySetInnerHTML={{
          __html:
            stipulation?.stipulationContents ||
            '<div style="display: flex; justify-content: center; align-items: center; min-height: 100px;">Not Available<div>',
        }}
      />
      {stipulation?.stipulationBeginDate && (
        <PolicyBottom>
          <PolicyDiv>부칙</PolicyDiv>
          <PolicyDiv>
            본 약관은 {getKoreanFormatDate(stipulation?.stipulationBeginDate)}
            부터 적용됩니다.
          </PolicyDiv>
        </PolicyBottom>
      )}
    </TabSection>
  );
};

export default DefaultPolicyPopupPage;
