import React, { useEffect, useState } from 'react';
import { getListDataRequest } from 'utils/request';
import FoldedCard from 'components/FoldedCard';
import NoListText from 'components/NoListText';
import HeadlessPagination from 'components/HeadlessPagination';

const NoticeTab = () => {
  const [_noticeList, _setNoticeList] = useState([]);
  const [noticeList, setNoticeList] = useState([]);
  // pagination
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(0);

  useEffect(() => {
    getListDataRequest({
      url: '/v1/na/notice',
      params: { pageNo: page + 1 },
      setData: _setNoticeList,
      setPaginationData,
    });
  }, [page]);

  useEffect(() => {
    if (_noticeList.length > 0) {
      setNoticeList(
        _noticeList
          .sort((a, b) => b.notiPeriodStartDt - a.notiPeriodStartDt)
          .map(item => ({ ...item, isOpen: false }))
      );
    }
  }, [_noticeList]);

  const onClickDetailBtn = seq => {
    setNoticeList(list =>
      list.map((item, index) =>
        index === seq
          ? { ...item, isOpen: !item.isOpen }
          : { ...item, isOpen: false }
      )
    );
  };

  if (noticeList.length === 0) {
    return (
      <NoListText
        title="공지사항이 없습니다."
        description="궁금한 점이 있으시면 고객센터로 문의해 주세요."
      />
    );
  }

  return (
    <div>
      {noticeList.map((item, index) => {
        return (
          <FoldedCard
            name="notice"
            seq={item.noticeSeq}
            key={`FoldedCard_${index}`}
            index={index}
            isOpen={item.isOpen}
            title={item.subject}
            subText={item.notiPeriodStartDt}
            contents={item.contents}
            onClickDetailBtn={onClickDetailBtn}
          />
        );
      })}

      <HeadlessPagination
        page={page}
        setPage={setPage}
        paginationData={paginationData}
      />
    </div>
  );
};

export default NoticeTab;
