import React from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import theme from 'styles/theme';
import {
  LogoTopbarHeader,
  HomeLogo,
  RContent,
  LoanButton,
  MypageButton,
  LoginButton,
} from './style';

const LogoTopbar = ({
  pathname,
  authenticated,
  showButtons = true,
  showBanner = false,
}) => {
  const navigate = useNavigate();
  const { serviceView, mypage } = ROUTE_PATH;

  return (
    <LogoTopbarHeader
      main={pathname === '/' || pathname === serviceView}
      showBanner={showBanner}
    >
      <HomeLogo
        tabIndex={0}
        title="홈으로 이동"
        onClick={() => {
          navigate('/');
        }}
      >
        셀러 크레딧 커넥트
      </HomeLogo>

      {showButtons === true && (
        <RContent>
          <LoanButton
            tabIndex={0}
            main={pathname === '/'}
            onClick={() => {
              navigate(mypage.checkLoan);
            }}
          >
            대출 비교하기
          </LoanButton>
          {authenticated ? (
            <MypageButton>
              <FeatherIcon
                icon="menu"
                size={20}
                color={theme.color.grey3}
                onClick={() => {
                  navigate(mypage.main);
                }}
              />
            </MypageButton>
          ) : (
            <LoginButton>
              <FeatherIcon
                icon="user"
                size={20}
                color={theme.color.grey9}
                onClick={() => {
                  navigate(mypage.noAuth);
                }}
              />
            </LoginButton>
          )}
        </RContent>
      )}
    </LogoTopbarHeader>
  );
};

export default LogoTopbar;
