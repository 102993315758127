import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthRouteOutlet } from './AuthRouteOutlet';
import ProtectedRoute from './protected_route';
import ROUTE_PATH from './config';
import Layout from 'layouts';
import EmptyPage from 'pages/EmptyPage';
import Error404 from 'pages/Error404Page';
// 메인
import Main from 'pages/Main';
// 서비스 소개
import ServiceView from 'pages/ServiceView';
// 이벤트
import EventPage from 'pages/Event/EventPage';
import EventDetailPage from 'pages/Event/EventDetailPage';
// 고객 센터
import ServiceCenter from 'pages/ServiceCenter';
import CreateQuestionNoAuthPage from 'pages/ServiceCenter/CreateQuestionNoAuthPage';
import CreateQuestionAuthPage from 'pages/ServiceCenter/CreateQuestionAuthPage';
import FAQDetailPage from 'pages/ServiceCenter/FAQTab/FAQDetailPage';
import NoticeDetailPage from 'pages/ServiceCenter/NoticeTab/NoticieDetailPage';
import QnAPolicyPopupPage from 'pages/ServiceCenter/QnAPolicyPopupPage';
// 이용 약관
import JoinMemberPolicyPopupPage from 'pages/Login/JoinMemberPolicyPopupPage';
// 본인 인증 팝업페이지
import IdentificationPage from 'pages/Identification/IdentificationPage';
import IdentificationSuccessCallback from 'pages/Identification/IdentificationSuccessCallback';
import IdentificationFailCallback from 'pages/Identification/IdentificationFailCallback';
// 로그인
import Login from 'pages/Login/Login';
import SearchId from 'pages/Login/SearchId';
import SearchPassword from 'pages/Login/SearchPassword';
import JoinMemberPage from 'pages/Login/JoinMemberPage';
// 마이페이지
import MyPageNoAuth from 'pages/MyPage/MyPageNoAuth';
import MyPageMain from 'pages/MyPage/MyPageMain';
import MyInform from 'pages/MyPage/MyInform';
import UpdateBusiness from 'pages/MyPage/MyInform/UpdateBusiness';
import UpdatePassword from 'pages/MyPage/MyInform/UpdatePassword';
import UpdateEmail from 'pages/MyPage/MyInform/UpdateEmail';
import Withdrawal from 'pages/MyPage/MyInform/MemberWithdrawal';
import Manager from 'pages/MyPage/Manager';
import ManagerRegister from 'pages/MyPage/Manager/ManagerRegister';
import DataLinkageManage from 'pages/MyPage/DataLinkage/DataLinkageManage';
import DataLinkageIndividualRequestPage from 'pages/MyPage/DataLinkage/DataLinkageRequest/IndividualRequestPage';
import DataLinkageDataProviderRequestPage from 'pages/MyPage/DataLinkage/DataLinkageRequest/DataProviderRequestPage';
import DataLinkageModify from 'pages/MyPage/DataLinkage/DataLinkageModify';
import DataLinkageGuide from 'pages/MyPage/DataLinkage/DataLinkageGuide';
import LoanDataProvideManage from 'pages/MyPage/LoanDataProvideManage';
import EstimatedAmountManage from 'pages/MyPage/EstimatedAmountManage';
import EstimatedAmountDetail from 'pages/MyPage/EstimatedAmountManage/EstimatedAmountManageTab/EstimatedAmountDetailPopupPage';
import LoanLimitBasicPage from 'pages/MyPage/LoanLimit/LoanLimitBasicPage';
import LoanProductsListPage from 'pages/MyPage/LoanLimit/LoanProductsListPage';
import LoanProductDetailPage from 'pages/MyPage/LoanLimit/LoanProductDetailPage';
import LoanRequest from 'pages/MyPage/LoanRequest';
import MallSecondAuthPage from '../pages/MallSecondAuthPage';
// 딥링크 안내 페이지
import DeepLinkAgreeEntryPage from 'pages/DeepLink/DeepLinkAgreeEntryPage';
import DeepLinkSecuredPage from 'pages/DeepLink/DeepLinkSecuredPage';
import DeepLinkUnsecuredPage from 'pages/DeepLink/DeepLinkUnsecuredPage';
import DeepLinkAgreePage from 'pages/DeepLink/DeepLinkAgreePage';
import DeepLinkPolicyPopupPage from 'pages/DeepLink/DeepLinkPolicyPopupPage';
import DeepLinkJoinMemberGuidePage from 'pages/DeepLink/DeepLinkJoinMemberGuidePage';
import FinancialSupervisoryServicePopupPage from 'pages/Popup/FinancialSupervisoryServicePopupPage';
import AffiliateFinancePopupPage from 'pages/Popup/AffiliateFinancePopupPage';
import DefaultPopupPage from '../pages/Popup';
import DefaultPolicyPopupPage from 'pages/Popup/DefaultPolicyPopupPage';
import DeepLinkJoinMemberPage from 'pages/DeepLink/DeepLinkJoinMemberPage';
import DeepLinkFinanceAppInterlockPage from '../pages/DeepLink/DeepLinkFinanceAppInterlockPage';
import ScrollToTop from '../helpers/ScrollToTop';

const Index = () => {
  const {
    identification,
    serviceView,
    event,
    eventDetail,
    policy,
    serviceCenter,
    login,
    mypage,
    deepLink,
    identificationSuccessCallback,
    identificationFailCallback,
    mallSecondAuth,
    financialSupervisoryService,
    affiliateFinance,
  } = ROUTE_PATH;

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" index element={<Main />} />
          <Route element={<AuthRouteOutlet otherwise={true} />}>
            <Route path={serviceView} element={<ServiceView />} />
            <Route path={event} element={<EventPage />} />
            <Route path={eventDetail} element={<EventDetailPage />} />
            <Route path={policy} element={<DefaultPolicyPopupPage />} />
            <Route path={serviceCenter.main} element={<ServiceCenter />} />
            <Route path={serviceCenter.faqDetail} element={<FAQDetailPage />} />
            <Route
              path={serviceCenter.noticeDetail}
              element={<NoticeDetailPage />}
            />
            <Route
              path={serviceCenter.qnaPolicy}
              element={<QnAPolicyPopupPage />}
            />
            <Route
              path={serviceCenter.qnaPolicyDefault}
              element={<QnAPolicyPopupPage />}
            />
            {/* 회원 가입 마지막 과정에서 로그인하게 되므로 페이지에서 직접 로그인여부 판단 */}
            <Route path={login.joinMember} element={<JoinMemberPage />} />
            <Route
              path={deepLink.joinMember}
              element={<DeepLinkJoinMemberPage />}
            />
            <Route
              path={login.joinMemberPolicyDefault}
              element={<JoinMemberPolicyPopupPage />}
            />
            <Route
              path={login.joinMemberPolicy}
              element={<JoinMemberPolicyPopupPage />}
            />
            <Route path={identification} element={<IdentificationPage />} />
            <Route
              path={identificationSuccessCallback}
              element={<IdentificationSuccessCallback />}
            />
            <Route
              path={identificationFailCallback}
              element={<IdentificationFailCallback />}
            />
            <Route
              path={mypage.dataLinkageGuide}
              element={<DataLinkageGuide />}
            />
            {/* 회원가입 딥링크 페이지*/}
            <Route
              path={deepLink.serviceGuide}
              element={<DeepLinkJoinMemberGuidePage />}
            />
            {/* 딥링크 안내페이지 (통합) */}
            <Route
              path={deepLink.default}
              element={<DeepLinkAgreeEntryPage />}
            />
            {/* 딥링크 안내페이지 (담보 / 정적) */}
            <Route path={deepLink.secured} element={<DeepLinkSecuredPage />} />
            {/* 딥링크 안내페이지 (담보 / 동적) */}
            <Route
              path={deepLink.securedDetail}
              element={<DeepLinkSecuredPage />}
            />
            {/* 딥링크 안내페이지 (비담보 / 정적) */}
            <Route
              path={deepLink.unsecured}
              element={<DeepLinkUnsecuredPage />}
            />
            {/* 딥링크 안내페이지 (비담보 / 동적) */}
            <Route
              path={deepLink.unsecuredDetail}
              element={<DeepLinkUnsecuredPage />}
            />
            <Route path={deepLink.agree} element={<DeepLinkAgreePage />} />
            <Route
              path={financialSupervisoryService}
              element={<FinancialSupervisoryServicePopupPage />}
            />
            <Route
              path={affiliateFinance}
              element={<AffiliateFinancePopupPage />}
            />
            <Route path={ROUTE_PATH.policy} element={<DefaultPopupPage />} />
            <Route
              path={deepLink.financeAppInterlock}
              element={<DeepLinkFinanceAppInterlockPage />}
            />
          </Route>
          {/* 권한이 반드시 없어야하는 페이지들 */}
          <Route element={<AuthRouteOutlet withoutAuth />}>
            <Route path={login.login} element={<Login />} />
            <Route path={login.searchId} element={<SearchId />} />
            <Route path={login.searchPassword} element={<SearchPassword />} />
            <Route path={mypage.noAuth} element={<MyPageNoAuth />} />
            <Route
              path={serviceCenter.noAuth.create}
              element={<CreateQuestionNoAuthPage />}
            />
          </Route>
          {/* 로그인 권한이 필요한 페이지들 */}

          <Route element={<AuthRouteOutlet />}>
            {/* 사업자 맵핑 체크하지 않아도 되는 페이지 */}
            {/* 내정보 */}
            <Route path={mypage.main} element={<MyPageMain />} />
            <Route path={mypage.myInform} element={<MyInform />} />
            <Route path={mypage.updateBusiness} element={<UpdateBusiness />} />
            <Route path={mypage.updatePassword} element={<UpdatePassword />} />
            <Route path={mypage.updateEmail} element={<UpdateEmail />} />
            <Route path={mypage.withdrawal} element={<Withdrawal />} />
            {/* 고객센터 */}
            <Route
              path={serviceCenter.auth.create}
              element={<CreateQuestionAuthPage />}
            />
            {/* 2차인증딥링크 */}
            <Route path={mallSecondAuth} element={<MallSecondAuthPage />} />
            {/* 약관동의딥링크 */}
            <Route
              path={deepLink.policyDefault}
              element={<DeepLinkPolicyPopupPage />}
            />
            <Route
              path={deepLink.policy}
              element={<DeepLinkPolicyPopupPage />}
            />
            {/* 사업자 맵핑 체크하는 페이지 */}
            {/* 직원 관리*/}
            <Route
              path={mypage.manager}
              element={<ProtectedRoute renderComp={<Manager />} />}
            />
            <Route
              path={mypage.managerRegister}
              element={<ProtectedRoute renderComp={<ManagerRegister />} />}
            />
            {/* 대출 신청 */}
            <Route
              path={mypage.checkLoan}
              element={<ProtectedRoute renderComp={<LoanLimitBasicPage />} />}
            />
            <Route
              path={mypage.checkLoanList}
              element={<ProtectedRoute renderComp={<LoanProductsListPage />} />}
            />
            <Route
              path={mypage.checkCreditLoanList}
              element={<ProtectedRoute renderComp={<LoanProductsListPage />} />}
            />
            <Route
              path={mypage.checkLoanDetail}
              element={
                <ProtectedRoute renderComp={<LoanProductDetailPage />} />
              }
            />
            <Route
              path={mypage.checkCreditLoanDetail}
              element={
                <ProtectedRoute renderComp={<LoanProductDetailPage />} />
              }
            />
            <Route
              path={mypage.requestLoan}
              element={<ProtectedRoute renderComp={<LoanRequest />} />}
            />
            <Route
              path={mypage.requestCreditLoan}
              element={<ProtectedRoute renderComp={<LoanRequest />} />}
            />
            {/* 대출 관리 */}
            <Route
              path={mypage.manageLoanDataProvide}
              element={
                <ProtectedRoute renderComp={<LoanDataProvideManage />} />
              }
            />
            {/* 데이터 연동 관리 */}
            <Route
              path={mypage.dataLinkageManage}
              element={<ProtectedRoute renderComp={<DataLinkageManage />} />}
            />
            <Route
              path={mypage.dataLinkageDataInterlockRequest}
              element={
                <ProtectedRoute
                  renderComp={<DataLinkageIndividualRequestPage />}
                />
              }
            />
            <Route
              path={mypage.dataLinkageDataProviderRequest}
              element={
                <ProtectedRoute
                  renderComp={<DataLinkageDataProviderRequestPage />}
                />
              }
            />
            <Route
              path={mypage.dataLinkageModify}
              element={<ProtectedRoute renderComp={<DataLinkageModify />} />}
            />
            {/* 정산예정금 관리 */}
            <Route
              path={mypage.estimatedAmountManage}
              element={
                <ProtectedRoute renderComp={<EstimatedAmountManage />} />
              }
            />
            <Route
              path={mypage.estimatedAmountDetail}
              element={
                <ProtectedRoute renderComp={<EstimatedAmountDetail />} />
              }
            />
          </Route>
          <Route path="/empty" element={<EmptyPage />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </>
  );
};

export default Index;
