import React, { useEffect, useState } from 'react';
import { encodeToBase64String } from 'tools/StringTool';
import CheckBox from 'components/CheckBox';
import TextArea from 'components/TextArea';
import { QUESTION_TYPE_CD } from './constants';
import { BlackLabel, BlueLabel } from '../style';

const EventQuestion = ({
  questionList = [],
  setPostData,
  checkUserLogin = event => {},
}) => {
  const [answerList, setAnswerList] = useState([...questionList]);

  useEffect(() => {
    const filteredAnswerList = answerList
      .flatMap(item => item.itemList)
      .filter(item => item.checked === true)
      .map(({ eventQuestionItemSeq, itemName }) => ({
        eventQuestionItemSeq,
        answerContents: encodeToBase64String(itemName),
      }));
    setPostData(prevState => ({
      ...prevState,
      answerList: filteredAnswerList,
    }));
  }, [answerList]);

  const onChangeTextArea = event => {
    checkUserLogin(event);
    const { name, value } = event.target;
    setAnswerList(prevState =>
      prevState.map(item => ({
        ...item,
        itemList: item.itemList?.map(subItem =>
          subItem.eventQuestionItemSeq === Number(name)
            ? {
                ...subItem,
                itemName: value,
                checked: !!value,
              }
            : { ...subItem }
        ),
      }))
    );
  };

  const onChangeCheckBox = event => {
    checkUserLogin(event);
    const { value, checked, name } = event.target;
    setAnswerList(prevState =>
      prevState.map(item => {
        return item.eventQuestionSeq === Number(name)
          ? item.duplicationAnswerYn === 'Y'
            ? {
                ...item,
                itemList: item.itemList?.map(subItem =>
                  subItem.eventQuestionItemSeq === Number(value)
                    ? { ...subItem, checked: checked }
                    : { ...subItem }
                ),
              }
            : {
                ...item,
                itemList: item.itemList?.map(subItem =>
                  subItem.eventQuestionItemSeq === Number(value)
                    ? { ...subItem, checked: checked }
                    : { ...subItem, checked: false }
                ),
              }
          : item;
      })
    );
  };

  return (
    <div>
      <BlueLabel>아래 질문에 답변을 입력해 주세요.</BlueLabel>
      {answerList.map((item, index) => {
        return (
          <div
            key={`event_question_${item.eventQuestionSeq}_${index}`}
            style={{ padding: '10px 0' }}
          >
            <BlackLabel>{item.questionContents}</BlackLabel>

            {item.questionTypeCd ===
            QUESTION_TYPE_CD.find(({ name }) => name === '서술형 질문').code
              ? item.itemList?.map(subItem => {
                  return (
                    <TextArea
                      key={`event_question_item_${subItem.eventQuestionItemSeq}`}
                      onChange={onChangeTextArea}
                      value={subItem.itemName || ''}
                      name={subItem.eventQuestionItemSeq}
                      maxLength={80}
                      style={{
                        margin: '4px 0 0 0',
                        padding: '8px 10px',
                        lineHeight: '1.9rem',
                      }}
                    />
                  );
                })
              : QUESTION_TYPE_CD.find(({ name }) => name === '선택형 질문').code
              ? item.itemList?.map(subItem => {
                  return (
                    !!subItem.itemName && (
                      <CheckBox
                        key={`event_question_item_${subItem.eventQuestionItemSeq}`}
                        id={`check-box-${subItem.eventQuestionItemSeq}`}
                        isChecked={subItem.checked}
                        onChangeCheckBox={onChangeCheckBox}
                        value={subItem.eventQuestionItemSeq}
                        name={item.eventQuestionSeq}
                        type={
                          item.duplicationAnswerYn === 'Y'
                            ? 'checkbox'
                            : 'radio'
                        }
                        text={subItem.itemName}
                        style={{
                          padding: '0 0 4px 0',
                          fontSize: '1.3rem',
                        }}
                      />
                    )
                  );
                })
              : null}
          </div>
        );
      })}
    </div>
  );
};

export default EventQuestion;
