import React, { useState, createContext, useEffect } from 'react';
import Route from 'routes';
import useAuth from 'hooks/useAuth';
import GlobalStyle from 'styles/globalStyle';

export const AppContext = createContext(undefined);

const App = () => {
  const { isLoading, ...auth } = useAuth({});
  const [savedCheckLoanPageData, setSavedCheckLoanPageData] = useState({});

  const setScreenSize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    setScreenSize();
  }, []);

  return (
    <div className="App">
      <AppContext.Provider
        value={{
          auth,
          savedCheckLoanPageData,
          setSavedCheckLoanPageData,
        }}
      >
        <GlobalStyle />
        <Route />
      </AppContext.Provider>
    </div>
  );
};

export default App;
