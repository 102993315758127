import styled from 'styled-components';
import theme from 'styles/theme';

export const Table = styled.table`
  width: 100%;
  margin: 26px 0 0;
  padding: 16px 0 17px;
  border-top: 1px solid ${theme.color.grey4};
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  width: 100%;
  border-bottom: 1px solid ${theme.borderColor.lightGrey};
`;

export const TableData = styled.td`
  padding: 14px 0 14px;
`;
