import React, { useEffect } from 'react';
import {
  BackgroundCard,
  ButtonSection,
  ImgSection,
  PopupButton,
  PopupImg,
  SideArea,
  PopupSection,
  PopupTitle,
} from './style';

const NoticeBottomPopup = ({
  data = {},
  showModal = false,
  onClose = () => {},
  onClick = () => {},
  onClickNotShowingPopupBtn = () => {},
}) => {
  const { subject, imageFilePath } = data;

  // 팝업이 열려 있는 동안 스크롤 방지
  useEffect(() => {
    if (!!showModal) {
      document.body.style.cssText = `
    position: fixed;
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `overflow-y: auto`;
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
  }, [showModal]);

  return (
    !!showModal && (
      <PopupSection>
        <SideArea onClick={onClose}>
          <BackgroundCard onClick={onClick}>
            {!!imageFilePath && (
              <ImgSection>
                <PopupImg src={imageFilePath} alt="신규 공지" />
              </ImgSection>
            )}
            <PopupTitle>{subject}</PopupTitle>
            <ButtonSection>
              <PopupButton onClick={onClickNotShowingPopupBtn}>
                오늘 하루 보지 않기
              </PopupButton>
              <PopupButton onClick={onClose}>닫기</PopupButton>
            </ButtonSection>
          </BackgroundCard>
        </SideArea>
      </PopupSection>
    )
  );
};

export default NoticeBottomPopup;
