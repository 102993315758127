import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const SelectAll = styled.div`
  padding: 20px 0 16px;
`;

export const Label = styled.label`
  display: inline-block;
  position: relative;
  float: left;
  max-width: 100%;
  margin-bottom: 0;
  padding: 6px 16px 5px 29px;
  line-height: 2.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;

  @media screen and (max-width: 375px) {
    padding-left: 30px;
  }

  @media screen and (max-width: 320px) {
    font-size: 1.4rem;
  }

  ${props => {
    if (props.className) {
      if (props.className === 'check-card') {
        return css`
          font-size: 1.5rem;
          font-weight: 400;
        `;
      }
    }
  }};
`;

export const CheckCards = styled.div`
  display: block;
  width: 100%;
  margin: 26px 0 0;
  border-top: 1px solid ${theme.color.grey4};
`;

export const CheckCardDiv = styled.div`
  display: flex;
  width: 100%;
`;

export const CheckCard = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding: 12px 0 18px;
  border-bottom: 1px solid ${theme.borderColor.lightGrey};

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  @media screen and (max-width: 375px) {
    padding: 36px 0 18px;
  }
`;

export const CheckCardText = styled.div`
  margin-left: 30px;
  line-height: 2.1rem;
  font-size: 1.2rem;
  font-weight: ${props => (props.bold ? '800' : '400')};
  text-align: left;
  color: ${theme.color.grey7};
`;

export const CheckCardStatus = styled.div`
  display: block;
  position: absolute;
  top: 21px;
  right: 0;
  width: 64px;
  height: 17px;
  line-height: 1.7rem;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  border-radius: 5px;

  color: ${({ color }) => theme.color[color || 'blue']};
  background: ${({ backgroundColor }) =>
    theme.backgroundColor[backgroundColor || 'checkBlue']};
`;

export const TextBox = styled.div`
  display: block;
  width: 100%;
  padding: 10px 0;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  text-align: center;
  color: ${theme.color.black};
`;
