import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: ${theme.layout.minWidth};
  max-width: ${theme.layout.maxWidth};
  min-height: 100%;

  @media only screen and (min-width: 1200px) {
    position: relative;
    left: calc(50% + 12px);
    margin: 0;
    box-shadow: 0 2px 50px 0 rgb(0 0 0 / 13%);
    z-index: 10;
    overflow-x: hidden;
  }

  @media only screen and (min-width: 486px) and (max-width: 1199.9px) {
    position: relative;
    margin: 0 auto;
    box-shadow: 0 2px 50px 0 rgb(0 0 0 / 13%);
    z-index: 10;
    max-width: 486px;
  }
`;

export const ModalWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 3000;
  width: calc(100% - 40px);
  min-width: 265px;
  max-width: 440px;
  top: calc(50% + 30px);
  left: calc(50% + 35px);

  padding: 0 12px;
  background: ${theme.backgroundColor.white};
  border-radius: 20px;
  transform: translateY(-50%);

  ${props => {
    if (props.border) {
      return css`
        border: 1px solid ${theme.borderColor.grey5};
      `;
    }
  }}

  @media screen and (max-width: 1199.9px) {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 485.9px) {
    width: calc(100% - 40px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const FilterModalWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 3001;
  width: calc(100% - 40px);
  min-width: 265px;
  max-width: 440px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 0 12px;
  background: ${theme.backgroundColor.white};
  border-radius: 20px;

  ${props => {
    if (props.$policy) {
      return css`
        height: 70vh;
        border-radius: 0;
      `;
    }
  }}

  @media screen and (max-width: 1199.9px) {
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const SideArea = styled.div`
  display: block;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  min-width: ${theme.layout.minWidth};
  max-width: ${theme.layout.maxWidth};
  min-height: calc(
    (var(--vh, 1vh) * 100) + var(--ios-safe-top) + var(--ios-safe-bottom)
  );
  top: 0;
  left: calc(50% + 12px);

  margin: 0;
  background: ${theme.backgroundColor.shadow};

  @media screen and (max-width: 1199.9px) {
    left: 50%;
    transform: translateX(-50%);
    max-width: ${theme.layout.maxWidth};
  }

  @media only screen and (min-width: 1200px) {
    left: calc(50% + 12px);
    margin: 0;
  }

  @media only screen and (min-width: 486px) and (max-width: 1199.9px) {
    left: 50%;
    transform: translateX(-50%);
    max-width: 486px;
  }

  @media screen and (max-width: 485.9px) {
    left: 50%;
    transform: translateX(-50%);
    max-width: 486px;
  }
`;

export const FilterSideArea = styled.div`
  overflow: hidden;
  display: block;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  min-width: ${theme.layout.minWidth};
  max-width: ${theme.layout.maxWidth};
  min-height: 100%;

  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  border-radius: 20px;
  background: ${theme.backgroundColor.shadow};

  @media screen and (max-width: 1199.9px) {
    left: 50%;
    transform: translateX(-50%);
    max-width: ${theme.layout.maxWidth};
  }
`;

export const ModalHeader = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 50px;

  padding: 18px 0 0;
  text-align: center;
  border-radius: 20px 20px 0 0;

  ${props => {
    if (props.$policy) {
      return css`
        width: calc(100% + 24px);
        transform: translateX(-12px);
        height: 58px;
        border-bottom: 1px solid ${theme.borderColor.lightGrey};
      `;
    }
  }}
`;

export const Title = styled.div`
  display: block;
  width: 100%;

  text-align: center;
  line-height: 2.6rem;
  font-size: 1.6rem;
  font-weight: 700;
  color: ${theme.color.black};
`;

export const ModalClose = styled.button`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 30px;
  height: 60px;

  ${props => {
    if (props.$policy) {
      return css`
        left: 0;
        padding-left: 10px;
      `;
    }
  }}
`;

export const NoScrollArea = styled.div`
  display: block;
  width: 100%;
  padding: 0 8px;
`;

export const ScrollArea = styled.div`
  overflow-y: ${props => (props.hiddenScroll ? 'hidden' : 'auto')};
  overflow-x: hidden;
  display: block;
  width: 100%;
  padding: 8px 8px 0;
  margin-bottom: 40px;
  max-height: calc(100vh - 366px);

  @media screen and (max-width: 375px) {
    margin-bottom: 30px;
  }

  ${props => {
    if (props.marginTop) {
      return css`
        margin-top: 20px;
      `;
    }
    if (props.title) {
      return css`
        margin-bottom: 18px;
      `;
    } else if (props.dataLink || props.marginTop) {
      return css`
        margin-top: 20px;
      `;
    }
  }}

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.backgroundColor.blue};
    border-radius: 0;
    background-clip: padding-box;
    border-left: 0 solid transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: ${theme.backgroundColor.scrollIcyBlue};
    border-radius: 0;
  }
`;

export const Contents = styled.div`
  width: 100%;
  padding: 0;
  line-height: 2.2rem;
  font-size: 1.5rem;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 500)};
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  color: ${theme.color.black};

  white-space: pre-line;

  @media screen and (max-width: 375px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 320px) {
    font-size: 1.4rem;
  }
`;

export const ContentsBox = styled.div`
  width: 100%;
  margin: 30px 0px;
`;

export const ModalBtnWrap = styled.div`
  display: flex;
  width: calc(100% + 24px);
  height: 54px;

  transform: translateX(-12px);

  @media screen and (max-width: 320px) {
    height: 45px;
  }
`;

export const ModalVerticalBtnWrap = styled.div`
  display: flex;
  flex-direction: column;
  //width: calc(100% + 24px);
  //transform: translateX(-12px);
`;

export const ModalBtn = styled.button`
  height: 48px;
  margin: 0 10px 20px 10px;

  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;

  border-radius: 8px;
  color: ${theme.color.white};
  background: ${props =>
    props.background
      ? theme.backgroundColor[props.background]
      : theme.backgroundColor.grey5};

  @media screen and (max-width: 320px) {
    height: 45px;
    font-size: 1.3rem;
  }

  &:disabled {
    background: ${theme.backgroundColor.buttonGrey};
    border: 1px solid ${theme.borderColor.buttonGrey};
    cursor: default;
  }
`;

export const ModalBtnLeft = styled.button`
  display: block;
  float: left;
  width: 50%;
  height: 54px;
  //line-height: 4.8rem; // 버튼 텍스트가 두줄 될 수 있음
  font-size: 1.6rem;
  text-align: center;
  font-weight: 700;
  color: ${theme.color.white};
  background: ${theme.backgroundColor.grey5};
  border: 1px solid ${theme.borderColor.grey5};
  border-radius: 0 0 0 20px;

  @media screen and (max-width: 375px) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 320px) {
    height: 45px;
    font-size: 1.3rem;
  }
`;

export const ModalBtnRight = styled.button`
  display: block;
  width: ${({ width }) => (width ? width : '50%')};
  height: 54px;
  //line-height: 4.8rem; // 버튼 텍스트가 두줄 될 수 있음
  font-size: 1.6rem;
  text-align: center;
  font-weight: 700;
  color: ${theme.color.white};
  background: ${theme.backgroundColor.blue};
  border: 1px solid ${theme.borderColor.blue};
  border-radius: 0 0 20px 0;
  float: left;

  @media screen and (max-width: 375px) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 320px) {
    height: 45px;
    font-size: 1.3rem;
  }
`;

export const ModalOneBtn = styled.button`
  display: block;
  width: 100%;
  height: 54px;
  line-height: 5.3rem;
  font-size: 1.6rem;
  text-align: center;
  font-weight: 700;
  color: ${theme.color.white};
  background: ${theme.backgroundColor.blue};
  border: 1px solid ${theme.borderColor.blue};
  border-radius: 0 0 20px 20px;

  &:disabled {
    background: ${theme.backgroundColor.buttonGrey};
    border: 1px solid ${theme.borderColor.buttonGrey};
    cursor: default;
  }
`;
