import React from 'react';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { KEY_DATAPROVIDEAGREESEQ } from 'tools/WebTool';
import ROUTE_PATH from 'routes/config';
import { DeepLinkBanner, ModalClose, Span } from './style';

const TopBanner = ({
  onClickClose = () => {},
  setShowTwoBtnModal = () => {},
  setShowBanner = () => {},
  bannerMessage = '',
  bannerData = {},
}) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (bannerMessage.includes('데이터 제공 동의')) {
      if (!!bannerData?.dataProvideAgreeSeq) {
        localStorage.setItem(
          KEY_DATAPROVIDEAGREESEQ,
          bannerData?.dataProvideAgreeSeq
        );
        navigate(ROUTE_PATH.deepLink.agree);
      }
    }
    if (bannerMessage.includes('데이터 연동')) {
      navigate(ROUTE_PATH.mypage.dataLinkageManage);
    }
    if (bannerMessage.includes('직원 초대')) {
      setShowTwoBtnModal(true);
      setShowBanner(false);
    }
  };

  return (
    <DeepLinkBanner>
      <Span onClick={onClick}>{bannerMessage}</Span>
      <ModalClose onClick={onClickClose}>
        <FeatherIcon icon="x" stroke="#fff" size={20} strokeWidth={2.2} />
      </ModalClose>
    </DeepLinkBanner>
  );
};

export default TopBanner;
