import React from 'react';
import { CheckCard, CheckCardText, CheckCardDiv, Label } from '../style';

const RenderMallRow = ({ index = 0, mall = {} }) => {
  const {
    mallName = '',
    dataProviderScheduledAmtList = [],
    mallAccountId = '',
  } = mall;

  return (
    <CheckCard>
      <CheckCardDiv>
        <Label className="check-card" style={{ cursor: 'default' }}>
          {mallName}
        </Label>
      </CheckCardDiv>
      <CheckCardText key={index}>아이디 {mallAccountId}</CheckCardText>

      {dataProviderScheduledAmtList?.map((item, index) => {
        return (
          <CheckCardText key={index} bold={true}>
            정산 예정금 {item?.scheduledSettlementAmt?.toLocaleString() || 0}원
          </CheckCardText>
        );
      })}
    </CheckCard>
  );
};

export default RenderMallRow;
