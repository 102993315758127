import styled from 'styled-components';
import theme from 'styles/theme';

export const FooterWrapper = styled.div`
  padding: 20px;
  margin-top: 23px;
  width: 100%;
  min-width: ${theme.layout.minWidth};
  max-width: ${theme.layout.maxWidth};
  min-height: 260px;
  font-size: 1.3rem;
  line-height: 2.6rem;

  background: ${theme.backgroundColor.footerBox};
`;

export const BlackLabel = styled.label`
  display: block;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 3.6rem;
`;
