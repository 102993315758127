import styled from 'styled-components';
import theme from 'styles/theme';

export const GreyInfoBoxWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 16px;
  background-color: ${theme.backgroundColor.grey8};
  font-size: 1.2rem;
  color: ${theme.color.black};
`;
