import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import useApp from 'hooks/useApp';
import {
  KEY_ACCESS_URL,
  KEY_ESSENTIAL_MALLS,
  KEY_GOODSHASH,
  KEY_INFLOW_CHANNEL,
} from 'tools/WebTool';
import {
  createDataRequest,
  getDataRequest,
  getResultDataRequest,
} from 'utils/request';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import { Container, Text, TextContainer } from './style';
import {
  IDENTIFY_FAIL_MODAL_CONTENTS,
  SHUTDOWN_CLOSE_MODAL_CONTENTS,
} from './constants';

// 금융기관 연동 회원가입
// ? 유입 URL 추가될 수 있음
// * 유입 URL 포맷 - /finance-app-interlock/member-join?memberInterlockHash='(해시키)'
const DeepLinkFinanceAppInterlockPage = () => {
  const { auth } = useApp();
  const navigate = useNavigate();
  const location = useLocation();
  const [memberInterlockData, setMemberInterlockData] = useState({});
  const [noBtnModalMessage, setNoBtnModalMessage] = useState('');
  const memberInterlockHash =
    !!location.search &&
    new URLSearchParams(location.search).get('memberInterlockHash');

  useEffect(() => {
    // 로그아웃 처리
    if (auth.authenticated) {
      auth.logout({ moveToHome: false });
    }

    // 금융기관 정보를 확인 가능한 api 호출
    getMemberInterlockRequest({
      memberInterlockHash,
      setMemberInterlockData,
      setNoBtnModalMessage,
      navigate,
    });
  }, []);

  return (
    <>
      <Container>
        {!!memberInterlockData?.businessName && (
          <TextContainer>
            <Text>잠시만 기다려주세요.</Text>
            <Text>셀러크레딧커넥트에서</Text>
            <Text>
              {`${memberInterlockData?.businessName} 사업자 정보를 확인하고 있어요.`}
            </Text>
          </TextContainer>
        )}
      </Container>
      {/* 알럿 메세지 모달 */}
      <NoBtnModal
        showModal={!!noBtnModalMessage}
        onClose={() => {
          setNoBtnModalMessage('');
          navigate('/');
        }}
      >
        <ScrollArea>
          <Contents textAlign="left">{noBtnModalMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </>
  );
};

// memberInterlockHash를 사용하여 DB 저장된 데이터 조회
const getMemberInterlockRequest = ({
  memberInterlockHash,
  setMemberInterlockData,
  setNoBtnModalMessage,
  navigate,
}) => {
  const successFnc = data => {
    if (!data) {
      setNoBtnModalMessage(
        '잘못된 해시키입니다. URL이 올바른지 확인해 주세요.'
      );
      return;
    }
    setMemberInterlockData(data);
    // 유입 경로 저장
    localStorage.setItem(KEY_INFLOW_CHANNEL, `${data.interlockKey}/join`);

    // 금융사에서 받은 몰 데이터와 대출상품 몰 데이터 비교
    createDataRequest({
      url: 'v1/na/interlock-request/validate/essential/mall',
      data: {
        malls: data.malls,
        creditGoodsMasterEventSeq: data.creditGoodsMasterEventSeq,
        memberInterlockHash: memberInterlockHash,
      },
      successFnc: () => {
        // 정적 링크 dataProvideAgreeSeq 생성에 사용됨
        localStorage.setItem(KEY_GOODSHASH, data.goodsHash);
        localStorage.setItem(KEY_ACCESS_URL, window.location.href);
        localStorage.setItem(
          KEY_ESSENTIAL_MALLS,
          JSON.stringify(data.malls ?? [])
        );
        checkBizNumDB({
          bizInfo: data,
          setNoBtnModalMessage,
          navigate,
          memberInterlockHash,
        });
      },
      errorFnc: () =>
        setNoBtnModalMessage(
          <MallCheckFailModalContents
            financeInstitutionName={data.financeInstitutionName}
          />
        ),
      serverErrorFnc: () => {
        setNoBtnModalMessage(
          '시스템 에러가 발생하였습니다.\n관리자에게 문의 바랍니다.'
        );
      },
    });
  };

  getResultDataRequest({
    url: `v1/na/interlock-request/member`,
    params: {
      memberInterlockHash,
    },
    successFnc,
    setModalMessage: setNoBtnModalMessage,
  });
};

// 회원가입 여부 검증
const checkBizNumDB = ({
  bizInfo,
  setNoBtnModalMessage,
  navigate,
  memberInterlockHash,
}) => {
  // 이미 가입된 회원
  const successFnc = data => {
    navigate(ROUTE_PATH.login.login);
  };
  const errorFnc = () => {
    // 등록된 계정이 없음
    checkBizNumNTS({
      bizInfo,
      setNoBtnModalMessage,
      navigate,
      memberInterlockHash,
    });
  };

  // DB 사업자 번호 체크
  getDataRequest({
    url: `/v1/na/member/businum/check`,
    params: { businessRegistrationNumber: bizInfo.businessRegistrationNumber },
    successFnc,
    errorFnc,
  });
};

// 사업자 유효성 검사
const checkBizNumNTS = ({
  bizInfo,
  setNoBtnModalMessage,
  navigate,
  memberInterlockHash,
}) => {
  const successFnc = data => {
    const { businessRegistrationNumberValidate = {} } = data;
    // 사업자 인증 성공 케이스
    if (
      businessRegistrationNumberValidate.representativeName &&
      businessRegistrationNumberValidate.businessRegistrationCertificationYn &&
      businessRegistrationNumberValidate.businessName &&
      businessRegistrationNumberValidate.businessRegistrationNumber &&
      businessRegistrationNumberValidate.businessRegistrationStatus !==
        'SHUTDOWN' &&
      businessRegistrationNumberValidate.businessRegistrationStatus !==
        'CLOSE' &&
      businessRegistrationNumberValidate.businessRegistrationCertificationYn ===
        'Y'
    ) {
      // 회원 가입 페이지로 이동 (& step2)
      navigate(ROUTE_PATH.login.joinMember, {
        state: {
          businessValidationInfo: {
            bizInfo: {
              ...bizInfo,
              ...businessRegistrationNumberValidate,
              active: true,
            },
          },
        },
      });
    } else if (
      // 휴폐업 상태: 회원 가입 페이지로 이동 (& step1)
      businessRegistrationNumberValidate.businessRegistrationStatus ===
        'SHUTDOWN' ||
      businessRegistrationNumberValidate.businessRegistrationStatus === 'CLOSE'
    ) {
      navigate(ROUTE_PATH.login.joinMember, {
        state: {
          businessValidationInfo: {
            bizInfo: {
              ...bizInfo,
              ...businessRegistrationNumberValidate,
              active: false,
            },
            noBtnModalInfo: {
              title: '',
              contentsHTML: SHUTDOWN_CLOSE_MODAL_CONTENTS,
            },
          },
        },
      });
    } else {
      // 사업자 정보 인증 실패: 회원 가입 페이지로 이동 (& step1)
      navigate(ROUTE_PATH.login.joinMember, {
        state: {
          businessValidationInfo: {
            bizInfo: {
              ...bizInfo,
              ...businessRegistrationNumberValidate,
              active: false,
            },
            noBtnModalInfo: {
              title: '사업자 정보가 상이합니다',
              contentsHTML: IDENTIFY_FAIL_MODAL_CONTENTS,
            },
          },
        },
      });
    }
  };
  const errorFnc = (data, message, errorNo) => {
    console.error(
      'DeepLinkFinanceAppInterlockPage: checkBizNumNTS',
      data,
      message,
      errorNo
    );
    setNoBtnModalMessage('오류가 발생하였습니다.\n관리자에게 문의하세요.');
  };

  // 국세청 사업자 번호 체크
  getDataRequest({
    url: `/v1/na/common/business/validate`,
    params: {
      businessRegistrationNumber: bizInfo.businessRegistrationNumber,
      representativeName: bizInfo.representativeName,
      establishmentDt: bizInfo.foundationDate,
      businessName: bizInfo.businessName,
      memberInterlockHash: memberInterlockHash,
    },
    successFnc,
    errorFnc,
    setModalMessage: setNoBtnModalMessage,
  });
};

const MallCheckFailModalContents = ({ financeInstitutionName }) => {
  return (
    <span>
      데이터 제공 동의를 위해 필요한 필수 판매몰 정보가 대출 상품 정보와
      일치하지 않습니다.
      <br /> 자세한 내용은{' '}
      <span style={{ fontWeight: 800 }}>{financeInstitutionName}</span>에 다시
      한번 문의해 주세요.
    </span>
  );
};
export default DeepLinkFinanceAppInterlockPage;
