import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { getDataRequest, modifyDataRequest } from 'utils/request';
import TextField from 'components/TextField';
import IdentificationBox from 'components/IdentificationBox';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { OneBtnModal, TwoBtnModal } from 'components/Modal';
import {
  ScrollArea,
  Contents,
  NoScrollArea,
  ContentsBox,
} from 'components/Modal/style';
import {
  BackgroundTextBox,
  BackgroundTextBoxContents,
} from 'pages/MyPage/DataLinkage/style';
import { DataKey } from 'pages/MyPage/Manager/EmployeeItem/style';
import { TextMedium } from './style';

const SearchPassword = () => {
  const navigate = useNavigate();
  const { serviceCenter, login } = ROUTE_PATH;

  const [step, setStep] = useState(1);
  const [memberId, setMemberId] = useState('');
  const [memberPassword, setPassword] = useState('');

  const [showTwoBtnModal, setShowTwoBtnModal] = useState(false);
  const [showOneBtnModal, setShowOneBtnModal] = useState(false);
  const [modalContents, setModalContents] = useState('');

  //공통 input창 onChange
  const onChangeData = e => {
    const { value, name } = e.target;
    if (name === 'memberId') {
      setMemberId(value);
    } else if (name === 'memberPassword') {
      setPassword(value);
    }
  };

  //step1 id check
  const checkId = useCallback(
    ({ memberCertificationSeq, memberId }) => {
      const params = { memberCertificationSeq, memberId };
      const successFnc = () => {
        setStep(2);
      };

      const exceptFnc = message => {
        setShowTwoBtnModal(true);
        setModalContents(message);
      };

      getDataRequest({
        url: `/v1/na/member/password/check-id`,
        params,
        successFnc,
        exceptFnc,
      });
    },
    [memberId]
  );

  //step1 본인인증 완료(성공)
  const identificationSuccessCallback = useCallback(
    memberCertificationSeq => {
      checkId({ memberCertificationSeq, memberId });
    },
    [memberId]
  );

  //step1 본인인증 완료(decode 실패)
  const identificationExceptCallback = useCallback(err => {
    console.error(err);
  }, []);

  //step2 비밀번호 변경
  const onClickChangePassword = () => {
    const data = { memberId, memberPassword };

    const successFnc = (data, message) => {
      setShowOneBtnModal(true);
      setModalContents(message);
    };

    const serverErrorFnc = message => {
      setShowTwoBtnModal(true);
      setModalContents(message);
    };

    modifyDataRequest({
      url: `/v1/na/member/password/set`,
      data,
      successFnc,
      serverErrorFnc,
    });
  };

  return (
    <div>
      {step === 1 ? (
        <div>
          <TextMedium>
            가입한 아이디를 입력해 주세요.
            <br />
            휴대폰 본인인증을 통해
            <br />
            비밀번호를 변경할 수 있습니다.
          </TextMedium>
          <TextField
            type="text"
            title="아이디 입력"
            name="memberId"
            placeholder="아이디"
            onChange={onChangeData}
            value={memberId}
          />
          <IdentificationBox
            text="휴대폰 본인인증"
            disabled={!memberId}
            identificationSuccessCallback={identificationSuccessCallback}
            identificationExceptCallback={identificationExceptCallback}
          />
        </div>
      ) : step === 2 ? (
        <div>
          <TextMedium>
            본인 확인되었습니다. <br />
            비밀번호 재설정을 해주세요.
          </TextMedium>
          <BackgroundTextBox style={{ marginTop: '23px' }}>
            <DataKey style={{ marginTop: '0px' }}>아이디</DataKey>
            <BackgroundTextBoxContents>{memberId}</BackgroundTextBoxContents>
          </BackgroundTextBox>
          <TextField
            type="password"
            title="비밀번호 입력"
            name="memberPassword"
            placeholder="비밀번호"
            onChange={onChangeData}
            value={memberPassword}
          />
          <StyledActiveButton onClick={onClickChangePassword}>
            비밀번호 재설정
          </StyledActiveButton>
        </div>
      ) : null}
      {!!showOneBtnModal && (
        <OneBtnModal
          showModal={showOneBtnModal}
          onClose={() => {
            setShowOneBtnModal(false);
          }}
          onClick={() => navigate(login.login)}
          btnContent={`로그인`}
        >
          <ScrollArea>
            <Contents>{modalContents}</Contents>
          </ScrollArea>
        </OneBtnModal>
      )}

      {!!showTwoBtnModal && (
        <TwoBtnModal
          showModal={showTwoBtnModal}
          onClose={() => {
            setShowTwoBtnModal(false);
          }}
          onClickLeftBtn={() => {
            navigate(serviceCenter.main + '?tab=QnA');
          }}
          onClick={() => {
            window.location.reload();
          }}
          leftButton="1:1 문의하기"
          rightButton="확인"
        >
          <NoScrollArea>
            <Contents>{modalContents}</Contents>
            <ContentsBox></ContentsBox>
          </NoScrollArea>
        </TwoBtnModal>
      )}
    </div>
  );
};

export default SearchPassword;
