export const GUIDE_LIST = [
  {
    seq: 0,
    subject: '대상 기준이 궁금해요.',
    contents:
      "<div><p>23.09.08 기준 '쿠팡 셀러'가 신청 가능하며, 지속적으로 대상마켓을 확대할 예정입니다.</p>" +
      "<a href='https://www.naver.com' target='_blank'>네이버 홈</a></div>",
    isOpen: false,
  },
  {
    seq: 1,
    subject: '대출 신청 전 어떤 확인이 필요한가요?',
    contents:
      '<p>대출실행을 위해 인증서를 준비해주세요. 공공마이데이터를 이용한 소득서류 제출 시 국세청에 별도 인증서 등록이 필요 없습니다. 만약 공공 마이데이터 사용이 어렵다면, 국세청 소득서류 제출을 위한 인증서를 추가로 확인해 주세요.</p>',
    isOpen: false,
  },
];

// 딥링크 종류: 데이터 제공 동의(DGT100), 회원가입(DGT200)
export const DEEPLINK_TYPE = {
  dataProviderAgree: 'DGT100',
  joinMember: 'DGT200',
};

export const CONTENTS_BY_BTN = {
  로그인: {
    blueBtnName: '로그인',
    textBtnName: '회원가입',
    textQuestion: '아직 셀러크레딧커넥트 회원이 아니신가요?',
  },
  회원가입: {
    blueBtnName: '회원가입',
    textBtnName: '로그인',
    textQuestion: '이미 셀러크레딧커넥트 회원이신가요?',
  },
};
