import React, { useEffect, useState } from 'react';
import useApp from 'hooks/useApp';
import { useParams, useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import api from 'utils/api';
import { getKoreanFormatDate } from 'tools/DateTool';
import { TabSection } from 'pages/Login/JoinMemberPolicyPopupPage/style';
import {
  PolicyDetailContent,
  PolicyDiv,
  PolicyTitle,
  PolicyBottom,
} from './style';

const QnAPolicyPopupPage = () => {
  const { auth } = useApp();
  const params = useParams();
  const navigate = useNavigate();
  const [stipulation, setStipulation] = useState({});

  useEffect(() => {
    if (params.seq) {
      getStipulation({
        stipulationSeq: params.seq,
        setStipulation,
        navigate,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.seq]);

  return (
    <TabSection>
      <PolicyTitle showBanner={auth.bannerMessage}>
        {stipulation?.stipulationCdName}
      </PolicyTitle>
      <PolicyDetailContent
        dangerouslySetInnerHTML={{
          __html:
            stipulation?.stipulationContents ||
            '<div style="display: flex; justify-content: center; align-items: center; min-height: 100px;">Not Available<div>',
        }}
      />
      <PolicyBottom>
        <PolicyDiv>부칙</PolicyDiv>
        <PolicyDiv>
          본 약관은 {getKoreanFormatDate(stipulation?.stipulationBeginDate)}부터
          적용됩니다.
        </PolicyDiv>
      </PolicyBottom>
    </TabSection>
  );
};

const getStipulation = ({ stipulationSeq, setStipulation, navigate }) => {
  api({
    url: `/v1/na/stipulation/${stipulationSeq}`,
    method: 'get',
  })
    .then(res => {
      const { data } = res;
      if (data.success) {
        setStipulation(data.data.result);
      } else {
        navigate(ROUTE_PATH.login.joinMemberPolicyDefault);
      }
    })
    .catch(err => {
      console.error(err);
      navigate(ROUTE_PATH.login.joinMemberPolicyDefault);
    });
};

export default QnAPolicyPopupPage;
