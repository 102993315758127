import styled from 'styled-components';
import theme from 'styles/theme';

export const Text = styled.div`
  display: block;
  width: 100%;
  padding: 41px 0 18px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: ${theme.color.black};
  text-align: center;
`;

export const TextSmall = styled.div`
  display: block;
  width: 100%;
  padding: 41px 0 18px;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
  text-align: center;
`;

export const TextBlue = styled.span`
  padding: 41px 0 18px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: ${theme.color.blue};
  text-align: center;
`;

export const TextBold = styled.span`
  font-weight: 700;
`;

export const NoData = styled.div`
  display: block;
  width: 100%;
  padding: 45px 0;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 2.1rem;
  color: ${theme.color.black};
  text-align: center;
`;
