import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: block;
  width: 100%;
  padding: 48px 0 28px;

  font-size: 1.7rem;
  line-height: 25px;
  text-align: center;
  font-weight: 700;
`;
