import React from 'react';
import {
  CheckBoxWrapper,
  CheckBoxInput,
  RadioInput,
  Label,
  IconSpan,
} from './style';

const CheckBox = ({
  id = 'check-box-0',
  isChecked = false,
  onChangeCheckBox = () => {},
  value = '',
  name = '',
  type = 'checkbox',
  text = '',
  style = {},
  required = false,
  recommended = false,
}) => {
  return (
    <CheckBoxWrapper style={style}>
      <Label className="check-box" htmlFor={id}>
        {type === 'checkbox' ? (
          <CheckBoxInput
            type="checkbox"
            className="check-box"
            id={id}
            checked={isChecked}
            onChange={onChangeCheckBox}
            value={value}
            name={name}
          />
        ) : (
          <RadioInput
            type="radio"
            className="check-box"
            id={id}
            checked={isChecked}
            onChange={onChangeCheckBox}
            value={value}
            name={name}
          />
        )}
        <IconSpan className="notice-check-icon" />
        <span
          style={{
            marginLeft: '3px',
            ...style,
          }}
        >
          {!!required && <span style={{ color: '#02A4FF' }}>[필수] </span>}
          {!!recommended && <span style={{ color: '#969696' }}>[선택] </span>}
          {text}
        </span>
      </Label>
    </CheckBoxWrapper>
  );
};

export default CheckBox;
