import styled from 'styled-components';
import theme from 'styles/theme';

export const FilterMenu = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0 0;
  border-bottom: 1px solid ${theme.borderColor.grey4};
`;

export const TotalQuantity = styled.div`
  float: left;
  width: 50px;
  height: 34px;
  line-height: 3.4rem;
  font-size: 1.3rem;
  font-weight: 700;
  color: ${theme.color.black};
  text-align: left;
  flex-shrink: 0;

  @media screen and (max-width: 320px) {
    font-size: 1.1rem;
    width: 35px;
  }
`;

export const LoanListWrap = styled.div`
  display: block;
  width: 100%;
  margin: 10px 0 0;
  min-height: 85vh;
`;

export const NoListTextTitle = styled.div`
  display: block;
  width: 100%;
  padding: 123px 0 100px;

  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
  text-align: center;
`;

export const NoListTextBlue = styled.span`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${theme.color.blue};
`;

export const PaginationWrapper = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  ul.pagination li {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 1px solid ${theme.borderColor.ivory};

    align-items: center;
    font-size: 1rem;
  }

  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }

  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }

  ul.pagination li a {
    text-decoration: none;
    color: ${theme.color.blue};
    font-size: 1rem;
  }

  ul.pagination li.active a {
    color: white;
  }

  ul.pagination li.active {
    background-color: ${theme.color.blue};
  }

  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: blue;
  }

  .page-selection {
    width: 48px;
    height: 30px;
    color: ${theme.color.blue};
  }
`;

export const Filter = styled.button`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
`;

export const EmptyContainer = styled.div`
  height: 100%;
  position: relative;
`;

export const LoanBtnContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
