import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';

// CSS 스타일 순서 (컨벤션)
// 1. display
// 2. list-style
// 3. position
// 4. float
// 5. clear
// 6. width / height
// 7. padding / margin
// 8. border / background
// 9. color / font
// 10. text-decoration
// 11. text-align / vertical-align
// 12. white-space
// 13. other text
// 14. content

// css 정책
// 폰트는 rem 단위, padding과 margin은 px 단위를 사용
// rem에서 r은 root, 즉 최상위 요소의 font-size를 기준으로 잡습니다. 따라서 <html> 요소의 font-size 속성값이 기준이 됩니다.
// 최상위 태그에 min-width 적용, 하위 태그에는 %로 width를 적용
// 상위 Box는 shadow, 하위 Box가 두 개 이상일 경우는 border를 적용
// 상위 Box 내부 패딩 적용할 때, 하위 Box는 각자 padding-right: 20px 적용, last child는 적용하지 않음

// 기본 마진과 패딩
const DEFAULT_MARGIN = 20;
const DEFAULT_PADDING = 20;

// flex 요소변경은 flexCustom을 사용
export const flexCustom = (flexDirection, alignItems, justifyContent) => css`
  display: flex;
  flex-direction: ${flexDirection || 'initial'};
  align-items: ${alignItems || 'center'};
  justify-content: ${justifyContent || 'center'};
`;

// 컴포넌트 구조 : Wrapper -> Row -> Box -> Row -> Box ... -> Content

export const AreaWrapper = React.memo(styled.div`
  min-width: ${theme.layout.minWidth};
  padding: ${DEFAULT_PADDING}px;
`);

export const AreaRow = React.memo(styled.div`
  ${flexCustom('row', 'center', 'center')}
  width: 100%;

  :not(:nth-of-type(1)) {
    margin-top: ${props =>
      props.marginTop ? `${props.marginTop}px` : `${DEFAULT_MARGIN}px`};
  }

  ${props => {
    return css`
      ${getAreaCssStr(props)}
    `;
  }}
`);

export const AreaBox = React.memo(styled.div`
  :not(:nth-child(1)) {
    margin-left: ${DEFAULT_MARGIN}px;
  }
  ${props => {
    return css`
      ${getAreaCssStr(props)}
    `;
  }}
`);

export const AreaBox1 = styled(AreaBox)`
  width: 100%;
`;
export const AreaBox2 = styled(AreaBox)`
  width: 50%;
`;
export const AreaBox3 = styled(AreaBox)`
  width: 33%;
`;
export const AreaBox4 = styled(AreaBox)`
  width: 25%;
`;

export const AreaContent = React.memo(styled.div`
  ${props => {
    return css`
      ${getAreaCssStr(props)}
    `;
  }}
`);

const getAreaCssStr = props => {
  let cssStr = '';
  cssStr += getCssStrMargin(props);
  cssStr += getCssStrPadding(props);
  return cssStr;
};

const getCssStrPadding = props => {
  let cssStr = '';
  let defaultCssStr = `${DEFAULT_PADDING}px;`;
  if (props.padding) {
    if (typeof props.padding === 'number') {
      cssStr += `padding: ${props.padding}px;`;
    }
    if (!!props.paddingStyle === true) {
      cssStr += `padding: ${props.paddingStyle};`;
    } else if (props.padding === true) {
      cssStr += `padding: ${defaultCssStr};`;
    }
  }
  return cssStr;
};

const getCssStrMargin = props => {
  let cssStr = '';
  const defaultCssStr = `${DEFAULT_MARGIN}px;`;
  if (props.margin === true) {
    if (!!props.marginStyle === true) {
      cssStr += `margin: ${props.marginStyle};`;
    } else {
      cssStr += `margin: ${defaultCssStr};`;
    }
  }
  return cssStr;
};

export const PreviewSection = styled.div`
  width: 100%;
  line-height: 2rem;
  img {
    max-width: 100%;
  }
  td {
    border: 1px solid ${theme.borderColor.greyA};
  }
  em {
    font-style: italic;
  }

  h1 {
    font-size: 3.2rem;
    line-height: 3.2rem;
  }
  h2 {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
  h3 {
    font-size: 2rem;
    line-height: 2rem;
  }
  h4 {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  h5 {
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-weight: 500;
  }
  h6 {
    font-size: 1.1rem;
    font-size: 1.1rem;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }
`;
