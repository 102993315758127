export const TAB_OPTIONS = [
  {
    label: '판매몰 개별 연동',
    value: 'dataInterlock',
  },
  {
    label: '데이터 제공사',
    value: 'dataProvider',
  },
];

export const DATA_INTERLOCK_INDEX = 0;
export const DATA_PROVIDER_INDEX = 1;

export const DATA_INTERLOCK_NOTICE_MESSAGE =
  '운영중인 판매몰을 등록해 보세요.\n판매몰이 많을수록 이용할 수 있는 데이터가 많아 대출 신청에 유리해요.';

export const DATA_PROVIDER_NOTICE_MESSAGE =
  '사용중인 데이터 제공사를 등록해 보세요.\n데이터 제공사를 연동시 이용할 수 있는 데이터가 많아 대출 신청에 유리해요.';

export const ESSENTIAL_MALL_LINKAGE_MESSAGE = {
  isCompleted: `판매몰 데이터 연동이 완료되었습니다. \n 데이터 제공 동의를 진행해 주세요.`,
  isCompletedOrWait: `데이터 연동 요청이 완료되었습니다. 연동 결과는 곧 문자로 발송될 예정입니다. \n 연동이 완료되면 페이지를 새로고침 해주세요.`,
};

export const LINKAGE_STATUS_COLOR = {
  WAIT: '#8427FB', //연동대기
  STOP: '#F50E0E', //연동중지
  FINISH: '#009FF9', //연동완료
  FAIL: '#F50E0E', //연동실패
  UNAVAILABLE: '#F50E0E', //연동불가
  NOT_LINKED: '#F50E0E', //미연동
};
