import styled from 'styled-components';
import theme from 'styles/theme';

export const DataBox = styled.div`
  display: block;
  width: 100%;
  padding: 25px 19px;
  margin: 23px 0 0;
  background: ${theme.backgroundColor.contentBox};
  border-radius: 10px;
`;

export const DataHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 12px;
`;

export const DivFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const DataName = styled.div`
  display: flex;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 700;
  color: ${theme.color.black};
  text-align: left;
  line-height: 2.3rem;

  @media (max-width: 350px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DataKey = styled.div`
  display: block;
  width: 100%;
  margin: 19px 0 0;
  color: ${theme.color.navyBlue};
  font-size: 1.3rem;
  font-weight: 400;
  text-align: left;
  line-height: 2.1rem;
`;

export const DataValue = styled.div`
  display: block;
  width: 100%;
  margin: 3px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const DataValueText = styled.div`
  display: block;
  float: left;
  color: ${theme.color.black};
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  line-height: 2.1rem;

  ${props => {
    if (props.description) {
      return `
        margin-top: 10px;
        font-size: 1.2rem;
        color: ${theme.color.grey5};
      `;
    }
  }}
`;

export const Position = styled.div`
  height: 18px;
  padding: 2px 8px;
  margin: 0px 5px;
  background: ${theme.backgroundColor.white};
  border: 1px solid ${theme.borderColor.buttonLink};
  border-radius: 9px;
  font-size: 1.4rem;
  text-align: center;

  color: ${theme.color.buttonLink};
`;

export const ModifyEmployee = styled.div`
  float: right;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const Edit = styled.a`
  position: relative;
  height: 23px;
  padding: 0 14px 0 0;
  line-height: 2.3rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: ${theme.color.buttonLink};

  ${props => {
    if (props.disabled) {
      return `
        color: ${theme.color.policyGrey};
        pointer-events: none;
      `;
    }
  }}

  &:link,
  &:visited {
    color: ${theme.color.buttonLink};
  }

  @media screen and (max-width: 375px) {
    padding: 0 8px 0 0;
  }
`;

export const Delete = styled.button`
  position: relative;
  height: 23px;
  padding: 0 14px 0 14px;
  line-height: 2.3rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: ${theme.color.buttonLink};

  &:disabled {
    color: ${theme.color.policyGrey};
    pointer-events: none;
  }

  &:link,
  &:visited {
    color: ${theme.color.buttonLink};
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 12px;
    background: ${theme.backgroundColor.blue};
    top: 6px;
    left: 0;
  }

  @media screen and (max-width: 375px) {
    padding: 0 8px 0 8px;
  }
`;

export const MapWrapper = styled.div``;
