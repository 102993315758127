import styled from 'styled-components';
import theme from 'styles/theme';

export const SideArea = styled.div`
  display: block;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  min-width: ${theme.layout.minWidth};
  max-width: ${theme.layout.maxWidth};
  min-height: calc(
    (var(--vh, 1vh) * 100) + var(--ios-safe-top) + var(--ios-safe-bottom)
  );
  bottom: 0;
  left: calc(50% + 12px);

  margin: 0;
  background: ${theme.backgroundColor.shadow};

  @media screen and (max-width: 1199.9px) {
    left: 50%;
    transform: translateX(-50%);
    max-width: ${theme.layout.maxWidth};
  }
`;

export const PopupTitle = styled.p`
  display: flex;
  justify-content: center;

  width: 100%;
  font-size: 1.8rem;
  font-weight: 700;
  padding: 30px 0 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: center;

  padding: ${props => (props.padding ? '24px 0' : '0')};
`;

export const Text = styled.p`
  font-size: 1.5rem;
`;

export const ButtonSection = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${theme.color.greyA};
`;

export const KaKaoImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  margin: 0 15px;

  &:hover {
    cursor: pointer;
  }

  ${props => (props.imageUrl ? `background-image: url(${props.imageUrl})` : '')}
`;

export const URLImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  margin: 0 15px;

  &:hover {
    cursor: pointer;
  }

  ${props => (props.imageUrl ? `background-image: url(${props.imageUrl})` : '')}
`;

export const URLText = styled.span`
  font-size: 1.3rem;
  color: ${theme.color.white};
`;

export const SuccessMessageWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: ${theme.layout.maxWidth};
  padding: 20px;
`;
