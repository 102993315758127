import React from 'react';
import { MarkerWrapper, MarkerSymbol } from './style';

/** default: 신규 포스팅(New) 마커 */
const Marker = ({ symbol = 'N' }) => {
  return (
    <MarkerWrapper>
      <MarkerSymbol>{symbol}</MarkerSymbol>
    </MarkerWrapper>
  );
};

export default Marker;
