import React, { useState } from 'react';
import Tab from 'components/Tab';
import EventList from './EventList';
import { EVENT_TAB_OPTIONS } from './constants';

const EventPage = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const contentComponents = EVENT_TAB_OPTIONS.map(item => (
    <EventList key={item.value} tabIndex={tabIndex} url={item.url} />
  ));

  return (
    <Tab
      options={EVENT_TAB_OPTIONS}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      contentComponents={contentComponents}
      secondary={true}
    />
  );
};

export default EventPage;
