import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useApp from 'hooks/useApp';
import {
  getDataProvideAgreeSeq,
  KEY_DEEPLINKHASH,
  KEY_GOODSHASH,
  KEY_ACCESS_URL,
} from 'tools/WebTool';
import { getDataRequest, modifyDataRequest } from 'utils/request';
import ROUTE_PATH from 'routes/config';
import DataProviderTable from 'pages/MyPage/LoanLimit/LoanLimitBasicPage/DataProviderTable';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import BlueAlertBox from 'components/BlueAlertBox';
import { TwoBtnModal } from 'components/Modal';
import CheckableTypeStipulation from 'components/CheckableTypeStipulation';
import TextTypeStipulation from 'components/TextTypeStipulation';
import { LoadingBar } from 'components/LoadingIndicator';
import DeepLinkAgreeOneBtnModal from './DeepLinkAgreeOneBtnModal';
import DeepLinkNoBtnModal from '../DeepLinkNoBtnModal';
import { AGREE_LIST, KEY_GUIDE } from './constants';
import { TWO_BTN_MODAL_DATA } from './constants';
import { Contents } from 'components/Modal/style';
import {
  TextLight,
  TextMedium,
  ColorSpan,
  TextButton,
  TextLeftAlign,
} from './style';

const tableHead = ['판매몰', '정산 기간', '정산 예정금'];
const tableHead2 = ['판매몰', '정산 기간'];

const DeepLinkAgreePage = () => {
  const { auth } = useApp();
  const { deepLink } = ROUTE_PATH;

  const navigate = useNavigate();

  //정적 답링크 errorCode
  const location = useLocation();
  const goodsData = location?.state?.goodsData || {}; //미확인 건일 경우, 이용중일 경우

  const localDataProvideAgreeSeq = getDataProvideAgreeSeq();

  // 초기 데이터 셋팅을 위한 로딩 (세팅 완료: true)
  const [isLoading, setIsLoading] = useState(false);
  // api 통신 로딩 (로딩 중: true)
  const [isApiLoading, setIsApiLoading] = useState(false);

  //데이터 제공동의 데이터
  const [agreementContentsData, setAgreementContentsData] = useState({});
  const [sellerInterlockedMallList, setSellerInterlockedMallList] = useState(
    []
  );
  const [targetMallList, setTargetMallList] = useState([]);
  const [stipulationList, setStipulationList] = useState(AGREE_LIST);

  //alert
  const [alertMessage, setAlertMessage] = useState(''); // NoBtnModal에 사용
  const [btnModalMessage, setBtnModalMessage] = useState('');
  const [showOneBtnModal, setShowOneBtnModal] = useState(false);
  const [showTwoBtnModal, setShowTwoBtnModal] = useState(false);
  const [errorNo, setErrorNo] = useState(0);
  const [errorData, setErrorData] = useState({});
  const [twoBtnModalName, setTwoBtnModalName] = useState('');
  const [isBackgroundNull, setIsBackgroundNull] = useState(false);

  const twoBtnModalData = TWO_BTN_MODAL_DATA[twoBtnModalName] || {};

  //정적 데이터 적재 후 에러 코드가 있을 경우 데이터 제공 동의 페이지에서 alert
  useEffect(() => {
    // 미확인 건 있을 경우
    if (
      (goodsData.errorCode === 'UNIDENTIFIED_EXIST' ||
        goodsData.errorCode === 'UNAVAILABLE_EXIST') &&
      !localDataProvideAgreeSeq
    ) {
      setErrorNo(500); // 미확인 건이 있을 경우 ErrorCode 500 설정
      setShowOneBtnModal(true);
      setBtnModalMessage(goodsData.message);
      setErrorData(goodsData); // 원버튼모달의 onClick에서 사용
    }

    // 이용중이거나 필수몰 유효성 체크 실패일 경우
    if (
      (goodsData.errorCode === 'ALREADY_USING' ||
        goodsData.errorCode === 'ESSENTIAL_MALL_NOT_VALIDATE') &&
      !localDataProvideAgreeSeq
    ) {
      setAlertMessage(goodsData.message);
      setIsBackgroundNull(true);
    }

    //방어 코드
    localStorage.removeItem(KEY_GOODSHASH);
    localStorage.removeItem(KEY_ACCESS_URL);
    localStorage.removeItem(KEY_DEEPLINKHASH);
  }, [goodsData.errorCode, goodsData.message]);

  //정적, 동적 모두 동일
  useEffect(() => {
    if (!!localDataProvideAgreeSeq) {
      //get 데이터 제공 동의 데이터 - 공통 에러 함수
      const getDataErrorFnc = (data, message, errorNo) => {
        setErrorNo(errorNo);

        const targetMallByCreditGoodsMallList =
          data?.creditGoodsMallList?.length > 0
            ? data?.creditGoodsMallList
            : [{ mallSeq: 0, mallName: '전체 몰' }];

        // Case1 : 데이터 제공 기한 만료 (3-1) / 사업자가 일치하지 않는 경우(동적 링크)
        if (errorNo === 100) {
          setShowOneBtnModal(true);
          setBtnModalMessage(message);
        }

        // Case2 : 판매몰 연동 X (5-1)
        else if (errorNo === 200) {
          setShowTwoBtnModal(true);
          setTwoBtnModalName('zeroInterlockedMall');
          setBtnModalMessage(message);
          setAgreementContentsData({
            ...data?.result,
          });
          const targetMallList =
            data?.requestMallList?.length > 0
              ? data?.requestMallList
              : [{ mallSeq: 0, mallName: '전체 몰' }];

          setTargetMallList(targetMallList);
        }

        // Case3 : 금융기관이 요청한 필수타겟몰이 연동되어있지 않을 경우 (6-2)
        else if (errorNo === 300) {
          setSellerInterlockedMallList(data?.list);
          setAgreementContentsData({
            ...data.result,
          });
          const targetMallList =
            data?.result?.mallList?.length > 0
              ? data?.result?.mallList
              : targetMallByCreditGoodsMallList;

          setTargetMallList(targetMallList);

          // Case4 : 금융기관이 요청한 상품타겟몰이 연동되어 있지 않을 경우 (6-2)
        } else if (errorNo === 400) {
          setSellerInterlockedMallList(data?.list);
          setTargetMallList(targetMallByCreditGoodsMallList);
          setAgreementContentsData({
            ...data?.result,
          });

          // 데이터 이용중인 경우 (동의한 약관 존재 O) - status: 200, success: false
        } else {
          setIsBackgroundNull(true);
          setAlertMessage(message);
        }
        setIsLoading(true);
      };

      getDataRequest({
        url: `/v1/au/data-provide-agree/${localDataProvideAgreeSeq}`,
        errorFnc: getDataErrorFnc,
        successFnc: data => {
          setAgreementContentsData({
            ...data.result,
          });
          setSellerInterlockedMallList(data.list || []);
          setStipulationList(data.stipulationList || []);
          setIsLoading(true);
        },
      });
    } else if (goodsData.errorCode) {
    } else {
      navigate('/');
    }
  }, [localDataProvideAgreeSeq]);

  const onClickTwoBtnModalLeftBtn = () => {
    // 동의 완료 (2case 포함)
    if (
      twoBtnModalName === 'dataInterlock' ||
      twoBtnModalName === 'dataProvideAgree'
    ) {
      navigate('/');
    }

    // 공통
    setShowTwoBtnModal(false);
    setTwoBtnModalName('');
    setBtnModalMessage('');
  };

  const onClickTwoBtnModalRightBtn = () => {
    // 데이터 제공 동의 거절
    if (twoBtnModalName === 'rejectAgreement') {
      modifyDataRequest({
        url: `/v1/au/data-provide-agree/disagree/${localDataProvideAgreeSeq}`,
        setModalMessage: setAlertMessage,
        setIsLoading: setIsApiLoading,
      });
    }
    // 판매몰 등록 여부 0건(error 200)
    if (twoBtnModalName === 'zeroInterlockedMall') {
      navigate(ROUTE_PATH.mypage.dataLinkageManage);
    }
    // 동의 완료 (1-4-a 상품타겟몰이 없는 경우)
    if (twoBtnModalName === 'dataInterlock') {
      navigate(ROUTE_PATH.mypage.dataLinkageManage);
    }
    // 동의 완료 (1-4-b 상품타겟몰이 없는 경우)
    if (twoBtnModalName === 'dataProvideAgree') {
      navigate(`${ROUTE_PATH.mypage.manageLoanDataProvide}?tab=dataUse`);
    }

    // 공통
    setShowTwoBtnModal(false);
    setTwoBtnModalName('');
    setBtnModalMessage('');
  };

  //동의 약관 보기
  const onClickViewBtn = stipulationSeq => {
    if (!!stipulationSeq) {
      window.open(`${deepLink.policyDefault}/${stipulationSeq}`, '_blank');
    }
  };

  // 동의하기 버튼 비활성화 여부
  const isAgreeBtnDisabled = stipulationList
    .filter(item => item.required === true)
    .some(item => item.checked === false);

  //동의하기
  const onClickAgreeBtn = () => {
    let mallList = [];

    // 약관 타입
    const agreeList = [
      ...stipulationList.map(({ stipulationSeq, checked }) => ({
        stipulationSeq,
        agreeYn: checked === true ? 'Y' : 'N',
      })),
    ];

    if (agreementContentsData?.dataProvideType === 'SECURED') {
      mallList = sellerInterlockedMallList?.map(item => ({
        mallSeq: item.mallSeq,
        mallAccountId: item.mallAccountId,
        settlementBeginDate: item.settlementBeginDate,
        settlementEndDate: item.settlementEndDate,
      }));
    }

    const successFnc = async (data, message) => {
      await setBtnModalMessage(message);
      setTwoBtnModalName(data?.data);
      setShowTwoBtnModal(true);
      setAlertMessage('');
    };

    if (agreementContentsData?.dataProvideAgreeSeq === 0) {
      return;
    }

    modifyDataRequest({
      url: `/v1/au/data-provide-agree/agree/${agreementContentsData?.dataProvideAgreeSeq}`,
      data: {
        dataProvideAgreeStipulationTypeCd:
          stipulationList[0]?.stipulationTypeCd,
        agreeList,
        mallList,
      },
      successFnc: successFnc,
      setModalMessage: setAlertMessage,
      setIsLoading: setIsApiLoading,
    });
  };

  // 미확인 상태 예외 케이스(errorNo: 500)
  // 데이터 제공 기한 만료 케이스 (errorNo: 100)
  // 사업자 유효성 체크 (errorNo: 100)
  if (showOneBtnModal === true) {
    return (
      <DeepLinkAgreeOneBtnModal
        errorNo={errorNo}
        showOneBtnModal={showOneBtnModal}
        setShowOneBtnModal={setShowOneBtnModal}
        btnModalMessage={btnModalMessage}
        errorData={errorData}
      />
    );
  }

  if (isBackgroundNull && alertMessage) {
    return <DeepLinkNoBtnModal alertMessage={alertMessage} />;
  }

  //타겟 판매몰 매칭 불가 케이스
  if (
    agreementContentsData &&
    targetMallList?.length > 0 &&
    (errorNo === 300 || errorNo === 400 || errorNo === 200)
  ) {
    return (
      <>
        <TextMedium>
          <ColorSpan>
            {agreementContentsData.businessName || auth.businessName}
          </ColorSpan>
          님
          <br />
          데이터 제공 동의가 불가합니다.
        </TextMedium>
        <div style={{ marginBottom: '20px' }}>
          <BlueAlertBox
            background="icyRed"
            title="데이터 제공 동의 불가 안내"
            contents={
              <div>
                <ColorSpan>
                  {agreementContentsData.financeInstitutionName}
                </ColorSpan>
                의 대출 서비스 이용을 위한 데이터 제공 동의 정보가
                <ColorSpan>
                  {` ${
                    agreementContentsData.businessName || auth.businessName
                  }`}
                </ColorSpan>
                님의 정산예정금 정보와 일치하지 않습니다.
                <br />
                금융기관 혹은 고객센터로 문의 부탁드립니다.
              </div>
            }
          />
        </div>
        {errorNo !== 200 && (
          <div>
            <TextLeftAlign style={{ paddingBottom: '0px' }}>
              <ColorSpan>
                {agreementContentsData.businessName || auth.businessName}
              </ColorSpan>
              님의 정산예정금 정보는 아래와 같습니다.
            </TextLeftAlign>

            {sellerInterlockedMallList && (
              <DataProviderTable
                tableHead={tableHead}
                dataProviderList={sellerInterlockedMallList}
                tableDataType="threeCell"
              />
            )}
          </div>
        )}
        <TextLeftAlign style={{ paddingBottom: '0px' }}>
          <ColorSpan>{agreementContentsData.financeInstitutionName}</ColorSpan>
          에서 동의 요청한 정보는 아래와 같습니다.
        </TextLeftAlign>
        {targetMallList && (
          <DataProviderTable
            tableHead={tableHead2}
            dataProviderList={targetMallList}
            tableDataType="twoCell"
          />
        )}
        <TextLight style={{ textAlign: 'left' }}>
          [정산예정금 안내]
          <br />
          정산예정금은 실제 정산예정금이 없거나, 해당 판매몰의 정산예정금을
          담보로 대출이 진행 중인 경우 0원으로 노출됩니다.
          <br />
          연동한 판매몰에 복수의 계정으로 운영되는 경우 아이디가 노출되며
          아이디가 5자리 이상인 경우 말줄임 표시로 노출됩니다.
        </TextLight>

        <StyledActiveButton tabIndex={0} onClick={() => navigate('/')}>
          확인
        </StyledActiveButton>
        <TextButton
          tabIndex={0}
          title="데이터 제공 동의 거절 처리"
          onClick={() => {
            setTwoBtnModalName('rejectAgreement');
            setShowTwoBtnModal(true);
          }}
        >
          데이터 제공 동의 거절하기
        </TextButton>
        {/* alert  */}
        <DeepLinkNoBtnModal alertMessage={alertMessage} />
        <TwoBtnModal
          showModal={showTwoBtnModal}
          onClose={onClickTwoBtnModalLeftBtn}
          onClickLeftBtn={onClickTwoBtnModalLeftBtn}
          onClick={onClickTwoBtnModalRightBtn}
          leftButton={twoBtnModalData.leftBtnText}
          rightButton={twoBtnModalData.rightBtnText}
          contents={twoBtnModalData.contents || btnModalMessage}
          isContentsHTML={twoBtnModalData.isContentsHTML}
          rightButtonDisabled={isApiLoading}
        />
      </>
    );
  }

  const isCheckableTypeStipulation =
    stipulationList[0]?.stipulationTypeCd === 'STC100';

  return (
    isLoading && (
      <>
        <TextMedium>
          <ColorSpan>
            {agreementContentsData.businessName || auth.businessName}
          </ColorSpan>
          님
          <br />
          데이터 제공동의를 진행해 주세요.
        </TextMedium>
        <Contents
          style={
            sellerInterlockedMallList?.length > 0
              ? { marginBottom: '20px' }
              : { marginBottom: '40px' }
          }
        >
          <ColorSpan>{agreementContentsData.financeInstitutionName}</ColorSpan>
          {agreementContentsData.financeInstitutionName && '의 '}대출 서비스
          이용을 위해
          <br />
          아래 내용을 읽고 동의해 주세요.
        </Contents>
        {sellerInterlockedMallList?.length > 0 && (
          <DataProviderTable
            tableHead={tableHead}
            dataProviderList={sellerInterlockedMallList}
            tableDataType="threeCell"
          />
        )}
        {sellerInterlockedMallList?.length > 0 && (
          <Contents textAlign="left" fontWeight={400}>
            {KEY_GUIDE[agreementContentsData.dataProvideType]}
          </Contents>
        )}
        {isCheckableTypeStipulation ? (
          <CheckableTypeStipulation
            stipulationList={stipulationList}
            setStipulationList={setStipulationList}
            onClickViewBtn={onClickViewBtn}
          />
        ) : (
          <TextTypeStipulation
            stipulationList={stipulationList}
            setStipulationList={setStipulationList}
          />
        )}

        <StyledActiveButton
          tabIndex={0}
          onClick={onClickAgreeBtn}
          disabled={isAgreeBtnDisabled || isApiLoading}
          margin="63px 0 0 0"
        >
          {isApiLoading ? <LoadingBar message={true} /> : '동의하기'}
        </StyledActiveButton>
        <TextButton
          tabIndex={0}
          title="데이터 제공 동의 거절 처리"
          onClick={() => {
            setTwoBtnModalName('rejectAgreement');
            setShowTwoBtnModal(true);
          }}
        >
          데이터 제공 동의 거절하기
        </TextButton>
        {/* alert  */}
        <DeepLinkNoBtnModal alertMessage={alertMessage} />
        <TwoBtnModal
          showModal={showTwoBtnModal}
          onClose={onClickTwoBtnModalLeftBtn}
          onClickLeftBtn={onClickTwoBtnModalLeftBtn}
          onClick={onClickTwoBtnModalRightBtn}
          leftButton={twoBtnModalData.leftBtnText}
          rightButton={twoBtnModalData.rightBtnText}
          contents={twoBtnModalData.contents || btnModalMessage}
          isContentsHTML={twoBtnModalData.isContentsHTML}
        />
      </>
    )
  );
};

export default DeepLinkAgreePage;
