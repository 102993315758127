import React from 'react';
import { TextBox } from 'components/SelectMallModal/RenderMall/style';
import CheckableTypeStipulation from 'components/CheckableTypeStipulation';
import TextTypeStipulation from 'components/TextTypeStipulation';

const PolicyContents = ({
  stipulationList = [],
  setStipulationList,
  onClickViewBtn = () => {},
  noText = false,
}) => {
  const isCheckableTypeStipulation =
    stipulationList[0]?.stipulationTypeCd === 'STC100';

  return (
    <>
      {noText ? (
        ''
      ) : (
        <TextBox>
          대표자 명의의 휴대폰 본인인증 후
          <br />
          대출 신청할 수 있어요.
        </TextBox>
      )}

      {isCheckableTypeStipulation ? (
        <CheckableTypeStipulation
          stipulationList={stipulationList}
          setStipulationList={setStipulationList}
          onClickViewBtn={onClickViewBtn}
        />
      ) : (
        <TextTypeStipulation
          stipulationList={stipulationList}
          setStipulationList={setStipulationList}
        />
      )}
    </>
  );
};

export default PolicyContents;
