import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApp from 'hooks/useApp';
import api from 'utils/api';
import ROUTE_PATH from 'routes/config';
import { validatePassword } from 'tools/ValidationTool';
import { alertMessages } from 'messages/alertMessages';
import TextField from 'components/TextField';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { OneBtnModal, NoBtnModal } from 'components/Modal';
import { convertNames } from './constants';
import { ScrollArea, Contents } from 'components/Modal/style';
import { TextDiv } from '../UpdateBusiness/style';

const UpdatePassword = () => {
  const navigate = useNavigate();
  const {
    auth: { logout },
  } = useApp();
  const { login } = ROUTE_PATH;
  const [showOneBtnModal, setShowOneBtnModal] = useState(false);
  const [showNoBtnModal, setShowNoBtnModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [passwords, setPasswords] = useState({
    originalPassword: '',
    newPassword: '',
    repeatPassword: '',
  });
  const [messages, setMessages] = useState({
    originalPassword: '',
    newPassword: '',
    repeatPassword: '',
  });

  const onChange = e => {
    const { name, value } = e.target;
    if (name === 'newPassword') {
      validatePassword(value)
        ? setMessages(prevState => ({ ...prevState, [name]: '' }))
        : setMessages(prevState => ({
            ...prevState,
            [name]: alertMessages.validatePw,
          }));
    }
    setPasswords(prevState => ({ ...prevState, [name]: value }));
  };

  const onBlur = e => {
    const { name, value } = e.target;
    if (value) {
      if (name === 'newPassword') {
        if (!validatePassword(value)) {
          return setMessages(prevState => ({
            ...prevState,
            [name]: alertMessages.validatePw,
          }));
        }
      }
      if (name === 'repeatPassword') {
        if (passwords.newPassword !== passwords.repeatPassword) {
          return setMessages(prevState => ({
            ...prevState,
            [name]: alertMessages.differPw,
          }));
        }
      }
      setMessages(prevState => ({ ...prevState, [name]: '' }));
    }
    if (!value) {
      setMessages(prevState => ({
        ...prevState,
        [name]: alertMessages[convertNames[name]],
      }));
    }
  };

  const onClickUpdatePassword = () => {
    checkPassword();
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      checkPassword();
    }
  };

  const onClickLogin = () => {
    logout();
    navigate(login.login);
  };

  const checkPassword = () => {
    const passwordValue = { memberPassword: passwords.originalPassword };
    api({
      url: '/v1/au/member/password/check',
      method: 'post',
      headers: { 'content-type': 'application/json' },
      data: passwordValue,
    })
      .then(res => {
        const { success, message } = res.data;
        if (success) {
          resetPassword();
        } else {
          setMessages(prevState => ({
            ...prevState,
            originalPassword: message,
          }));
        }
      })
      .catch(err => {
        console.error(err);
        setMessages(prevState => ({
          ...prevState,
          originalPassword: err?.response?.data?.message,
        }));
      });
  };

  const resetPassword = () => {
    const passwordValues = {
      orgMemberPassword: passwords.originalPassword,
      newMemberPassword: passwords.newPassword,
    };
    api({
      url: '/v1/au/member/password/reset',
      method: 'put',
      headers: { 'content-type': 'application/json' },
      data: passwordValues,
    })
      .then(res => {
        const { success, message } = res.data;
        if ((success, message)) {
          setModalMessage(message);
          setShowOneBtnModal(true);
        } else {
          setModalMessage(message);
          setShowNoBtnModal(true);
        }
      })
      .catch(err => {
        console.error(err);
        setModalMessage(err?.response?.data?.message);
        setShowNoBtnModal(true);
      });
  };

  const isButtonAbled =
    passwords.originalPassword &&
    passwords.newPassword &&
    passwords.repeatPassword &&
    validatePassword(passwords.newPassword) &&
    validatePassword(passwords.repeatPassword) &&
    passwords.newPassword === passwords.repeatPassword;

  return (
    <>
      <TextDiv>
        비밀번호를 주기적으로 변경하면,
        <br />
        개인정보를 안전하게 보호할 수 있어요.
      </TextDiv>

      <div>
        <TextField
          type="password"
          title="비밀번호 입력"
          name="originalPassword"
          placeholder="비밀번호"
          onChange={onChange}
          onBlur={onBlur}
          showMessage={!!messages.originalPassword}
          message={messages.originalPassword}
        />

        <TextField
          type="password"
          title="새 비밀번호 입력"
          name="newPassword"
          placeholder="새 비밀번호"
          maxLength={30}
          onChange={onChange}
          onBlur={onBlur}
          showMessage={!!messages.newPassword}
          message={messages.newPassword}
        />

        <TextField
          type="password"
          title="새 비밀번호 확인 입력"
          name="repeatPassword"
          placeholder="새 비밀번호 확인"
          maxLength={30}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          showMessage={!!messages.repeatPassword}
          message={messages.repeatPassword}
        />

        <StyledActiveButton
          tabIndex={0}
          onClick={onClickUpdatePassword}
          disabled={!isButtonAbled}
        >
          비밀번호 변경
        </StyledActiveButton>

        <OneBtnModal
          showModal={showOneBtnModal}
          onClose={onClickLogin}
          btnContent="로그인"
        >
          <ScrollArea>
            <Contents>{modalMessage}</Contents>
          </ScrollArea>
        </OneBtnModal>

        <NoBtnModal
          showModal={showNoBtnModal}
          onClose={() => {
            setShowNoBtnModal(false);
          }}
        >
          <ScrollArea>
            <Contents>{modalMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>
      </div>
    </>
  );
};

export default UpdatePassword;
