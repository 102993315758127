import styled from 'styled-components';
import theme from 'styles/theme';

export const MainBoxBtn = styled.button`
  background: url(/images/icons/dropdown/main_ar_s2.png) right no-repeat;
  padding: 0 14px 0 0;
  background-size: 6px 11px;
`;

export const MainBoxTooltip = styled.button`
  display: flex;
`;

export const LoanLimitWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
`;

export const MainSectionTwo = styled.div`
  position: relative;
  width: 100%;
  padding: 54px 35px 0;

  @media screen and (max-width: 485.9px) {
    padding: 54px 27px 0;
  }

  @media screen and (max-width: 375px) {
    padding: 54px 20px 0;
  }
`;

export const ListTop = styled.div`
  display: flex;
`;

export const BlackTitle = styled.div`
  width: 100%;
  line-height: 2.5rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${theme.color.black};
  word-wrap: break-word;
  word-break: break-all;

  @media screen and (max-width: 375px) {
    font-size: 1.7rem;
  }
`;

export const LoanName = styled.div`
  width: 100%;
  margin-right: 20px;
  font-size: 1.3rem;
  font-weight: 400;
  color: ${theme.color.grey7};
  word-wrap: break-word;
  word-break: break-all;
`;

export const TextMedium = styled.div`
  padding: 41px 0 18px;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
  text-align: center;
`;

export const TextBlue = styled.span`
  color: ${theme.color.blue};
`;

export const ChildText = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
`;

export const ChildTextContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0 0;
`;

export const Tabs = styled.div``;

export const TabSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const TabButton = styled.button`
  width: 96px;
  height: 34px;
  line-height: 32px;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  border: 1px solid ${theme.borderColor.blue};
  border-radius: 17px;
  color: ${props => (props.active ? theme.color.white : theme.color.blue)};
  background: ${props =>
    props.active ? theme.backgroundColor.blue : theme.backgroundColor.white};

  &:not(:first-child) {
    margin: 0 0 0 5px;
  }
`;

export const ImgDiv = styled.div`
  float: left;
  width: 40px;
  height: 40px;
  line-height: 0;

  @media screen and (max-width: 375px) {
    display: none;
  }
`;

export const Img = styled.img`
  width: 40px;
  height: 40px;

  @media screen and (max-width: 375px) {
    display: none;
    width: 0px;
    height: 0px;
  }
`;

export const Column = styled.div`
  float: right;
  width: calc(100% - 55px);
  position: relative;

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

export const TextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 13px 0 0;
`;

export const TextTitle = styled(LoanName)`
  color: inherit;
  margin-right: 30px;
`;

export const TextContent = styled(BlackTitle)`
  font-size: 1.7rem;
  font-weight: 500;
`;

export const Text = styled.div``;

export const BasicTableDiv = styled.div`
  height: 90px;
`;

export const MainInfoSection = styled.div``;

export const MainBox = styled.div`
  margin: 20px 0px;
`;

export const MainBoxSection = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`;

export const MainBoxTitle = styled.div`
  width: 100%;
  line-height: 2.5rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${theme.color.black};
  word-wrap: break-word;
  word-break: break-all;

  @media screen and (max-width: 375px) {
    font-size: 1.7rem;
  }
`;

export const MainBoxImgDiv = styled.div`
  margin-right: 20px;
`;

export const MainBoxColumn = styled.div`
  width: calc(100% - 55px);
  position: relative;

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

export const MainBoxTextDiv = styled.div`
  width: 100%;
  margin: 13px 0 0;
`;

export const MainBoxTextBlue = styled.div`
  width: 100%;
  font-size: 1.8rem;
  font-weight: 500;
  color: ${theme.color.blue};
  word-wrap: break-word;
  word-break: break-all;

  @media screen and (max-width: 375px) {
    font-size: 1.7rem;
  }
`;

export const MainBoxText = styled.div`
  margin-top: 7px;
  width: 100%;
  height: 18px;
  font-size: 1.3rem;
  font-weight: 400;
  color: #777;
`;

export const Overlay = styled.div`
  display: ${props => (props.display === 'true' ? `flex` : `none`)};
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  justify-content: center;
  align-items: center;
`;

export const LoadingMessage = styled.div`
  z-index: 1000;
  position: absolute;
  justify-content: center;
  align-items: center;
  color: ${theme.color.white};
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.8rem;
`;
