import styled from 'styled-components';
import theme from 'styles/theme';

export const PopupSection = styled.div`
  @media only screen and (min-width: 1200px) {
    display: none;
  }
`;

export const SideArea = styled.div`
  display: block;
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100dvh;
  min-width: ${theme.layout.minWidth};
  max-width: ${theme.layout.maxWidth};
  min-height: calc(
    (var(--vh, 1vh) * 100) + var(--ios-safe-top) + var(--ios-safe-bottom)
  );
  top: 0;
  left: calc(50% + 12px);

  margin: 0;
  background: ${theme.backgroundColor.shadow};

  @media screen and (max-width: 1199.9px) {
    left: 50%;
    transform: translateX(-50%);
    max-width: ${theme.layout.maxWidth};
  }
`;

export const BackgroundCard = styled.div`
  width: 100%;
  background-color: #ffffff;
  z-index: 999;
  position: absolute;
  bottom: 0;
  border-radius: 30px 30px 0 0;
`;

export const ImgSection = styled.div`
  border-radius: 30px 30px 0 0;
  width: 100%;
`;
export const PopupImg = styled.img`
  width: 100%;
  border-radius: 30px 30px 0 0;
`;

export const PopupTitle = styled.p`
  height: 50px;
  width: 90%;
  font-size: 1.8rem;
  font-weight: 700;
  padding-left: 15px;
  display: block;
  padding-top: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
`;

export const ButtonSection = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PopupButton = styled.button`
  padding: 0 25px;
  -webkit-tap-highlight-color: transparent;
`;
