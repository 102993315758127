export const QUESTION_TYPE_CD = [
  {
    name: '선택형 질문',
    code: 'QTC100',
  },
  {
    name: '서술형 질문',
    code: 'QTC200',
  },
];
