import React from 'react';
import useApp from 'hooks/useApp';
import LogoTopbar from './LogoTopbar';
import BackPageTopbar from './BackPageTopbar';
import ClosePageTopbar from './ClosePageTopbar';
import {
  PAGE_TITLE,
  LOGO_PATH,
  POPUP_PATH_PREFIX,
  PAGE_TITLE_PREFIX,
  DEEPLINK_PATH_PREFIX,
} from './constants';

const getPageTitle = pathname => {
  let pageTitle = PAGE_TITLE[pathname] || '';
  if (!pageTitle) {
    const prefixKeys = Object.keys(PAGE_TITLE_PREFIX);
    prefixKeys.forEach(key => {
      if (pathname.startsWith(key)) {
        pageTitle = PAGE_TITLE_PREFIX[key];
      }
    });
  }
  return pageTitle;
};

const Topbar = ({ pathname, showBanner }) => {
  const pageTitle = getPageTitle(pathname);
  const {
    auth: { authenticated },
  } = useApp();

  return LOGO_PATH.includes(pathname) ||
    DEEPLINK_PATH_PREFIX.some(item => pathname.startsWith(item)) ? (
    <LogoTopbar
      pathname={pathname}
      authenticated={authenticated}
      showButtons={LOGO_PATH.includes(pathname)}
      showBanner={showBanner}
    />
  ) : POPUP_PATH_PREFIX.some(item => pathname.startsWith(item)) ? (
    <ClosePageTopbar pageTitle={pageTitle} />
  ) : (
    <BackPageTopbar
      pageTitle={pageTitle}
      pathname={pathname}
      authenticated={authenticated}
      showBanner={showBanner}
    />
  );
};

export default Topbar;
