import React from 'react';
import { BlueBackgroundBox, SubText, Text } from './style';

const WideButton = ({
  onClick = () => {},
  subText = '다른 판매몰이 더 있으신가요?',
  text = '데이터 연동 추가 하기',
  disabled = false,
}) => {
  return (
    <BlueBackgroundBox tabIndex={0} onClick={onClick} disabled={disabled}>
      <SubText>{subText}</SubText>
      <Text>{text}</Text>
    </BlueBackgroundBox>
  );
};

export default WideButton;
