import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { Header } from '../style';

export const ClosePageTopbarHeader = styled(Header)`
  display: block;
  position: fixed;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  ${props => {
    return css`
      ${getCssStrBorder(props)}
    `;
  }}
`;

export const CloseButton = styled.button`
  display: block;
  position: absolute;
  z-index: 3001;
  top: 0;
  right: 0;
  width: 40px;
  height: 60px;

  @media screen and (max-width: 375px) {
    width: 42px;
  }
`;

export const HeaderH1 = styled.h1`
  display: block;
  width: 100%;
  height: 60px;
  line-height: 5.7rem;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${theme.color.black};

  @media screen and (max-width: 375px) {
    letter-spacing: -0.2px;
  }
`;

const getCssStrBorder = props => {
  let cssStr = '';
  if (props.main === true) {
    cssStr += `border-bottom: none;`;
  }
  return cssStr;
};
