import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  MainSectionOne,
  MainTextWrap,
  TextOne,
  TextTwo,
  TextThree,
  PageCounter,
  SlideContainer,
  SlideImg,
  SlideVideo,
  SlideSource,
  SlideVideoContainer,
} from './style';

const SectionOne = ({ bigBannerContents = [] }) => {
  const isMultiBanner = bigBannerContents.length > 1;
  return (
    <MainSectionOne>
      {isMultiBanner ? (
        <BannerMultiSlider bigBannerContents={bigBannerContents} />
      ) : (
        <BannerSingleSlide bigBannerContent={bigBannerContents[0]} />
      )}
    </MainSectionOne>
  );
};

const BannerMultiSlider = ({ bigBannerContents = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const setting = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
    useTransform: false,
    beforeChange: (_, currentIndex) => {
      setCurrentPage(currentIndex + 1);
    },
  };
  const checkIsVideoFile = data => data?.orgFileNm?.includes('mp4');

  return (
    <>
      <Slider {...setting}>
        {bigBannerContents.map((data, index) => {
          return checkIsVideoFile(data) ? (
            <VideoSlide key={index} slideData={data} />
          ) : (
            <ImgSlide key={index} slideData={data} />
          );
        })}
      </Slider>
      <PageCounter>{`${currentPage}/${bigBannerContents.length}`}</PageCounter>
    </>
  );
};

const BannerSingleSlide = ({ bigBannerContent = {} }) => {
  const isVideoContent = bigBannerContent?.orgFileNm?.includes('mp4');
  return (
    <>
      {isVideoContent ? (
        <VideoSlide slideData={bigBannerContent} />
      ) : (
        <ImgSlide slideData={bigBannerContent} />
      )}
    </>
  );
};

const VideoSlide = ({ slideData = {} }) => {
  const [mousePosition, setMousePosition] = useState({
    clientX: 0,
    clientY: 0,
  });
  return (
    <SlideVideoContainer bgUrl={slideData.thumbnailImageFileUrl}>
      <a
        href={slideData.movementUrl}
        onClick={e => {
          if (
            mousePosition.clientX !== e.clientX ||
            mousePosition.clientY !== e.clientY
          ) {
            e.preventDefault();
          }
        }}
        onMouseDown={e => {
          setMousePosition({ clientX: e.clientX, clientY: e.clientY });
          e.preventDefault();
        }}
        target="_blank"
        style={{ height: '520px' }}
      >
        <SlideVideo loop muted playsInline autoPlay>
          <SlideSource src={slideData.fileUrl} type="video/mp4" />
        </SlideVideo>
        <TextWarp slideData={slideData} />
      </a>
    </SlideVideoContainer>
  );
};

const ImgSlide = ({ slideData = {} }) => {
  const [mousePosition, setMousePosition] = useState({
    clientX: 0,
    clientY: 0,
  });
  return (
    <SlideContainer>
      <a
        href={slideData.movementUrl}
        onClick={e => {
          if (
            mousePosition.clientX !== e.clientX ||
            mousePosition.clientY !== e.clientY
          ) {
            e.preventDefault();
          }
        }}
        onMouseDown={e => {
          setMousePosition({ clientX: e.clientX, clientY: e.clientY });
          e.preventDefault();
        }}
        target="_blank"
        style={{ height: '520px' }}
      >
        <SlideImg
          src={slideData.fileUrl}
          alt={`메인 빅배너_${slideData.orgFileNm}`}
        />
        <TextWarp slideData={slideData} />
      </a>
    </SlideContainer>
  );
};

const TextWarp = ({ slideData = {} }) => {
  return (
    <MainTextWrap>
      <TextOne>{slideData.firstTitle}</TextOne>
      <TextTwo>{slideData.secondTitle}</TextTwo>
      <TextThree>{slideData.firstSubTitle}</TextThree>
      <TextThree>{slideData.secondSubTitle}</TextThree>
      <TextThree>{slideData.thirdSubTitle}</TextThree>
    </MainTextWrap>
  );
};

export default SectionOne;
