export const SERVICE_TEXT = [
  {
    id: 1,
    title: '매출은 높지만, 반품율이 높은 셀러',
    imgSrc: '/images/service_s1.png',
    score: 470,
    description:
      '반품율이 높은 셀러는 매출이 많고 정산금 규모가 크더라도 신용 평가 시 부정적인 평가를 받게 됩니다.',
    productA: '최대 약정한도 : 7억원 / 대출 금리 : 10.6%',
    productB: '최대 약정한도 : 3억원 / 대출 금리 : 8.2%',
  },
  {
    id: 2,
    title: '재판매율이 높은 셀러',
    imgSrc: '/images/service_s2.png',
    score: 920,
    description:
      '재판매율이 높은 셀러는 마케팅 비용이 적고 꾸준한 매출이 발생하기 때문에 신용평가 시 긍정적 평가요인 입니다.',
    productA: '최대 약정한도 : 10억원 / 대출 금리 : 3.6%',
    productB: '최대 약정한도 : 7억원 / 대출 금리 : 3.1%',
  },
  {
    id: 3,
    title: '매출은 낮지만, 재고회전율이 높은 셀러',
    imgSrc: '/images/service_s3.png',
    score: 892,
    description:
      '매출이 적으나 재고자산 회전율이 높은 셀러는 판매상품 구입을 늘리면 매출이 증가할 수 있는 잠재력을 가진 셀러로 신용평가시 긍정적인 평가를 받을 수 있습니다.',
    productA: '최대 약정한도 : 5억원 / 대출 금리 : 4.3%',
    productB: '최대 약정한도 : 3억원 / 대출 금리 : 3.5%',
  },
  {
    id: 4,
    title: '거래규모는 크지만, 비용규모가 큰 셀러',
    imgSrc: '/images/service_s4.png',
    score: 486,
    description:
      '거래규모가 크지만 비용규모도 큰 셀러는 마케팅 효율과 재고자산 회전율 이 나쁘고 판매 비용 구조가 안좋을 가능성이 높아 신용평가 시 부정적인 평가를 받을 수 있습니다.',
    productA: '최대 약정한도 : 3억원 / 대출 금리 : 12.6%',
    productB: '최대 약정한도 : 1억원 / 대출 금리 : 10.5%',
  },
  {
    id: 5,
    title: '거래규모는 작지만, 마케팅효율이 좋은 셀러',
    imgSrc: '/images/service_s5.png',
    score: 857,
    description:
      '마케팅 효율이 높은 셀러는 판매 상품 규모를 늘리거나 판매몰을 늘려 거래규모를 키울 수 있는 우량 셀러 입니다.',
    productA: '최대 약정한도 : 3억원 / 대출 금리 : 3.5%',
    productB: '최대 약정한도 : 2.6억원 / 대출 금리 : 2.8%',
  },
  {
    id: 6,
    title: '매출규모는 크지만, 정산금 규모가 적은 셀러',
    imgSrc: '/images/service_s6.png',
    score: 416,
    description:
      '거래규모는 크지만 정산금 규모가 적은 셀러는 반품이 많거나 판매몰에서 사용한 광고비 및 공제금액이 과도한 것으로 판매내역을 자세히 검토할 필요가 있습니다.',
    productA: '최대 약정한도 : 7억원 / 대출 금리 : 12.7%',
    productB: '최대 약정한도 : 3억원 / 대출 금리 : 13.5%',
  },
];
