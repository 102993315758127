import React from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { OneBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';

const AlertMessageModal = ({
  mallSecondAuthType = '',
  showFailModal = false,
  setShowFailModal,
  failModalMessage = '',
  isGeneralSecondAuthType = false,
  showModal = false,
  setShowModal,
  mallName = '',
}) => {
  const navigate = useNavigate();

  const onClickModalButton = () => {
    navigate(ROUTE_PATH.mypage.dataLinkageManage, { replace: true });
  };

  if (showFailModal === true) {
    // 해시 유효성검사 결과 실패시
    if (!mallSecondAuthType) {
      return (
        <OneBtnModal
          showModal={showFailModal}
          onClose={() => navigate('/', { replace: true })}
          onClick={() => navigate('/', { replace: true })}
          btnContent={<>확인</>}
        >
          <ScrollArea>
            <Contents>{failModalMessage}</Contents>
          </ScrollArea>
        </OneBtnModal>
      );
    }

    return (
      <OneBtnModal
        showModal={showFailModal}
        onClose={() => setShowFailModal(false)}
        onClick={() => setShowFailModal(false)}
        btnContent={<>확인</>}
      >
        <ScrollArea>
          <Contents>
            인증이 실패하였습니다.
            <br />
            다시 한번 시도해주시거나 고객센터로 문의해 주세요.
          </Contents>
        </ScrollArea>
      </OneBtnModal>
    );
  }

  return (
    <OneBtnModal
      showModal={showModal}
      onClose={() => setShowModal(false)}
      onClick={onClickModalButton}
      btnContent={<>데이터 연동 관리 가기</>}
    >
      <ScrollArea>
        <Contents>
          {isGeneralSecondAuthType === true ? (
            <>
              인증이 완료되었어요.
              <br />
              잠시 후 연동 결과를 확인할 수 있어요.
            </>
          ) : (
            <>
              {mallName} 판매몰의
              <br />
              보안 인증이 완료되었어요.
              <br />
              잠시 후 연동 결과를 확인할 수 있어요.
              <br />
              <br />* 캡차 인증 후 판매몰의 방침에 따라
              <br />
              2차 인증 절차를 진행할 수 있어요.
            </>
          )}
        </Contents>
      </ScrollArea>
    </OneBtnModal>
  );
};

export default AlertMessageModal;
