// 추후삭제
export const alertMessages = {
  typeId: '아이디를 입력해주세요.',
  validateIdLength: '아이디는 최소 4자, 최대 30자 입력할 수 있어요.',
  validateIdCharacter: '아이디는 한글과 공백 입력이 불가합니다.',
  validateIdDuplication: '아이디 중복 여부를 확인해 주세요.',
  typePw: '비밀번호를 입력해주세요.',
  checkPw: '비밀번호를 한번 더 입력해 주세요.',
  newPw: '새 비밀번호를 입력해주세요.',
  repeatPw: '새 비밀번호를 한번 더 입력해 주세요.',
  validatePw: '숫자, 알파벳 포함 최소 8자, 최대 30자 입력할 수 있어요.',
  differPw: '비밀번호가 일치하지 않아요.',
  wrongPw: '비밀번호를 잘못 입력했어요.',
  wrongIdPw: '아이디 또는 비밀번호를 잘못 입력했어요.',
  typeRepeatPw: '비밀번호를 한번 더 입력해 주세요.',
  newEmail: '새 이메일을 입력해주세요.',
  validEmail: '새 이메일 주소를 확인해주세요.',
  typeEmail: '이메일을 입력해주세요.',
  validateEmail: '이메일을 올바르게 입력해주세요.',
  charMemberName: '한글, 영문은 혼용할 수 없어요.',
  typeBizNum: '사업자번호를 입력해주세요.',
  typeBizNumLength: "사업자 번호를 '-'없이 숫자 10자리를 입력해 주세요.",
  lengthBizNum: '사업자번호는 숫자 10자리예요.',
  typeBizName: '상호명을 입력해주세요.',
  typeMemberName: '대표자를 입력해주세요.',
  typeFoundationDate: '설립일을 입력해주세요.',
  lengthFoundationDate: '설립일은 숫자 8자리예요. (예: 20231127)',
  wrongKorMemberName: '한글이름은 공백을 포함할 수 없어요.',
  wrongEngMemberName: '영문이름은 대문자만 입력할 수 있어요.',
};
