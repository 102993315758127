import React, { useEffect, useState } from 'react';
import ROUTE_PATH from 'routes/config';
import api from 'utils/api';
import { aizenGlobalAboutUs } from 'routes/config';
import { footerInfo } from 'messages/contactInfo';
import { TEXT_LIST } from './constants';
import {
  Footer,
  CCNLogoImg,
  AreaBox,
  MenuButton,
  AizenLogoImg,
  AizenGlobalText,
  Span,
  ISpan,
  Copyright,
  ListIcon,
  ListText,
} from './style';

const {
  ceo,
  address,
  businessNum,
  emailContact,
  phoneContact,
  loanOriginatorRegistrationNumber,
} = footerInfo;

const AizenFooter = () => {
  const [policyList, setPolicyList] = useState([]);

  // 회원가입 약관 조회
  const getPolicies = () => {
    api({
      url: `/v1/na/stipulation?stipulationClassificationCd=SCC100`,
      method: 'get',
      headers: { 'content-type': 'application/json' },
    })
      .then(res => {
        const { success, data } = res.data;
        if (success) {
          if (data?.result?.length > 0) {
            setPolicyList(data.result.filter(item => item.essentialYn === 'Y'));
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    getPolicies();
  }, []);

  const onClickPolicyButton = stipulationCd => {
    const stipulationSeq = policyList.find(
      item => item.stipulationCd === stipulationCd
    ).stipulationSeq;
    // 회원가입 약관 페이지
    window.open(
      `${ROUTE_PATH.login.joinMemberPolicyDefault}/${stipulationSeq}`,
      '_blank'
    );
  };

  const onClickAffiliateFinance = () => {
    window.open(`${ROUTE_PATH.financialSupervisoryService}?index=0`, '_blank');
  };

  return (
    <Footer>
      <CCNLogoImg
        src="/images/aizen/top-logo-new.png"
        alt="Seller CreditConnect"
      />
      <AreaBox className="ff-row2">
        <MenuButton
          tabIndex={0}
          title="회사소개로 이동"
          target="_blank"
          href={aizenGlobalAboutUs}
        >
          회사소개
        </MenuButton>
        <MenuButton
          tabIndex={0}
          title="서비스 이용약관으로 이동"
          onClick={() => onClickPolicyButton('STI100')}
        >
          이용약관
        </MenuButton>
        <MenuButton
          tabIndex={0}
          title="금융소비자보호 새 창으로 열기"
          onClick={() => onClickAffiliateFinance()}
        >
          금융소비자보호
        </MenuButton>
        <MenuButton
          tabIndex={0}
          title="개인정보처리방침"
          onClick={() => onClickPolicyButton('STI200')}
        >
          개인정보처리방침
        </MenuButton>
      </AreaBox>
      <AreaBox className="ff-row3">
        <AizenLogoImg src="/images/aizen/aizen-logo.png" alt="AIZEN" />
        <AizenGlobalText>AIZEN GLOBAL</AizenGlobalText>
      </AreaBox>

      <AreaBox className="ff-row4">
        <AreaBox className="ff-r4wrap">
          <Span>대표 : {ceo}</Span>
          <ISpan> | </ISpan>
          <Span>주소 : {address}</Span>
          <ISpan> | </ISpan>
          <Span>사업자등록번호 : {businessNum}</Span>
          <br />
          <Span>이메일 : {emailContact}</Span>
          <ISpan> | </ISpan>
          <Span>연락처 : {phoneContact}</Span>
          <br />
          <Span>대출모집법인 등록번호 {loanOriginatorRegistrationNumber}</Span>
        </AreaBox>
      </AreaBox>
      <Copyright>© Copyright - AIZEN</Copyright>
      <AreaBox className="ff-row5">
        {TEXT_LIST.map((item, index) => {
          return (
            <AreaBox className="ff-row5-one" key={index}>
              <ListIcon />
              <ListText>{item}</ListText>
            </AreaBox>
          );
        })}
      </AreaBox>
    </Footer>
  );
};

export default AizenFooter;
