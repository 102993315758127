import React, { useEffect, useState, useRef } from 'react';

window._popHasOpened = false;

const IdentificationPage = () => {
  const formRef = useRef(null);
  const [encodeData, setEncodeData] = useState('');

  useEffect(() => {
    loopGetWindowEncodeData({ setEncodeData });
  }, []);

  useEffect(() => {
    if (formRef?.current && encodeData) {
      formRef.current.submit();
    }
  }, [encodeData, formRef]);

  return (
    <form
      method="post"
      action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
      target="_self"
      ref={formRef}
    >
      <input type="hidden" name="m" value="checkplusService" />
      <input type="hidden" name="EncodeData" value={encodeData} />
      <input type="hidden" name="recvMethodType" value="get" />
    </form>
  );
};

const loopGetWindowEncodeData = ({
  setEncodeData,
  maxCount = 10,
  count = 0,
}) => {
  if (window._encodeData) {
    setEncodeData && setEncodeData(window._encodeData);
  } else {
    if (maxCount > count) {
      setTimeout(() => {
        loopGetWindowEncodeData({ setEncodeData, maxCount, count: count + 1 });
      }, 10);
    } else {
      if (!!window._popHasOpened) {
        alert(
          '기존 팝업이 열려 있어 본인인증 진행이 어렵습니다.\n팝업을 닫고 다시 시도해주세요.'
        );
      } else {
        alert('인증에 필요한 정보를 찾을 수 없습니다.\n관리자에게 문의하세요.');
      }
      window.close();
    }
    window._popHasOpened = true;
  }
};

export default IdentificationPage;
