import React from 'react';
import ROUTE_PATH from 'routes/config';
import { useNavigate } from 'react-router-dom';
import { ArrowButton, BlueInfoBoxText, BlueInfoBoxWrap } from './style';
import { TextBold } from '../../style';

const BlueInfoBox = ({
  index = 0,
  setShowInWaitMallModal = false,
  mallInterlockStatusInWaitCount,
}) => {
  const navigate = useNavigate();

  const text =
    !mallInterlockStatusInWaitCount || index === 1
      ? '최적의 대출 조건을 위해 다른 판매몰을 등록해 보세요'
      : `건의 판매몰이 연동 진행 중이에요`;

  const onClickBlueBox = () => {
    if (!!mallInterlockStatusInWaitCount) {
      setShowInWaitMallModal(true);
    } else {
      navigate(ROUTE_PATH.mypage.dataLinkageManage);
    }
  };
  return (
    <BlueInfoBoxWrap onClick={onClickBlueBox}>
      <BlueInfoBoxText>
        {!!mallInterlockStatusInWaitCount && (
          <TextBold style={{ color: '#009FF9' }}>
            {mallInterlockStatusInWaitCount}
          </TextBold>
        )}
        {text}
      </BlueInfoBoxText>

      <ArrowButton />
    </BlueInfoBoxWrap>
  );
};

export default BlueInfoBox;
