import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'utils/api';
import ROUTE_PATH from 'routes/config';
import LinkFailedModal from 'pages/MyPage/DataLinkage/DataLinkageManage/LinkFailedModal';
import DeleteLinkModal from 'pages/MyPage/DataLinkage/DataLinkageManage/DeleteLinkModal';
import { Contents, ScrollArea } from 'components/Modal/style';
import { NoBtnModal } from 'components/Modal';
import BlueAlertBox from 'components/BlueAlertBox';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import {
  LINKAGE_STATUS,
  statusActions,
  DATA_INTERLOCK_LINK_ERR_CASE,
  LINKED_FAIL,
  LINKED_UNAVAILABLE,
} from './constants';
import { LINKAGE_FAIL_USER_MESSAGES } from 'pages/MyPage/DataLinkage/constants';
import {
  DataBox,
  DataHeader,
  DataKey,
  DataName,
  DataValue,
  DataValueText,
  Delete,
  Edit,
  MapWrapper,
  ModifyEmployee,
} from 'pages/MyPage/Manager/EmployeeItem/style';
import {
  StatusSpan,
  BlueAlertBoxTitle,
  BlueAlertBoxContentContainer,
} from './style';

const LinkedMallItem = ({ item, type, tabValue }) => {
  const {
    businessDataProviderMallMappingSeq,
    mallMainId,
    mallName,
    mallInterlockStatus,
    mallInterlockStatusName,
    errorCode,
    errorMessage,
  } = item;
  const navigate = useNavigate();
  const [isLinkFailedModalOpen, setIsLinkFailedModalOpen] = useState(false);
  const [responseModalData, setResponseModalData] = useState({
    isOpen: false,
    text: '',
  });
  const [isTwoBtnModalOpen, setIsTwoBtnModalOpen] = useState(false);
  const [isNoBtnModalOpen, setIsNoBtnModalOpen] = useState(false);
  const LINKED_ERROR_BUTTON_DATA = {
    captureRetry: {
      text: '캡차 인증 재요청하기',
      onClick: () => {
        requestScrapingAPI();
      },
    },
    secondCheckRetry: {
      text: '2차 인증 재요청하기',
      onClick: () => {
        requestScrapingAPI();
      },
    },
    askInquire: {
      text: '1:1 문의하기',
      onClick: () => {
        goToQnAPage();
      },
    },
    editInfo: {
      text: '계정 정보 수정하기',
      onClick: () => {
        goToEditMallPage();
      },
    },
  };

  // 스크래핑 요청 API 호출
  const requestScrapingAPI = data =>
    api({
      url: `/v1/au/data-provider/requestScraping/${businessDataProviderMallMappingSeq}`,
      method: 'post',
      headers: { 'content-type': 'application/json' },
      data: data,
    }).finally(res => {
      setResponseModalData({
        isOpen: true,
        text: '인증 재요청이 완료되었습니다.',
      });
    });

  // TODO: 대출 진행 여부 체크하는 validation (api 추후개발)
  const isOnLoan = false;
  const onClickDelete = isOnLoan => {
    // 대출 진행중이지 않은 경우
    if (!isOnLoan) {
      setIsTwoBtnModalOpen(true);
    }
    // 대출 진행중인 경우
    if (!!isOnLoan) {
      setIsNoBtnModalOpen(true);
    }
  };

  const goToEditMallPage = () => {
    navigate(ROUTE_PATH.mypage.dataLinkageModify, {
      state: { linkageType: tabValue, item: item },
    });
  };

  const goToQnAPage = () => {
    navigate(ROUTE_PATH.serviceCenter.main + '?tab=QnA');
  };

  const renderCheckDeleteModal = isOnLoan => {
    return !isOnLoan ? (
      <DeleteLinkModal
        type={type}
        seq={businessDataProviderMallMappingSeq}
        name={mallName}
        isOpenModal={isTwoBtnModalOpen}
        setIsOpenModal={setIsTwoBtnModalOpen}
        onReset={goToEditMallPage}
      />
    ) : (
      <NoBtnModal
        showModal={isNoBtnModalOpen}
        onClose={() => setIsNoBtnModalOpen(false)}
      >
        <ScrollArea>
          <Contents>
            대출이 진행중인 상태에요. 대출이 완료 후 삭제가 가능해요.
          </Contents>
        </ScrollArea>
      </NoBtnModal>
    );
  };

  const BlueAlertButton = ({ text, onClick }) => (
    <BlueAlertBoxContentContainer>
      <StyledActiveButton
        $small
        round
        height="31px"
        onClick={onClick}
        style={{ fontSize: '1.2rem', width: '125px' }}
      >
        {text}
      </StyledActiveButton>
    </BlueAlertBoxContentContainer>
  );

  const BlueAlertBoxContent = () => {
    // errorCode는 연동 실패 일때 존재 (연동 불가는 포함 안 됨)
    // 정의되지 않은 에러코드일 경우, 일대일문의 버튼을 보여줌
    const errBtnType =
      Object.keys(DATA_INTERLOCK_LINK_ERR_CASE).find(item =>
        DATA_INTERLOCK_LINK_ERR_CASE[item].includes(errorCode)
      ) || 'askInquire';

    return errBtnType === 'noBtn'
      ? ''
      : BlueAlertButton(LINKED_ERROR_BUTTON_DATA[errBtnType]);
  };

  const NoticeMessageBox = () => {
    // 연동불가 케이스: errorCode 없고, errorMessage만 존재한다

    const getNoticeMessage = (errorCode, errorMessage, mallName) => {
      return errorCode === 'E11420'
        ? `판매몰 회원 정책으로 인해 로그인이 실패하였습니다. ${mallName}의 고객센터로 문의해주세요.`
        : LINKAGE_FAIL_USER_MESSAGES[errorCode] ||
            errorMessage ||
            LINKAGE_FAIL_USER_MESSAGES.ETC;
    };

    return (
      <BlueAlertBox
        title={
          <BlueAlertBoxTitle
            style={{
              fontSize: '1.2rem',
              lineHeight: '2.1rem',
              marginBottom: '-2px',
            }}
          >
            {getNoticeMessage(errorCode, errorMessage, mallName)}
          </BlueAlertBoxTitle>
        }
        contents={<BlueAlertBoxContent />}
      />
    );
  };

  return (
    <DataBox>
      <DataHeader>
        <DataName>
          {mallName}
          <StatusSpan status={mallInterlockStatus}>
            {mallInterlockStatusName ||
              LINKAGE_STATUS[mallInterlockStatus] ||
              ''}
          </StatusSpan>
        </DataName>
        <LinkFailedModal
          isOpenModal={isLinkFailedModalOpen}
          setIsOpenModal={setIsLinkFailedModalOpen}
        />
        <ModifyEmployee>
          <Edit
            onClick={goToEditMallPage}
            disabled={!statusActions[mallInterlockStatus]?.modify || false}
          >
            수정
          </Edit>
          <Delete
            onClick={() => onClickDelete(isOnLoan)}
            disabled={!statusActions[mallInterlockStatus]?.delete || false}
          >
            삭제
          </Delete>
        </ModifyEmployee>
      </DataHeader>
      {renderCheckDeleteModal(isOnLoan)}
      {/* 연동 실패, 연동 불가일 때 안내 메시지 */}
      {(mallInterlockStatus === LINKED_FAIL ||
        mallInterlockStatus === LINKED_UNAVAILABLE) && <NoticeMessageBox />}
      <MapWrapper>
        <DataKey>아이디</DataKey>
        <DataValue>
          <DataValueText>{mallMainId || '-'}</DataValueText>
        </DataValue>
      </MapWrapper>
      <NoBtnModal
        showModal={responseModalData.isOpen}
        onClose={() => {
          setResponseModalData({ ...responseModalData, isOpen: false });
          window.location.reload();
        }}
      >
        <ScrollArea>
          <Contents>{responseModalData.text}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </DataBox>
  );
};

export default LinkedMallItem;
