import React from 'react';
import DOMPurify from 'dompurify';
import { PreviewSection } from 'styles/styleArea';
import { MainSectionFour, Title, MallImageSection, SubTitle } from './style';

const MallSection = ({ middleAreaContents }) => {
  return (
    <MainSectionFour>
      <Title>
        셀러 크레딧커넥트는
        <br />
        셀러의 데이터와 금융을 연결합니다.
      </Title>
      <SubTitle>
        <br />
        정산예정금 데이터를 시작으로
        <br />
        매출, 거래규모, 재판매율, 재고 회전율, 반품율 등
        <br />
        셀러의 모든 활동을 데이터화 합니다.
      </SubTitle>
      <MallImageSection>
        {!!middleAreaContents && (
          <PreviewSection
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(middleAreaContents),
            }}
          />
        )}
      </MallImageSection>
    </MainSectionFour>
  );
};

export default MallSection;
