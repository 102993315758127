import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import useApp from 'hooks/useApp';
import { getDataRequest, getListDataRequest } from 'utils/request';
import { orderLanguage } from 'tools/SortTool';
import BlueAlertBox from 'components/BlueAlertBox';
import {
  ChildText,
  ChildTextContent,
  SelectOption,
  SelectProvider,
} from '../DataLinkageRequest/style';
import { PaddingBox, GuideContents } from './style';

const DataLinkageGuide = () => {
  const {
    auth: { authenticated: isLogin },
  } = useApp();
  const location = useLocation();
  const [dataInterlockSeqList, setDataInterlockSeqList] = useState([]);
  const [_dataInterlockMallList, _setDataInterlockMallList] = useState([]);
  const [_mallLinkageGuide, _setMallLinkageGuide] = useState({ contents: '' });
  const [dataInterlockMallList, setDataInterlockMallList] = useState([
    {
      label: '판매몰 선택',
      value: '',
    },
  ]);
  const [selectedMall, setSelectedMall] = useState({
    seq: '',
    name: '',
  });
  const selectedProvider = selectedMall && selectedMall.seq;

  useEffect(() => {
    // 팝업으로 mallName을 받는 경우
    if (window._mallName && dataInterlockMallList) {
      setSelectedMall({
        seq:
          dataInterlockMallList.find(item => item.label === window._mallName)
            ?.value || '',
        name: window._mallName,
      });
    } else if (!!location.search && dataInterlockMallList) {
      // URL 쿼리스트링에서 mallName을 받는 경우
      const search = location.search.replace('?', '');
      const qo = !!search ? qs.parse(search) : {};
      const mallName = qo.mallName;
      setSelectedMall({
        seq:
          dataInterlockMallList.find(item => item.label === mallName)?.value ||
          '',
        name: mallName,
      });
    }
  }, [dataInterlockMallList, location.search]);

  useEffect(() => {
    getListDataRequest({
      url: isLogin
        ? `/v1/au/data-provider/list/individual`
        : `/v1/na/data-provider/list/individual`,
      setData: setDataInterlockSeqList,
    });
  }, [isLogin]);

  useEffect(() => {
    if (
      dataInterlockSeqList?.length > 0 &&
      !!dataInterlockSeqList[0]?.dataProviderSeq
    ) {
      getListDataRequest({
        url: isLogin
          ? `/v1/au/data-provider/individual/interlock/mall`
          : `/v1/na/data-provider/individual/interlock/mall`,
        setData: _setDataInterlockMallList,
        params: {
          dataProviderSeq: dataInterlockSeqList[0]?.dataProviderSeq,
        },
      });
    }
  }, [dataInterlockSeqList, isLogin]);

  useEffect(() => {
    if (selectedProvider)
      getDataRequest({
        url: '/v1/na/data-provider/guide',
        setData: _setMallLinkageGuide,
        params: { mallSeq: selectedProvider },
      });
  }, [selectedProvider]);

  useEffect(() => {
    const filteredData = orderLanguage(_dataInterlockMallList)?.map(item => {
      return {
        label: item.mallName,
        value: item.mallSeq,
      };
    });
    setDataInterlockMallList([
      {
        label: '판매몰 선택',
        value: '',
      },
      ...filteredData,
    ]);
  }, [_dataInterlockMallList]);

  const onChangeSelectProvider = event => {
    const { value } = event.target;
    setSelectedMall({
      seq: value,
      name:
        dataInterlockMallList.find(item => item.value === +value)?.label || '',
    });
  };

  return (
    <>
      <ChildText>
        <SelectProvider
          onChange={onChangeSelectProvider}
          value={selectedProvider}
        >
          {dataInterlockMallList?.map((item, index) => (
            <SelectOption
              key={`SelectOption_${item.value}_${index}`}
              value={item.value}
            >
              {item.label}
            </SelectOption>
          ))}
        </SelectProvider>
      </ChildText>

      {/*TODO: 최종 동영상 url 반영 */}
      {/*<ChildTextContent style={{ margin: '10px 0' }}>*/}
      {/*  연동 안내 영상*/}
      {/*</ChildTextContent>*/}
      {/*<ChildTextContent2>*/}
      {/*  영상을 확인하시면 연동 방법을 상세하게 확인할 수 있어요.*/}
      {/*</ChildTextContent2>*/}

      {/*<VideoBox>*/}
      {/*  <iframe*/}
      {/*    title="video-player"*/}
      {/*    id="XxuL_eHO_Pg"*/}
      {/*    src="https://www.youtube.com/embed/rcnlogyxbJ8"*/}
      {/*    allowFullScreen*/}
      {/*    width="100%"*/}
      {/*    height="100%"*/}
      {/*    style={{ position: 'absolute' }}*/}
      {/*  />*/}
      {/*</VideoBox>*/}

      {selectedProvider ? (
        <>
          <ChildTextContent style={{ margin: '20px 0 10px' }}>
            연동 상세 안내
          </ChildTextContent>
          <PaddingBox key={`linkageGuide_${selectedProvider}`}>
            <GuideContents
              dangerouslySetInnerHTML={{ __html: _mallLinkageGuide.contents }}
            />
          </PaddingBox>
        </>
      ) : !window._mallName && !location.search ? (
        <BlueAlertBox
          title="판매몰을 선택해 주세요."
          contents={
            <p style={{ textIndent: '2rem' }}>
              연동 등록 가이드를 확인할 수 있습니다.
            </p>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DataLinkageGuide;
