export const modalMessages = {
  certificationNo: `대표자가 변경되었어요.\n다시 로그인해서 대표자 명의 휴대폰으로 본인인증하고, 대출 신청해주세요.`,
  memberStatusNo: `회원은 대출 신청할 수 없습니다.`,
  BusinessNumberNo: `사업자정보가 변경되었어요.\n사업자정보 변경하고, 대출 신청해주세요.`,
  BusinessClose: `휴폐업 상태입니다.\n상태가 정상화되기 전까지 대출 신청할 수 없어요.`,
  availableCountZero: '',
  passedValidation: (
    <span>
      대출 신청을 위해 <b className="bold">신청자 휴대폰 본인인증</b>이
      필요해요.
    </span>
  ),
};

export const buttonContents = {
  login: '로그인',
  updateBusiness: '사업자정보 변경',
};

export const unit = {
  TEN_THOUSAND: 10000,
  HUNDRED_THOUSAND: 100000,
  MILLION: 1000000,
  TEN_MILLION: 10000000,
  HUNDRED_MILLION: 100000000,
};
