import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { getDataRequest } from 'utils/request';
import IdentificationBox from 'components/IdentificationBox';
import { TwoBtnModal } from 'components/Modal';
import StyledLinkButton from 'components/Button/StyledLinkButton';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { AreaRow } from 'styles/styleArea';
import { Contents, NoScrollArea, ContentsBox } from 'components/Modal/style';
import {
  BackgroundTextBox,
  BackgroundTextBoxContents,
} from 'pages/MyPage/DataLinkage/style';
import { DataKey } from 'pages/MyPage/Manager/EmployeeItem/style';
import { TextMedium } from './style';

const SearchId = () => {
  const navigate = useNavigate();
  const { serviceCenter, login } = ROUTE_PATH;

  const [step, setStep] = useState(1);
  const [userIdList, setUserIdList] = useState([]);

  const [showTwoBtnModal, setShowTwoBtnModal] = useState(false);
  const [modalContents, setModalContents] = useState('');

  //step1 id search
  const searchId = useCallback(({ memberCertificationSeq }) => {
    const params = { memberCertificationSeq };

    //성공 : 화면전환
    const successFnc = data => {
      setUserIdList(data?.result || []);
      setStep(2);
    };

    //실패 : two Btn Modal
    const exceptFnc = message => {
      setShowTwoBtnModal(true);
      setModalContents(message);
    };

    getDataRequest({
      url: `/v1/na/member/id/check`,
      params,
      successFnc,
      exceptFnc,
    });
  }, []);

  //step1 본인인증 완료(성공)
  const identificationSuccessCallback = useCallback(
    memberCertificationSeq => {
      searchId({ memberCertificationSeq });
    },
    [searchId]
  );

  //step1 본인인증 완료(decode 실패)
  const identificationExceptCallback = useCallback(err => {
    console.error(err);
  }, []);

  return (
    <div>
      {step === 1 ? (
        <div>
          <TextMedium>
            휴대폰 본인인증을 통해 <br />
            아이디를 찾을 수 있습니다.
          </TextMedium>
          <IdentificationBox
            text="휴대폰 본인인증"
            identificationSuccessCallback={identificationSuccessCallback}
            identificationExceptCallback={identificationExceptCallback}
          />
        </div>
      ) : step === 2 ? (
        <div>
          <TextMedium>아이디를 찾았어요.</TextMedium>
          <BackgroundTextBox style={{ marginTop: '23px' }}>
            <DataKey style={{ marginTop: '0px' }}>아이디</DataKey>
            {userIdList &&
              userIdList.map(item => {
                return (
                  <BackgroundTextBoxContents key={item}>
                    {item}
                  </BackgroundTextBoxContents>
                );
              })}
          </BackgroundTextBox>
          <AreaRow>
            <StyledLinkButton onClick={() => navigate(login.searchPassword)}>
              비밀번호 찾기
            </StyledLinkButton>
            <StyledActiveButton
              halfButton
              onClick={() => navigate(login.login)}
            >
              로그인
            </StyledActiveButton>
          </AreaRow>
        </div>
      ) : null}

      {!!showTwoBtnModal && (
        <TwoBtnModal
          showModal={showTwoBtnModal}
          onClose={() => {
            setShowTwoBtnModal(false);
          }}
          onClickLeftBtn={() => {
            navigate(serviceCenter.main + '?tab=QnA');
          }}
          onClick={() => navigate(login.joinMember)}
          leftButton="1:1 문의하기"
          rightButton="회원가입"
        >
          <NoScrollArea>
            <Contents>{modalContents}</Contents>
            <ContentsBox></ContentsBox>
          </NoScrollArea>
        </TwoBtnModal>
      )}
    </div>
  );
};

export default SearchId;
