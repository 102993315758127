import styled from 'styled-components';
import theme from 'styles/theme';

export const LoanLimitWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
`;

export const MainSectionTwo = styled.div`
  position: relative;
  width: 100%;
  padding: 54px 35px 0;

  @media screen and (max-width: 485.9px) {
    padding: 54px 27px 0;
  }

  @media screen and (max-width: 375px) {
    padding: 54px 20px 0;
  }
`;

export const TextMedium = styled.div`
  padding: 41px 0 18px;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
  text-align: center;

  white-space: pre-line;
`;

export const TextBlue = styled.span`
  color: ${theme.color.blue};
`;

export const ChildText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0;
`;

export const ChildTextContent = styled.div`
  font-size: ${theme.fontSize.h5};
  font-weight: 500;
`;

export const Table = styled.table`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const TableHead = styled.thead``;

export const TableHeadRow = styled.tr`
  width: 100%;
`;

export const TableHeadCell = styled.th`
  padding: 11px 2px;
  line-height: 2.3rem;
  font-size: ${props => (props.smallFont ? '1.2rem' : '1.3rem')};
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  border-left: 1px solid ${theme.color.white};
  color: ${theme.color.black};
  background: ${theme.backgroundColor.icyBlue};
  width: ${props => (props.type === 'twoCell' ? '67%' : '100%')};

  white-space: pre-line;

  &:first-child {
    border-left: none;
    width: ${props => (props.type === 'twoCell' ? '33%' : '100%')};
  }

  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }
`;

export const TableBody = styled.tbody``;

export const TableDataRow = styled.tr`
  width: 100%;
  margin: 2px 0 0;
  border-top: 1px solid ${theme.borderColor.white};
`;

export const TableDataCell = styled.td`
  padding: 11px 2px;
  line-height: 2.3rem;
  font-size: ${props => (props.smallFont ? '1.2rem' : '1.3rem')};
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  border-left: 1px solid ${theme.color.white};
  color: ${theme.color.black};
  background: ${theme.backgroundColor.table};

  &:first-child {
    border-left: none;
  }

  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }

  @media screen and (max-width: 280px) {
    font-size: 1rem;
    line-height: 1.6rem;
  }
`;

export const TableDataCell2 = styled(TableDataCell)`
  width: 100%;

  &:first-child {
    width: 33%;
    border-left: none;
  }

  ${props => {
    if (props.wordBreak) {
      return `word-break: ${props.wordBreak}`;
    }
  }}
`;

export const TableDiv = styled.div`
  margin: 10px 0 30px 0;
`;

export const Overlay = styled.div`
  display: ${props => (props.display === 'true' ? `flex` : `none`)};
  position: absolute;
  z-index: 999;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
`;

export const LoadingMessage = styled.div`
  z-index: 1000;
  position: absolute;
  justify-content: center;
  align-items: center;
  color: ${theme.color.white};
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.8rem;
`;
