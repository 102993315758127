import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import FinancialSupervisoryServiceDetail from './FinancialSupervisoryServiceDetail';
import { FINANCE_SUPERVISORY_SERVICE_TAB } from './constants';
import {
  JoinPolicyWrapper,
  TabButton,
  TabSection,
} from 'pages/Login/JoinMemberPolicyPopupPage/style';

const FinancialSupervisoryServicePopupPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const index = Number(params.get('index'));

  useEffect(() => {
    if (
      !!params.has('index') &&
      !!params.get('index') &&
      Number(params.get('index')) < FINANCE_SUPERVISORY_SERVICE_TAB.length
    ) {
      return;
    }
    navigate(`${ROUTE_PATH.financialSupervisoryService}?index=0`);
  }, [params]);

  const onClickTab = tabIndex => {
    navigate(`${ROUTE_PATH.financialSupervisoryService}?index=${tabIndex}`, {});
  };

  return (
    <JoinPolicyWrapper>
      <TabSection>
        {FINANCE_SUPERVISORY_SERVICE_TAB.map(item => {
          return (
            <TabButton
              key={item.index}
              onClick={() => onClickTab(item.index)}
              active={index === item.index}
            >
              {item.label}
            </TabButton>
          );
        })}
      </TabSection>

      <FinancialSupervisoryServiceDetail index={index} />
    </JoinPolicyWrapper>
  );
};

export default FinancialSupervisoryServicePopupPage;
