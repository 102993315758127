import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { getDataRequest } from 'utils/request';
import StyledLinkButton from 'components/Button/StyledLinkButton';
import ROUTE_PATH from 'routes/config';
import CustomerServiceFooter from 'layouts/Footer/CustomerServiceFooter';
import MyPageNavLink from '../MyPageMain/MyPageNavLink';
import { NAV_LIST_GUIDE } from '../MyPageMain/constants';
import { NavALinkWrap } from '../MyPageMain/MyPageNavLink/style';
import { JoinText, JoinDescription, LoginDescription, NavLogin } from './style';

const MyPageNoAuth = () => {
  const navigate = useNavigate();
  const [hasNewEvent, setHasNewEvent] = useState(false);

  useEffect(() => {
    getDataRequest({
      url: '/v1/na/event/new',
      successFnc: data => {
        return data?.newEventYn === 'Y' ? setHasNewEvent(true) : null;
      },
    });
  }, []);

  return (
    <div>
      <NavALinkWrap
        onClick={() => {
          navigate(ROUTE_PATH.login.login);
        }}
      >
        <NavLogin tabIndex={0} title="로그인">
          로그인이 필요해요
        </NavLogin>
        <FeatherIcon
          style={{ marginTop: '10px' }}
          icon="chevron-right"
          size={20}
          strokeWidth={2}
          stroke="black"
        />
      </NavALinkWrap>
      <LoginDescription>
        셀러크레딧커넥트에서 <br />
        나에게 맞는 대출을 찾아보세요
      </LoginDescription>
      <StyledLinkButton
        height="80px"
        tabIndex={0}
        onClick={() => {
          navigate(ROUTE_PATH.login.joinMember);
        }}
      >
        <JoinDescription>아직 회원이 아니신가요?</JoinDescription>
        <JoinText> 회원가입</JoinText>
      </StyledLinkButton>
      <MyPageNavLink
        title="안내"
        menuList={NAV_LIST_GUIDE}
        ShowDesc={false}
        hasNewEvent={hasNewEvent}
        borderTop="1px solid black"
      />
      <CustomerServiceFooter hasKaKao={true} />
    </div>
  );
};

export default MyPageNoAuth;
