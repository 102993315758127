import React, { useEffect, useState } from 'react';
import LinkedMallItem from './LinkedMallItem';
import { statusOrder } from './LinkedMallItem/constants';
import { SecondText } from '../../style';

const NO_DATA_TEXT = '연동된 판매몰이 없습니다.';
const LinkedMall = ({
  type = '',
  data: _data = [],
  tabValue,
  additionalData,
}) => {
  const [data, setData] = useState(_data);
  useEffect(() => {
    setData(
      _data
        .map(item => ({
          ...item,
          dataProviderSeq: additionalData.dataProviderSeq,
        }))
        .sort(statusOrder)
    );
  }, [type, _data]);

  return _data.length === 0 ? (
    <SecondText>{NO_DATA_TEXT}</SecondText>
  ) : (
    data?.map((item, index) => {
      return (
        <LinkedMallItem
          key={`linkedMallItem_${item.seq}_${index}`}
          item={item}
          type={type}
          tabValue={tabValue}
        />
      );
    })
  );
};

export default LinkedMall;
