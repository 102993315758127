import React from 'react';
import { OneBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
const OneBtnAlertModal = ({
  showOneBtnAlertModal,
  setShowOneBtnAlertModal,
  showOneBtnAlertModalText,
  showOneBtnAlertModalBtnText,
  onClickModalButton,
}) => {
  return (
    <OneBtnModal
      showModal={showOneBtnAlertModal}
      onClose={() => {
        setShowOneBtnAlertModal(false);
      }}
      onClick={onClickModalButton}
      btnContent={showOneBtnAlertModalBtnText}
    >
      <ScrollArea>
        <Contents>{showOneBtnAlertModalText}</Contents>
      </ScrollArea>
    </OneBtnModal>
  );
};

export default OneBtnAlertModal;
