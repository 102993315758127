import React, { useState } from 'react';
import { deleteDataRequest } from 'utils/request';
import { TwoBtnModal, FilterModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import { TextBlue } from '../../style';

const DeleteLinkModal = ({
  type,
  seq,
  name,
  isOpenModal,
  setIsOpenModal,
  onReset,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');

  const onClickRight = () => {
    const url =
      type === 'dataProvider'
        ? `/v1/au/data-provider/${seq}`
        : `/v1/au/data-provider/interlock/${seq}`;
    deleteDataRequest({
      url: url,
      setModalMessage: setDeleteModalMessage,
      setShowModal: setShowDeleteModal,
    });
  };

  const onClickClose = () => {
    setShowDeleteModal(false);
    setIsOpenModal(false);
    window.location.reload();
  };

  return (
    <TwoBtnModal
      showModal={isOpenModal}
      leftButton="연동 중지 하기"
      rightButton="삭제"
      onClose={() => setIsOpenModal(false)}
      onReset={onReset}
      onClick={onClickRight}
    >
      <ScrollArea>
        <Contents>
          <TextBlue>{name}</TextBlue>의
          <br />
          연동 정보를 정말 삭제하시겠어요?
          <ul style={{ padding: '20px 20px 0' }}>
            <li>삭제 시 복구가 불가해요</li>
            <li>일시적으로 연동이 불필요 한 경우, 연동 중지가 가능해요</li>
            <li>
              '연동 완료' 상태에서 삭제 시 금융기관 심사에 영향을 줄 수 있습니다
            </li>
          </ul>
        </Contents>
      </ScrollArea>
      <FilterModal showModal={showDeleteModal} onClose={onClickClose}>
        <ScrollArea>
          <Contents>{deleteModalMessage}</Contents>
        </ScrollArea>
      </FilterModal>
    </TwoBtnModal>
  );
};

export default DeleteLinkModal;
