import { useEffect } from 'react';
import { createMarkup } from 'tools/WebTool';
import { StipulationContents } from 'components/StipulationAgreeRow/style';
import {
  PolicyAgreeAllCheckbox,
  PolicyAgreeAllCheckboxIcon,
  PolicyAgreeAllCheckboxLabel,
  PolicyAgreeAllWrapper,
} from 'pages/Login/JoinMemberPage/JoinMemberStep2/style';

const TextTypeStipulation = ({ stipulationList = [], setStipulationList }) => {
  // 모두 동의합니다 체크 여부
  const isAllChecked = stipulationList[0].checked;

  // 전체 동의 체크박스 클릭
  const onChangeAll = event => {
    const { checked } = event.target;

    setStipulationList(prev =>
      prev.map(item => {
        return { ...item, checked: checked };
      })
    );
  };

  useEffect(() => {
    if (stipulationList && stipulationList.length > 0) {
      setStipulationList(
        stipulationList.map(item => ({
          ...item,
          required: item.essentialYn === 'Y',
          checked: false,
        }))
      );
    }
  }, []);

  return (
    <div>
      <PolicyAgreeAllWrapper>
        <PolicyAgreeAllCheckboxLabel>
          {stipulationList[0].required ? (
            <span style={{ color: '#02A4FF' }}>[필수] </span>
          ) : (
            <span style={{ color: '#969696' }}>[선택] </span>
          )}
          <PolicyAgreeAllCheckbox
            type="checkbox"
            onChange={onChangeAll}
            checked={isAllChecked}
            readOnly
          />
          모두 동의합니다.
          <PolicyAgreeAllCheckboxIcon />
        </PolicyAgreeAllCheckboxLabel>
      </PolicyAgreeAllWrapper>

      <StipulationContents
        dangerouslySetInnerHTML={createMarkup(
          stipulationList[0].stipulationContents || ''
        )}
      />
    </div>
  );
};

export default TextTypeStipulation;
