import React from 'react';
import { NoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';

const NoBtnAlertModal = ({
  showNoBtnAlertModal,
  setShowNoBtnAlertModal,
  showNoBtnAlertModalText,
}) => {
  return (
    <NoBtnModal
      showModal={showNoBtnAlertModal}
      onClose={() => {
        setShowNoBtnAlertModal(false);
      }}
    >
      <ScrollArea>
        <Contents>{showNoBtnAlertModalText}</Contents>
      </ScrollArea>
    </NoBtnModal>
  );
};

export default NoBtnAlertModal;
