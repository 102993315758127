import React, { useState } from 'react';
import { deleteDataRequest, getDataRequest } from 'utils/request';
import FeatherIcon from 'feather-icons-react';
import { NoBtnModal, TwoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import theme from 'styles/theme';
import {
  QnACardRowDetail,
  QnACard,
  QnACardColumn,
  QnACardStatus,
  QnADiv,
  QnAImg,
  QnATitle,
  QnADetailTitle,
  QnACardRow,
  QnACardRowSecond,
  OpenButton,
  CloseButton,
  DeleteButton,
  ContentBox1,
  ContentBox2,
  ContentRow,
  ContentRowTitle,
  ContentData,
  ContentDataText,
} from '../style';
import { QNA_CARD_STATUS_SETTING } from '../constants';

const AuthQuestion = ({ simpleQnAData }) => {
  const { manToManInquirySeq, answerStatus } = simpleQnAData;
  const QnACardStatusData = QNA_CARD_STATUS_SETTING.find(
    cardSetting => cardSetting.type === answerStatus
  );
  //디테일 페이지 상태
  const [isDetailShow, setIsDetailShow] = useState(false);
  // 상세 컴포넌트
  const [detailQnAData, setDetailQnAData] = useState({});
  // 모달창
  const [alertMessage, setAlertMessage] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onClickDeleteBtn = () => {
    setShowDeleteModal(true);
  };

  // 문의 상세 펼치기
  const onClickOpenDetailBtn = manToManInquirySeq => {
    const successFnc = () => {
      setIsDetailShow(true);
    };
    getDataRequest({
      url: `/v1/au/qna/${manToManInquirySeq}`,
      setData: setDetailQnAData,
      successFnc: successFnc,
      setModalMessage: setAlertMessage,
    });
  };

  const renderNoBtnModal = () => {
    return (
      <NoBtnModal
        showModal={!!alertMessage}
        onClose={() => setAlertMessage('')}
      >
        <ScrollArea>
          <Contents>{alertMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    );
  };

  const renderTwoBtnModal = () => {
    const onClickDelete = async () => {
      await deleteDataRequest({
        url: `/v1/au/qna/${manToManInquirySeq}`,
        successFnc: () => window.location.reload(),
        setModalMessage: setAlertMessage,
      });
      setShowDeleteModal(false);
    };

    return (
      <TwoBtnModal
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onClick={onClickDelete}
        leftButton="아니오"
        rightButton="예"
      >
        <ScrollArea>
          <Contents>문의 건을 삭제하시겠어요?</Contents>
        </ScrollArea>
      </TwoBtnModal>
    );
  };

  return (
    <>
      {/* QnA 컴포넌트 */}
      <QnACard>
        <QnACardColumn>
          {isDetailShow ? (
            <QnADetailComp
              QnAData={detailQnAData}
              QnACardStatusData={QnACardStatusData}
              onClickDeleteBtn={onClickDeleteBtn}
              setIsDetailShow={setIsDetailShow}
            />
          ) : (
            <QnASimpleComp
              QnAData={simpleQnAData}
              QnACardStatusData={QnACardStatusData}
              onClickOpenDetailBtn={onClickOpenDetailBtn}
            />
          )}
        </QnACardColumn>
      </QnACard>
      {/* 삭제 확인 모달  */}
      {renderTwoBtnModal()}
      {/* 오류 alert 모달  */}
      {renderNoBtnModal()}
    </>
  );
};

const QnASimpleComp = ({
  QnAData = {},
  QnACardStatusData = {},
  onClickOpenDetailBtn = () => {},
}) => {
  const { inquirySubject, manToManInquirySeq, inquiryDt } = QnAData;
  const { color, backgroundColor, text } = QnACardStatusData;

  return (
    <>
      <QnACardRow>
        <QnATitle>{inquirySubject}</QnATitle>
        <QnADiv>
          <QnACardStatus color={color} backgroundColor={backgroundColor}>
            {text}
          </QnACardStatus>
          <OpenButton onClick={() => onClickOpenDetailBtn(manToManInquirySeq)}>
            <FeatherIcon icon="chevron-down" width="20px" height="20px" />
          </OpenButton>
        </QnADiv>
      </QnACardRow>

      <QnACardRow>
        <QnACardRowSecond color={theme.color.grey9}>
          {inquiryDt}
        </QnACardRowSecond>
      </QnACardRow>
    </>
  );
};

const QnADetailComp = ({
  QnAData = {},
  QnACardStatusData = {},
  onClickDeleteBtn = () => {},
  setIsDetailShow,
}) => {
  const [isContentsShow, setIsContentsShow] = useState(
    !QnAData?.result?.filePathList > 0
  );
  const { result = {}, qnaAnswerList } = QnAData;
  const { inquirySubject, inquiryContents, filePathList, inquiryDt } = result;
  const { color, backgroundColor, text } = QnACardStatusData;
  return (
    <>
      <QnACardRowDetail>
        <QnADetailTitle>{inquirySubject}</QnADetailTitle>
        <QnADiv>
          <QnACardStatus color={color} backgroundColor={backgroundColor}>
            {text}
          </QnACardStatus>
          <CloseButton onClick={() => setIsDetailShow(false)}>
            <FeatherIcon icon="chevron-up" width="20px" height="20px" />
          </CloseButton>
        </QnADiv>
      </QnACardRowDetail>

      <ContentBox1 isShow={isContentsShow}>
        <QnACardRowDetail>
          <QnACardRowSecond>{inquiryContents}</QnACardRowSecond>
        </QnACardRowDetail>
        <QnACardRow
          style={{ justifyContent: 'flex-start', margin: '10px 0px' }}
        >
          {filePathList?.length > 0 &&
            filePathList.map((item, index) => {
              return (
                <QnAImg
                  src={item}
                  alt={item}
                  key={index}
                  onLoad={() => {
                    if (index === 0) {
                      setIsContentsShow(true);
                    }
                  }}
                />
              );
            })}
        </QnACardRow>
        <QnACardRowDetail>
          <QnACardRowSecond color={theme.color.grey9}>
            {inquiryDt}
          </QnACardRowSecond>

          {!qnaAnswerList?.length > 0 && (
            <DeleteButton onClick={onClickDeleteBtn}>삭제</DeleteButton>
          )}
        </QnACardRowDetail>
      </ContentBox1>

      {isContentsShow &&
        qnaAnswerList?.length > 0 &&
        qnaAnswerList.map(qnaAnswer => (
          <ContentBox2>
            <ContentRow>
              <ContentRowTitle>셀러 크레딧</ContentRowTitle>
            </ContentRow>
            <ContentData>
              <ContentDataText>{qnaAnswer.answerContents}</ContentDataText>
            </ContentData>
            <QnACardRowDetail
              style={{
                margin: '5px 0 0',
              }}
            >
              <QnACardRowSecond color={theme.color.grey9}>
                {qnaAnswer.answerDt}
              </QnACardRowSecond>
            </QnACardRowDetail>
          </ContentBox2>
        ))}
    </>
  );
};

export default AuthQuestion;
