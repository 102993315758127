export const LOAN_TYPE_PROCESS = [
  {
    type: 'guarantee',
    title: '담보 대출 비교하기',
    context:
      '신용점수 영향 없이 대출 상품 확인이 가능해요. 판매몰이 많을수록 이용할 수 있는 데이터가 많아 대출 한도에 유리해요.',
  },
  {
    type: 'credit',
    title: '신용 대출 비교하기',
    context:
      '복잡한 서류 제출 없이, 신용정보를 활용하여 대출 상품 확인이 가능해요.\n*대출비교시 신용점수에는 영향이 가지 않아요.',
  },
];
