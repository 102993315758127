import styled from 'styled-components';
import theme from 'styles/theme';

export const BlueInfoBoxWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 16px;
  margin-top: 10px;
  background-color: ${theme.backgroundColor.lightIcyBlye};
  border-radius: 10px;
  font-size: 1.2rem;
  color: ${theme.color.infoBlue};
  cursor: pointer;
`;

export const BlueInfoBoxText = styled.div`
  background-color: ${theme.backgroundColor.lightIcyBlye};
  border-radius: 10px;
  font-size: 1.2rem;
  color: ${theme.color.infoBlue};
`;

export const ArrowButton = styled.button`
  width: 11px;
  height: 11px;
  background: url(/images/icons/dropdown/main_ar_s2.png) right no-repeat;
  background-size: 6px 11px;
`;
