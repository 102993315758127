import styled from 'styled-components';
import theme from 'styles/theme';

export const TextMedium = styled.div`
  padding: 41px 0 18px;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
  text-align: center;
`;
