import React from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { NoBtnModal } from 'components/Modal';
import Card from '../Card';
import CardHeader from '../CardHeader';
import { Contents, ScrollArea } from 'components/Modal/style';
import {
  CheckCardText,
  Text,
} from 'components/SelectMallModal/SelectMall/style';
import { BlueLink } from 'pages/MyPage/DataLinkage/DataLinkageRequest/style';

const InWaitMallModal = ({
  showInWaitMallModal,
  setShowInWaitMallModal,
  mallInterlockStatusInWait,
}) => {
  const navigate = useNavigate();

  const onClickBlueLink = () => {
    navigate(ROUTE_PATH.mypage.dataLinkageManage);
  };

  return (
    <NoBtnModal
      showModal={showInWaitMallModal}
      title="연동대기 판매몰"
      onClose={() => setShowInWaitMallModal(false)}
    >
      <ScrollArea>
        <Contents>
          <CardHeader mallCount={mallInterlockStatusInWait?.length} />
          {mallInterlockStatusInWait?.map((item, index) => (
            <Card
              key={`FoldedCard_mall_${item.mallSeq}_${index}`}
              index={index}
              item={item}
              showInWaitMallModal={showInWaitMallModal}
            />
          ))}
          <CheckCardText style={{ margin: '30px 0 0', display: 'flex' }}>
            <Text style={{ marginRight: '3px' }}>*</Text>
            <Text>
              판매몰 연동이 진행중이에요. 최대 10분 이내로 대출 가능한
              판매몰인지 확인할 수 있어요. 상세 진행상태는{' '}
              <BlueLink
                onClick={onClickBlueLink}
                target="_blank"
                tabIndex={0}
                title="데이터 연동 관리 페이지로 이동"
              >
                데이터 연동 관리
              </BlueLink>
              를 확인해주세요.
            </Text>
          </CheckCardText>
        </Contents>
      </ScrollArea>
    </NoBtnModal>
  );
};

export default InWaitMallModal;
