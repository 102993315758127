import styled, { keyframes } from 'styled-components';
import theme from 'styles/theme';
import { ModalWrapper } from 'components/Modal/style';

export const CenteredWrapper = styled.div`
  position: relative;
  transform: ${props =>
    props.transform ? `${props.transform}` : `translate(-50%, -50%)`};
  width: 40px;
  height: 40px;
  margin: auto;
`;

export const ModifiedModalWrapper = styled(ModalWrapper)`
  position: fixed;
  top: 50%;
  z-index: 0;
  padding: 20px 0;
  background: none;
`;

const circleFadeDelay = keyframes`
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
`;

export const Circle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${props =>
    props.rotate &&
    `
    -webkit-transform: rotate(${props.rotate}deg);
    -ms-transform: rotate(${props.rotate}deg);
    transform: rotate(${props.rotate}deg);
    `}

  &:before {
    content: '';
    display: block;
    width: 15%;
    height: 15%;
    margin: 0 auto;
    background-color: ${theme.color.grey9};
    border-radius: 100%;
    animation: ${circleFadeDelay} 1.2s infinite ease-in-out both;

    ${props =>
      props.delay &&
      `
      -webkit-animation-delay: ${props.delay}s;
      animation-delay: ${props.delay}s;
  `};
  }
`;
