import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const Header = styled.header`
  z-index: 20;

  top: ${props => (props.showBanner ? theme.layout.topBanner : 0)};
  width: 100%;
  height: 60px;
  min-width: ${theme.layout.minWidth};
  max-width: ${theme.layout.maxWidth};

  padding-top: var(--ios-safe-top);
  background: ${theme.backgroundColor.white};
  border-bottom: 1px solid ${theme.borderColor.lightGrey};

  ${props => {
    return css`
      ${getCssStrBorder(props)}
    `;
  }}
`;

const getCssStrBorder = props => {
  let cssStr = '';
  if (props.main === true) {
    cssStr += `border-bottom: none;`;
  }
  return cssStr;
};
