import styled from 'styled-components';
import theme from 'styles/theme';
import { ColoredStatusSpan } from 'styles/ColoredStatusSpan';

export const ColorSpan = styled.span`
  color: ${props =>
    props.required === true
      ? `${theme.color.blue}`
      : `${theme.color.policyGrey}`};
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.backgroundColor.lightGrey};
  margin: 20px 0 23px;
`;

export const RequiredCheckboxWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 16px;
`;

export const IconSpan = styled.span`
  position: absolute;
  top: 7px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: url(/images/icons/checkbox/check-004.png) no-repeat;
  background-size: 20px 20px;
  content: '';
`;

export const Button = styled.button`
  display: block;
  float: right;
  top: 0;
  right: 0;
  background: url(/images/icons/dropdown/main_ar_s2.png) right no-repeat;
  background-size: 6px 11px;
  padding: 0 14px 0 0;
  margin: 2px 0 0;
`;

export const ButtonSpan = styled(ColoredStatusSpan)`
  width: 32px;
  cursor: pointer;
`;

export const StipulationContents = styled.div`
  padding-left: 5px;
  margin-top: 15px;
  line-height: 2rem;
  font-size: 1.4rem;
`;
