export const LOAN_DATA_USE_MANAGE_TAB_OPTIONS = [
  {
    label: '대출',
    value: 'loan',
    tab: 'loan',
  },
  {
    label: '데이터 제공',
    value: 'dataUse',
    tab: 'dataUse',
  },
];
