import React from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import StyledLinkButton from 'components/Button/StyledLinkButton';
import { theme } from 'styles/theme';

const ShareButton = ({ setShowShareBottomPopup }) => {
  return (
    <StyledLinkButton
      tabIndex={0}
      name="shareEvent"
      onClick={() => {
        setShowShareBottomPopup(true);
      }}
      style={{
        width: '65%',
        minWidth: '150px',
        margin: '23px auto 0',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FeatherIcon
        icon="share-2"
        size={18}
        stroke={theme.color.blue}
        onClick={() => {}}
        style={{
          margin: '0 7px 3px 0',
        }}
      />
      공유하기
    </StyledLinkButton>
  );
};

export default ShareButton;
