import styled from 'styled-components';
import theme from 'styles/theme';

export const ContentSubpage = styled.div`
  position: relative;
  width: 100%;
  background: ${theme.backgroundColor.white};
`;

export const ContentTopLogo = styled.img`
  display: block;
  margin: 70px auto 43px;
  width: 240px;
  height: 18px;
`;

export const SaveIdSection = styled.div`
  display: flex;
  width: 100%;
  padding: 17px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const CheckboxLabel = styled.label`
  display: inline-block;
  position: relative;
  float: left;
  max-width: 100%;
  margin-bottom: 0;
  line-height: 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
`;

export const Checkbox = styled.input`
  position: absolute;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin: 4px 0 0;
  padding: 0;
  line-height: normal;
  outline: 1px solid ${theme.borderColor.lightGrey};
  border-radius: 10px;
  cursor: pointer;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: 2px solid ${theme.color.label};
    outline-offset: -2px;
  }

  &:not(:checked) {
    outline: 0px solid ${theme.color.label};
    outline-offset: -2px;
    background: url(/images/icons/checkbox/check-004.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }

  &:checked {
    outline: 0px solid ${theme.color.label};
    outline-offset: -2px;
    background: url(/images/icons/checkbox/check-004-cn1.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }
`;

export const CheckboxText = styled.span`
  position: relative;
  top: 5px;
  left: 20px;
  max-width: 100%;
  height: 20px;
  padding: 0px 9px;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
`;
