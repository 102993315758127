import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NoBtnModal } from 'components/Modal';
import ROUTE_PATH from 'routes/config';
import { Contents, ScrollArea } from 'components/Modal/style';
import LinkFailedModal from 'pages/MyPage/DataLinkage/DataLinkageManage/LinkFailedModal';
import DeleteLinkModal from 'pages/MyPage/DataLinkage/DataLinkageManage/DeleteLinkModal';
import {
  LINKAGE_STATUS,
  statusActions,
} from '../../LinkedMall/LinkedMallItem/constants';
import {
  DataBox,
  DataHeader,
  DataKey,
  DataName,
  DataValue,
  DataValueText,
  Delete,
  Edit,
  MapWrapper,
  ModifyEmployee,
} from '../../../../Manager/EmployeeItem/style';
import { StatusSpan } from '../../LinkedMall/LinkedMallItem/style';

const LinkedDataProviderItem = ({ item, type, tabValue }) => {
  const {
    businessDataProviderMallMappingSeq,
    dataProviderId,
    dataProviderName,
    providerInterlockStatus,
    providerInterlockStatusName,
  } = item;
  const navigate = useNavigate();
  const [isLinkFailedModalOpen, setIsLinkFailedModalOpen] = useState(false);
  const [responseModalData, setResponseModalData] = useState({
    isOpen: false,
    text: '',
  });
  const [isTwoBtnModalOpen, setIsTwoBtnModalOpen] = useState(false);
  const [isNoBtnModalOpen, setIsNoBtnModalOpen] = useState(false);

  // TODO: 대출 진행 여부 체크하는 validation (api 추후개발)
  const isOnLoan = false;
  const onClickDelete = isOnLoan => {
    // 대출 진행중이지 않은 경우
    if (!isOnLoan) {
      setIsTwoBtnModalOpen(true);
    }
    // 대출 진행중인 경우
    if (!!isOnLoan) {
      setIsNoBtnModalOpen(true);
    }
  };

  const goToEditMallPage = () => {
    navigate(ROUTE_PATH.mypage.dataLinkageModify, {
      state: { linkageType: tabValue, item: item },
    });
  };

  const renderCheckDeleteModal = isOnLoan => {
    return !isOnLoan ? (
      <DeleteLinkModal
        type={type}
        seq={businessDataProviderMallMappingSeq}
        name={dataProviderName}
        isOpenModal={isTwoBtnModalOpen}
        setIsOpenModal={setIsTwoBtnModalOpen}
        onReset={goToEditMallPage}
      />
    ) : (
      <NoBtnModal
        showModal={isNoBtnModalOpen}
        onClose={() => setIsNoBtnModalOpen(false)}
      >
        <ScrollArea>
          <Contents>
            대출이 진행중인 상태에요. 대출이 완료 후 삭제가 가능해요.
          </Contents>
        </ScrollArea>
      </NoBtnModal>
    );
  };

  return (
    <DataBox>
      <DataHeader>
        <DataName>
          {dataProviderName}
          <StatusSpan status={providerInterlockStatus}>
            {providerInterlockStatusName ||
              LINKAGE_STATUS[providerInterlockStatus] ||
              ''}
          </StatusSpan>
        </DataName>
        {/* 연결 실패 모달 */}
        <LinkFailedModal
          isOpenModal={isLinkFailedModalOpen}
          setIsOpenModal={setIsLinkFailedModalOpen}
        />
        <ModifyEmployee>
          <Edit
            onClick={goToEditMallPage}
            disabled={!statusActions[providerInterlockStatus]?.modify || false}
          >
            수정
          </Edit>
          <Delete
            onClick={() => onClickDelete(isOnLoan)}
            disabled={!statusActions[providerInterlockStatus]?.delete || false}
          >
            삭제
          </Delete>
        </ModifyEmployee>
        {renderCheckDeleteModal(isOnLoan)}
      </DataHeader>
      {/* 계정 정보 */}
      <MapWrapper>
        <DataKey>아이디</DataKey>
        <DataValue>
          <DataValueText>{dataProviderId || '-'}</DataValueText>
        </DataValue>
      </MapWrapper>
      {/* 버튼이 없는 안내 모달 */}
      <NoBtnModal
        showModal={responseModalData.isOpen}
        onClose={() => {
          setResponseModalData({ ...responseModalData, isOpen: false });
        }}
      >
        <ScrollArea>
          <Contents>{responseModalData.text}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </DataBox>
  );
};

export default LinkedDataProviderItem;
