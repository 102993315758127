import styled from 'styled-components';
import theme from 'styles/theme';

export const TitleWrapper = styled.div`
  text-align: center;
  padding: 40px;
`;

export const TitleText = styled.div`
  display: block;
  width: 100%;
  padding: 0;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: ${theme.color.black};

  white-space: pre-line;
`;

export const ContentWrapper = styled.div``;

export const ContentSectionWrapper = styled.div`
  padding: 1rem 0;
`;

export const ContentSectionTitle = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 4rem;
`;

export const ContentSectionContentWrapper = styled.div`
  padding: 1rem 0;
`;

export const ContentSectionContent = styled.div`
  font-size: 1.4rem;
`;

export const ContentSectionTextFieldAdjustment = styled.div`
  margin-top: -23px;
`;

export const CaptchaImage = styled.img`
  margin-top: 23px;
  width: 100%;
`;

export const TextFiledDescription = styled.p`
  margin-top: 23px;
  line-height: 2.3rem;
  font-size: 1.2rem;
`;

export const ContentBlueText = styled.span`
  line-height: inherit;
  font-size: inherit;
  color: ${theme.color.blue};
`;
