import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { getDataRequest } from 'utils/request';
import SelectBox from 'components/SelectBox';
import DataProvideAgreeList from './DataProvideAgreeList';
import {
  FilterMenu,
  LoanListWrap,
  TotalQuantity,
} from '../LoanManageTab/style';
import { EmptyContent, FileImage, SelectWrapper, Text } from '../style';
import { FilterWrapper } from './style';

const DataProvideTab = ({ financeInstitutionList, dataProvideStatusList }) => {
  const [dataUseTotalCount, setDataUseTotalCount] = useState(null);
  const [paginationCount, setPaginationCount] = useState({});
  const [validManagerList, setValidManagerList] = useState([]);
  const [financeInstitutionSeq, setFinanceInstitutionSeq] = useState('');
  const [dataProvideStatus, setDataProvideStatus] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  // 무한스크롤
  const [dataProvideAgreeList, setDataProvideAgreeList] = useState([]);
  const [oldList, setOldList] = useState([]);
  const [newList, setNewList] = useState([]);
  const [ref, inView] = useInView();

  // 데이터 제공 관리 목록 조회
  useEffect(() => {
    getDataProvideAgreeRequest(1);
    setPageNo(1);
    setOldList([]);
  }, [financeInstitutionSeq, dataProvideStatus]);

  useEffect(() => {
    if (inView === true && hasMore === true) {
      setTimeout(() => getDataProvideAgreeRequest(), 300);
    }
  }, [inView, hasMore]);

  useEffect(() => {
    const paging = paginationCount;

    // 데이터가 없을 때
    if (paging?.totalCount === 0) {
      setHasMore(false);
      setDataProvideAgreeList([]);
      setOldList([]);
    } else {
      // pageNo: 현재 페이지 / endPageNo: 마지막 페이지
      if (paging?.pageNo === 1) {
        setDataProvideAgreeList([...newList]);
        setOldList([...newList]);
        if (paging?.endPageNo === 1) {
          setHasMore(false);
        } else if (paging?.endPageNo > 1) {
          setHasMore(true);
          setPageNo(prev => prev + 1);
        }
      } else {
        setDataProvideAgreeList([...oldList, ...newList]);
        setOldList([...oldList, ...newList]);
        if (paging?.pageNo < paging?.endPageNo) {
          setHasMore(true);
          setPageNo(prev => prev + 1);
        } else if (paging?.pageNo === paging?.endPageNo) {
          setHasMore(false);
        }
      }
    }
  }, [paginationCount]);

  const getDataProvideAgreeRequest = initPageNo => {
    // 값이 없는 인자를 제외함
    const exceptEmptyParam = (params = {}) => {
      let result = {};
      const keyList = Object.keys(params);
      keyList.forEach(key => {
        if (!params[key]) {
          return;
        }
        result[key] = params[key];
      });
      return result;
    };

    const params = exceptEmptyParam({
      pageNo: initPageNo ? 1 : pageNo,
      dataProvideStatus,
      financeInstitutionSeq,
    });

    const successFnc = data => {
      setDataUseTotalCount(data?.totalCount);
      setNewList(data?.list);
      setPaginationCount(data?.paging || {});
    };

    getDataRequest({
      url: '/v1/au/data-provide-agree',
      params: params,
      successFnc: successFnc,
    });
  };

  return (
    <div>
      {dataUseTotalCount !== null ? (
        dataUseTotalCount > 0 ? (
          <div>
            <FilterMenu>
              <TotalQuantity>총 {paginationCount?.totalCount}개</TotalQuantity>
              <FilterWrapper hasDataProvideStatus={!!dataProvideStatus}>
                <SelectWrapper width="120px">
                  <SelectBox
                    optionList={[
                      { label: '금융기관 전체', value: '' },
                      ...financeInstitutionList,
                    ]}
                    optionHandleChange={event => {
                      setFinanceInstitutionSeq(event.target.value);
                    }}
                    name="selectStatus"
                    value={financeInstitutionSeq}
                    isNotDisabled={true}
                    noPadding={true}
                    $gray
                    $grayPadding="0 22px 0 0"
                  />
                </SelectWrapper>
                <SelectWrapper width={!!dataProvideStatus ? '100px' : '165px'}>
                  <SelectBox
                    optionList={[
                      { label: '데이터제공 상태 전체 ', value: '' },
                      ...dataProvideStatusList,
                    ]}
                    optionHandleChange={event => {
                      setDataProvideStatus(event.target.value);
                    }}
                    name="selectFinancial"
                    value={dataProvideStatus}
                    isNotDisabled={true}
                    noPadding={true}
                    $gray
                    $grayPadding="0 22px 0 0"
                  />
                </SelectWrapper>
              </FilterWrapper>
            </FilterMenu>
            {paginationCount?.totalCount > 0 && (
              <>
                <LoanListWrap>
                  {dataProvideAgreeList.map((item, index) => {
                    return (
                      <DataProvideAgreeList
                        item={item}
                        key={`CreditRequest_${item.creditRequestSeq}_${index}`}
                        financeInstitutionList={financeInstitutionList}
                        validManagerList={validManagerList}
                      />
                    );
                  })}
                </LoanListWrap>
                <div ref={ref} />
              </>
            )}
            {paginationCount?.totalCount === 0 && (
              <EmptyContent>
                <FileImage />
                <Text>검색 결과가 없습니다.</Text>
              </EmptyContent>
            )}
          </div>
        ) : (
          <EmptyContent>
            <FileImage />
            <Text>데이터 제공건이 없습니다.</Text>
          </EmptyContent>
        )
      ) : null}
    </div>
  );
};

export default DataProvideTab;
