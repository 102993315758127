import React from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { footerInfo } from 'messages/contactInfo';
import { AreaBox1 } from 'styles/styleArea';
import { PhoneAreaRow, PhoneLink } from '../style';

const PhoneService = () => {
  return (
    <AreaBox1>
      <PhoneAreaRow>
        <FeatherIcon icon="phone" fill="black" size={18} />
        <PhoneLink href={`tel:${footerInfo.phoneContact}`}>전화 상담</PhoneLink>
      </PhoneAreaRow>
    </AreaBox1>
  );
};

export default PhoneService;
