import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import qs from 'qs';
import useApp from 'hooks/useApp';
import ROUTE_PATH from 'routes/config';
import { getDataRequest } from 'utils/request';
import {
  getDeepLinkHash,
  KEY_DEEPLINKHASH,
  KEY_ACCESSID,
  KEY_INFLOW_CHANNEL,
  getAccessId,
  createMarkup,
} from 'tools/WebTool';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import FoldedCard from 'components/FoldedCard';
import { TwoBtnModal } from 'components/Modal';
import DeepLinkNoBtnModal from '../DeepLinkNoBtnModal';
import { Card, CardName } from 'components/FoldedCard/style';
import { Contents, ScrollArea, Title } from 'components/Modal/style';
import StyledLinkButton from 'components/Button/StyledLinkButton';
import { ChildTextContent2, TextButton } from './style';
import { PreviewSection } from 'styles/styleArea';
import {
  BlueBox,
  BlueBoxContents,
  BlueBoxTitle,
} from 'components/BlueAlertBox/style';
import { DEEPLINK_TYPE } from '../constants';

//이전 담보 페이지
const DeepLinkSecuredPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { auth } = useApp();
  const { login, deepLink } = ROUTE_PATH;
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [financeInstitutionName, setFinanceInstitutionName] = useState({});
  const [deepLinkContentsData, setDeepLinkContentsData] = useState({});
  const [guideList, setGuideList] = useState([]);
  const [showTwoBtnModal, setShowTwoBtnModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const deepLinkHash = getDeepLinkHash();
  // 담보 동적 예시: /data-provide-agree/secured?deepLinkHash=${deepLinkHash}
  const accessId = getAccessId();
  // 담보 정적 예시: /data-provide-agree/secured/${accessId}

  useEffect(() => {
    if (auth.authenticated) {
      auth.logout({ moveToHome: false });
      alert(
        '데이터 제공 동의를 위해 기존 계정이 로그아웃되었습니다.\n다시 로그인을 진행해 주세요.'
      );
    }

    // 동적
    if (!!location.search) {
      const deepLinkHash = searchParams.get('deepLinkHash');
      localStorage.setItem(KEY_DEEPLINKHASH, deepLinkHash);
    }
    // 정적
    if (!location.search && !!params.seq) {
      const accessId = params.seq;
      localStorage.setItem(KEY_ACCESSID, accessId);
    }
  }, [location, params, searchParams, auth]);

  //동적일 경우
  useEffect(() => {
    if (!!deepLinkHash) {
      const successFnc = data => {
        setIsLoading(false);
        localStorage.setItem(KEY_INFLOW_CHANNEL, data?.interlockKey);
        setDeepLinkContentsData(data?.deeplinkList);
        setGuideList(data?.deeplinkList?.guidanceResultList);
        setFinanceInstitutionName(data?.financeInstitutionName);
      };
      getDataRequest({
        url: `/v1/na/data-provide-agree/dynamic/deeplink-contents/${deepLinkHash}`,
        successFnc,
        setModalMessage: setAlertMessage,
      });
    }
  }, [deepLinkHash]);

  //정적일 경우
  useEffect(() => {
    if (!!accessId) {
      const successFnc = data => {
        localStorage.setItem(KEY_INFLOW_CHANNEL, accessId);
        setDeepLinkContentsData(data?.deeplinkList);
        setGuideList(data?.deeplinkList?.guidanceResultList);
        setFinanceInstitutionName(data?.financeInstitutionName);
      };
      getDataRequest({
        url: `/v1/na/data-provide-agree/static/deeplink-contents/${accessId}`,
        successFnc,
        setModalMessage: setAlertMessage,
      });
    }
  }, [accessId, params.seq]);

  const onClickDetailBtn = seq => {
    setGuideList(list =>
      list.map((item, index) =>
        index === seq
          ? { ...item, isOpen: !item.isOpen }
          : { ...item, isOpen: false }
      )
    );
  };

  const onClickLoginBtn = () => {
    const search =
      '?' +
      qs.stringify({
        'redirect-to': deepLink.agree,
      });

    navigate(login.login + search);
  };

  const onClickJoinMemberBtn = () => {
    if (!!financeInstitutionName) {
      navigate(
        `${login.joinMember}?financialInstitutionName=${financeInstitutionName}&type=${DEEPLINK_TYPE.dataProviderAgree}`
      );
    }
  };

  const onClickCustomBtn = () => {
    window.open(deepLinkContentsData.customButtonUrl, '_blank');
  };

  const renderTwoBtnModal = () => {
    return (
      <TwoBtnModal
        showModal={showTwoBtnModal}
        onClose={() => setShowTwoBtnModal(false)}
        onClick={onClickJoinMemberBtn}
        leftButton="닫기"
        rightButton="회원 가입하기"
      >
        <ScrollArea>
          <Contents textAlign="left">{`${financeInstitutionName} 고객님,
          ${financeInstitutionName}의 대출 신청을 위한
          데이터 제공동의는 아래 요건이 완료되어야 가능합니다.\n
          1. 사업자 회원가입
          2. 판매몰의 데이터 연동 완료\n
          위 내용을 완료 후 ${financeInstitutionName}에 다시 한번 문의 부탁드립니다.
          `}</Contents>
        </ScrollArea>
      </TwoBtnModal>
    );
  };

  if (!!alertMessage) {
    return (
      <DeepLinkNoBtnModal
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
      />
    );
  }

  if (isLoading === true) {
    return <></>;
  }

  // 2. 딥링크 안내 페이지 (담보)
  return (
    deepLinkContentsData && (
      <div>
        {/* 컨텐츠 1번 영역 */}
        <div style={{ marginTop: '20px' }}>
          <PreviewSection
            dangerouslySetInnerHTML={createMarkup(
              deepLinkContentsData.deeplinkContents
            )}
          />
        </div>
        {/* 컨텐츠 2번 영역 : 추가 안내*/}
        {deepLinkContentsData.additionGuidanceExposureYn === 'Y' && (
          <BlueBox>
            <BlueBoxTitle>추가 안내</BlueBoxTitle>
            <BlueBoxContents>
              <PreviewSection
                dangerouslySetInnerHTML={createMarkup(
                  deepLinkContentsData.additionGuidanceContents
                )}
              />
            </BlueBoxContents>
          </BlueBox>
        )}

        {/* 버튼 영역*/}
        {deepLinkContentsData.buttonSectionCd === '로그인' && (
          <div>
            <StyledActiveButton tabIndex={0} onClick={onClickLoginBtn}>
              로그인
            </StyledActiveButton>
            {deepLinkContentsData.customButtonExposureYn === 'Y' && (
              <StyledLinkButton onClick={onClickCustomBtn}>
                {deepLinkContentsData.customButtonName}
              </StyledLinkButton>
            )}
            <ChildTextContent2>
              아직 셀러크레딧커넥트 회원이 아니신가요?
              <TextButton
                tabIndex={0}
                title="회원가입"
                onClick={() => {
                  setShowTwoBtnModal(true);
                }}
              >
                회원가입
              </TextButton>
            </ChildTextContent2>
          </div>
        )}

        {deepLinkContentsData.buttonSectionCd === '회원가입' && (
          <div>
            <StyledActiveButton
              tabIndex={0}
              onClick={() => {
                setShowTwoBtnModal(true);
              }}
            >
              회원가입
            </StyledActiveButton>
            {deepLinkContentsData.customButtonExposureYn === 'Y' && (
              <StyledLinkButton onClick={onClickCustomBtn}>
                {deepLinkContentsData.customButtonName}
              </StyledLinkButton>
            )}
            <ChildTextContent2>
              이미 셀러크레딧커넥트 회원이신가요?
              <TextButton tabIndex={0} title="로그인" onClick={onClickLoginBtn}>
                로그인
              </TextButton>
            </ChildTextContent2>
          </div>
        )}

        {/* 그 외 안내 사항(아코디언) */}
        <Card>
          <CardName style={{ fontWeight: 500 }}>주요 안내 사항</CardName>
        </Card>
        {guideList &&
          guideList.length > 0 &&
          guideList.map((item, index) => {
            return (
              <FoldedCard
                key={`FoldedCard_${index}`}
                index={index}
                borderTop={false}
                isOpen={item.isOpen}
                title={item.subject}
                contents={item.contents}
                onClickDetailBtn={onClickDetailBtn}
              />
            );
          })}
        {renderTwoBtnModal()}
      </div>
    )
  );
};

export default DeepLinkSecuredPage;
