import styled from 'styled-components';
import theme from 'styles/theme';
import { Header } from '../style';

export const BackPageTopbarHeader = styled(Header)`
  position: fixed;
  display: flex;
  justify-content: space-between;
`;

export const LeftLayoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  padding-left: 20px;
  cursor: pointer;
  @media screen and (max-width: 375px) {
    width: 60px;
    padding-left: 10px;
  }
`;

export const RightLayoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
  width: 80px;
  padding-right: 20px;
  @media screen and (max-width: 375px) {
    width: 50px;
    padding-right: 20px;
  }
`;

export const HeaderH2 = styled.h1`
  padding: 3px 0;
  height: 60px;
  line-height: 5.7rem;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${theme.color.black};

  @media screen and (max-width: 375px) {
    letter-spacing: -0.2px;
    font-size: 1.6rem;
  }
`;

export const HiddenSpan = styled.span`
  font-size: 0;
`;
