import styled from 'styled-components';
import theme from 'styles/theme';

export const MainSectionFour = styled.div`
  position: relative;
  width: 100%;
  padding: 70px 20px 0;
`;

export const Title = styled.div`
  width: 100%;
  line-height: 2.9rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  color: ${theme.color.black};

  @media screen and (max-width: 375px) {
    line-height: 2.7rem;
    font-size: 1.6rem;
  }
`;

export const SubTitle = styled.div`
  width: 100%;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  color: ${theme.color.black};

  @media screen and (max-width: 375px) {
    line-height: 2.1rem;
    font-size: 1.2rem;
  }
`;

export const SubBoldTitle = styled(SubTitle)`
  font-weight: 700;
`;

export const MallImageSection = styled.div`
  width: 100%;
  padding: 40px 20px;
  margin: 40px 0 0;
  border: 1px solid ${theme.borderColor.lightGrey};
  border-radius: 10px;
  line-height: 0;
  text-align: center;

  @media screen and (max-width: 375px) {
    padding: 30px 20px;
  }
`;

export const MallImages = styled.img`
  display: block;
  width: 100%;
  margin: 20px auto;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MallDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.4rem;
  text-align: center;
  color: ${theme.color.black};

  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
  }
`;

export const DescriptionTag = styled.span`
  min-width: 55px;
  padding: 2px 4px;
  margin: 2px 4px;
  border: 1px solid ${theme.color.blue};
  border-radius: 6px;
  font-size: 1.1rem;
  text-align: center;
  color: ${theme.color.blue};

  @media screen and (max-width: 375px) {
    font-size: 1rem;
  }
`;
