import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const TextTitle = styled.p`
  display: block;
  width: 100%;
  padding: 41px 0 18px;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${theme.color.black};
  margin-bottom: 3px;
`;
export const TextDiv2 = styled.div`
  display: block;
  width: 100%;
  padding: 0 0 10px 3px;
  line-height: 2.4rem;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
  white-space: pre-wrap;
  color: ${theme.color.grey5};
`;

export const JoinAgreeAll = styled.div`
  display: block;
  width: 100%;
  border-radius: 10px;
  margin-top: 5px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ReasonLabel = styled.label`
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin-bottom: 0;
  padding: 4px 16px 5px 29px;
  line-height: 2.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;

  @media screen and (max-width: 375px) {
    padding-left: 30px;
  }

  @media screen and (max-width: 320px) {
    font-size: 1.4rem;
  }

  ${props => {
    if (props.className) {
      if (props.className === 'check-card') {
        return css`
          font-size: 1.5rem;
          font-weight: 400;
        `;
      }
    }
  }};
`;

export const Label = styled.label`
  display: inline-block;
  position: relative;
  float: left;
  max-width: 100%;
  margin-bottom: 0;
  padding: 6px 16px 5px 29px;
  line-height: 2.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;

  @media screen and (max-width: 375px) {
    padding-left: 30px;
  }

  @media screen and (max-width: 320px) {
    font-size: 1.4rem;
  }

  ${props => {
    if (props.className) {
      if (props.className === 'check-card') {
        return css`
          font-size: 1.5rem;
          font-weight: 400;
        `;
      }
    }
  }};
`;

export const RightLabel = styled(Label)`
  float: right;
`;

export const CheckboxInput = styled.input`
  position: absolute;
  margin: 4px 0 0;
  padding: 0;
  line-height: normal;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &:focus + .notice-check-icon {
    outline: 2px solid ${theme.color.label};
    outline-offset: -2px;
  }

  &:not(:checked) + .notice-check-icon {
    background: url(/images/icons/checkbox/check-004.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }

  &:checked + .notice-check-icon {
    background: url(/images/icons/checkbox/check-004-cn1.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }
`;

export const IconSpan = styled.span`
  position: absolute;
  top: 7px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: url(/images/icons/checkbox/check-004.png) no-repeat;
  background-size: 20px 20px;
  content: '';
`;

export const WithdrawalReasonSection = styled.div`
  margin-bottom: 30px;
`;

export const RadioInput = styled.input`
  position: absolute;
  margin: 4px 0 0;
  padding: 0;
  line-height: normal;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &:focus + .notice-check-icon {
    outline: 2px solid ${theme.color.label};
    outline-offset: -2px;
  }

  &:not(:checked) + .notice-check-icon {
    background: url(/images/icons/checkbox/radio-off2.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }

  &:checked + .notice-check-icon {
    background: url(/images/icons/checkbox/radio-a-on.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }
`;

export const RadioIconSpan = styled.span`
  position: absolute;
  top: 7px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: url(/images/icons/checkbox/radio-off2.png) no-repeat;
  background-size: 20px 20px;
  content: '';
`;

export const TextArea = styled.textarea`
  display: block;
  width: 100%;
  height: 140px;
  margin-top: 10px;
  font-size: 1.5rem;
  padding: 15px;
  border: 1px solid ${theme.color.lightGrey};
  border-radius: 5px;
  resize: none;

  &::placeholder {
    color: ${theme.color.grey9};
  }
`;

export const WithdrawalNoticeSection = styled.div`
  margin-bottom: 55px;
`;

export const ReasonTitle = styled.p`
  margin-left: 10px;
  line-height: 2.4rem;
  font-size: 1.6rem;
`;

export const ReasonExplain = styled.p`
  margin-left: 10px;
  color: ${theme.color.grey9};
  line-height: 2.4rem;
  font-size: 1.4rem;
`;

export const ReasonBox = styled.div`
  margin-bottom: 15px;
`;

export const NoticeContainer = styled.div`
  margin-top: 25px;
`;

export const NoticeTitle = styled.p`
  color: ${theme.color.blue};
  font-size: 1.5rem;
  font-weight: 600;

  @media screen and (max-width: 320px) {
    font-size: 1.4rem;
  }
`;

export const NoticeContents = styled.p`
  font-size: 1.5rem;
  margin-top: 7px;
  font-weight: 500;
  display: flex;

  @media screen and (max-width: 320px) {
    font-size: 1.4rem;
  }
`;

export const BulletSpan = styled.span``;
