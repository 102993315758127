import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDataRequest } from 'utils/request';
import { Card, CardName, CardRowFirst } from 'components/FoldedCard/style';
import { Contents } from './style';
import { StyledReactQuill } from 'styles/StyledReactQuill';

const FAQDetailPage = () => {
  const params = useParams();
  const paramsSeq = parseInt(params.seq);
  const [data, setData] = useState({});

  useEffect(() => {
    if (paramsSeq) {
      getDataRequest({
        url: `/v1/na/faq/${paramsSeq}`,
        setData: setData,
      });
    } else {
      alert('잘못된 접근 입니다.');
      window.close();
    }
  }, [paramsSeq]);

  return (
    data &&
    data.result && (
      <div>
        <Card>
          <CardName>{data.result.subject}</CardName>
          <CardRowFirst>{data.result.classificationCdNm}</CardRowFirst>
        </Card>
        <Contents>
          <StyledReactQuill value={data.result.contents} readOnly={true} />
        </Contents>
      </div>
    )
  );
};

export default FAQDetailPage;
