import React, { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'qs';
import useApp from 'hooks/useApp';
import ROUTE_PATH from 'routes/config';
import Tab from 'components/Tab';
import NoticeTab from './NoticeTab';
import QnATab from './QnATab';
import FAQTab from './FAQTab';
import { SERVICE_CENTER_TAB_OPTIONS } from './constants';

const ServiceCenter = () => {
  const { auth } = useApp();
  const isLogin = auth.authenticated;
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  const paramsTab = searchParams.get('tab');
  const tabList = SERVICE_CENTER_TAB_OPTIONS.map(({ tab }) => tab);
  const tabIndex = tabList.indexOf(paramsTab);

  useEffect(() => {
    if (!paramsTab || !tabList.includes(paramsTab)) {
      navigate({
        pathname: ROUTE_PATH.serviceCenter.main,
        search: `?tab=${SERVICE_CENTER_TAB_OPTIONS[0].tab}`,
      });
    }

    // 답변 알림으로 전송된 링크로 진입
    if (paramsTab === 'QnA') {
      if (searchParams.get('isMember') === 'Y') {
        // 회원 문의일 경우
        const redirectURL = qs.stringify({
          'redirect-to': location.pathname + location.search,
        });
        if (!isLogin) {
          navigate({
            pathname: ROUTE_PATH.login.login,
            search: '?' + redirectURL,
          });
        }
      } else if (searchParams.get('isMember') === 'N') {
        // 비회원 문의일 경우
        if (isLogin) {
          auth.logout({ moveToHome: false }); // 로그인 상태 - 로그아웃 후 1:1 문의 페이지로 이동
        }
      }
    }
  }, [paramsTab, isLogin]);

  const onChange = index => {
    navigate({
      pathname: ROUTE_PATH.serviceCenter.main,
      search: `?tab=${SERVICE_CENTER_TAB_OPTIONS[index].tab}`,
    });
  };

  const contentComponents = [
    <NoticeTab key="notice" />,
    <QnATab key="oneToOneInquiry" />,
    <FAQTab key="faq" />,
  ];

  return (
    <Tab
      options={SERVICE_CENTER_TAB_OPTIONS}
      tabIndex={tabIndex}
      onChange={onChange}
      contentComponents={contentComponents}
      secondary={true}
    />
  );
};

export default ServiceCenter;
