import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { ChildTextRow, ChildTextContent } from './style';

const GoBackToPrev = ({
  onClickReset = () => {},
  text = '처음으로 돌아가기',
}) => {
  return (
    <ChildTextRow>
      <FeatherIcon icon="chevron-left" size={20} />
      <ChildTextContent onClick={onClickReset}>{text}</ChildTextContent>
    </ChildTextRow>
  );
};

export default GoBackToPrev;
