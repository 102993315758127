import React from 'react';
import {
  NextButton,
  PageSpan,
  Pagination,
  PaginationWrapper,
  PrevButton,
} from './style';

const HeadlessPagination = ({ page, setPage, paginationData }) => {
  return (
    <PaginationWrapper>
      <Pagination
        currentPage={page}
        setCurrentPage={setPage}
        totalPages={paginationData.totalPageCount || 1}
        // edgePageCount={15}
        // middlePagesSiblingCount={1}
      >
        <PrevButton>{'<'}</PrevButton>
        <PageSpan>
          {page + 1}/{paginationData.totalPageCount || 1}
        </PageSpan>
        <NextButton>{'>'}</NextButton>
      </Pagination>
    </PaginationWrapper>
  );
};

export default HeadlessPagination;
