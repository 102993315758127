import React from 'react';
import ROUTE_PATH from 'routes/config';
import { ESSENTIAL_MALL_LINKAGE_MESSAGE } from '../constants';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { useNavigate } from 'react-router-dom';
import BlueAlertBox from 'components/BlueAlertBox';
import { BlueAlertBoxTitle } from '../LinkedMall/LinkedMallItem/style';
import { KEY_DATAPROVIDEAGREESEQ, KEY_DEEPLINKHASH } from 'tools/WebTool';

const DataLinkageStatusMessageBox = ({
  essentialMallList = [],
  dataProvideAgreeSeq = 0,
  deepLinkHash = '',
}) => {
  const navigate = useNavigate();

  const isCompleted = essentialMallList.every(
    item => item.mallInterlockStatus === 'FINISH'
  );

  const isCompletedOrWait = !essentialMallList.some(
    item =>
      item.mallInterlockStatus === 'STOP' ||
      item.mallInterlockStatus === 'FAIL' ||
      item.mallInterlockStatus === 'UNAVAILABLE' ||
      item.mallInterlockStatus === 'NOT_LINKED'
  );

  const linkageStatus = isCompleted ? 'isCompleted' : 'isCompletedOrWait';

  const onClickBtn = () => {
    if (isCompleted && !!dataProvideAgreeSeq) {
      navigate(ROUTE_PATH.deepLink.agree);
      localStorage.removeItem(KEY_DEEPLINKHASH);
      localStorage.setItem(KEY_DATAPROVIDEAGREESEQ, dataProvideAgreeSeq);
      return;
    }
    if (isCompleted && !!deepLinkHash) {
      navigate(ROUTE_PATH.deepLink.agree);
      localStorage.removeItem(KEY_DATAPROVIDEAGREESEQ);
      localStorage.setItem(KEY_DEEPLINKHASH, deepLinkHash);
      return;
    }
    window.location.reload();
  };

  return (
    (isCompleted || isCompletedOrWait) && (
      <BlueAlertBox
        title={
          <BlueAlertBoxTitle
            style={{
              fontSize: '1.2rem',
              lineHeight: '2.1rem',
              marginBottom: '-2px',
            }}
          >
            {ESSENTIAL_MALL_LINKAGE_MESSAGE[linkageStatus]}
            <StyledActiveButton
              $small
              round
              height="31px"
              tabIndex={0}
              onClick={onClickBtn}
              style={{ marginTop: '10px', fontSize: '1.2rem', width: '125px' }}
            >
              {isCompleted ? '데이터 제공 동의하기' : '새로고침'}
            </StyledActiveButton>
          </BlueAlertBoxTitle>
        }
      />
    )
  );
};

export default DataLinkageStatusMessageBox;
