import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const ContentBox2 = styled.div`
  display: block;
  width: 100%;
  padding: 25px 19px;
  margin: 23px 0 0;
  border-radius: 10px;
  background: ${theme.backgroundColor.contentBox};
`;

export const ContentRow = styled.div`
  display: block;
  width: 100%;
  padding: 0 0 12px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ContentRowTitle = styled.div`
  float: left;
  width: auto;
  line-height: 2.1rem;
  font-size: 1.6rem;
  font-weight: 700;
  color: ${theme.color.black};
`;

export const ContentTitle = styled.div`
  display: block;
  width: 100%;
  margin: 19px 0 0;
  line-height: 2.1rem;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.navyBlue};

  &:first-child {
    margin: 0;
  }
`;

export const ContentData = styled.div`
  display: block;
  width: 100%;
  margin: 3px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ContentDataText = styled.div`
  float: left;
  display: block;
  line-height: 2.1rem;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  color: ${theme.color.black};
`;

export const ContentButton = styled.button`
  float: right;
  height: 23px;
  padding: 0px 12px 0 0;
  line-height: 2.1rem;
  font-size: 1.3rem;
  text-align: right;
  color: ${theme.color.button};
  background: url(/images/icons/dropdown/main_ar_s2.png) right no-repeat;
  background-size: 6px 11px;

  ${props => {
    if (props.className) {
      if (props.className === 'btn-c-more2') {
        return css`
          height: 21px;
          line-height: 1.9rem;
        `;
      }
      if (props.className === 'btn-c-more3') {
        return css`
          float: left;
          margin: 30px 0 0;
        `;
      }
    }
  }}
`;

export const ToggleWrap = styled.div`
  display: block;
  width: 100%;
  margin: 19px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ToggleTitle = styled.div`
  display: block;
  float: left;
  height: 30px;
  line-height: 3rem;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.grey6};
`;

export const ToggleInput = styled.input`
  display: none;
  width: 0;
  height: 0;
`;

export const ToggleLabel = styled.label`
  display: block;
  position: relative;
  float: right;
  width: 52px;
  height: 30px;
  border-radius: 15px;
  background: ${theme.backgroundColor.labelToggleGrey};

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 5px;
    left: 5px;
    border-radius: 10px;
    background: ${theme.backgroundColor.white};
  }

  ${props => {
    if (props.checked) {
      return css`
        background: ${theme.backgroundColor.blue};
        &:after {
          content: '';
          position: absolute;
          top: 5px;
          left: auto;
          right: 5px;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          background: ${theme.backgroundColor.white};
        }
      `;
    }
  }}
`;
