import styled from 'styled-components';
import theme from 'styles/theme';
import { ColoredStatusSpan } from 'styles/ColoredStatusSpan';

export const TextMedium = styled.div`
  width: 100%;
  padding: 41px 0 18px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  text-align: center;
  color: ${theme.color.black};
`;

export const ColorSpan = styled.span`
  color: ${props =>
    props.required === false
      ? `${theme.color.policyGrey}`
      : `${theme.color.blue}`};
`;

export const TextLight = styled.div`
  width: 100%;
  padding: 12px 0 18px;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.1rem;
  text-align: center;
  color: ${theme.color.black};
`;

export const TextLeftAlign = styled(TextLight)`
  text-align: left;
  font-weight: 500;
  word-break: keep-all;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.backgroundColor.lightGrey};
  margin: 20px 0 23px;
`;

export const RequiredCheckboxWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 16px;
`;

export const IconSpan = styled.span`
  position: absolute;
  top: 7px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: url(/images/icons/checkbox/check-004.png) no-repeat;
  background-size: 20px 20px;
  content: '';
`;

export const Button = styled.button`
  display: block;
  float: right;
  top: 0;
  right: 0;
  background: url(/images/icons/dropdown/main_ar_s2.png) right no-repeat;
  background-size: 6px 11px;
  padding: 0 14px 0 0;
  margin: 2px 0 0;
`;

export const ButtonSpan = styled(ColoredStatusSpan)`
  width: 32px;
`;

export const TextButton = styled.button`
  display: block;
  margin: 28px auto 0;

  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  text-decoration: underline;
`;
