import styled from 'styled-components';
import theme from 'styles/theme';

export const Contents = styled.div`
  margin-top: 20px;
  width: 100%;
  line-height: 2.2rem;
  font-size: 1.5rem;

  li {
    margin-left: 19px;
  }

  a {
    text-decoration: underline;
  }

  img {
    max-width: 320px;
    margin: 5px 0px;
    @media screen and (max-width: 320px) {
      max-width: 100%;
    }
  }
`;
