export const MAll_DATA_TAB_OPTIONS = [
  {
    index: 0,
    label: '대출가능',
    greyInfoBoxTitle: '대출가능 몰/총 정산예정금',
    noDataText: '대출가능한 판매몰이 없습니다.',
    noDataBySearchText: '검색 결과가 없습니다.',
  },
  {
    index: 1,
    label: '대출불가',
    greyInfoBoxTitle: '대출불가 몰',
    noDataText: '대출불가한 판매몰이 없습니다.',
    noDataBySearchText: '검색 결과가 없습니다.',
  },
];
