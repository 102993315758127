import React from 'react';
import { AreaBox1 } from 'styles/styleArea';
import { PhoneAreaRow, PhoneLink } from '../style';

const OneToOneService = ({ onClick = () => {} }) => {
  return (
    <AreaBox1>
      <PhoneAreaRow>
        <PhoneLink onClick={onClick}>1:1 문의하기</PhoneLink>
      </PhoneAreaRow>
    </AreaBox1>
  );
};

export default OneToOneService;
