import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const ToggleWrap = styled.div`
  display: block;
  width: 100%;
  margin: 19px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ToggleTitle = styled.div`
  display: block;
  float: left;
  height: 30px;
  line-height: 3rem;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.grey6};
`;

export const ToggleInput = styled.input`
  display: none;
  width: 0;
  height: 0;
`;

export const ToggleLabel = styled.label`
  display: block;
  position: relative;
  float: right;
  width: 52px;
  height: 28px;
  border-radius: 15px;
  background: ${theme.backgroundColor.labelToggleGrey};
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    top: 5px;
    left: 5px;
    border-radius: 10px;
    background: ${theme.backgroundColor.white};
  }

  @media screen and (max-width: 375px) {
    width: 38px;
    height: 22px;

    &:after {
      width: 12px;
      height: 12px;
    }
  }

  ${props => {
    if (props.checked) {
      return css`
        background: ${theme.backgroundColor.blue};
        &:after {
          content: '';
          position: absolute;
          top: 5px;
          left: auto;
          right: 5px;
          width: 18px;
          height: 18px;
          border-radius: 10px;
          background: ${theme.backgroundColor.white};
        }

        @media screen and (max-width: 375px) {
          width: 34px;
          height: 22px;

          &:after {
            width: 12px;
            height: 12px;
          }
        }
      `;
    }
  }}
`;
