import React from 'react';
import { useLocation } from 'react-router-dom';
import AizenFooter from './AizenFooter';
import { AIZEN_FOOTER_PATH } from './constants';

const Footer = () => {
  const location = useLocation();
  const { pathname } = location;

  return AIZEN_FOOTER_PATH.includes(pathname) ? <AizenFooter /> : null;
};

export default Footer;
