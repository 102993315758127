import React from 'react';
import { modifyDataRequest } from 'utils/request';
import { OneBtnModal } from 'components/Modal';
import { Contents, NoScrollArea } from 'components/Modal/style';
import { TextBox } from 'pages/Event/EventDetailPage/style';
import { CheckCards } from 'components/SelectMallModal/RenderMall/style';
import {
  CheckCard,
  CheckCardDiv,
  CheckCardText,
  CheckCardTitleText,
} from 'components/SelectManager/SelectManagerRow/style';
import { TextSpanLight } from 'pages/MyPage/MyPageMain/style';

const ExportModal = ({
  showExportModal,
  exportModalInfo,
  toggleExportModal,
  successFnc,
  setAlertModal,
  setAlertMessage,
}) => {
  const {
    businessName,
    businessManagerSeq,
    managerName,
    position,
    department,
    mobilePhone,
  } = exportModalInfo;
  const onClickButton = () => {
    const errorFnc = () => {
      toggleExportModal();
    };
    modifyDataRequest({
      url: `/v1/au/manager/export/${businessManagerSeq}`,
      successFnc,
      errorFnc,
      serverErrorFnc: errorFnc,
      setModalMessage: setAlertMessage,
      setShowModal: setAlertModal,
    });
  };

  return (
    !!exportModalInfo && (
      <OneBtnModal
        showModal={showExportModal}
        onClose={toggleExportModal}
        onClick={onClickButton}
        btnContent="회원 내보내기"
      >
        <NoScrollArea>
          <Contents fontWeight={400}>
            <TextSpanLight>{managerName}</TextSpanLight>님을
            <br />
            내보내기를 하시겠어요?
            <br />
            내보내기시 <TextSpanLight>{businessName}</TextSpanLight>와의
            <br />
            연결이 즉시 끊어집니다.
          </Contents>

          <CheckCards>
            <CheckCard>
              <CheckCardDiv>
                <CheckCardTitleText style={{ paddingLeft: '15px' }}>
                  {managerName}
                </CheckCardTitleText>
                <CheckCardTitleText style={{ paddingRight: '15px' }}>
                  {!!mobilePhone && mobilePhone}
                </CheckCardTitleText>
              </CheckCardDiv>
              <CheckCardText style={{ paddingLeft: '15px' }}>
                {department}
                {department && position && '/'}
                {position}
              </CheckCardText>
            </CheckCard>
          </CheckCards>
          <TextBox style={{ margin: '25px 10px' }}>
            <CheckCardText style={{ padding: '0' }}>
              회원 내보내기시 즉시 사업자와의 연결이 끊어집니다.
              <br />
              사업자와의 연결이 끊어진 회원은 사이트의 사용이 제한됩니다.
              <br />
              회원탈퇴는 회원이 직접 탈퇴를 진행해주셔야 합니다.
              <br />
            </CheckCardText>
          </TextBox>
        </NoScrollArea>
      </OneBtnModal>
    )
  );
};

export default ExportModal;
