import React, { useState } from 'react';
import CheckPassword from '../CheckPassword';
import MemberWithdrawalPage from './MemberWithdrawalPage';

const Withdrawal = () => {
  const [step, setStep] = useState(1);

  return (
    <>
      {step === 1 && <CheckPassword setStep={setStep} />}
      {step === 2 && <MemberWithdrawalPage />}
    </>
  );
};

export default Withdrawal;
