import React from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { ClosePageTopbarHeader, CloseButton, HeaderH1 } from './style';

const ClosePageTopbar = ({ pageTitle }) => {
  return (
    <ClosePageTopbarHeader>
      <CloseButton
        onClick={() => {
          window.close();
        }}
        tabIndex={0}
        title="이 화면 닫기"
      >
        <FeatherIcon icon="x" size={21} strokeWidth={2.2} />
      </CloseButton>
      <HeaderH1>{pageTitle}</HeaderH1>
    </ClosePageTopbarHeader>
  );
};

export default ClosePageTopbar;
