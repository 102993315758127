import React from 'react';
import {
  Label,
  CheckboxInput,
  IconSpan,
} from 'pages/MyPage/MyInform/MemberWithdrawal/MemberWithdrawalPage/style';
import { CheckCard, CheckCardText, CheckCardDiv } from '../style';

const SelectMallRow = ({
  index = 0,
  mall = {},
  checkStatusList = [],
  onChangeCheckBox = () => {},
}) => {
  const {
    mallName = '',
    dataProviderScheduledAmtList = [],
    mallSeq = 0,
    mallAccountId = '',
  } = mall;

  return (
    <CheckCard>
      <CheckCardDiv>
        <Label className="check-card" htmlFor={`check-card_${index}`}>
          <CheckboxInput
            type="checkbox"
            className="check-card"
            id={`check-card_${index}`}
            checked={
              checkStatusList.find(
                item =>
                  `${item.mallSeq}_${item.mallAccountId}` ===
                  `${mallSeq}_${mallAccountId}`
              )?.isChecked || false
            }
            onChange={onChangeCheckBox}
            name={mallAccountId}
            value={mallSeq}
          />
          {mallName}
          <IconSpan className="notice-check-icon" />
        </Label>
      </CheckCardDiv>
      <CheckCardText key={index}>아이디 {mallAccountId}</CheckCardText>

      {dataProviderScheduledAmtList?.map((item, index) => {
        return (
          <CheckCardText key={index} bold={true}>
            정산 예정금 {item?.scheduledSettlementAmt?.toLocaleString() || 0}원
          </CheckCardText>
        );
      })}
    </CheckCard>
  );
};

export default SelectMallRow;
