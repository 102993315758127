import { useState, useEffect } from 'react';

const useInfiniteScroll = (
  initialList = [],
  getList = () => {},
  paginationData = {
    articleCount: 15,
    totalCount: 0,
    pageNo: 0,
    endPageNo: 0,
  },
  inView = false
) => {
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  // 검색
  const onClickSearchButton = () => {
    setPage(1);
    getList(1);
  };

  // 목록 조회
  useEffect(() => {
    if (inView && hasMore) {
      setTimeout(() => getList(page), 500); // 스크롤시 데이터 즉각적으로 보이는것보다 약간의 시차가 발생하는게 더 자연스러움
    }
  }, [inView, hasMore]);

  useEffect(() => {
    const list = initialList;
    const paging = paginationData;

    // 리스트 데이터가 없을 때
    if (list.length === 0) {
      setHasMore(false);
      setList([]);
    } else {
      // pageNo: 현재 페이지 / endPageNo: 마지막 페이지
      if (paging?.pageNo === 1) {
        setList(list);
        if (paging?.endPageNo === 1) {
          setHasMore(false);
        } else if (paging?.endPageNo > 1) {
          setHasMore(true);
          setPage(prev => prev + 1);
        }
      } else {
        setList(prev => [...prev, ...list]);
        if (paging?.pageNo < paging?.endPageNo) {
          setHasMore(true);
          setPage(prev => prev + 1);
        } else if (paging?.pageNo === paging?.endPageNo) {
          setHasMore(false);
        }
      }
    }
  }, [initialList, paginationData]);

  return { list, onClickSearchButton };
};

export default useInfiniteScroll;
