import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const TextMedium = styled.div`
  padding: 41px 0 18px;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
  text-align: center;
`;

export const TextareaDiv = styled.div`
  position: relative;
`;

export const TextareaField = styled.textarea`
  width: 100%;
  margin: 23px 0 0;
  padding: 14px;
  line-height: 2.2rem;
  font-size: 1.3rem;
  font-weight: 500;
  border: ${props =>
    props.showMessage ? `1px solid ${theme.borderColor.blue};` : 'transparent;'}
  border-radius: 10px;
  background: ${theme.backgroundColor.form};
  color: ${theme.color.black};

  ${props => {
    if (props.uppercase) {
      return css`
        text-transform: uppercase;
      `;
    }
  }}

  &:focus {
    border: 1px solid ${theme.borderColor.blue};
  }

  ::placeholder {
    color: ${theme.color.placeholder};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  position: relative;
`;

export const InputLabel = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 5px;
  right: 10px;
  color: ${theme.color.placeholder};
  font-size: 1.3rem;
  line-height: 2rem;
`;

export const ImageTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 23px 0 0;
  width: 100%;
`;

export const ImageField = styled.div`
  display: flex;
  width: 100%;
  min-height: 80px;
  padding: 13px;
  background: ${theme.backgroundColor.contentBox};
  border-radius: 10px;
  margin: 5px 0 0;
`;

export const ImageDiv = styled.div`
  margin-left: 10px;
  font-size: 1.3rem;
  font-weight: 500;
  color: ${theme.color.black};
`;

export const ImageIcon = styled.label`
  cursor: pointer;
`;

export const ImageInput = styled.input`
  display: none;
`;

export const QnAImgDiv = styled.div`
  position: relative;
  width: 25%;
`;

export const QnAImg = styled.img`
  width: 100%;
  padding: 0 1px;
  border-radius: 5px;
`;

export const QnAImgDeleteBtn = styled.button`
  position: absolute;
  color: white;
  z-index: 10;
  top: 5px;
  right: 5px;
`;

export const SectionTitle = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 3.5rem;
`;

export const NoticeText = styled.p`
  font-size: 1.4rem;
  margin-top: 1.1rem;
  color: ${theme.color.grey7};
`;

export const CheckboxSection = styled.div`
  display: flex;
  width: 180px;
`;

export const CheckboxContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;
`;
