import React, { useEffect, useState } from 'react';
import { statusOrder } from '../LinkedMall/LinkedMallItem/constants';
import { SecondText } from '../../style';
import LinkedDataProviderItem from './LinkedDataProviderItem';

const NO_DATA_TEXT = '연동된 데이터 제공사가 없습니다.';
const LinkedDataProvider = ({
  type = '',
  data: _data = [],
  tabValue,
  additionalData,
}) => {
  return _data.length === 0 ? (
    <SecondText>{NO_DATA_TEXT}</SecondText>
  ) : (
    _data.sort(statusOrder)?.map((item, index) => {
      return (
        <LinkedDataProviderItem
          key={`linkedDataProviderItem_${item.seq}_${index}`}
          item={item}
          type={type}
          tabValue={tabValue}
        />
      );
    })
  );
};

export default LinkedDataProvider;
