import React, { useState } from 'react';
import api from 'utils/api';
import ROUTE_PATH from 'routes/config';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { encodeToBase64String } from 'tools/StringTool';
import { createDataRequest } from 'utils/request';
import Checkbox from 'components/CheckBox';
import LoadingIndicator from 'components/LoadingIndicator';
import TextField from 'components/TextField';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import CreateQuestionAuthImgRow from './CreateQuestionAuthImgRow';
import {
  TextMedium,
  TextareaDiv,
  TextareaField,
  InputLabel,
  ImageIcon,
  ImageTitle,
  ImageField,
  ImageDiv,
  ImageInput,
  SectionTitle,
  NoticeText,
  CheckboxSection,
  CheckboxContainer,
} from './style';

const CreateQuestionAuthPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // 백엔드에 보낼 params
  const [qnaData, setQnaData] = useState({
    inquirySubject: '',
    inquiryContents: '',
    fileInfoSeqList: [],
    smsYn: 'N',
    emailYn: 'N',
  });
  // 내용 count
  const [textCount, setTextCount] = useState(0);
  // 파일 리스트
  const [fileList, setFileList] = useState([]);
  // Alert Message
  const [alertMessage, setAlertMessage] = useState('');

  const onChangeText = event => {
    const { name, value } = event.target;
    if (name === 'inquiryContents') {
      setTextCount(value.length);
    }
    setQnaData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeAnswerMethodCheckbox = event => {
    const { id, checked } = event.target;
    setQnaData({
      ...qnaData,
      [id]: checked === true ? 'Y' : 'N',
    });
  };

  const goToQnAListPage = () => {
    const timeoutId = setTimeout(
      () => navigate(ROUTE_PATH.serviceCenter.main + '?tab=QnA'),
      1800
    );
    return () => clearTimeout(timeoutId);
  };

  const onChangeImg = event => {
    const tmpFileList = [];
    const files = event.target.files;
    if (!!files) {
      if (files.length + fileList.length < 5) {
        for (let i = 0; i < files.length; i++) {
          const previewURL = URL.createObjectURL(files[i]);
          tmpFileList.push({ fileObject: files[i], previewURL: previewURL });
        }
        setFileList([...fileList, ...tmpFileList]);
        event.target.value = '';
      } else {
        setAlertMessage('이미지는 4개 이하로 업로드해주세요.');
      }
    } else {
    }
  };

  const onClickPostBtn = () => {
    setIsLoading(true);
    const qnaDataParams = {
      ...qnaData,
      inquirySubject: encodeToBase64String(qnaData.inquirySubject),
      inquiryContents: encodeToBase64String(qnaData.inquiryContents),
    };
    if (fileList.length > 0) {
      const formData = new FormData();
      fileList.forEach(item => {
        formData.append(`imageFileList`, item.fileObject);
      });
      const timeoutId = setTimeout(() => {
        createQnAWithImgRequest({
          formData,
          setAlertMessage,
          qnaData: qnaDataParams,
          goToQnAListPage,
          setIsLoading,
        });
      }, 1800);
      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        createQnARequest({
          data: qnaDataParams,
          successFnc: goToQnAListPage,
          setModalMessage: setAlertMessage,
          setIsLoading: setIsLoading,
        });
      }, 1800);
      return () => clearTimeout(timeoutId);
    }
  };

  // QnA 등록
  const createQnARequest = ({
    data,
    successFnc,
    setModalMessage,
    setIsLoading,
  }) => {
    createDataRequest({
      url: `/v1/au/qna`,
      data: data,
      successFnc: successFnc,
      setModalMessage: setModalMessage,
      setIsLoading: setIsLoading,
    });
  };

  // 이미지 파일이 있는 QnA 등록
  const createQnAWithImgRequest = ({
    formData,
    setAlertMessage,
    qnaData,
    goToQnAListPage,
    setIsLoading,
  }) => {
    api({
      url: `/v1/au/file/upload-image-private-list`,
      method: 'post',
      headers: { 'content-type': 'application/json' },
      data: formData,
    })
      .then(res => {
        const { success, data } = res.data;
        if (success) {
          const fileList = data.fileList;
          const fileInfoSeq = fileList.map(item => {
            return item.fileSeq;
          });

          const QnADataWithImgFiles = {
            ...qnaData,
            fileInfoSeqList: fileInfoSeq,
          };

          setQnaData(prev => ({
            ...prev,
            ...QnADataWithImgFiles,
          }));

          createQnARequest({
            data: QnADataWithImgFiles,
            successFnc: goToQnAListPage,
            setModalMessage: setAlertMessage,
            setIsLoading: setIsLoading,
          });
        }
      })
      .catch(err => {
        console.error(err);
        const { status, data } = err.response;
        if (status === 400) {
          setAlertMessage(data.message);
        } else {
          setAlertMessage(
            '이미지가 업로드 되지 않았습니다. 다시 한 번 시도해 주세요.'
          );
        }
        setIsLoading(false);
      });
  };

  const renderNoBtnModal = () => {
    return (
      <NoBtnModal
        showModal={!!alertMessage}
        onClose={() => {
          setAlertMessage('');
        }}
      >
        <ScrollArea>
          <Contents>{alertMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    );
  };

  return (
    <div>
      <TextMedium>문의 내용을 입력해주세요.</TextMedium>
      {/* 제목, 내용 입력 */}
      <TextField
        type="text"
        title="제목"
        name="inquirySubject"
        maxLength={50}
        placeholder="제목을 입력해주세요."
        onChange={onChangeText}
        value={qnaData.inquirySubject}
      />
      <TextareaDiv>
        <TextareaField
          type="text"
          title="내용"
          name="inquiryContents"
          placeholder="내용을 입력해주세요."
          maxLength={1500}
          onChange={onChangeText}
          value={qnaData.inquiryContents}
          style={{ height: '200px' }}
        />
        <InputLabel>({textCount.toLocaleString()}/1,500)</InputLabel>
      </TextareaDiv>
      {/* 이미지 등록 */}
      <ImageTitle>
        <ImageIcon htmlFor="input-file">
          <ImageInput
            type="file"
            accept="image/*"
            id="input-file"
            multiple
            onChange={onChangeImg}
          />
          <FeatherIcon icon="camera" size={4} width="35px" height="35px" />
        </ImageIcon>
        <ImageDiv>사진 {fileList.length}/4</ImageDiv>
      </ImageTitle>
      <ImageField>
        {fileList.length > 0 &&
          fileList.map((file, index) => {
            return (
              <CreateQuestionAuthImgRow
                file={file}
                key={`createQuestionAuthImgRow_${index}`}
                setFileList={setFileList}
              />
            );
          })}
      </ImageField>
      {/* 답변 알림 방식 선택 */}
      <SectionTitle>알림 방법 선택</SectionTitle>
      <NoticeText>선택된 수단으로 답변 알림이 전송됩니다.</NoticeText>
      <CheckboxSection>
        <CheckboxContainer>
          <Checkbox
            id="smsYn"
            onChangeCheckBox={onChangeAnswerMethodCheckbox}
            text="문자"
            isChecked={qnaData['smsYn'] === 'Y'}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            id="emailYn"
            onChangeCheckBox={onChangeAnswerMethodCheckbox}
            text="이메일"
            isChecked={qnaData['emailYn'] === 'Y'}
          />
        </CheckboxContainer>
      </CheckboxSection>
      {/* 저장 버튼 */}
      <StyledActiveButton
        tabIndex={0}
        disabled={
          qnaData.inquiryContents === '' || qnaData.inquirySubject === ''
        }
        onClick={onClickPostBtn}
      >
        문의 등록하기
      </StyledActiveButton>
      {isLoading && <LoadingIndicator message="문의를 등록하고 있습니다." />}
      {renderNoBtnModal()}
    </div>
  );
};

export default CreateQuestionAuthPage;
