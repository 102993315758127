import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import SelectManagerRow from 'components/SelectManager/SelectManagerRow';
import {
  CheckCards,
  TextBox,
  TextSpan,
  ContentData,
  TextBoxSpan,
} from './style';

const SelectManager = ({ bankName, setSelectedManager, _managerList = [] }) => {
  const navigate = useNavigate();
  const { mypage } = ROUTE_PATH;
  const [managerList, setManagerList] = useState([]);
  const [checkStatusList, setCheckStatusList] = useState([]);

  const onChangeRadioButton = e => {
    const { value } = e.target;

    setCheckStatusList(prev =>
      prev.map(item =>
        +value === item.businessManagerSeq
          ? { ...item, isChecked: true }
          : { ...item, isChecked: false }
      )
    );
  };

  useEffect(() => {
    setManagerList(_managerList);
  }, [_managerList]);

  useEffect(() => {
    setCheckStatusList(
      managerList.map(item => {
        return {
          businessManagerSeq: item.businessManagerSeq,
          managerName: item.managerName,
          department: item.department,
          position: item.position,
          mobilePhone: item.mobilePhone,
          contactNo: item.contactNo,
          isChecked: false,
        };
      })
    );
  }, [managerList]);

  useEffect(() => {
    if (checkStatusList) {
      setSelectedManager(checkStatusList.find(item => item.isChecked));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkStatusList]);

  const onClickModifyPhoneNumber = () => {
    navigate(mypage.manager);
  };

  return (
    <>
      <TextBox>
        <TextSpan>{bankName}</TextSpan> 대출을 담당할 직원을 <br />
        선택해주세요.
      </TextBox>

      <CheckCards>
        {managerList &&
          managerList.length > 0 &&
          managerList.map((manager, index) => {
            const {
              businessManagerSeq,
              managerName,
              mobilePhone,
              department,
              position,
            } = manager;

            return (
              <SelectManagerRow
                key={`managerList_${businessManagerSeq}_${index}`}
                businessManagerSeq={businessManagerSeq}
                managerName={managerName}
                mobilePhone={mobilePhone}
                department={department}
                position={position}
                checkStatusList={checkStatusList}
                onChangeRadioButton={onChangeRadioButton}
              />
            );
          })}
      </CheckCards>
      <TextBox style={{ marginTop: '30px' }}>
        <ContentData style={{ fontSize: '1.3rem' }}>
          휴대폰 번호가 정확한지 확인해 주세요. <br />
          해당 연락처로 금융기관 담당자가 연락 예정입니다. <br />
          번호 수정이 필요하다면?
          <TextBoxSpan onClick={onClickModifyPhoneNumber}>
            휴대폰 번호 수정하기
          </TextBoxSpan>
        </ContentData>
      </TextBox>
    </>
  );
};

export default SelectManager;
