import React, { useEffect, useState } from 'react';
import useApp from 'hooks/useApp';
import { useParams } from 'react-router-dom';
import { getDataRequest } from 'utils/request';
import { getKoreanFormatDate } from 'tools/DateTool';
import { getDataProvideAgreeSeq } from 'tools/WebTool';
import { TabSection } from 'pages/Login/JoinMemberPolicyPopupPage/style';
import {
  PolicyDetailContent,
  PolicyDiv,
  PolicyTitle,
  PolicyBottom,
} from './style';
const DeepLinkPolicyPopupPage = () => {
  const params = useParams();
  const { auth } = useApp();
  const paramsSeq = parseInt(params.seq);
  const dataProvideAgreeSeq = getDataProvideAgreeSeq();

  const [stipulationList, setStipulationList] = useState([]);
  const stipulation = stipulationList?.find(
    item => item.stipulationSeq === paramsSeq
  );

  const getStipulationList = url => {
    const successFnc = data => {
      setStipulationList(data.stipulationList || []);
    };

    getDataRequest({
      url,
      successFnc,
    });
  };

  useEffect(() => {
    let url = '';
    if (!!dataProvideAgreeSeq) {
      url = `/v1/au/data-provide-agree/${dataProvideAgreeSeq}`;
    }
    if (!url) {
      return;
    }
    getStipulationList(url);
  }, [dataProvideAgreeSeq]);

  return (
    <TabSection>
      <PolicyTitle showBanner={auth.bannerMessage}>
        {stipulation?.stipulationCdName}
      </PolicyTitle>
      <PolicyDetailContent
        dangerouslySetInnerHTML={{
          __html:
            stipulation?.stipulationContents ||
            '<div style="display: flex; justify-content: center; align-items: center; min-height: 100px;">Not Available<div>',
        }}
      />
      <PolicyBottom>
        <PolicyDiv>부칙</PolicyDiv>
        <PolicyDiv>
          본 약관은 {getKoreanFormatDate(stipulation?.stipulationBeginDate)}부터
          적용됩니다.
        </PolicyDiv>
      </PolicyBottom>
    </TabSection>
  );
};

export default DeepLinkPolicyPopupPage;
