import React from 'react';
import {
  PolicyAgreeCheckbox,
  PolicyAgreeCheckboxIcon,
  PolicyAgreeCheckboxType,
  PolicyAgreeShow,
  PolicyAgreeShowWrapper,
  PolicyAgreeTitle,
  PolicyAgreeWrapper,
} from '../style';

const PolicyAgreeRow = ({
  item,
  togglePolicyAgree,
  agreedPolicies,
  openPopupPolicy,
  policyTypeText = '선택',
}) => {
  return (
    <PolicyAgreeWrapper>
      <PolicyAgreeTitle>
        <PolicyAgreeCheckbox
          type="checkbox"
          value={item.stipulationSeq}
          onClick={() => {
            togglePolicyAgree(item.stipulationSeq);
          }}
          checked={agreedPolicies.includes(item.stipulationSeq)}
          readOnly
        />
        <PolicyAgreeCheckboxType policyTypeText={policyTypeText}>
          [{policyTypeText}]
        </PolicyAgreeCheckboxType>{' '}
        {item.stipulationCdName}
        <PolicyAgreeCheckboxIcon />
      </PolicyAgreeTitle>
      <PolicyAgreeShowWrapper
        tabIndex={0}
        className="btn-goto-policy"
        title={`새 창 열기 : ${item.stipulationCdName}`}
      >
        <PolicyAgreeShow
          onClick={() => {
            openPopupPolicy(item.stipulationSeq);
          }}
        >
          보기
        </PolicyAgreeShow>
      </PolicyAgreeShowWrapper>
    </PolicyAgreeWrapper>
  );
};

export default PolicyAgreeRow;
