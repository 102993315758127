import { Tab as _Tab } from '@headlessui/react';
import {
  StyledTabList,
  StyledTab,
  SecondaryTabList,
  SecondaryTab,
} from './style';
const { Group, List, Panels, Panel } = _Tab;

const Tab = ({
  options = [],
  tabIndex = 0,
  setTabIndex,
  onChange = index => {},
  contentComponents = [],
  secondary = false,
}) => {
  const onChangeTab = index => {
    !!setTabIndex && typeof setTabIndex === 'function' && setTabIndex(index);
    !!onChange && typeof onChange === 'function' && onChange(index);
  };

  return (
    <Group selectedIndex={tabIndex} onChange={onChangeTab}>
      {secondary === true ? (
        <SecondaryTabList>
          {options.map((option, index) => (
            <SecondaryTab
              key={`styledTab_${index}`}
              selected={index === tabIndex}
              value={option.value}
            >
              {option.label}
            </SecondaryTab>
          ))}
        </SecondaryTabList>
      ) : (
        <StyledTabList>
          {options.map((option, index) => (
            <StyledTab
              key={`styledTab_${index}`}
              selected={index === tabIndex}
              value={option.value}
            >
              {option.label}
            </StyledTab>
          ))}
        </StyledTabList>
      )}

      <Panels>
        {contentComponents.map((content, index) => (
          <Panel key={`panel_${index}`}>{content}</Panel>
        ))}
      </Panels>
    </Group>
  );
};

export default Tab;
