import dayjs from 'dayjs';
import { leftPad } from './StringTool';

const today = new Date();

export const formatStringDate = (date, formatType) => {
  if (typeof date === 'object' && date !== null) {
    return formatStringDate(date.toISOString(), formatType);
  }
  if (!date || typeof date !== 'string' || dayjs(date).isValid() === false) {
    return '';
  }

  let format = '';
  switch (formatType) {
    case 'time':
      format = 'YYYY-MM-DD HH:mm';
      break;
    case 'second':
      format = 'YYYY-MM-DD HH:mm:ss';
      break;
    case 'trim':
      format = 'YYYYMMDD';
      break;
    case 'dot':
      format = 'YYYY.MM.DD';
      break;
    default:
      format = 'YYYY-MM-DD';
  }

  return dayjs(date).format(format);
};

export const getCurrentDate = () => {
  const year = today.getFullYear(); //yyyy
  let month = 1 + today.getMonth(); //M
  month = month >= 10 ? month : '0' + month; //month 두자리로 저장
  let day = today.getDate(); //d
  day = day >= 10 ? day : '0' + day; //day 두자리로 저장
  return year + '년 ' + month + '월 ' + day + '일'; // '0000년 00월 00일'
};

export const getStringDate = date => {
  return date
    .replace(/\s/g, '')
    .replace('년', '')
    .replace('월', '')
    .replace('일', '');
};

export const getKoreanFormatDate = date => {
  if (!date || typeof date !== 'string') {
    return;
  }
  return (
    date.split('-')[0] +
    '년 ' +
    date.split('-')[1] +
    '월 ' +
    date.split('-')[2] +
    '일'
  );
};

export const formatDate = (date, format) => {
  if (!date.valueOf()) return ' ';
  const weekKorName = [
    '일요일',
    '월요일',
    '화요일',
    '수요일',
    '목요일',
    '금요일',
    '토요일',
  ];
  const weekKorShortName = ['일', '월', '화', '수', '목', '금', '토'];
  const weekEngName = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const weekEngShortName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const d = date;
  return format.replace(
    /(yyyy|yy|MM|dd|KS|KL|ES|EL|HH|hh|mm|ss|ms|a\/p)/gi,
    function ($1) {
      switch ($1) {
        case 'yyyy':
          return d.getFullYear(); // 년 (4자리)
        case 'yy':
          return leftPad(d.getFullYear() % 1000, 2, '0'); // 년 (2자리)
        case 'MM':
          return leftPad(d.getMonth() + 1, 2, '0'); // 월 (2자리)
        case 'dd':
          return leftPad(d.getDate(), 2, '0'); // 일 (2자리)
        case 'KS':
          return weekKorShortName[d.getDay()]; // 요일 (짧은 한글)
        case 'KL':
          return weekKorName[d.getDay()]; // 요일 (긴 한글)
        case 'ES':
          return weekEngShortName[d.getDay()]; // 요일 (짧은 영어)
        case 'EL':
          return weekEngName[d.getDay()]; // 요일 (긴 영어)
        case 'HH':
          return leftPad(d.getHours(), 2, '0'); // 시간 (24시간 기준, 2자리)
        case 'hh':
          return leftPad(d.getHours() % 12 ? d.getHours() % 12 : 12, 2, '0'); // 시간 (12시간 기준, 2자리)
        case 'mm':
          return leftPad(d.getMinutes(), 2, '0'); // 분 (2자리)
        case 'ss':
          return leftPad(d.getSeconds(), 2, '0'); // 초 (2자리)
        case 'ms':
          return leftPad(d.getMilliseconds(), 3, '0'); // 밀리초 (3자리)
        case 'a/p':
          return d.getHours() < 12 ? '오전' : '오후'; // 오전/오후 구분
        default:
          return $1;
      }
    }
  );
};
