import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { modifyDataRequest } from 'utils/request';
import CustomerServiceFooter from 'layouts/Footer/CustomerServiceFooter';
import { NoBtnModal, TwoBtnModal } from 'components/Modal';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import TextField from 'components/TextField';

// style
import { Contents, ScrollArea } from 'components/Modal/style';
import {
  FirstText,
  ChildText,
  ChildTextContent,
  BlueModifySection,
  BlueModifyText,
} from 'pages/MyPage/DataLinkage/style';
import { DataLinkagePaddingWrapper } from '../../DataLinkageRequest/style';

const DataProviderModifyPage = ({
  dataProviderInfo,
  setDataProviderInfo,
  businessRegistrationNumber,
}) => {
  const navigate = useNavigate();

  const {
    dataProviderSeq,
    dataProviderId,
    dataProviderPassword,
    dataProviderName,
  } = dataProviderInfo;
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isTwoBtnModalOpen, setIsTwoBtnModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const onChangeTextField = event => {
    const { name, value } = event.target;
    !!setDataProviderInfo &&
      setDataProviderInfo(prev => ({ ...prev, [name]: value }));
  };

  // '데이터 연동 정보 수정' 버튼 클릭시
  const onClickModifyButton = () => {
    const seq = dataProviderSeq;
    const url = `/v1/au/data-provider/`;
    const data = dataProviderInfo;
    const successMessage = `${dataProviderInfo.name}의 데이터 연동 수정요청이 완료되었습니다.`;

    modifyDataRequest({
      url: `${url}/${seq}`,
      data: data,
      setModalMessage,
      setShowModal: setIsTwoBtnModalOpen,
      setShowAlertModal: setIsMessageModalOpen,
      successMessage,
    });
  };

  return (
    <DataLinkagePaddingWrapper>
      <FirstText>
        데이터 제공사 정보 수정이 필요한 경우
        <br />
        수정하기를 눌러주세요.
      </FirstText>
      <ChildText>
        <ChildTextContent>사업자 번호</ChildTextContent>
        <ChildTextContent>{businessRegistrationNumber}</ChildTextContent>
      </ChildText>
      <ChildText>
        <ChildTextContent>연동 정보</ChildTextContent>
        <ChildTextContent>데이터 제공사 연동</ChildTextContent>
      </ChildText>
      <TextField
        name="name"
        placeholder="이름"
        readOnly
        value={dataProviderName}
        style={{ margin: 0 }}
      />
      <BlueModifySection>
        <BlueModifyText onClick={() => setIsReadOnly(false)}>
          계정 정보 수정하기
        </BlueModifyText>
      </BlueModifySection>
      <TextField
        style={{ margin: 0 }}
        name="dataProviderId"
        placeholder="아이디"
        type="text"
        value={dataProviderId}
        readOnly={isReadOnly}
        onChange={onChangeTextField}
      />
      <TextField
        name="dataProviderPassword"
        placeholder="비밀번호"
        type="password"
        value={dataProviderPassword}
        readOnly={isReadOnly}
        onChange={onChangeTextField}
      />

      {!isReadOnly && (
        <StyledActiveButton
          disabled={dataProviderPassword === '' || dataProviderId === ''}
          onClick={onClickModifyButton}
        >
          데이터 연동 요청
        </StyledActiveButton>
      )}
      <CustomerServiceFooter
        hasOneToOne={true}
        hasInfo={false}
        margin="65px 0px 0px 0px"
      />
      <TwoBtnModal
        showModal={isTwoBtnModalOpen}
        onClose={() => setIsTwoBtnModalOpen(false)}
        onReset={() => {
          navigate(ROUTE_PATH.mypage.dataLinkageManage);
        }}
        onClick={() => {
          navigate(ROUTE_PATH.mypage.dataLinkageManage);
        }}
        leftButton="닫기"
        rightButton="데이터 연동 관리"
      >
        <ScrollArea>
          <Contents>{modalMessage}</Contents>
        </ScrollArea>
      </TwoBtnModal>
      <NoBtnModal
        showModal={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
      >
        <ScrollArea>
          <Contents>{modalMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </DataLinkagePaddingWrapper>
  );
};

export default DataProviderModifyPage;
