import styled from 'styled-components';
import theme from 'styles/theme';

export const ContentBox = styled.div`
  display: block;
  width: 100%;
  min-height: 330px;
`;

export const ChildText = styled.div`
  display: block;
  width: 100%;
  padding: 28px 0 20px;
`;

export const ChildTextRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0 12px;
`;

export const ChildTextSpan = styled.span`
  margin-bottom: 10px;
  margin-right: 10px;
  text-align: left;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: ${props => (!!props.bold ? '500' : '400')};

  color: ${theme.color.black};

  white-space: nowrap;

  &:not(:first-of-type) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 375px) {
    font-size: 1.4rem;
    margin-right: 5px;
  }
`;

export const ChildTextContent = styled.div`
  display: block;
  float: right;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: ${props => (!!props.bold ? '500' : '400')};

  color: ${theme.color.black};

  white-space: pre-line;

  &:not(:first-of-type) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 375px) {
    font-size: 1.4rem;
  }
`;

export const ChildTextContent2 = styled.div`
  display: block;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: left;
  line-height: ${props => (props.lineHeight ? '3.6rem' : '2.1rem')};
  color: ${theme.color.black};

  white-space: pre-line;
`;

export const LinkageGuideLink = styled.a`
  float: right;
  margin-bottom: 15px;
  font-size: 1.3rem;
  color: ${theme.color.blue};
  text-decoration: underline;
`;

export const SelectProvider = styled.select`
  position: relative;
  width: 100%;
  height: 45px;
  line-height: 4.5rem;
  padding: 0 14px 0 14px;
  margin: 0;
  border: 1px solid ${theme.borderColor.lightGrey};
  border-radius: 10px;
  background: ${theme.backgroundColor.form}
    url(/images/icons/dropdown/main_ar_s2r.png) calc(100% - 11px) center
    no-repeat;
  background-size: 12px 6px;
  color: ${theme.color.black};
  font-size: 1.3rem;
  font-weight: 500;
  vertical-align: middle;

  -webkit-appearance: none; /* Safari 및 Chrome */
  -moz-appearance: none; /* Firefox */
  appearance: none;

  ${props =>
    props.noAfter
      ? ''
      : `&:after {
    content: '';
    position: absolute;
    z-index: 2300;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    background: ${theme.backgroundColor.black};
  }`};
`;

export const SelectOption = styled.option``;

export const SelectProviderBtn = styled.button`
  position: relative;
  width: 100%;
  height: 45px;
  line-height: 4.5rem;
  padding: 0 14px 0 14px;
  margin: 0;
  border: 1px solid ${theme.borderColor.lightBlue};
  border-radius: 10px;
  background: ${theme.backgroundColor.checkBlue}
    url(/images/icons/dropdown/main_ar_s2r.png) calc(100% - 11px) center
    no-repeat;
  background-size: 12px 6px;
  color: ${theme.color.black};
  font-size: 1.3rem;
  font-weight: 500;
  vertical-align: middle;

  -webkit-appearance: none; /* Safari 및 Chrome */
  -moz-appearance: none; /* Firefox */
  appearance: none;
`;
export const BlueLink = styled.a`
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: underline;
  color: ${theme.color.blue};
`;

export const GreenQuestion = styled.div`
  margin: 23px 0 0;
  font-size: 1.3rem;
  color: ${theme.color.lightGreen};
`;

export const CapchaQuestion = styled.div`
  margin: 23px 0 0;
  font-size: 1.3rem;
`;

export const DataLinkagePaddingWrapper = styled.div`
  padding: 0 35px 0 35px;
`;
