import React from 'react';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { ChildText, ChildTextContent } from '../style';

const NoLinkedDataStatus = ({ onClickDataLinkagePage = () => {} }) => {
  return (
    <div>
      <StyledActiveButton tabIndex={0} onClick={onClickDataLinkagePage}>
        데이터 연동
      </StyledActiveButton>
      <ChildText>
        <ChildTextContent>
          연동하는 데이터가 많을수록 대출에 유리해요.
        </ChildTextContent>
      </ChildText>
    </div>
  );
};

export default NoLinkedDataStatus;
