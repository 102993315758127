import styled from 'styled-components';
import theme from 'styles/theme';

export const DataBox = styled.div`
  display: block;
  width: 100%;
  padding: 25px 19px;
  background: ${theme.backgroundColor.contentBox};
  border-radius: 10px;
  margin: 23px 0 0;
`;

export const DataHeader = styled.div`
  display: block;
  width: 100%;
  padding: 0 0 12px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const DataName = styled.div`
  display: block;
  float: left;
  text-align: left;
  width: auto;
  margin: 0;

  line-height: 2.3rem;
  font-size: 1.7rem;
  font-weight: 500;

  color: ${theme.color.blue};
`;

export const NextStepBtn = styled.button`
  float: right;
  text-align: right;
  font-size: 1.7rem;
  height: 17px;
  padding: 0 12px 0 0;

  line-height: 2.3rem;

  background: url(/images/icons/dropdown/main_ar_s2.png) right no-repeat;
  background-size: 6px 11px;
  color: ${theme.color.button};
`;

export const DataContent = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;

  white-space: pre-line;
`;
