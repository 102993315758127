import styled from 'styled-components';
import theme from 'styles/theme';

export const JoinMemberPolicyPopupDetailWrapper = styled.div`
  display: block;
  width: 100%;
  margin: 25px 0 60px;
  :after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const JoinMemberPolicyPopupDetailContent = styled.div`
  line-height: 2.4rem;

  table {
    margin-bottom: 10px;
  }
  th {
    border: 1px solid black;
    padding: 5px;
  }
  td {
    border: 1px solid black;
    padding: 5px;
  }
  img {
    max-width: 100%;
  }
  em {
    font-style: italic;
  }

  ul {
    margin-left: 7px;

    a {
      margin: 0;
      padding: 0;
      color: #02a4ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  li.list-item {
    display: list-item !important;
  }
  .title {
    font-size: 14px;
    font-weight: bold;
  }
  .padding {
    padding: 10px 20px;
  }
  .paddingTop {
    padding: 10px 0 0 0;
  }
  .bold {
    font-weight: bold;
  }
  .underline {
    text-decoration: underline;
  }
  .line {
    display: block;
    height: 1px;
    margin: 24px auto;
    border-top: 1px solid ${theme.borderColor.footer};

    &:last-child {
      border: none;
    }
  }
`;

export const JoinMemberPolicyPopupDetailLine = styled.div`
  width: 100%;
  display: block;
  height: 1px;
  margin: 30px auto;
  border-top: 1px dashed ${theme.borderColor.footer};
`;

export const JoinPolicyDiv = styled.div`
  margin: 20px 0;
  font-size: 13px;
  line-height: 23px;
  text-align: left;
  font-weight: 700;
`;

export const JoinPolicyBottom = styled.div`
  margin-top: 40px;
`;

export const JoinPolicyTitle = styled.div`
  margin: 30px 0;
  font-size: 1.7rem;
  line-height: 25px;
  text-align: center;
  font-weight: 700;
`;
