import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useApp from 'hooks/useApp';
import api from 'utils/api';
import ROUTE_PATH from 'routes/config';
import { getStringDate } from 'tools/DateTool';
import { replaceHyphen, trimSpace } from 'tools/StringTool';
import { validateOwnerName } from 'tools/ValidationTool';
import LoadingIndicator from 'components/LoadingIndicator';
import TextField from 'components/TextField';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { NoBtnModal, OneBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import {
  TextDiv,
  ContentBox,
  ContentTitle,
  ContentData,
  ContentDataText,
} from './style';

const UpdateBusiness = () => {
  const navigate = useNavigate();
  const { auth } = useApp();
  const { mypage, login } = ROUTE_PATH;
  const [isNameValidate, setIsNameValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showOneBtnModal, setShowOneBtnModal] = useState(false);
  const [showNoBtnModal, setShowNoBtnModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [businessData, setBusinessData] = useState({});
  const {
    user: { memberSeq },
    logout,
  } = auth;
  const businessNameValue = businessData.businessName || '';
  const representativeNameValue = businessData.representativeName || '';

  const onChange = e => {
    const { name, value } = e.target;
    if (name === 'representativeName') {
      validateOwnerName(value)
        ? setIsNameValidate(true)
        : setIsNameValidate(false);
      setBusinessData(prev => ({
        ...prev,
        [name]: value.toUpperCase(),
      }));
    }
    setBusinessData(prev => ({ ...prev, [name]: value }));
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      checkBusinessValidate();
    }
  };

  const onClickLogin = () => {
    logout();
    navigate(login.login);
  };

  const onClickBusinessValidate = () => {
    setIsLoading(true); // 로딩창 on
    checkBusinessValidate();
  };

  const openModal = message => {
    setIsLoading(false); // 로딩창 off
    setShowNoBtnModal(true);
    setModalMessage(message);
  };

  const checkBusinessValidate = () => {
    const params = {
      ...businessData,
      representativeName: trimSpace(
        businessData.representativeName.toUpperCase()
      ),
      businessRegistrationNumber: replaceHyphen(
        businessData.businessRegistrationNumber
      ),
      establishmentDt: getStringDate(businessData.establishmentDt),
    };
    api({
      url: '/v1/au/common/business/validate',
      method: 'get',
      headers: { 'content-type': 'application/json' },
      params,
    })
      .then(res => {
        if (res.status === 200) {
          const { success, data, message } = res.data;
          if (success) {
            const { businessRegistrationStatus } =
              data.businessRegistrationNumberValidate;
            businessRegistrationStatus
              ? sendBusinessInfo(data.businessRegistrationNumberValidate)
              : openModal(message); // 사업자정보 확인 되지 않은 케이스
          }
        }
      })
      .catch(err => {
        console.error(err);
        setIsLoading(false); // 로딩창 off
        setModalMessage(err?.response?.data?.message);
        setShowNoBtnModal(true);
      });
  };

  const sendBusinessInfo = businessInfo => {
    api({
      url: `/v1/au/member/business-info/${memberSeq}`,
      method: 'put',
      headers: { 'content-type': 'application/json' },
      data: businessInfo,
    })
      .then(res => {
        setIsLoading(false); // 로딩창 off
        const { success, message } = res.data;
        if (success) {
          // 정상 케이스 (사업자정보 변경 성공)
          navigate(mypage.myInform, { state: { message, step: 2 } });
        } else {
          // 휴폐업 케이스 (status는 200인데 success는 false)
          setShowNoBtnModal(true);
          setModalMessage(message);
        }
      })
      .catch(err => {
        console.error(err);
        const { status, data } = err.response;
        setIsLoading(false); // 로딩창 off
        if (status === 400) {
          // 대표자 변경 케이스
          setShowOneBtnModal(true);
          setModalMessage(data.message);
        }
        setModalMessage(data.message);
        setShowNoBtnModal(true);
      });
  };

  useEffect(() => {
    const getBusinessData = () => {
      api({
        url: `/v1/au/member/${memberSeq}`,
        method: 'get',
        headers: { 'content-type': 'application/json' },
      })
        .then(res => {
          const { success, data } = res.data;
          if (success) {
            setBusinessData({
              businessRegistrationNumber:
                data.result.businessRegistrationNumber,
              establishmentDt: data.result.foundationDate,
            });
          } else {
            setBusinessData({});
          }
        })
        .catch(err => {
          console.error(err);
        });
    };

    getBusinessData();
  }, [auth, memberSeq]);

  return (
    <>
      <TextDiv>
        사업자정보를 입력해주세요.
        <br />
        입력한 정보는 사업자등록증의 내용과 일치해야 합니다. 공동대표 사업자는
        사업자등록증의 주대표자를 입력해주세요.
      </TextDiv>
      <ContentBox>
        <ContentTitle>사업자번호</ContentTitle>
        <ContentData>
          <ContentDataText>
            {businessData.businessRegistrationNumber}
          </ContentDataText>
        </ContentData>
        <ContentTitle>설립일</ContentTitle>
        <ContentData>
          <ContentDataText>{businessData.establishmentDt}</ContentDataText>
        </ContentData>
      </ContentBox>
      <div>
        <TextField
          type="text"
          name="businessName"
          title="상호명 입력"
          placeholder="상호명"
          value={businessNameValue}
          onChange={onChange}
        />
        <TextField
          type="text"
          name="representativeName"
          uppercase
          maxLength={20}
          title="대표자 입력"
          placeholder="대표자"
          value={representativeNameValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />

        <StyledActiveButton
          tabIndex={0}
          onClick={onClickBusinessValidate}
          disabled={!isNameValidate}
        >
          사업자정보 인증
        </StyledActiveButton>

        <NoBtnModal
          showModal={showNoBtnModal}
          onClose={() => {
            setShowNoBtnModal(false);
          }}
        >
          <ScrollArea>
            <Contents>{modalMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>

        <OneBtnModal
          showModal={showOneBtnModal}
          onClose={() => {
            setShowOneBtnModal(false);
          }}
          onClick={onClickLogin}
          btnContent="로그인"
        >
          <ScrollArea>
            <Contents>{modalMessage}</Contents>
          </ScrollArea>
        </OneBtnModal>

        {isLoading && (
          <LoadingIndicator
            message={`잠시만 기다려주세요.\n 사업자정보를 확인하고 있어요.`}
          />
        )}
      </div>
    </>
  );
};

export default UpdateBusiness;
