import React, { useEffect, useState } from 'react';
import GreyInfoBox from './GreyInfoBox';
import BlueInfoBox from './BlueInfoBox';
import CardHeader from './CardHeader';
import CardMain from './CardMain';
import InWaitMallModal from './InWaitMallModal';

const EstimatedAmountManageTab = ({
  tabData = {},
  filteredLoansMallList = [],
  setFilterdLoansMallList = () => {},
  dataProviderList = [],
}) => {
  const {
    index,
    greyInfoBoxTitle,
    noDataText,
    noDataBySearchText,
    totalScheduledSettlementAmt,
    mallInterlockStatusInWaitCount,
    mallInterlockStatusInWait,
    mallList,
  } = tabData;

  const [mallListInFilter, setMallListInFilter] = useState([]);
  const [dataProviderListInFilter, setDataProviderListInFilter] = useState([
    ...dataProviderList,
  ]);
  const [mallSeq, setMallSeq] = useState(0);
  const [dataProviderSeq, setDataProviderSeq] = useState(0);
  const [showInWaitMallModal, setShowInWaitMallModal] = useState(false);

  const isDataByFilter = !!mallSeq || !!dataProviderSeq;

  useEffect(() => {
    const uniqueMallSeq = [...new Set(mallList?.map(item => item.mallSeq))];
    const filteredMallList = uniqueMallSeq.map(seq => {
      const item = mallList.find(item => item.mallSeq === seq);
      return { label: item.mallName, value: item.mallSeq };
    });
    setMallListInFilter([
      { label: '판매몰 전체', value: 0 },
      ...filteredMallList,
    ]);
  }, [mallList]);

  //mallSeq와 dataProviderSeq에 따른 분기처리
  useEffect(() => {
    let updatedMallList = [];

    if (!mallSeq && !dataProviderSeq) {
      updatedMallList = mallList;
    }

    if (!!mallSeq && !dataProviderSeq) {
      const filteredListByMall = mallList?.filter(
        item => item.mallSeq === mallSeq
      );
      updatedMallList = filteredListByMall;
    }

    if (!mallSeq && !!dataProviderSeq) {
      const filteredListBydataProvider = mallList?.filter(
        item => item.dataProviderSeq === dataProviderSeq
      );
      updatedMallList = filteredListBydataProvider;
    }

    if (!!mallSeq && !!dataProviderSeq) {
      const filteredListByAll = mallList?.filter(
        item =>
          item.dataProviderSeq === dataProviderSeq && item.mallSeq === mallSeq
      );
      updatedMallList = filteredListByAll;
    }

    setFilterdLoansMallList(updatedMallList);
  }, [mallSeq, dataProviderSeq]);

  const updateData = (name, isInit) => {
    const params =
      name === 'mall'
        ? { list: mallListInFilter, labelText: '판매몰 전체', seq: mallSeq }
        : {
            list: dataProviderListInFilter,
            labelText: '데이터제공사 전체',
            seq: dataProviderSeq,
          };

    const updatedList = params?.list?.map(item => {
      return item; //조건에 맞지 않는 경우 원본 항목 반환
    });

    const initList = params?.list?.map(item => {
      if (item.value === 0) {
        return { ...item, label: params?.labelText }; //value가 '0'인 경우 label 변경
      }
      return item; //조건에 맞지 않는 경우 원본 항목 반환
    });

    return isInit ? initList : updatedList;
  };

  const onChangeSelect = event => {
    const name = event.target.name;
    const value = +event.target.value;

    //label '초기값'으로 변경
    if (name === 'mall') {
      mallSeq !== 0 &&
        value === 0 &&
        setMallListInFilter(updateData(name, true));
      setMallSeq(value);
    }

    if (name === 'dataProvider') {
      dataProviderSeq !== 0 &&
        value === 0 &&
        setDataProviderListInFilter(updateData(name, true));
      setDataProviderSeq(value);
    }
  };

  const onClickSelect = event => {
    const name = event.target.name;
    const value = +event.target.value;

    //label '전체'로 변경
    if (name === 'mall') {
      mallSeq === 0 &&
        value === 0 &&
        setMallListInFilter(updateData(name, false));
    }

    if (name === 'dataProvider') {
      dataProviderSeq === 0 &&
        value === 0 &&
        setDataProviderListInFilter(updateData(name, false));
    }
  };

  return (
    <div>
      <GreyInfoBox
        title={greyInfoBoxTitle}
        totalMallCount={mallList?.length}
        totalScheduledSettlementAmt={totalScheduledSettlementAmt?.toLocaleString()}
      />
      <BlueInfoBox
        index={index}
        setShowInWaitMallModal={setShowInWaitMallModal}
        mallInterlockStatusInWaitCount={mallInterlockStatusInWaitCount}
      />
      {mallList?.length > 0 && (
        <CardHeader
          mallCount={filteredLoansMallList?.length}
          mallListInFilter={mallListInFilter}
          dataProviderListInFilter={dataProviderListInFilter}
          mallSeq={mallSeq}
          dataProviderSeq={dataProviderSeq}
          onClickSelect={onClickSelect}
          onChangeSelect={onChangeSelect}
        />
      )}
      <CardMain
        listData={filteredLoansMallList}
        noDataText={isDataByFilter ? noDataBySearchText : noDataText}
      />

      {/* 연동대기 판매몰 모달 */}
      <InWaitMallModal
        showInWaitMallModal={showInWaitMallModal}
        setShowInWaitMallModal={setShowInWaitMallModal}
        mallInterlockStatusInWait={mallInterlockStatusInWait}
      />
    </div>
  );
};

export default EstimatedAmountManageTab;
