import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import ROUTE_PATH from 'routes/config';
import { getListDataRequest } from 'utils/request';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import Card from './Card';
import { EmptyContent, FileImage, Text, TotalCount } from './style';

const EventList = ({ tabIndex = 0, url = '' }) => {
  const navigate = useNavigate();
  const [ref, inView] = useInView({
    threshold: 0,
  });
  const [eventList, setEventList] = useState([]);
  const [eventPaginationData, setEventPaginationData] = useState({});

  const getEventList = page => {
    if (!url || typeof page !== 'number') {
      return;
    }
    getListDataRequest({
      url: url,
      params: { pageNo: page },
      setData: setEventList,
      setPaginationData: setEventPaginationData,
    });
  };

  const { list: renderedEventList } = useInfiniteScroll(
    eventList,
    getEventList,
    eventPaginationData,
    inView
  );

  useEffect(() => {
    getEventList(1);
  }, []);

  const onClickDetailBtn = seq => {
    navigate(`${ROUTE_PATH.eventDetailDefault}/${seq}`);
  };

  return eventPaginationData?.totalCount > 0 ? (
    <div>
      <TotalCount>총 {eventPaginationData.totalCount}개 </TotalCount>
      {renderedEventList.map((item, index) => (
        <Card
          key={`FoldedCard_event_${item.eventSeq}_${index}`}
          isEventOpen={tabIndex === 0}
          index={index}
          seq={item.eventSeq}
          title={item.subject}
          contents={item.principalDesc}
          beginDt={item.beginDt}
          endDt={item.endDt}
          dDay={item.dday}
          onClickDetailBtn={onClickDetailBtn}
        />
      ))}
      <div ref={ref} />
    </div>
  ) : eventPaginationData?.totalCount === 0 ? (
    <EmptyContent>
      <FileImage />
      {tabIndex === 1 ? (
        <Text>종료된 이벤트가 없습니다.</Text>
      ) : (
        <Text>진행중인 이벤트가 없습니다.</Text>
      )}
    </EmptyContent>
  ) : null;
};

export default EventList;
