import { useEffect } from 'react';
import useApp from 'hooks/useApp';
import api from 'utils/api';
import qs from 'qs';
import { KEY_MEMBERCERTIFICATIONSEQ, KEY_EXPIRETIME } from 'tools/WebTool';
const IdentificationSuccessCallback = () => {
  const {
    auth: { authenticated },
  } = useApp();

  const search = window.location.search;

  useEffect(() => {
    const encodeDataObj = qs.parse(search, {
      ignoreQueryPrefix: true,
      decoder: s => s,
    });
    const encodeData = encodeDataObj.EncodeData;
    const purposeType = window.opener._identificationPurposeType || 'NONE';
    decodeData({
      postData: {
        encodeData: encodeData || '',
        isSuccess: true,
        purposeType: purposeType,
      },
      authenticated,
    });
  }, [search]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

const decodeData = ({ postData, authenticated }) => {
  const apiUrl = authenticated
    ? '/v1/au/nice-id/decode'
    : '/v1/na/nice-id/decode';
  api({
    url: apiUrl,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: postData,
  })
    .then(res => {
      const today = new Date();
      const expireTime = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59
      );

      const data = res.data;
      if (!data.success) {
        alert(data?.message || '');
      } else if (data?.data?.code !== '0000') {
        alert(data?.data?.message);
      } else if (!data?.data?.memberCertificationSeq) {
        alert('잘못된 인증 정보 입니다.\n관리자에게 문의하세요.');
      } else {
        localStorage.setItem(
          KEY_MEMBERCERTIFICATIONSEQ,
          data?.data?.memberCertificationSeq
        );
        localStorage.setItem(KEY_EXPIRETIME, expireTime);
        window.opener._identificationSuccessCallback &&
          window.opener._identificationSuccessCallback(
            data?.data?.memberCertificationSeq
          );
      }
      window.close();
    })
    .catch(err => {
      console.error(err);
      window.opener._identificationExceptCallback &&
        window.opener._identificationExceptCallback(err);
      window.close();
    });
};

export default IdentificationSuccessCallback;
