import styled from 'styled-components';
import theme from 'styles/theme';

export const JoinDescription = styled.div`
  height: 30px;
  line-height: 4.3rem;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  color: ${theme.color.blue};
`;

export const JoinText = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  color: ${theme.color.blue};
`;

export const LoginDescription = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: left;
  margin-left: 5px;
  color: ${theme.color.greyA};
`;

export const NavLogin = styled.a`
  display: block;
  width: 100%;
  height: 60px;
  line-height: 6.2rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 10px;
  color: ${theme.color.black};

  &:link,
  &:visited {
    color: ${theme.color.black};
  }

  @media screen and (max-width: 375px) {
    height: 52px;
    line-height: 5.2rem;
    font-size: 1.3rem;
  }
`;
