import styled from 'styled-components';
import theme from 'styles/theme';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import StyledLinkButton from 'components/Button/StyledLinkButton';

export const SearchBoxSection = styled.div`
  width: 100%;
`;

export const SearchRow = styled.div`
  display: flex;
  padding: 0 0 17px;
  width: 100%;
`;

export const InputNormal = styled.input`
  width: 100%;
  height: 45px;

  margin-right: 10px;
  padding: 0 14px;
  line-height: 4.5rem;
  font-size: 1.3rem;
  font-weight: 500;
  vertical-align: middle;
  border-radius: 10px;
  background: ${theme.backgroundColor.form};
  color: ${theme.color.black};

  &:focus {
    border: 1px solid ${theme.borderColor.blue};
  }

  ::placeholder {
    color: ${theme.color.placeholder};
  }

  @media screen and (max-width: 320px) {
    ::placeholder {
      font-size: 1rem;
    }
  }
`;

export const SearchBtn = styled(StyledActiveButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  width: 62px;
`;

export const ResetBtn = styled(StyledLinkButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  width: 62px;
`;
