import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getListDataRequest } from 'utils/request';
import { makeEllipsisString } from 'tools/StringTool';
import Tab from 'components/Tab';
import LoanManageTab from './LoanManageTab';
import DataProvideTab from './DataProvideTab';
import ROUTE_PATH from 'routes/config';
import { LOAN_DATA_USE_MANAGE_TAB_OPTIONS } from './constants';

const LoanDataProvideManage = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  // 검색 필터 옵션
  const [financeInstitutionList, setFinanceInstitutionList] = useState([]);
  const [creditStatusList, setCreditStatusList] = useState([]);
  const [dataProvideStatusList, setDataProvideStatusList] = useState([]);

  const paramsTab = searchParams.get('tab');
  const tabList = LOAN_DATA_USE_MANAGE_TAB_OPTIONS.map(({ tab }) => tab);
  const tabIndex = tabList.indexOf(paramsTab);

  useEffect(() => {
    if (!paramsTab || !tabList.includes(paramsTab)) {
      navigate({
        pathname: ROUTE_PATH.mypage.manageLoanDataProvide,
        search: `?tab=${LOAN_DATA_USE_MANAGE_TAB_OPTIONS[0].tab}`,
      });
    }
    getFinanceInstitutionList({ setFinanceInstitutionList });
    getCreditStatusList({ setCreditStatusList });
    getDataProvideStatusList({ setDataProvideStatusList });
  }, []);

  const onChange = index => {
    navigate({
      pathname: ROUTE_PATH.mypage.manageLoanDataProvide,
      search: `?tab=${LOAN_DATA_USE_MANAGE_TAB_OPTIONS[index].tab}`,
    });
  };

  const contentComponents = [
    <LoanManageTab
      key="loan"
      financeInstitutionList={financeInstitutionList}
      creditStatusList={creditStatusList}
    />,
    <DataProvideTab
      key="dataUse"
      financeInstitutionList={financeInstitutionList}
      dataProvideStatusList={dataProvideStatusList}
    />,
  ];

  return (
    <Tab
      options={LOAN_DATA_USE_MANAGE_TAB_OPTIONS}
      tabIndex={tabIndex}
      onChange={onChange}
      contentComponents={contentComponents}
      secondary={true}
    />
  );
};

const getFinanceInstitutionList = ({ setFinanceInstitutionList }) => {
  const successFnc = dataList => {
    setFinanceInstitutionList(
      dataList?.map(data => ({
        label: makeEllipsisString(data?.financeInstitutionName, 10),
        value: data?.financeInstitutionSeq,
      }))
    );
  };
  getListDataRequest({
    url: 'v1/au/common/finance-institution',
    successFnc,
  });
};

const getDataProvideStatusList = ({ setDataProvideStatusList }) => {
  const successFnc = dataList => {
    setDataProvideStatusList(
      dataList?.map(data => ({
        label: data?.codeName,
        value: data?.code,
      }))
    );
  };
  getListDataRequest({
    url: 'v1/au/data-provide-agree/data-provide-status',
    successFnc,
  });
};

const getCreditStatusList = ({ setCreditStatusList }) => {
  const successFnc = dataList => {
    setCreditStatusList(
      dataList?.map(data => ({
        label: data?.codeName,
        value: data?.code,
      }))
    );
  };
  getListDataRequest({
    url: 'v1/au/credit-request/credit-status',
    successFnc,
  });
};

export default LoanDataProvideManage;
